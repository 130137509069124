import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { BiLogoFacebook, BiLogoLinkedin, BiSolidCopy } from 'react-icons/bi';
import { FaIndianRupeeSign, FaXTwitter } from 'react-icons/fa6';
import { MdOutlineWhatsapp } from 'react-icons/md';
import dummy from "../../../assets/images/dummy-profile-pic.jpg";
import "./TeachersShareCards.css";
import { RiMessengerFill } from 'react-icons/ri';
import { useGetProviderDetailsQuery } from '../../../features/Auth/authApi';
import { toast } from 'react-toastify';
import SpinnerSecondary from '../../spinner/SpinnerSecondary';
import { RxCross1 } from 'react-icons/rx';
import { useDebounce } from '../../../utils/debouncer';
import { PiInstagramLogoDuotone } from 'react-icons/pi';

function TeachersShareCards({ show, handleClose, data }: any) {

    const websiteURL = new URL(window.location.href);
    const [currentURL, setCurrentUrl] = useState(`https://${websiteURL.host}/teacher-profile/?advertId=${data?.adId}`);
    const text = 'Check out my new courses in VedicHom ';
    const { data: adData, error, isLoading } = useGetProviderDetailsQuery(data?.adId, {
        skip: data?.adId == undefined || data?.adId == null
    });

    useEffect(()=>{
       if(data?.adId) setCurrentUrl(`https://${websiteURL.host}/teacher-profile/?advertId=${data?.adId}`);
    },[data])

    const CopyText = () => {
        toast.dismiss();
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard
                .writeText(currentURL)
                .then(() => {
                    toast.success("Link copied to Clipboard");
                })
                .catch((error) => {
                    toast.error("Error copying URL to clipboard");
                });
        }
        else {
            toast.error("Clipboard is not supported on your browser");
        }
    };

    const handleCopyURL = useDebounce(CopyText, 500);

    const handleShare = (url: string) => {
        setTimeout(() => {
            window.open(url, '_blank');
        }, 300);
        CopyText()
    };

    return (
        <>
            <Modal
                className='custom-modal'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={handleClose}
                animation={false}>
                <Modal.Body >
                    {(data == null || isLoading) ?
                        <SpinnerSecondary wrapperClass={'d-flex justify-content-center'} />
                        : <Container>
                            <Row className=' justify-content-end'>
                                <Button className='noOutlineBtn d-lg-none position-relative' onClick={handleClose}><RxCross1 /></Button>
                            </Row>
                            <Row>
                                <Col lg={6} className='share-tutor-card-back '>
                                    <Card className="mt-4 fav-card teacher-card max-width-320 mx-auto mb-4 ad-profile-card">
                                        <div className="fav-img overflow-hidden">
                                            <img src={adData?.data?.adDetail?.profileImage || dummy} alt="profile picture" />
                                        </div>
                                        <div className="option-fav d-flex justify-content-between align-items-center">
                                            <Button className="play-btn" variant="transparent">
                                                {/* <img src={playbutton} alt="Play button" /> */}
                                            </Button>
                                        </div>
                                        <div className="info-astrologer">
                                            <div className="text-align-center">
                                                <h2 className="ast-name mb-1">{`${adData?.data?.adDetail?.providerId?.firstName} ${adData?.data?.adDetail?.providerId?.lastName}`}</h2>
                                                <div className="rating">
                                                    <span className="total-rating mb-0">{`${adData?.data?.adDetail?.vedicSubject} Mentor`}</span>
                                                    {/* <small className="reviews">(85 Reviews)</small> */}
                                                </div>
                                                <Button className='share-price-btn mt-2'><FaIndianRupeeSign />
                                                    {`${adData?.data?.adDetail?.avgPrice} /hr`}</Button>
                                            </div>
                                        </div>
                                    </Card>
                                    <div className="mt-4"></div>
                                </Col>
                                <Col lg={6}>
                                    <div className='share-head-text d-flex align-items-center justify-content-center mt-3'>Share Ad</div>
                                    <div>
                                        <ul className="share-cards-social-links-box mt-4">
                                            <li className='user-select-none'>
                                                <Button
                                                    className="share-cards-social-link"
                                                    onClick={handleCopyURL}
                                                >
                                                    <span>
                                                        <BiSolidCopy size={18} />
                                                    </span>
                                                    Copy Link
                                                </Button>
                                            </li>
                                            <li>
                                                <Button
                                                    className="share-cards-social-link"
                                                    onClick={() => handleShare(`https://wa.me/?text=${encodeURIComponent(text + currentURL)}`)}
                                                >
                                                    <span>
                                                        <MdOutlineWhatsapp size={18} />
                                                    </span>
                                                    Whatsapp
                                                </Button>
                                            </li>
                                            <li>
                                                <Button
                                                    className="share-cards-social-link"
                                                    onClick={() => handleShare(`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(currentURL)}&hashtags=vedichom`)}
                                                >
                                                    <span>
                                                        <FaXTwitter size={18} />
                                                    </span>
                                                    Twitter
                                                </Button>
                                            </li>
                                            <li>
                                                <Button
                                                    className="share-cards-social-link"
                                                    onClick={() => handleShare(`https://www.instagram.com/?url=${currentURL}&text=${text}`)}
                                                >
                                                    <span>
                                                        <PiInstagramLogoDuotone size={18} />
                                                    </span>
                                                    Instagram
                                                </Button>
                                            </li>
                                            <li>
                                                <Button
                                                    className="share-cards-social-link"
                                                    onClick={() => handleShare(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentURL)}&title=${encodeURIComponent(currentURL)}&summary=${encodeURIComponent(currentURL)}&source=${encodeURIComponent('vedichome.online')}`)}
                                                >
                                                    <span>
                                                        <BiLogoLinkedin size={18} />
                                                    </span>
                                                    Linkedin
                                                </Button>
                                            </li>
                                            <li>
                                                <Button
                                                    className="share-cards-social-link"
                                                    onClick={() => handleShare(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentURL)}&quote=${encodeURIComponent(text)}`)}
                                                >
                                                    <span>
                                                        <BiLogoFacebook size={18} />
                                                    </span>
                                                    Facebook
                                                </Button>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Container>}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default TeachersShareCards;
