  import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
  import { RootState, store } from "../../app/store"
  import { handlePayment } from "../../components/ScheduleSession/paymentGatewayfunction"
  import headerlogo from "../../assets/img/logo.png"
  import { useNavigate } from "react-router-dom"
import { auth } from "../../app/firebase"
import { setPaymentLoading } from "./seekerDashboardSlice"
import { providerDashboardApi } from "../featProviderDashboard/FeatProviderApi"
import { toast } from "react-toastify"
import { APP_ENV } from "../../constants"
import { appendData } from "../search/searchSlice"
import { SearchRequest, SearchResponseType } from "../../Types"

const BASE_URL = APP_ENV &&  ['testing','development'].includes(APP_ENV as string) ? import.meta.env.VITE_REACT_TEST_API_URL : import.meta.env.VITE_REACT_APP_API_URL 

  export const seekerDashboardApi:any = createApi({
    reducerPath: "seekerDashboardApi",
    baseQuery: fetchBaseQuery({
      baseUrl: `${BASE_URL}/api/`,
      prepareHeaders: (headers, { getState }) => {
        const token =  
          (getState() as RootState).auth.seekerToken ||
          (getState() as RootState).auth.providerToken
        if (token) {
          headers.set("authorization", `Bearer ${token}`)
        }
        return headers
      },
    }),
    tagTypes: [
      "getseekerProfile",
      "seekerRequests",
      "searchSources",
      "GetFavourites",
      'seekersReviews',
      'providersWithoutReview'
    ],
    endpoints: (builder) => ({
      seekerRequests: builder.query<any, any>({
        query: (arg: void) => ({ url: "/seeker/get-request" }),
        providesTags: ["seekerRequests"],
      }),
      seekerReviews: builder.query({
        query: (args: void) => "/seeker/get-reviews",
        providesTags: ['seekersReviews']
      }),
      getseekerAcceptedReviewsList: builder.query({
        query: (args: void) => "/seeker/get-list-of-accepted-requests",
      }),
      getseekerProfile: builder.query({
        query: (args: null | void) => "/seeker/get-profile",
        providesTags: ["getseekerProfile"],
      }),

      updateSeekerProfile: builder.mutation<any, any>({
        query: (data) => ({
          url: `seeker/update-profile`,
          method: "POST",
          body: data,
        }),
        invalidatesTags: ["getseekerProfile"],
      }),
      changeSeekerPassword: builder.mutation<any, any>({
        query: (data) => ({
          url: `seeker/change-password`,
          method: "POST",
          body: data,
        }),
      }),
      deleteSeekerAccount: builder.mutation({
        query: (args: void) => ({
          url: `seeker/delete-account`,
          method: "POST",
        }),
      }),
      createSeekerReview: builder.mutation<any, any>({
        query: (data) => ({
          url: `seeker/create-review`,
          method: "POST",
          body: data,
        }),
        invalidatesTags: ['seekersReviews', 'providersWithoutReview']
      }),

      seekerSearch: builder.mutation<any, any>({
        query: (data) => ({
          url: `/seeker/search-sort-filter-request?searchOption=${data.search}&requestStatus=${data.status}&sortByDate=${data.sort}`,
        }),
        transformResponse: (rawData) =>
          transformSeekerRequestSearchResponse(rawData),
      }),
      seekerClassRequests: builder.query<any, any>({
        query: (data) => ({
          url: `/seeker/search-sort-filter-request?${data.search ? `searchOption=${data.search}`: ''}${data.status ? `&requestStatus=${data.status}`: ''}&sortByDate=${data.sort || -1}`,
        }),
        transformResponse: (rawData) =>
          transformRequestDataObject(rawData),
      }),
      getUnratedProviders: builder.query<any, void>({
        query: () => ({
          url: `/seeker/get-unrated-reviews`,
          providesTags: ["providersWithoutReview"],
        }),
      }),
      acceptDeleteRequest: builder.mutation<any, any>({
        query: (data) => ({
          url: `seeker/create-review`,
          method: "POST",
          body: data,
        }),
      }),
      // search landing page
      searchSources: builder.query<SearchResponseType, SearchRequest>({
        query: (data) => {
          
      // Check if there is data to construct a query
      if (data) {
      
        // Initialize the base URL and parameters
        const baseUrl = '/seeker/search-sort-providers';
        const params = new URLSearchParams({
          services: data?.teacherType || "source",
          vedicSubject: data?.vedicSubject || "",  
          pageLimit: '9',
          pageNo: data?.pageNo ? data?.pageNo.toString() : '1'
        });         
        if(data?.sortOrder) params.append('sortOrder', data.sortOrder)
        else params.delete('sortOrder')
        if(data?.firstClassFree) params.append('firstClassFree', data.firstClassFree)
        else params.delete('firstClassFree')
        if(data?.classType || data?.groupOrPrivate)  { 
          params.append('classType', `${data.classType || "any"}`)
          params.append('groupOrPrivate', `${data.groupOrPrivate || "any"}`)
          if((data?.classType && data?.groupOrPrivate)) {
            if(data?.classType=='any' || data?.groupOrPrivate == 'any') params.delete('sortBy')
            else params.append('sortBy', `${data.classType && data.classType == 'realTime' ? 'realtime' : data.classType}-${data?.groupOrPrivate ? data?.groupOrPrivate : 'private'}`)
            }
          else params.delete('sortBy')
        }
        if(data?.priceRange)   params.append('priceRange', `${data.priceRange}`);
        else params.delete('priceRange')
        if(data?.aroundMe && data?.aroundMe?.length >= 1) params.append('aroundMe', `${data.aroundMe}`);
        else params.delete('aroundMe')

        // Return the full URL with encoded parameters
        return `${baseUrl}?${params.toString()}`;
      } else {
        return '/seeker/search-sort-providers'; // Default endpoint if no data
        }
    },
    keepUnusedDataFor: 3000,

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(appendData({ data: data?.data, totalItems: data.totalItems, pageNo: arg.pageNo || data.currentPage, totalPages: data.totalPages, arg: arg }));
        } catch (error: any) {
          console.error("Error fetching data:", error);
          // TODO: handle error 
          console.log(error,'error');
          dispatch(appendData({ data: error?.error?.data?.data, totalItems: error?.error?.data.totalItems, pageNo: arg.pageNo || error?.error?.data.currentPage, totalPages: error?.error?.data.totalPages, arg: arg}));
        }
      },
  providesTags:['searchSources']}),
      // searchLandingPage: builder.mutation<any, any>({
      //   query: (data) => {
      //     if (data) {
      //       let query = `/seeker/search-sort-providers?services=${data.teacherType}&vedicSubject=${data.VedicSubject}&sortOrder=${data.sortOrder}&sortBy=${data.sortBy || ""}&pageLimit=8&pageNo=${data.pageNo}`;
      //       // Check if modeOfClass exists before adding it to the query
      //       if (data.modeOfClass) {
      //         query += `&modeOfClass=${data.modeOfClass}`;
      //       }
      //       return query;
      //     } else {
      //       return '/seeker/search-sort-providers';
      //     }
      //   }
      // }),

      createSeekerRequest: builder.mutation<any, any>({
        query: (data) => ({
          url: `seeker/create-request`,
          method: "POST",
          body: data,
       }),
        invalidatesTags: ["seekerRequests"],
      
      }),
      seekerDeleteRequest: builder.mutation<any, any>({
        query: (data) => ({
          url: `seeker/delete-request`,
          method: "POST",
          body: data,
        }),
        invalidatesTags: ["seekerRequests"],
      }),

      seekerRequestSorting: builder.mutation<any, any>({
        query: (sort) => ({
          url: `/seeker/sort-request?sortOrder=${sort}`,
          providesTags: ["seekerRequests"],
        }),
      }),
      // depriciated
      // seekerSentReviews: builder.query({
      //   query: (rated) => `/seeker/get-rated-unrated-reviews?rated=${rated || ''}`,
      // }),
      // PymentGateway

      paymentCheckout: builder.mutation<any, any>({
        query: (args:{packageName:string, key:string, requestId:string}) => ({
          url: `/seeker/payment/checkout`,
          method: "POST",
          body: args,
        }),
        onQueryStarted: async(args:{ key:string,payload:any, requestId:string},{queryFulfilled, dispatch }) =>{
          try {
            const checkoutData = await queryFulfilled
            const {amount, key, id} =checkoutData.data.data.order
              var options = {
              key: key,
              amount: amount,
              currency: "INR",
              name: "VedicHom",
              description: "buying product",
              image: headerlogo,
              order_id: id,
              handler: function (payment: any) {
          dispatch(seekerDashboardApi.endpoints.paymentVerify.initiate({
                  data: {
                    razorpay_payment_id: payment.razorpay_payment_id,
                    razorpay_signature: payment.razorpay_signature,
                  },
                  id: id,
                requestId:args.requestId   }))
              },
              
              modal: {
                ondismiss: function () {
                  // Handle the dismissal of the checkout modal
                  dispatch(setPaymentLoading(false))
                }  },
              
              prefill: {
                name: "VedicHom Pvt Limited",
                email: "namaste@vedichom.com",
                contact: "9000090000",
              },
              notes: {
                address: "VedicHom Corporate Office",
              },
              theme: {
                color: "#FFB636",
            
              },  
            }
            //@ts-ignore
            var razor = new window.Razorpay(options)
            razor.open()
          } catch (error) {
            toast.dismiss()
            toast.error((error as any)?.error?.data)
            dispatch(setPaymentLoading(false))

          }
        
        }
      }),
      paymentVerify: builder.mutation<any, any>({
        query: (data: { data: any, requestId: string, id: string }) => ({
          url: `/seeker/payment/payment-verify?orderId=${data?.id}`,
          method: "POST",
          body: data?.data,
        }),
        onQueryStarted: async (data, { queryFulfilled, dispatch }) => {
          try {
            await queryFulfilled
            dispatch(setPaymentLoading(false))
            if (data.requestId) {
              if (store.getState().auth.seekerToken) {
                dispatch(seekerDashboardApi.util.invalidateTags([{ type: "getseekerProfile"}]))
              await dispatch(seekerDashboardApi.endpoints.createSeekerRequest.initiate(store.getState().seekerDashboardSlice.scheduleRequestPayload)) 
              window.location.replace(`/seeker-dashboard?payment=success`)
            }
            else {
              dispatch(providerDashboardApi.util.invalidateTags([{ type: "providerAccountDetails"}]))
              window.location.replace(`/source-dashboard?payment=success`)
            }
              
            } else {
              if (store.getState().auth.seekerToken) {
                dispatch(seekerDashboardApi.util.invalidateTags([{ type: "getseekerProfile"}]))
                window.location.replace(`/seeker-dashboard?payment=success`)
              }
              else {
                dispatch(providerDashboardApi.util.invalidateTags([{ type: "providerAccountDetails"}]))
                window.location.replace(`/source-dashboard?payment=success`)
              }
            }
          } catch (error) {
            if (data.requestId) window.location.replace(`${window.location.pathname}?payment=failure`);

          }
        },
        invalidatesTags: ["getseekerProfile"],
        //       onQueryStarted(arg, {queryFulfilled, dispatch}) {
        // queryFulfilled.then(()=>
        // dispatch(
        //   seekerDashboardApi.endpoints.getseekerProfile.initiate(null)       

        // ))

        //       },
      }),
      initatePaymentKey: builder.mutation<{ key: string }, { packageName: string, requestId: string }>({
        query: (args: { amount: string, packageName: string, expireInMonths: number, requestId: string }) => `/seeker/payment/get-key`,
        onQueryStarted: async (args: { packageName: string, key: string, requestId: string }, { queryFulfilled, dispatch }) => {
          try {
            dispatch(setPaymentLoading(true))
            const { data } = await queryFulfilled
            if (data.key && args) await dispatch(seekerDashboardApi.endpoints.paymentCheckout.initiate({ packageName: args.packageName, key: data.key, requestId: args.requestId }))
          } catch (error: any) {
            console.error('key error', error)
          }
        }
      }),
      paymentKey: builder.query<{ key: string }, { packageName: string, requestId: string }>({
        query: (args: { packageName: string, requestId: string }) => `/seeker/payment/get-key`,
        onQueryStarted: async (args: { packageName: string, key: string, requestId: string }, { queryFulfilled, dispatch }) => {
          try {
            const { data } = await queryFulfilled
            if (data.key && args) await dispatch(seekerDashboardApi.endpoints.paymentCheckout.initiate({ packageName: args.packageName, key: data.key, requestId: args.requestId }))
          } catch (error: any) {
            console.error('key error', error)
          }
        }
      }),

  
      addToFav: builder.mutation<any, any>({
        query: (data) => ({
          url: `/seeker/make-fav-ad?adId=${data}`,
          method: "GET",
        }),
        invalidatesTags: ["GetFavourites"],
      }),
      uploadDocuments: builder.mutation<any, any>({
        query: (data) => ({
          url: `/seeker/upload-docs?${data.delKey || ''}`,
          method: "POST",
          body: data?.data,
        }),
        invalidatesTags: ["getseekerProfile"],
      }),
      GetFavourites: builder.query({
        query: (args: void) => `/seeker/get-fav-ads`,
        providesTags: ["GetFavourites"],
      }),
      GetFAQ: builder.query({
        query: (args: void) => `/static-data/get-faq?category=General`,
      }),
      GetSeekerFAQ: builder.query<any, any>({
        query: (data) => ({  
          url: `/seeker/get-faq?adId=${data?.adId}&pageNo=${data?.pageNo || 1}&pageLimit=${data?.pageLimit ||5}`,
          method: "GET",
        }),
      }),
      seekerPhoneVerification: builder.mutation({
        query: (args: void) => ({
          url: "seeker/send-mobile-otp",
          method: "GET",
        }),
      }),

      seekerVerifyPhoneOtp: builder.mutation({
        query: (data) => ({
          url: "seeker/verify-mobile",
          method: "POST",
          body: data,
        }),
        invalidatesTags: ["getseekerProfile"],
      }),
      seekerVerifyPhoneOtpSignup: builder.mutation({
        query: (data) => ({
          url: "seeker/verify-mobile-on-signup",
          method: "POST",
          body: data,
        }),
        invalidatesTags: ["getseekerProfile"],
      }),

      getRecomendations: builder.query<any, void>({
        query: () => ({
          url: "seeker/get-ad-recommendation?limit=9",
          method: "GET",
        }),
      }),

      reportAdvert: builder.mutation({
        query: (arg: any) => ({
          url: "seeker/report-ad",
          method: "POST",
          body: arg,
        })
      }),

      seekerTermsAndCondition: builder.mutation({
        query: (arg: any) => ({
          url: '/seeker/accept-terms',
          method: "POST",
          body: arg,
        }),
        invalidatesTags: ["getseekerProfile"],
      }),

      seekerReportChat: builder.mutation({
        query: (arg: any) => ({
          url: '/seeker/report-chat',
          method: "POST",
          body: arg,
        }),
      }),
    }),
  })

  export const {
    useSeekerRequestsQuery,
    useSeekerReviewsQuery,
    useAcceptDeleteRequestMutation,
    useGetseekerAcceptedReviewsListQuery,
    useGetseekerProfileQuery,
    useUpdateSeekerProfileMutation,
    useChangeSeekerPasswordMutation,
    useCreateSeekerReviewMutation,
    // useSearchLandingPageMutation,
    useCreateSeekerRequestMutation,
    useSeekerRequestSortingMutation,
    // useSeekerSentReviewsQuery, depriciated
    useSeekerSearchMutation,
    useSeekerClassRequestsQuery,
    useGetUnratedProvidersQuery,
    // useGetMyProvidersQuery,
    useSeekerDeleteRequestMutation,
    usePaymentCheckoutMutation,
    usePaymentKeyQuery,
    usePaymentVerifyMutation,
    useDeleteSeekerAccountMutation,
    useAddToFavMutation,
    useGetFavouritesQuery,
    useGetFAQQuery,
    useUploadDocumentsMutation,
    useGetSeekerFAQQuery,
    useSeekerPhoneVerificationMutation,
    useSeekerVerifyPhoneOtpMutation,
    useSeekerVerifyPhoneOtpSignupMutation,
    useSearchSourcesQuery,
    useInitatePaymentKeyMutation,
    useGetRecomendationsQuery,
    useReportAdvertMutation,
    useSeekerTermsAndConditionMutation,
    useSeekerReportChatMutation,
  } = seekerDashboardApi
  export const transformSeekerRequestSearchResponse = (rawData: any) => {
    if (!rawData || !rawData.data) return null

    return {
      totalRequests: rawData.totalRequests,
      data: rawData.data.map((item: any) => ({
        _id: item?._id || null,
        seekerId: item.seekerId || null,
        adId: {
          _id: item?._id || null,
          providerId: {
            _id: item.providerData?.[0]?._id || null,
            firstName: item.providerData?.[0]?.firstName || null,
            lastName: item.providerData?.[0]?.lastName || null,
            profilePic: item.providerData?.[0]?.profilePic || null,
          },
          vedicSubject: item.adData?.[0]?.vedicSubject || null,
        },
        sessionPreference: item.sessionPreference || null,
        date: item.updatedAt || null,
        sessionForWhom: item.sessionForWhom || null,
        requestMessage: item.requestMessage || null,
        isDeleted: item.isDeleted || null,
        requestStatus: item.requestStatus || null,
        createdAt: item.createdAt || null,
        updatedAt: item.updatedAt || null,
        __v: item.__v || null,
      })),
    }
  }

export const transformRequestDataObject = (rawData: any) => {
  if (!rawData || !rawData.data) return null

  return ({
    message: rawData.message,
    totalRequests: rawData.totalRequests,
    requestCount: rawData.requestCount,
    data: rawData.data.map((item: any) => ({
      _id: item._id,
      updatedAt: item.updatedAt,
      requestStatus: item.requestStatus,
      isDeleted: item.isDeleted,
      requestMessage: item.requestMessage,
      sessionPreference: item.sessionPreference,
      seekerId: item.seekerId,
      adData: item?.adData?.length == 0 ? null : item?.adData,
      providerData: item?.providerData?.length == 0 ? null : item?.providerData,
      date: item.date,
    }))
  })
}
