import { InfinitySpin } from "react-loader-spinner"
interface spinnerProps {
  wrapperStyle?: {}
  wrapperClass?: string
}

const SpinnerSecondary = ({ wrapperStyle, wrapperClass }: spinnerProps) => {
  return (
    <>
      <div className={wrapperClass || "d-flex justify-content-center align-items-center landing-spinner"}>
        {/* <MutatingDots
          visible={true}
          height="100"
          width="100"
          color="#FB6D48"
          secondaryColor="#ffb900"
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={wrapperStyle}
          wrapperClass={wrapperClass}
        /> */}
        <InfinitySpin color="#FB6D48" />
      </div>
    </>
  )
}

export default SpinnerSecondary
