function generateRandomFileName(extension: string): string {
    const allowedChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const extensionRegex = /^\.[a-zA-Z0-9]+$/; 

    let randomName = '';
    const fileNameLength = 10; 
    for (let i = 0; i < fileNameLength; i++) {
        randomName += allowedChars.charAt(Math.floor(Math.random() * allowedChars.length));
    }
    return randomName + '.' + extension;
}

export { generateRandomFileName }