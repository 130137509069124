import React from 'react'
import { Button, Col, Container, Dropdown, Form, Row } from 'react-bootstrap'
import { RxCrossCircled } from 'react-icons/rx'

interface props {
    data: any,
    onToggle: any,
}

const CheckboxGroupOffcanvas = (prop: props) => {
  return (
    <>
    <Container key={prop.data.key}>
    <Row className='filter-heading'>
    {prop.data.sortType || prop.data.label}
    </Row>
    <Row className=' flex-wrap '>
        {Object.entries(prop.data.options).map(([key, value], index)=>(
            <><Col className='w-50 mt-2' sm={'auto'} key={index}>
            <Form.Check className='fc-opt-text'
            label={(value as any).label}
            checked={(value as any).value}
            onChange={(e)=>{prop.onToggle(prop.data.key,key, e.target.checked)}}
            />
            </Col></>
        ))}
    </Row>
    </Container>
    </>
  )
}

export default CheckboxGroupOffcanvas