import { useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
import webcam from "../../../assets/img/cam-icon.svg"
import reviewimage from "../../../assets/img/certified-image2.png"
import starimage from "../../../assets/img/star-active-icon.svg"
import cardimage from "../../../assets/img/card-image.png"
import heartimage from "../../../assets/img/heart.svg"
import playbutton from "../../../assets/img/play-icon.svg"
import dummy from '../../../assets/images/dummy-profile-pic.jpg';
import { FaStar } from "react-icons/fa"


const PaymentCard = ({ data }: any) => {
  return (
    <>
      <section className="payment-card mt-5">
        <Container>
          <Row>
            <Col xs={12}>
              <Card className="fav-card teacher-card max-width-320 mx-auto mb-4">
                <div className="fav-img overflow-hidden">
                  <img src={data?.adDetail?.profileImage || dummy} alt="profile picture" />
                </div>
                {/* <div className="option-fav d-flex justify-content-between align-items-center">
                  <Button className="play-btn" variant="transparent">
                    <img src={playbutton} />
                  </Button>
                </div> */}
                <div className="info-astrologer">
                  <div className="text-center">
                    <h2 className="ast-name mb-1">
                      {data?.adDetail.providerId?.firstName}{" "}
                      {data?.adDetail.providerId?.lastName}
                    </h2>
                   {
                    data?.adDetail.providerId?.rating > 0 &&
                    <div className="rating justify-content-center d-flex">
                      <FaStar color="gold" size={15} className="mb-1"/>
                      <span className="total-rating mb-0">
                        { data?.adDetail.providerId?.rating}
                      </span>
                      <small>{`(${data?.adDetail?.providerId?.reviewCount} Reviews)`}</small>
                    </div>}
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default PaymentCard
