import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { api } from "../Auth/authApi"
import { seekerDashboardApi } from "./seekerDashboardApi"

interface User {
  user: any
  isPremium: boolean
  _id: string
  firstName: string
  lastName: string
  email: string
  isEmailVerified: boolean
  resetPassword: boolean
  status: string
  createdAt: string
  updatedAt: string
  __v: number
  role: string
  requestId: number
  newMessageLength: null
  messageNotificationClick: any
  acceptRejectRequest: boolean
  scheduleRequestPayload:any
}

const initialState = {
  _id: "",
  firstName: "",
  lastName: "",
  email: "",
  isEmailVerified: false,
  status: "",
  role: "",
  updatedAt: "",
  __v: 0,
  resetPassword: false,
  isPremium: false,
  createdAt: "",
  requestId: 0,
  newMessageLength: null,
  messageNotificationClick: null,
  acceptRejectRequest: false,
  scheduleRequestPayload:{},
}

const seekerDashboardSlice = createSlice({
  name: "seekerDashboardSlice",
  initialState: {
    userData: initialState as User,
    requestData: null as null | string | any,
    scheduleSessionData: null as null,
    searchedData: null as any,
    newMessageSent: false as boolean,
    newMessageLength: null as null,
    messageNotificationClick: null as any,
    acceptRejectRequest: false as boolean,
    scheduleRequestPayload:{} as any,
    paymentLoading : false as boolean
  
  },
  reducers: {
    setSeekerData: (state: any, action: any) => {
      state.userData = action.payload
    },
    setSeekerRequest: (state: any, action: any) => {
      state.requestData = action.payload
    },
    setMessageNotificationClick: (state: any, action: any) => {
      state.messageNotificationClick = action.payload
    },
    setSeekerScheduleSessionData: (state: any, action: any) => {
      state.scheduleSessionData = action.payload
    },
    setRequestId: (state: any, action: any) => {
      state.userData.requestId = action.payload
    },
    setSeekerLandingPageSearchData: (state: any, action: any) => {
      state.searchedData = action.payload
    },
    setNewMessageSent: (state: any, action: any) => {
      state.newMessageSent = action.payload
    },
    setNewMessageLength: (state: any, action: any) => {
      state.newMessageLength = action.payload
    },
    setAcceptRejectRequest: (state: any, action: any) => {
      state.acceptRejectRequest = action.payload
    },setScheduleRequestPayload: (state: any, action: any) => {
      state.scheduleRequestPayload = action.payload
    },
    setPaymentLoading: (state: any, action: {payload:boolean}) => {
      state.paymentLoading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.login.matchFulfilled,
      (state, { payload: user }: PayloadAction<any>) => {
        state.userData = { ...state.userData, ...user }
      },
    ),
      builder.addMatcher(api.endpoints.logout.matchFulfilled, (state) => {
        state.userData.user = initialState
      }),
      builder.addMatcher(api.endpoints.postCreateRquest.matchFulfilled, (state) => {
        state.scheduleRequestPayload = initialState
      })  
   },
})

export default seekerDashboardSlice.reducer
export const {
  setSeekerData,
  setSeekerRequest,
  setSeekerScheduleSessionData,
  setRequestId,
  setSeekerLandingPageSearchData,
  setNewMessageSent,
  setNewMessageLength,
  setMessageNotificationClick,
  setAcceptRejectRequest,
  setScheduleRequestPayload,
  setPaymentLoading
} = seekerDashboardSlice.actions