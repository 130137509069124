import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import FormInput from "../../../form/FormInput"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap"
import mailicon from "../../../../assets/img/mail-icon.svg"
import passwordicon from "../../../../assets/img/password-icon.svg"
import { FaFacebook, FaRegUser } from "react-icons/fa"
import googleimage from "../../../../assets/img/google-icon.png"
import { toast } from "react-toastify"
import { useAppDispatch } from "../../../../app/store"
import {
  closeModal,
  setModalName,
} from "../../../../features/Auth/ModalHandler/modalSlice"
import { APP_ENV, isLaunched, MODALNAME, USERTYPE } from "../../../../constants"
// import { useProviderloginMutation } from "../../../../features/Auth/authApi"
import { useLoginMutation } from "../../../../features/Auth/authApi"
import { useLocation, useNavigate } from "react-router-dom"
import { setCurrentUser } from "../../../../features/Auth/authSlice"
import { seekerLoginSchema } from "../../../../utils/yupSchema/Schema"
import { useProviderloginMutation } from "../../../../features/featProviderDashboard/FeatProviderApi"
import SpinnerMain from "../../../spinner/SpinnerMain"

const BASE_URL = APP_ENV &&  ['testing','development'].includes(APP_ENV as string) ? import.meta.env.VITE_REACT_TEST_API_URL : import.meta.env.VITE_REACT_APP_API_URL 
const ENDPOINT_URL_PROVIDER = import.meta.env
  .VITE_REACT_APP_GOOGLE_SIGN_UP_PROVIDER
const ENDPOINT_URL_SEEKER = import.meta.env.VITE_REACT_APP_GOOGLE_SIGN_UP_SEEKER
type Props = {
  userType: string | null
}
interface LoginUser {
  emailOrPhone: string
  password: string
}
enum SigninStates {
  signinForm = 0,
  choiceVerification = 1
}
enum otpSentThrough {
  mobileNo = "mobileNo",
  email = 'email'
}
export default function LoginSeeker({ userType }: Props) {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const [signInState, setState] = useState<SigninStates>(SigninStates.signinForm)
  const [submitForm, { isSuccess, isError, error, data, isLoading }] =
    useProviderloginMutation()

  const [
    seekerForm,
    {
      isError: seekerisError,
      error: seekerError,
      data: seekerData,
      isLoading: seekerIsloading,
    },
  ] = useLoginMutation()

  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm<LoginUser>({
    resolver: seekerLoginSchema,
  })

  const onSubmit = async (values: any) => {
    const payload = {
      email: values.emailOrPhone?.trim(),
      password: values.password,
    };
  
    if (userType === USERTYPE.SEEKER) {
      seekerForm(payload);
    } else if (userType === USERTYPE.PROVIDER) {
      submitForm(payload);
    }
  };
  const navigate = useNavigate()
  function handleVerifyWithPhone () {dispatch(setModalName(MODALNAME.OTP_VERIFICATION_PHONE)) }
  function handleVerifyWithEmail () {dispatch(setModalName(MODALNAME.OTP_VERIFICATION))}
  useEffect(() => {
    if (data?.message || seekerData?.message) {
      if (data?.user.role === "Provider") {
        if (data?.isEmailVerified && data?.isMobileNoVerified) {
          toast.success(data?.message)
          dispatch(closeModal())
        } else {
          toast?.success(data?.message)
          dispatch(setCurrentUser(USERTYPE.PROVIDER))
          if(data?.otpSentThrough && data?.otpSentThrough === otpSentThrough.mobileNo) handleVerifyWithPhone()
          else if(data?.otpSentThrough && data?.otpSentThrough === otpSentThrough.email) handleVerifyWithEmail()
        }
        navigate('/source-dashboard/my-advt')
      } else {
        if (seekerData?.isEmailVerified && seekerData?.isMobileNoVerified) {
          toast.success(seekerData?.message)
          dispatch(closeModal())
        } else {
          toast?.success(seekerData?.message)
          dispatch(setCurrentUser(USERTYPE.SEEKER))
          if( seekerData?.otpSentThrough && seekerData?.otpSentThrough === otpSentThrough.mobileNo) handleVerifyWithPhone()
          else if(seekerData?.otpSentThrough  && seekerData?.otpSentThrough === otpSentThrough.email) handleVerifyWithEmail()
        }
        if (location.pathname === "/source-signup") {
          navigate("/")
        }
      }
    } else {
      toast.dismiss()
      toast.error(
        (error as any)?.data?.message || (seekerError as any)?.data?.message,
      )
    }
  }, [data, seekerData, seekerError, error])

  useEffect(() => {
    reset()
  }, [userType])
  const handleGoogleLogin = async () => {
    if (userType === USERTYPE.PROVIDER) {
      window.location.replace(`${BASE_URL}/api/provider/auth/google`)
    } else {
      window.location.replace(`${BASE_URL}/api/seeker/auth/google`)
    }
  }

  return (
    <>
      {signInState==SigninStates.signinForm?<Form onSubmit={handleSubmit(onSubmit)}>
        <div className="popup-field-wrapper mb-1 W-100">
          {/* <img src={mailicon} alt="Mail Icon" className="user-select-none" /> */}
          {/* <FormInput
            name="email"
            type="email"
            placeholder={"Enter your Phone number or Email"}
            register={register}
            // errors={errors}
            className="popup-field w-100"
          /> */}
          <FaRegUser color="#727272"/>
          <FormInput
            name="emailOrPhone"
            type="text"
            placeholder={"Enter Phone number or Email"}
            register={register}
            className="popup-field w-100"
          />
        </div>
        <span className="error text-danger">{errors?.emailOrPhone?.message}</span>
        <div className="popup-field-wrapper mb-1 mt-4 w-100">
          <img src={passwordicon} alt="Password Icon" className="user-select-none" />
          <FormInput
            name="password"
            type="password"
            placeholder="Password"
            register={register}
            // errors={errors}
            watch={watch('password')}
            className="popup-field w-100"
          />
        </div>
        <span className="error text-danger">{errors?.password?.message}</span>
        <div className="text-end mb-md-4 mb-3">
          <Button
            variant="transparent"
            onClick={() =>
              dispatch(
                userType == USERTYPE.SEEKER
                  ? setModalName(MODALNAME.FORGET_PASSWORD_SEEKER)
                  : setModalName(MODALNAME.FORGET_PASSWORD_TUTOR),
              )
            }
            className="forgot-link p-0"
          >
            Forgot Password?
          </Button>
        </div>
        <Button
          type="submit"
          className="common-btn auth-btn mb-3"
          disabled={seekerIsloading || isLoading}
        >
          {seekerIsloading || isLoading ? <Spinner size="sm" /> : "Login"}
        </Button>

        <div className="d-md-flex gap-3 max-w-450 mx-auto mb-4 user-select-none">
          <a
            className="loginwith-button google-btn d-block mb-3"
            onClick={handleGoogleLogin}
          >
            <img src={googleimage} alt="Google Icon" />
            Log in with Google
          </a>
          {/* <a className="loginwith-button facebook-btn d-block mb-3">
            <FaFacebook />
            Login with Facebook
          </a> */}
        </div>
        <div className="text-center">
          <a
            onClick={() => {
              if (userType === USERTYPE.SEEKER) dispatch(setModalName(MODALNAME.SIGNUP))
              else {
                navigate("/source-signup")
                dispatch(setModalName(MODALNAME.CLOSED))
              }
            }}
            className="signup-link user-select-none"
          >
            Don’t have an account? <span>Sign up</span>
          </a>
        </div>
        {isLaunched && <div className="text-center mt-2 primary-bold-text">
          or <br />
          <div
            className="mt-2 user-select-none " style={{ cursor: 'pointer' }}
            onClick={() =>
              dispatch(
                setCurrentUser(
                  userType === USERTYPE.SEEKER
                    ? USERTYPE.PROVIDER
                    : USERTYPE.SEEKER,
                ),
              )
            }
          >
            Login as {userType === USERTYPE.SEEKER ? "Source" : "Seeker"}
          </div>
          {/* Login as a {userType === USERTYPE.SEEKER_LOGIN ? <span onClick={()=>setUserType(USERTYPE.TUTOR_LOGIN)}>Tutor</span>: <span onClick={()=>setUserType(USERTYPE.TUTOR_LOGIN)}> Seeker</span>}   */}
        </div>}
      </Form>
      :
      // verify with mutiple mode for future usage 
      signInState==SigninStates.choiceVerification ?
      <>
      <Container>
        <p>Your are not Verified</p>
        {(userType && userType ==  USERTYPE.SEEKER ) ? 
        <>
        <Row>
          <Col sm={12} lg={6}>
          <Button className="w-100" onClick={handleVerifyWithPhone}>
            Verify with Phone Number</Button>
          </Col>
          <Col sm={12} lg={6}>
          <Button className="w-100" onClick={handleVerifyWithEmail}>
            Verify with Email</Button>
          </Col>
        </Row>
        </>
        :<>Source</>}
      </Container>
      </>
      : <SpinnerMain/>}
    </>
  )
}
