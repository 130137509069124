import React from 'react'
import { Button, Col, Container, Dropdown, Form, Row } from 'react-bootstrap'
import { RxCrossCircled } from 'react-icons/rx'
import { useSearchParams } from 'react-router-dom'

interface props {
    paramsKeys: string | string [],
    data: any,
    setFilters: any,
}

const MutiCheckBoxGroup = (prop: props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const isSelected: string | null = typeof prop.paramsKeys === 'string'
  ? searchParams.get(prop.paramsKeys) !== null
    ? prop.paramsKeys
    : null
  : prop.paramsKeys.find((pKey) => searchParams.get(pKey) !== null) || null;

  function clearFilter () {
    searchParams.delete('sortOrder')
    searchParams.delete('sortBy')
    setSearchParams(searchParams)
    // clearFilter(prop.data.key)
    // document.getElementById(prop.data.key)?.click()
    prop.setFilters((prev: any) => {
      const updatedOptions = Object.fromEntries(
        Object.entries(prop.data.options).map(([key, option]: [string, any]) => {
          return [
            key,
            {
              ...option,
              value: false,
            },
          ];
        })
      );
      return {
        ...prev,
        sortOrder: {
          ...prev.sortOrder,
          options: updatedOptions},
      };
    });
  }
  function onApply() {
    searchParams.delete('sortOrder')
    searchParams.delete('sortBy')
    Object.entries(prop.data.options).some(([optionKey, value], index) => {
      if((value as any).value as boolean) {
        searchParams.set((value as any).paramsKey, (value as any).key)
      }
    })
    setSearchParams(searchParams)
}
  function onToggle(currentTarget: string, checked: boolean): void {
    prop.setFilters((prev: any) => {
      const updatedOptions = Object.fromEntries(
        Object.entries(prop.data.options).map(([key, option]: [string, any]) => {
          return [
            key,
            {
              ...option,
              value: option.key === currentTarget ? !option.value : false,
            },
          ];
        })
      );
      return {
        ...prev,
        sortOrder: {
          ...prev.sortOrder,
          options: updatedOptions},
      };
    });
  }

  const ifSelectedFindTheValue = (key: string | null) => {
    if (!key) return null;
  
    const foundOption = Object.values(prop.data.options).find(
      (option: any) => option.paramsKey === isSelected
    );
  
    return foundOption ? (foundOption as any).label : null;
  };
  return (
    <>
    {isSelected
    ?
    <button className={`dropdown-menu_show ${isSelected?'bg-dark-subtle':''}`} onClick={()=>{clearFilter()}}>
    {ifSelectedFindTheValue(isSelected)}
    <RxCrossCircled style={{ marginLeft: "5px" }} size={20} />
    </button>
    :<Dropdown key={prop.data.key} id={prop.data.key}>
        <Dropdown.Toggle
        className='dropdown-menu_show '
        variant="secondary">
        {prop.data.label}
        </Dropdown.Toggle>
        <Dropdown.Menu>
        <Container className={`${false&& ' d-none '} m-2`}>
        {Object.entries(prop.data.options).map(([key, value], index)=>(
            <><Col key={key}>
            <Form.Check
            id={key}
            label={(value as any).label}
            checked={(value as any).value}
            onChange={(e)=>{onToggle(e.target.id, e.target.checked)}}
            />
            </Col></>
        ))}
        </Container>
        <Row className="p-0 p-md-3 " >
        <Col md={6}>
        <Button
        variant="link"
        className="filter-cancel"
        onClick={clearFilter}>
        Clear
        </Button>
        </Col>
        <Col md={6}>
        <Button
        variant="link"
        className="filter-cancel"
        onClick={()=>onApply()}>
        Apply
        </Button>
        </Col>
        </Row>
        </Dropdown.Menu>
    </Dropdown>}
    </>
  )
}

export default MutiCheckBoxGroup