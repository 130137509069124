import { useCallback } from "react";
import ReactGA from "react-ga4";
// const MEASUREMENT_ID = import.meta.env.VITE_MEASUREMENT_ID;
// const MEASUREMENT_ID1 = import.meta.env.VITE_MEASUREMENT_ID1;


const useGeoLocationTracker = () => {
  const trackLocation = useCallback(
    (location: string, action: string) => {
      ReactGA.send({
        hitType: "event", // Required: Specifies that this is an event type hit
        eventCategory: "User Location", // Custom event category
        eventAction: action, // The dynamic action you want to track
        eventLabel: "Location", // A custom label to describe what is being tracked
        eventValue: location, // Custom value; in this case, the location string
      });
    },
    []
  );

  return trackLocation;
};

export default useGeoLocationTracker;
