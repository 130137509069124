import { SetStateAction, useEffect, useRef, useState } from "react"
import { Accordion, Container, Tabs, Tab, Spinner, Button, Form } from "react-bootstrap"
import "./HelpCenter.css"
import searchicon from "../../../assets/img/search.svg"
import LandingHeader from "../../navbars/TopNavbar"
import { useGetHelpFaqQuery } from "../../../features/Auth/authApi"
import leftarrow from "../../../assets/img/left-arrow.svg"
import { useLocation, useNavigate } from "react-router-dom"
import CustomBackNavButton from "../../buttons/custumBackNavButton/CustomBackNavButton"
import blankImage from "../../../assets/images/error-404.png"
import { isLaunched } from "../../../constants"

const HelpCenter = () => {
  const [seekerHelp, setSeekerHelpData] = useState<any[]|null>(null)
  const [providerHelp, setProviderHelpData] = useState<any[]|null>(null)
  const [questionsList, setQuestions] =  useState<string[]>([])
  const [activeTab, setActiveTab] = useState("mentor")
  const [searchvalue, setSearchValue] = useState<string>('')
  const [data, setData] = useState({
    search: "",
    userType: "",
  })
  const { data: helpData, error, isLoading, refetch }= useGetHelpFaqQuery(data, {refetchOnMountOrArgChange: true})
  const state = useLocation()
  useEffect(()=>{
    if(state?.state?.tabId) setActiveTab(state?.state?.tabId)
  },[state])
  const handleTabChange = (key: SetStateAction<string>) => {
    setActiveTab(key)
    setData({
      search: "",
      userType: "",
    })
  }
  const  handleChangeSearchValue = (value: string) => {
    setData({
      search: value,
      userType: activeTab === "student" ? "Seeker" : "Provider",
    })
  }
  const handleSearch = () => {
    refetch()
  }
  useEffect(()=>{
    setSeekerHelpData(helpData)
    setProviderHelpData(helpData)
    if(data.search=='') setQuestions(()=>{
      if(helpData) return (helpData as any[])?.map((item: any, index: number)=>{
        return item?.question
      })
      return []
    })
  },[helpData])
  const [filteredQuestions, setFilteredQuestions] = useState<string[]>([]);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleSearchValue = (value: string) => {
    if(value=='') setData({search:'',userType:''})
    setSearchValue(value);
    if (value) {
      const filtered = questionsList.filter((question) =>
        question.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredQuestions(filtered);
    } else {
      setFilteredQuestions([]);
    }
  };

  const handleFocus = (event: any) => {
    setIsFocused(true);
  };

  const [dropdownInFocous, setDropDownFocous] = useState<boolean>(false)
  const handleBlur = (event: any) => {
    setTimeout(() => {
      if(dropdownInFocous) {
        return
      }
      setIsFocused(false)}, 200); 
  };
  return (
    <>
      <div className="top-nav">
        <LandingHeader />
      </div>
      <section className={`support-help ${activeTab}`}>
        <Container fluid className="px-0">
          <div className="inner-help">
            <div className="support-head-wrapper">
              <p className="support-head text-center mb-4 lh-1">
                Namaste, what can we do for you?
              </p>
              <div className="search-field-group">
                <input
                  className={`form-control search-field ${(isFocused && filteredQuestions.length > 0)?'border-bottom-radious-0':''}`}
                  value={searchvalue}
                  type="text"
                  placeholder="Start typing your search"
                  onChange={(e) =>
                    handleSearchValue(e.target.value)
                  }
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />

                {isFocused && filteredQuestions.length > 0 && (
                  <ul className={`search-dropdown help-scrollbar ${activeTab === "student" ? 'scrollbar-variant-1':'scrollbar-variant-2'}`} onMouseLeave={()=>setDropDownFocous(false)} onMouseEnter={()=>setDropDownFocous(true)}>
                    {filteredQuestions.map((question, index) => (
                      <li
                        key={index}
                        onClick={() => {setSearchValue(question);handleChangeSearchValue(question);setIsFocused(false)}}
                        className="search-dropdown-item"
                      >
                        {question}
                      </li>
                    ))}
                  </ul>
                )}

                <button className="btn search-btn" onClick={()=>{handleChangeSearchValue(searchvalue)}}>
                  <img src={searchicon} />
                </button>
              </div>
            </div>
            <div className="support-tab-wrapper">
              <Tabs
                variant="pills"
                className={`justify-content-center support-tabs ${isLaunched ? '': 'hide-tabNav'}`}
                defaultActiveKey="student"
                activeKey={activeTab}
                onSelect={handleTabChange as any}
                id="pills-tab"
              >
                <Tab eventKey="mentor" title="Mentor">
                  <div className="category-wrapper pb-5">
                    <div>
                      <h3 className="category-heading">Categories</h3>
                      <div className="category-accordion">
                        {isLoading ? (
                          <Spinner />
                        ) : (
                          (providerHelp && providerHelp?.length > 0) ?
                          providerHelp?.map(
                            (data: any, index: number) => {
                              return (
                                <Accordion flush>
                                  <Accordion.Item
                                    className="category-acc-item"
                                    eventKey="0"
                                  >
                                    <Accordion.Header className="category-acc-head">
                                      {data?.question}
                                    </Accordion.Header>
                                    <Accordion.Body className="p-0">
                                      <div className="innerText">
                                        {data?.answer}
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              )
                            },
                          )
                          :
                          <>
                          <div className="ms-5 mt-2 d-flex justify-content-center align-items-center flex-column">
                        <img
                          src={blankImage}
                          alt="No results found"
                          className="img-fluid"
                          width={200}
                        />
                        <div className=" mt-2 text-silver">
                          {" "}
                          No result found
                        </div>
                      </div></>
                        )}
                      </div>
                    </div>
                  </div>
                </Tab>
                {isLaunched && <Tab eventKey="student" title="Student">
                  <div className="category-wrapper pb-5">
                    <div>
                      <h3 className="category-heading">Categories</h3>
                      <div className="category-accordion">
                        {isLoading ? (
                          <Spinner />
                        ) : (
                          (seekerHelp && seekerHelp?.length > 0) ?
                          seekerHelp?.map(
                            (data: any, index: number) => {
                              return (
                                <Accordion flush>
                                  <Accordion.Item
                                    className="category-acc-item"
                                    eventKey="0"
                                  >
                                    <Accordion.Header className="category-acc-head">
                                      {data?.question}
                                    </Accordion.Header>
                                    <Accordion.Body className="p-0">
                                      <div className="innerText">
                                        {data?.answer}
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              )
                            },
                          )
                          :
                          <><div className="ms-5 mt-2 d-flex justify-content-center align-items-center flex-column">
                          <img
                            src={blankImage}
                            alt="No results found"
                            className="img-fluid"
                            width={200}
                          />
                          <div className=" mt-2 text-silver">
                            {" "}
                            No result found
                          </div>
                        </div></>
                        )}
                      </div>
                    </div>
                  </div>
                </Tab>}
              </Tabs>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}

export default HelpCenter
