import React, { useEffect, useState } from 'react';
import { Button, Offcanvas, Container, Row, Col } from 'react-bootstrap';
import './FilterButtonsCard.css';
import { useSearchParams } from 'react-router-dom';
import { RxCross2, RxHamburgerMenu } from "react-icons/rx";
import { FaFilter } from 'react-icons/fa6';
import CheckboxGroup from './filterComponents/filterDropdowns/CheckboxGroup';
import RangeSelector from './filterComponents/filterDropdowns/RangeSelector';
import CheckBox from './filterComponents/filterDropdowns/CheckBox';
import CheckboxGroupOffcanvas from './filterComponents/filterOffcanvas/CheckboxGroupOffcanvas';
import OffcanvasRangeselector from './filterComponents/filterOffcanvas/OffcanvasRangeselector';
import { FaSortAmountDown, FaSortAmountUp } from 'react-icons/fa';
import MutiCheckBoxGroup from './filterComponents/filterDropdowns/MutiCheckBoxGroup';
import { FilterOptions } from '../../Types';

interface propsType {
    filterOptions: FilterOptions
    setFilters: any
}

export const FilterButtonsCard = ({filterOptions, setFilters}: propsType) => {
    // const [filterOptions, setFilters] = useState<FilterOptions>(InitialFilterState);
    const [searchParams, setSearchParams] = useSearchParams()
    const [isOffcanvasVisible, setOffcanvasVisible] = useState<boolean>(false)
    const [isRightOffCanvas, setRightOffCanvas] = useState<boolean>(false)
    function onToggle(filterOption: keyof typeof filterOptions, key: string, value: boolean) {
        setFilters((prevOptions: typeof filterOptions) => {
            const updatedOptions: Record<string, { key: string; label: string; value: boolean }> = {};
            for (const optionKey in prevOptions[filterOption].options) {
                if (Object.prototype.hasOwnProperty.call(prevOptions[filterOption].options, optionKey)) {
                    if (optionKey === key) {
                        updatedOptions[optionKey] = {
                            ...prevOptions[filterOption].options[optionKey],
                            value: value
                        };
                    } else {
                        updatedOptions[optionKey] = {
                            ...prevOptions[filterOption].options[optionKey],
                            value: false
                        };
                    }
                }
            }
            return {
                ...prevOptions,
                [filterOption]: {
                    ...prevOptions[filterOption],
                    options: updatedOptions
                }
            };
        });
    }
    function onApply(key: keyof typeof filterOptions) {
        let selectedOption = null;
        Object.entries(filterOptions[key].options).some(([optionKey, value], index) => {
            if (filterOptions[key].options[optionKey].value === true) {
                selectedOption = filterOptions[key].options[optionKey].key;
                const updateSelectedValue = filterOptions[key].options[optionKey].label
                setFilters((prevOptions: typeof filterOptions) => {
                    return {
                        ...prevOptions,
                        [key]: {
                            ...prevOptions[key],
                            selectedValue: updateSelectedValue
                        }
                    };
                })
                return true;
            }
        })
        if (selectedOption) searchParams.set(key.toString(), selectedOption)
        else searchParams.delete(key.toString())
        setSearchParams(searchParams)
    }

    function updateRangeValues(filterOption: keyof typeof filterOptions, min: number, max: number) {
        setFilters((prevOptions: typeof filterOptions) => {
            const updatedValues = {
                ...filterOptions[filterOption].options,
                minimum: {
                    ...filterOptions[filterOption].options.minimum,
                    value: min,
                },
                maximum: {
                    ...filterOptions[filterOption].options.maximum,
                    value: max,
                },
            }
            return {
                ...prevOptions,
                [filterOption]: {
                    ...prevOptions[filterOption],
                    options: updatedValues
                }
            };
        })
    }

    function applyRange(filterOption: keyof typeof filterOptions) {
        searchParams.set(filterOption as string, `${filterOptions[filterOption].options.minimum.value},${filterOptions[filterOption].options.maximum.value}`)
        setSearchParams(searchParams)
    }

    function applyAll() {
        setFilters((prevFilters: typeof filterOptions) => {
            let updatedFilters = { ...prevFilters };
            Object.values(filterOptions).forEach((filterOption: any) => {
                if (filterOption.type == 'range') {
                    searchParams.set(filterOption.key, `${filterOption.options.minimum.value},${filterOption.options.maximum.value}`);
                    updatedFilters[filterOption.key].selectedValue = `${filterOption.options.minimum.value},${filterOption.options.maximum.value}`
                }
                else {
                    let check: string | null = null
                    updatedFilters[filterOption.key].selectedValue = null
                    Object.values(filterOption.options).forEach((option: any) => {
                        if (typeof option.value === 'boolean' && option.value === true) {
                            check = option.key;
                        }
                    });
                    if (check) {
                        updatedFilters[filterOption.key].selectedValue = check
                        searchParams.set(filterOption.key, check)
                    }
                    check = null
                }
            });
            setSearchParams(searchParams);
            return { ...updatedFilters };
        })
        
        setOffcanvasVisible(false); setRightOffCanvas(false);
    }

    function clearFilter(key: keyof typeof filterOptions) {
        setFilters((prevFilters: any) => {
            const updatedFilters = { ...prevFilters };

            if (updatedFilters[key]) {
                updatedFilters[key].selectedValue = null;

                for (const optionKey in updatedFilters[key].options) {
                    if (updatedFilters[key].options.hasOwnProperty(optionKey)) {
                        // Check if the filter has a range property
                        if (updatedFilters[key].range) {
                            const range = updatedFilters[key].range;
                            if (range) {
                                if (optionKey === 'minimum') {
                                    updatedFilters[key].options[optionKey].value = range[optionKey].value;
                                } else if (optionKey === 'maximum') {
                                    updatedFilters[key].options[optionKey].value = range[optionKey].value;
                                } else {
                                    updatedFilters[key].options[optionKey].value = false;
                                }
                            }
                        }
                        // If object has no range property 
                        else updatedFilters[key].options[optionKey].value = false;
                    }
                }
            }

            return updatedFilters;
        });
        searchParams.delete(key as string);
        setSearchParams(searchParams);
    }

    function handleClearAll() {
        Object.keys(filterOptions).forEach((key) => {
            clearFilter(key)
        })
    }
    function clearAroundMe () {
        searchParams.delete('aroundMe')
        setSearchParams(searchParams)
    }


    return (
        <>
            <div className='filter-card-wraper mb-3'>
                <Container className='d-md-none p-0'>
                    <Row className=' align-items-center justify-content-between filter-row'>
                        <Button className='filter-canvas-btn col-auto' onClick={() => { setOffcanvasVisible(!isOffcanvasVisible) }}>
                            <FaFilter className='mb-1 me-1'/>Filter
                        </Button>
                        <Button className='filter-canvas-btn col-auto' onClick={() => { setRightOffCanvas(!isRightOffCanvas) }}>
                            {(filterOptions.sortOrder.options.descending.value) ? <FaSortAmountDown />
                                : (filterOptions.sortOrder.options.ascending.value) ? <FaSortAmountUp />
                                    : <RxHamburgerMenu className='mb-1 me-1' />}
                            Sort By
                        </Button>
                    </Row>
                    <div className=' d-flex flex-wrap gap-1 align-items-center '>
                        {(searchParams.get('aroundMe') && searchParams.get('aroundMe')!=='') && <span className='fc-badges mt-2'> {searchParams.get('aroundMe')} <span onClick={clearAroundMe}><RxCross2 /></span></span>}
                        {searchParams.get('firstClassFree')!==null && <span className='fc-badges mt-2'> {'First Class Free'} <span onClick={()=>clearFilter('firstClassFree')}><RxCross2 /></span></span>}
                        {searchParams.get('classType')!==null && <span className='fc-badges mt-2'> {filterOptions.classType.options[searchParams.get('classType')!].label} <span onClick={()=>clearFilter('classType')}><RxCross2 /></span></span>}
                        {searchParams.get('groupOrPrivate')!==null && <span className='fc-badges mt-2'> {filterOptions.groupOrPrivate.options[searchParams.get('groupOrPrivate')!].label} <span onClick={()=>clearFilter('groupOrPrivate')}><RxCross2 /></span></span>}
                        {searchParams.get('priceRange')!==null && <span className='fc-badges mt-2'>        {searchParams.get('priceRange')?.includes(',') ? searchParams.get('priceRange')?.replace(',', '-') : searchParams.get('priceRange')} <span onClick={()=>clearFilter('priceRange')}><RxCross2 /></span></span>}
                        {searchParams.get('sortOrder')!==null && <span className='fc-badges mt-2'> {filterOptions.sortOrder.options[searchParams.get('sortOrder')!].label} <span onClick={()=>clearFilter('sortOrder')}><RxCross2 /></span></span>}
                        {searchParams.get('sortBy')!==null && <span className='fc-badges mt-2'> {filterOptions.sortOrder.options[searchParams.get('sortBy')!]?.label} <span onClick={()=>clearFilter('sortBy')}><RxCross2 /></span></span>}
                     </div>
                </Container>
                <Offcanvas show={isOffcanvasVisible} onHide={() => { setOffcanvasVisible(false) }} placement="bottom" className="filter-canvas">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title className='fc-top-name'>Filters</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className='p-3 pt-1'>
                        <Container>
                            <Row>
                                {
                                    Object.entries(filterOptions).map(([key, value], index) => (
                                        <>
                                            {(value as any).type === 'check'
                                                ? <><CheckboxGroupOffcanvas
                                                    data={value}
                                                    onToggle={onToggle} /></>
                                                : (value as any).type === 'checkbox'
                                                    ? <><CheckboxGroupOffcanvas
                                                        data={value}
                                                        onToggle={onToggle} /></>
                                                    : (value as any).type === 'checkbox-mobile'
                                                        ? <><CheckboxGroupOffcanvas
                                                            data={value}
                                                            onToggle={onToggle} /></>
                                                        : (value as any).type === 'range'
                                                            ? <><OffcanvasRangeselector
                                                                minimum={(value as any)?.range?.minimum.value || 0} maximum={(value as any)?.range?.maximum.value || 1200}
                                                                updateRangeValues={updateRangeValues} onApply={applyRange}
                                                                data={value} /></>
                                                            : null}</>
                                    ))
                                }
                            </Row>
                            <Row className="p-0" >
                                <Col xs={6}>
                                    <Button
                                        variant="link"
                                        onClick={handleClearAll}
                                        className="filter-cancel w-100">
                                        Clear
                                    </Button>
                                </Col>
                                <Col xs={6}>
                                    <Button
                                        className="filter-btn w-100"
                                        onClick={applyAll}>
                                        Apply
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Offcanvas.Body>
                </Offcanvas>

                <Offcanvas show={isRightOffCanvas} placement="bottom" onHide={() => { setRightOffCanvas(false) }} className="filter-canvas">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title className='fc-top-name'>Sort by</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className='p-4'>
                        <Container>
                            <Row>
                                {
                                    Object.entries(filterOptions).map(([key, value], index) => (
                                        <>
                                            {(value as any).type === 'right-multi-checkbox' ?
                                                <><CheckboxGroupOffcanvas
                                                    data={value}
                                                    onToggle={onToggle} /></> :
                                                (value as any).type === 'right-checkbox' ?
                                                    <><CheckboxGroupOffcanvas
                                                        data={value}
                                                        onToggle={onToggle} /></> : null}
                                        </>
                                    ))
                                }
                            </Row>
                            <Row className="p-0 mt-4" >
                                <Col xs={6}>
                                    <Button
                                        variant="link"
                                        onClick={handleClearAll}
                                        className="filter-cancel w-100">
                                        Clear
                                    </Button>
                                </Col>
                                <Col xs={6}>
                                    <Button
                                        className="filter-btn w-100"
                                        onClick={applyAll}>
                                        Apply
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Offcanvas.Body>
                </Offcanvas>

                <Container className='d-none d-md-flex justify-content-between '>
                    <Row className=' overflowX-auto flex-nowrap'>
                        {
                            Object.entries(filterOptions).map(([key, value], index) => {
                                const paramsValue = searchParams.get(key);
                                const filterValue = paramsValue && value.options[paramsValue] ? value.options[paramsValue].label : '';
                                return(
                                <><Col sm={'auto'}>{(value as any).type === 'check'
                                    ? <><CheckBox isSelected={searchParams.get((value as any).key) !== null}
                                    data={value} onToggle={onToggle} onApply={onApply} /></>
                                    : (value as any).type === 'checkbox'
                                        ? <><CheckboxGroup
                                            onToggle={onToggle} onApply={onApply} selectedValue={filterValue}
                                            isSelected={searchParams.get((value as any).key) !== null}
                                            data={value} clearFilter={clearFilter} /></>
                                        : (value as any).type === 'range'
                                            ? <><RangeSelector minimum={(value as any)?.range?.minimum.value || 0} maximum={(value as any)?.range?.maximum.value || 1200}
                                                updateRangeValues={updateRangeValues} onApply={applyRange} selectedValue={searchParams.get((value as any).key)}
                                                isSelected={searchParams.get((value as any).key) !== null}
                                                data={value} clearFilter={clearFilter} /></>
                                            : null}</Col></>
                            )})
                        }
                    </Row>
                    <Row className=' overflowX-auto flex-nowrap justify-content-evenly '>
                        {
                            Object.entries(filterOptions).map(([key, value], index) => {
                                const paramsValue = searchParams.get(key);
                                const filterValue = paramsValue && value.options[paramsValue] ? value.options[paramsValue].label : '';
                                return(
                                <><Col className='px-0 px-md-1' sm={'auto'} key={key}>
                                    {(value as any).type === 'right-multi-checkbox' ? <>
                                        <MutiCheckBoxGroup
                                            setFilters={setFilters}
                                            paramsKeys={(value as any).key} data={value} /></>
                                        : (value as any).type === 'right-checkbox' ?
                                            <><CheckboxGroup
                                                onToggle={onToggle} onApply={onApply} selectedValue={filterValue}
                                                isSelected={searchParams.get((value as any).key) !== null}
                                                data={value} clearFilter={clearFilter} /></>
                                            : null}
                                </Col></>)})

                        }
                    </Row>
                </Container>
            </div>
        </>)
}

export default FilterButtonsCard