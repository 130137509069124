import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

interface Search {
  searchType: string
  searchText: string 
  selectedSearchText: string
  isSelectedLocation: boolean
  selectedLocation: string | null
  locationText: string
}
const initialState: Search = {
  searchType: "",
  searchText: "",
  selectedSearchText: "",
  isSelectedLocation: false,
  selectedLocation: "",
  locationText: "",
}

const exploreSeekers = createSlice({
  name: "exploreSeekers",
  initialState,
  reducers: {
    setSearchType: (state, action: PayloadAction<string>) => {
      state.searchType = action.payload
    },
    setSearchText: (state, action: PayloadAction<any>) => {
      state.searchText = action.payload
      state.selectedSearchText = ""
    },
    re_setSearchText: (state) => {
      state.searchText = ''
      state.selectedSearchText = ""
    },
    setSelectedSearchText: (state, action: PayloadAction<string>) => {
      state.selectedSearchText = action.payload
      state.searchText = action.payload
    },
    setLocationText: (state, action: PayloadAction<string>) => {
      state.locationText = action.payload
    },

    setLocation: (state, action: PayloadAction<string>) => {
   
      state.selectedLocation = action.payload
    },
    setIsSelectedLocation: (state, action: PayloadAction<boolean>) => {
      state.isSelectedLocation = action.payload
    },
  },
})

export default exploreSeekers.reducer
export const {
  setSearchText,
  setSearchType,
  re_setSearchText,
  setSelectedSearchText,
  setIsSelectedLocation,
  setLocation,
  setLocationText
} = exploreSeekers.actions

export const locationState   = (state: RootState) => state.exploreSeekers
