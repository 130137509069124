import React from 'react'
import { Modal } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa6'

const ReportFinalStep = () => {
  return (
    <>
  <Modal.Body>
<div className=" report_modal1 d-flex justify-content-center flex-column align-items-center">
<FaCheck />
<p className="text-center">Your report is <br />
submitted successfully.
</p>
</div>
  <p></p>
  <p></p>
  </Modal.Body></>


  )
}

export default ReportFinalStep