import { Container, Row, Col, Form, Button, Badge, Spinner } from "react-bootstrap"
import ProfileAccountImg from "../../assets/images/profile-a.png"
import styles from "./ChatUi.module.css"
import { RootState, useAppDispatch } from "../../app/store"
import { useAppSelector } from "../../app/hooks"
import {
  Timestamp,
  arrayRemove,
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  where,
} from "firebase/firestore"
import { auth, db, database } from "../../app/firebase"
import { SeekerToken, selectCurrentUser } from "../../features/Auth/authSlice"
import { MutableRefObject, useEffect, useLayoutEffect, useRef, useState } from "react"
import {
  Message,
  chatApi,
  useGetMessagesQuery,
} from "../../features/chat/ChatApi"

import React from "react"
import { requestData } from "../../features/chat/ChatSlice"
import { FiCheck, FiEye } from "react-icons/fi"
import { useSearchParams } from "react-router-dom"
import SpinnerMain from "../spinner/SpinnerMain"
import { CirclesWithBar, ColorRing, Comment } from "react-loader-spinner"
import { getTimeDifferenceAsString } from "../../utils/timeDifferene/getTimeDifference"
import RenderProfile from "../../utils/RenderProfilePic/RenderProfilePic"
import { motion, AnimatePresence } from "framer-motion"
import { getMessage } from "@reduxjs/toolkit/dist/actionCreatorInvariantMiddleware"
import Dialogue from "./Dialogue"
import { IoArrowDown } from "react-icons/io5"
import { ItemWithISODate } from "../../Types"
import { useDebounce } from "../../utils/debouncer"


interface DialogueProps {
  selectedChatInfo: ItemWithISODate
  refference: any;
  reff: MutableRefObject<HTMLDivElement | null>;
}

const ChatDialogues: React.FC<DialogueProps> = ({ selectedChatInfo, reff }) => {
  const {user, providerToken , seekerToken} = useAppSelector(selectCurrentUser)
  const [messageLimit, setMessagesLimit] = useState(20)
  const [loader, setLoader] = useState(false)
  const [searchParams] = useSearchParams()
  const [lastVisible, setLastVisible] = useState<any>(null)
  const reqData = useAppSelector(requestData)
  const [ifChatScrolling, setIfScrolling] = useState<boolean>(false)
  const [ifAtBottom, _ifAtBottom] = useState<boolean>(false)
  const dispatch: any = useAppDispatch()
  const [messages, setMessages] = useState<Message[]>([])
  const { uid }: any = auth.currentUser
  const { data: chatMessages, isSuccess, isLoading, isFetching } = useGetMessagesQuery(
    { reqId: searchParams.get("requestId") || "", messageLimit: messageLimit },
    { refetchOnMountOrArgChange: true, skip: !searchParams.get("requestId"),
      selectFromResult: ({ data, isLoading, isSuccess, isFetching }) => ({ data, isLoading, isSuccess, isFetching }) },
  )
  useEffect(() => {
    dispatch(chatApi.util.invalidateTags(['getMessages']))
  }, [searchParams.get("requestId")])
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef<IntersectionObserver | null>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const loadeMoreChatDebounced = useDebounce(loadeMoreChat, 600)
  function loadeMoreChat () {
    setMessagesLimit((prev)=>{return prev+20})
  }

  // useEffect(() => {
  //   if (!chatContainerRef.current) return;

  //   const options = {
  //     root: chatContainerRef.current,
  //     rootMargin: '50px',
  //     threshold: 1.0
  //   };

  //   const callback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
  //     if (entries[0].isIntersecting && hasMore) {
  //       if (chatMessages && chatMessages[0]) setLastVisible(chatMessages[0])

  //       setMessagesLimit(messageLimit + 20)
  //       //  if(oldChatMessages){ setLastVisible(oldChatMessages[0])} else {if(chatMessages)
  //       //    setLastVisible(chatMessages[0])
  //       //   }
  //     }
  //   };

  //   observer.current = new IntersectionObserver(callback, options);

  //   if (chatContainerRef.current.firstChild) {
  //     observer.current.observe(chatContainerRef.current.firstChild as Element);
  //   }

  //   // Cleanup the observer on component unmount
  //   return () => observer.current?.disconnect();
  // }, [hasMore]);


  function handleScrollToBottom() {
    reff.current?.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    let timeoutId: string | number | NodeJS.Timeout | undefined;

    const handleScroll = () => {
      if (!chatContainerRef.current) return;

      const scrollTop = chatContainerRef.current.scrollTop;
      setIfScrolling(scrollTop > 400);
      if(!(scrollTop > 200)) {
        if(!isLoading && hasMore)  {
          loadeMoreChatDebounced()
        }
      }
      if (Math.round(scrollTop + 10) >= Math.round(chatContainerRef.current.scrollHeight - chatContainerRef.current.offsetHeight)) {
        clearTimeout(timeoutId);
        _ifAtBottom(true)
      }
      else _ifAtBottom(false)
      timeoutId = setTimeout(() => {
        setIfScrolling(false);
      }, 1100);
    };


    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
      clearTimeout(timeoutId);
    };
  }, [chatContainerRef]);

useEffect(()=>{
  if(hasMore && messageLimit<=20) reff.current?.scrollIntoView({ behavior: "smooth" })
}, [chatMessages, reff])

useEffect(()=>{
  if(chatMessages ) {
    if(chatMessages?.at(0)?.id==undefined) {setHasMore(false); setLastVisible(chatMessages?.at(0))}
    else setHasMore(true)
    // else if(chatMessages?.at(0)?.id!==undefined) chatContainerRef?.current?.scrollTo(0, 200)
  }
  if (providerToken && selectedChatInfo?.unreadSeekerCount?.length && chatMessages && searchParams.get('requestId') === selectedChatInfo.requestId) {
    const seenMessages = selectedChatInfo.unreadSeekerCount.filter((item: string) =>
      chatMessages.some((msg:Message) => msg.messageId === item)
    );  
    setDoc(doc(db, "requests", searchParams.get('requestId') || ""), { unreadSource: false , unreadSeekerCount:arrayRemove(...seenMessages) }, { merge: true }) 
  }
  if (seekerToken && selectedChatInfo?.unreadSourceCount?.length && chatMessages && searchParams.get('requestId') === selectedChatInfo.requestId) {
    const seenMessages = selectedChatInfo.unreadSourceCount.filter((item: string) =>
      chatMessages.some((msg:Message) => msg.messageId === item)
    );

    setDoc(doc(db, "requests", searchParams.get('requestId') || ""), { unread: false , unreadSourceCount:arrayRemove(...seenMessages) }, { merge: true }) 
  }
},[chatMessages])
  
  return  loader ?(
    <ColorRing
      visible={true}
      height="70"
      width="70"
      ariaLabel="color-ring-loading"
      wrapperStyle={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
      colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
    />
  ) : (
    <>
      <AnimatePresence mode="wait">
        <div
          className={`${styles["chat-dialogues"]}`}
          ref={chatContainerRef}
          style={{ scrollBehavior: "smooth" }}
        >
          {isFetching && <ColorRing
      visible={true}
      height="32"
      width="32"
      ariaLabel="color-ring-loading"
      wrapperStyle={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        position: 'absolute',
      }}
      colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
    />}
          {chatMessages &&
            Array.isArray(chatMessages) &&
            chatMessages.length > 0 &&
            chatMessages.map((message: any, index: number) => {
              const { date, senderId, senderMsg, read, id } = message
              const isLoggedInUser = senderId === uid
              const senderSubText =
                message?.senderId && messages[index - 1]?.senderId
                  ? messages[index - 1]?.senderId === message?.senderId
                  : false
              // const dateNow = getTimeDifferenceAsString(message)
              return (
                <React.Fragment key={message.id}>
                  <Dialogue senderSubText={senderSubText} DialogueData={message} isLoggedInUser={isLoggedInUser} selectedChatInfo={selectedChatInfo} />
                 
                </React.Fragment>
              )
            })}
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: ifChatScrolling ? 1 : 0 }}
            whileHover={{ opacity: ifAtBottom ? 0 : 1 }}
            transition={{ duration: 0.2, easing: 'easeOut', delay: 0.1 }}
            onClick={handleScrollToBottom}
            className={` btn scroll-button d-flex ratio-1x1 rounded-circle  ${styles["scroll-icon"]} bg-white bg-opacity-50 `}>
            <IoArrowDown />
          </motion.button>
          {/* This is the div that we reffer as bottom of chats */}
          <div ref={reff} />
        </div>
      </AnimatePresence>
    </>
  )
}

export default ChatDialogues
