import { useEffect, useRef, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
import Select from "react-select"
import ProviderStepper from "../mastersStepper/ProviderStepper"
import LandingHeader from "../../../navbars/TopNavbar"
import UploadProviderVedio from "../uploadProviderVedio/UploadProviderVedio"
import demoimage from "../../../../assets/img/card-image.png"
import thumbs from "../../../../assets/images/thumbs-up 1.png"
import playicon from "../../../../assets/img/play-icon.svg"
import {
  resetProviderFunel,
  resetProviderStepper,
  setIsStepValid,
  updateFunnel,
} from "../../../../features/ServiceFunnel/FeatProviderFunnel/providerFunnelSlice"
import { useAppDispatch } from "../../../../app/store"
import { useDeleteAdMutation, useUploadAdProfileDetailsMutation } from "../../../../features/featProviderDashboard/FeatProviderApi"
import VideoModal from "../../../modals/VideoModal/VideoModal"
import { useAppSelector } from "../../../../app/hooks"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { IoCloseOutline } from "react-icons/io5"
import { AiOutlineInfo } from "react-icons/ai"
import Cropper from "react-easy-crop"
import { Image } from "image-js"
import getCroppedImg from "./GetCroppedImg"
import FormRange from "react-bootstrap/esm/FormRange"
import CroperModal from "../../../modals/imageCroper/CroperModal"
import { CiCirclePlus } from "react-icons/ci"
import { FaMinus } from "react-icons/fa"
import SpinnerSecondary from "../../../spinner/SpinnerSecondary"
import { resetAdvertID } from "../../../../features/ServiceFunnel/ProviderImgVideoSlice/ProviderImgVideoSlice"
import { setModal, setModalName } from "../../../../features/Auth/ModalHandler/modalSlice"
import { MAX_AD_IMAGE_SIZE_MB, MAX_AD_VIDEO_SIZE_MB, MODALNAME } from "../../../../constants"
import { generateRandomFileName } from "../../../../utils/commonFunctions/randomFilenameGenerator"

const UploadProviderPhoto = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const adId = useAppSelector((state: any) => state.imgVideoSlice)
  const [videoBtn, setVideoBtn] = useState<boolean>(false)
  const [imgSrc, setImgSrc] = useState<any>(null)
  const [videoSrc, setVideoSrc] = useState<File | null>(null)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [infoactive, setInfoActive] = useState(false)
  const [pickImg, _pickImg] = useState<any>(null)

  const [
    uploadeMediaToAD,
    { data: profileUploadData, isLoading: loading, isSuccess, isError, error: adMediaUploadeError },
  ] = useUploadAdProfileDetailsMutation()

  const handleCloseModal = () => setShowModal(false)
  const handleSkipVideoModal = () => {
    setVideoBtn(false)
    setShowModal(false)
  }
  const handleInfoActive = () => {
    setInfoActive(!infoactive)
  }
  const handleShowModal = () => {
    if (!isError) {
      setTimeout(() => {
        setShowModal(true)
        setVideoBtn(true)
      }, 500)
    }
  }

  const [DeleteAd, { isLoading, isError: deleteError }] =
  useDeleteAdMutation()
  
  function handleImageChange (event: any) {
    const file = event.target.files[0]
    if (file && file.size <= MAX_AD_IMAGE_SIZE_MB * 1024 * 1024) {
      setImgSrc(file);
    } else {
      toast.error(`File size must be less than or equal to ${MAX_AD_IMAGE_SIZE_MB} MB`)
    }
  }
  const handleImageUploadClick = () => {
    ;(document as any).getElementById("image-upload").click()
  }
  const handleVideoChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        if (file.size > MAX_AD_VIDEO_SIZE_MB * 1024 * 1024) {
          toast.error(`File size exceeds ${MAX_AD_VIDEO_SIZE_MB} MB limit`);
          setVideoSrc(null);
        } else {
            setVideoSrc(event.target.files[0])
        }
      }
  }
  function onSuccess (res: any) {
      setVideoSrc(null);
      setImgSrc(null);
      _pickImg(null)
      dispatch(setModalName(MODALNAME.AD_CREATED));

        dispatch(resetProviderFunel())
        navigate(res?.data?._id ? `/source-dashboard/my-advt?adid=${res?.data?._id}` : '/source-dashboard/my-advt')
        toast.success("Ad Created Successfully")
        dispatch(resetAdvertID())
        setTimeout(() => {
          dispatch(resetProviderStepper());
        }, 1000);
  }
  function onFailed (error: any) {
    toast.error('Sorry, we are unable to update your request')
    console.error("Failed to upload media:", error);
    //DO IT only if ad uploade gives a lot of problems and throws error
    // Call the mutation to delete the ad if the upload fails
    //useDeleteAdMutation({ id: adId.adId });
    // ⚠️: Sensitive code
    DeleteAd({ adId: adId.adId })
  }
  useEffect(() => {
    if (adId?.adId && (pickImg || videoSrc)) {
      const formData = new FormData();

      if (videoSrc) {
        const extension = videoSrc?.name?.split('.').at(-1) || 'mp4'
        formData.append("profileVideo", videoSrc, generateRandomFileName(extension))
        uploadeMediaToAD({ id: adId.adId, adData: formData }).unwrap()
        .finally(()=>{
          formData.delete('profileVideo')
          if (pickImg) {
            formData.append("profileImage", pickImg)
          }
          uploadeMediaToAD({ id: adId.adId, adData: formData })
          .unwrap()
          .then((res) => {
            onSuccess(res)
          })
          .catch((error) => {
            onFailed(error)
          })
        })
        return
      }
      if (pickImg) {
        formData.append("profileImage", pickImg)
      }
      uploadeMediaToAD({ id: adId.adId, adData: formData })
        .unwrap()
        .then((res) => {
          onSuccess(res)
          // setVideoSrc(null);
          // setImgSrc(null);
          // _pickImg(null)
          // dispatch(setModalName(MODALNAME.AD_CREATED));

          //   dispatch(resetProviderFunel())
          //   navigate(res?.data?._id ? `/source-dashboard/my-advt?adid=${res?.data?._id}` : '/source-dashboard/my-advt')
          //   toast.success("Ad Created Successfully")
          //   dispatch(resetAdvertID())
          //   setTimeout(() => {
          //     dispatch(resetProviderStepper());
          //   }, 1000);
        })
        .catch((error) => {
          onFailed(error)
          // toast.error('Sorry, we are unable to update your request')
          // console.error("Failed to upload media:", error);
          // //DO IT only if ad uploade gives a lot of problems and throws error
          // // Call the mutation to delete the ad if the upload fails
          // //useDeleteAdMutation({ id: adId.adId });
          // // ⚠️: Sensitive code
          // DeleteAd({ adId: adId.adId })
        });
    }
  }, [adId.adId]);
  
  // useEffect(()=>{
  //   if(profileUploadData){
  //     setVideoSrc(null);
  //     setImgSrc(null);
  //     _pickImg(null)
  //     dispatch(setModalName(MODALNAME.AD_CREATED));

  //       dispatch(resetProviderFunel())
  //       navigate(profileUploadData?.data?._id ? `/source-dashboard/my-advt?adid=${profileUploadData?.data?._id}` : '/source-dashboard/my-advt')
  //       toast.success("Ad Created Successfully")
  //       dispatch(resetAdvertID())
  //       setTimeout(() => {
  //         dispatch(resetProviderStepper());
  //       }, 1000);
  //   }
  //   if(adMediaUploadeError) {
  //     toast.error('Sorry, we are unable to update your request')
  //     console.error("Failed to upload media:", adMediaUploadeError);
  //     //DO IT only if ad uploade gives a lot of problems and throws error
  //     // Call the mutation to delete the ad if the upload fails
  //     //useDeleteAdMutation({ id: adId.adId });
  //     // ⚠️: Sensitive code
  //     DeleteAd({ adId: adId.adId })
  //   }
  // },[profileUploadData, adMediaUploadeError])
  useEffect(()=>{
    if(pickImg!==null) {
      dispatch(setIsStepValid(false))
      handleShowModal()
    }
  },[pickImg])
  // useEffect(()=>{
  //   if(profileUploadData) {
  //     dispatch(resetProviderFunel())
  //     dispatch(resetAdvertID())
  //     navigate(profileUploadData?.data?._id ? `/source-dashboard/my-advt?adid=${profileUploadData?.data?._id}` : '/source-dashboard/my-advt')
  //     toast.success("Ad Created Successfully")
  //     setTimeout(() => {
  //       dispatch(resetProviderStepper());
  //     }, 1000);
  //   }
  //   if(isError) {
  //     toast.error('Sorry, we are unable to update your request')
  //   }
  // },[profileUploadData, isError])

  const imgPickerRef = useRef<any>(null)
  function closeCroper() {
    setImgSrc(null)
    if (imgPickerRef.current) {
      imgPickerRef.current.value = '';
    }
  }
  function removeSelectedImage () { _pickImg(null)}

  return (
    <>
      {loading && (<SpinnerSecondary />)}
      <section className="py-lg-5 funnel-section">
        <Container>
          <Row>
            <Col lg={6}>
              <div className=" d-flex flex-row-lg-reverse justify-content-lg-start justify-content-center">
                <div className="d-flex mb-5 flex-column  mt-5 gap-4"></div>
                {!videoBtn ? (
                  <div>
                    <p className="get-started-text fw-normal">
                      Upload Image
                      <span className=" text-danger">*</span>
                    </p>
                    <div className="image-box">
                      {(!imgSrc && pickImg==null) && (
                        <div
                          className="d-flex justify-content-center align-items-center h-100"
                          onClick={handleImageUploadClick}
                        >
                          <CiCirclePlus size={87} color="#EDEDED" />
                        </div>
                      )}
                      {pickImg && (
                        <img src={URL.createObjectURL(pickImg)} alt="Uploaded" />
                      )}
                    </div>
                    <div>
                      <input
                        type="file"
                        className="d-none"
                        id="image-upload"
                        onChange={handleImageChange}
                        accept=".jpg, .jpeg, .png"
                        ref={imgPickerRef}
                      />
                      <label
                        htmlFor="image-upload"
                        className="schedule-btn2 upload-btn mb-3"
                      >
                       {pickImg ? 'Replace Image' : 'Upload Image'}
                      </label>
                    </div>
                  </div>
                ) : (
                  <div>
                    <p className="get-started-text fw-normal">
                      Add your profile Video
                    </p>
                    <div
                      className="video-box"
                      style={{ width: "300px", height: "300px" }}
                    >
                      {videoSrc instanceof File ? (
                          <video
                            key={videoSrc.name} // Key based on file name
                            muted
                            className="video-uploaded-box"
                            loop
                            controls
                            autoPlay={true}
                            style={{ width: '100%' }}
                          >
                            <source
                              src={URL.createObjectURL(videoSrc)}
                              type={videoSrc.type}
                            />
                          {/* <source src={URL.createObjectURL(videoSrc)} type="video/mp4" /> */}
                          Your browser does not support the video tag.
                        </video>
                      )
                      :<>
                            <div
                              className="d-flex justify-content-center align-items-center h-100"
                              onClick={()=>{setShowModal(true)}}
                            >
                              <CiCirclePlus size={87} color="#EDEDED" />
                            </div>

                      </>}
                    </div>
                    <div className={` ${videoSrc ? "" : ""}`}>
                      <input
                        type="file"
                        className="d-none"
                        id="video-upload"
                        accept="video/mp4,video/mkv, video/x-m4v,video/*"
                        onChange={handleVideoChange}
                      />
                      <label
                        htmlFor="video-upload"
                        className="schedule-btn2 upload-btn mb-3"
                      >
                        {` ${videoSrc ? "Edit Video" : "Upload Video"}`}
                      </label>
                    </div>
                    {/* <div>
                      <input
                        type="file"
                        className="d-none"
                        id="video-upload"
                        accept="video/mp4,video/mkv, video/x-m4v,video/*"
                        onChange={handleVideoChange}
                      />
                    </div> */}
                  </div>
                )}
              </div>
            </Col>
            <Col lg={6}>
              <button className="popupinfo-btn" onClick={handleInfoActive}>
                <AiOutlineInfo />
              </button>
              <div
                className={
                  infoactive
                    ? "information-wrapper active"
                    : "information-wrapper"
                }
              >
                <div className="text-end mb-4">
                  <button
                    className="close-popup d-lg-none"
                    onClick={handleInfoActive}
                  >
                    <IoCloseOutline />
                  </button>
                </div>
                <div className="make-it-better">
                  <div
                    className="d-flex gap-2 mb-5 "
                    style={{ height: "2rem" }}
                  >
                    <img
                      src={thumbs}
                      alt="thumbs-up"
                      style={{ height: "2.5rem" }}
                    />
                    <h3 className="make-heading text-black fw-bolder fs-3 mt-2">
                    Did You Know?
                    </h3>
                  </div>
                  <p className="font-lg-16 mb-0">
                 <strong> Instant Setup ⚡ —</strong> Go live in minutes and start connecting with seekers through a seamless platform.
                  </p>
                  <ul><li>
                  <strong>Selecting the right image</strong> for your advertisement is crucial for attracting more users. Consider adding a video as a secondary option to enhance engagement—just ensure it’s under 2MB for optimal performance!</li></ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
        <VideoModal
          show={showModal}
          handleSkipVideoModal={handleSkipVideoModal}
          handleClose={handleCloseModal}
          handleVideoChange={handleVideoChange}
        />
      <CroperModal
        showModal={imgSrc}
        image={imgSrc}
        setImage={setImgSrc}
        setResults={_pickImg}
        handleClose={closeCroper}
        extension={"jpeg"} />
    </>
  )
}

export default UploadProviderPhoto
