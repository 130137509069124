import React from "react"
import Login from "../../components/modals/AuthComponents/loginModal/Login"
import SignUpAlt from "../../components/modals/AuthComponents/signupModal/Signup"
import Forgot from "../../components/modals/AuthComponents/forget/Forgot"
import Reset from "../../components/modals/AuthComponents/Reset/Reset"
import SuccesfullPassword from "../../components/modals/AuthComponents/ChnageSuccess/SuccesfulChange"
import SignUpSuccess from "../../components/modals/AuthComponents/SignupSuccess/SignUpSuccessfull"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import OTP from "../../components/Verification/Number/Otp"

interface authProps {}

const AuthCollection = ({}: authProps) => {
  const dispatch = useAppDispatch()
  // const { data } = useLoginQuery()
  const providerToken = useAppSelector((state) => state.auth.providerToken)
  const seekerToken = useAppSelector((state) => state.auth.seekerToken)

  return (
    <>
      <Login />
      <SignUpAlt />
      <Forgot />
      <Reset />
      <SuccesfullPassword />
      <SignUpSuccess />
      <OTP />
    </>
  )
}
export default AuthCollection;
