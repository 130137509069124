import React from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { logout, selectCurrentUser } from '../../../features/Auth/authSlice';
import { resetProviderFunel, resetProviderStepper } from '../../../features/ServiceFunnel/FeatProviderFunnel/providerFunnelSlice';
import { resetFunel, reset } from '../../../features/ServiceFunnel/FeatSeekerFunnel/seekerFunnelSlice';
import { signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import { useLogoutMutation, useLogoutProviderMutation } from '../../../features/Auth/authApi';
import { auth } from '../../../app/firebase';
import { RootState } from '../../../app/store';
import { useNavigate } from 'react-router-dom';
interface props {
    show: boolean;
    handleClose: () => void;
    data: any;
}
const LogoutConfirmationModal = ({ show, handleClose, data }: props) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { seekerToken, user } = useAppSelector(selectCurrentUser)
    const [
        logoutApi,
        { data: logoutData, isSuccess: logoutSuccess, isLoading: logoutLoading },
      ] = useLogoutMutation()
      const [
        logoutProvider,
        { data: providerData, isLoading: providerisLoading, error: providerError },
      ] = useLogoutProviderMutation()
    function commonLogoutCallbacks() {
        dispatch(logout())
        dispatch(resetProviderFunel())
        dispatch(resetFunel())
        dispatch(reset())
        dispatch(resetProviderStepper())
      }
      const signOutUser = () => {
        signOut(auth)
          .then(() => {
            handleClose()
            if (seekerToken) {
              toast.promise(
                new Promise((resolve, reject) => {
                    logoutApi().unwrap()
                      .then(() => {commonLogoutCallbacks();resolve('Logout successful!')})
                      .catch(() => reject(new Error("Logout failed")));
                }),
                {
                  pending: "Logging out...",
                  success: {render:"Logout successful!", autoClose: 1500},
                  error: "Something went wrong. Please try again later."
                }
              );
            } else {
              toast.promise(
                new Promise((resolve, reject) => {
                    logoutProvider().unwrap()
                      .then(() => {commonLogoutCallbacks();resolve('Logout successful!')})
                      .catch(() => reject(new Error("Logout failed")));
                }),
                {
                  pending: "Logging out...",
                  success: {render:"Logout successful!", autoClose: 1500},
                  error: "Something went wrong. Please try again later."
                }
              );
              navigate('/')
            }
          })
          .catch((error) => {
            toast.error("Something went wrong")
          })
      }
  return (
    <Modal show={show}
    onBackdropClick={handleClose}
    onHide={handleClose}
    centered>
        <Modal.Body>
            <Container className='px-sm-5 py-4'>
                <Row className='mb-2'>
                    <p className="get-started-text mb-1 fs-6">
                        Do you really want to logout !
                    </p>
                </Row>
                  <Row className='lh-1_2'>
                      {
                          (user?.isEmailVerified && user?.isMobileNoVerified) ? (
                              <p>Both your email and mobile number are verified. Do you want to log out?</p>
                          ) : (!user?.isEmailVerified && !user?.isMobileNoVerified) ? (
                  <p className='text-danger'>
                    Neither your email nor mobile number is verified or added. You may lose access. Log out anyway?
                  </p>
                          ) : (!user?.isEmailVerified || !user?.email) ? (
                              <p>Your email is not verified or missing. Log out without verifying?</p>
                          ) : (
                              <p>Your mobile number is not verified or missing. Log out without verifying?</p>
                          )
                      }
                  </Row>
                <Row className='mt-2'>
                    <Col xs={6}><Button variant='outline-secondary' className=" modal-btn-2" onClick={handleClose}>Cancel</Button></Col>
                    <Col xs={6}><Button className='schedule-session-btn modal-btn-2 delete-btn-2' onClick={signOutUser}>Logout</Button></Col>
                </Row>
            </Container>
        </Modal.Body>
    </Modal>
  )
}

export default LogoutConfirmationModal