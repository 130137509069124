import React, { Suspense, useEffect, useState, lazy } from "react"
import { Modal, Button, Form } from "react-bootstrap"
const TermsModalContent = lazy(
  () => import("../../termsModalContent/TermsModalContent"),
)
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { MODALNAME } from "../../../constants"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  logout,
  selectCurrentUser,
  setTermApproved,
} from "../../../features/Auth/authSlice"
import { RxCross2 } from "react-icons/rx"
import { useProviderTermsAndConditionMutation } from "../../../features/featProviderDashboard/FeatProviderApi"
import { useSeekerTermsAndConditionMutation } from "../../../features/featSeekerDashboard/seekerDashboardApi"
import { toast } from "react-toastify"
import { closeModal } from "../../../features/Auth/ModalHandler/modalSlice"
import { termsandConditionContent } from "../../../assets/content/termsandConditionscontent"

export const TermsAndConditionLable = () => {
  const [serarchParams, setSearchParams] = useSearchParams()
  const { isTermsConditionAccepted } = useAppSelector(
    (state: any) => state.auth,
  )
  const [isDisabled, setDisabled] = useState<boolean>(true)
  function handleShow() {
    serarchParams.set("modal", MODALNAME.TERMS_MODAL)
    setSearchParams(serarchParams)
  }
  const dispatch = useAppDispatch()
  useEffect(() => {
    setDisabled(!isTermsConditionAccepted)
  }, [isTermsConditionAccepted])
  return (
    <>
      <Form className="mt-3 ms-0 ms-md-4">
        <div
          className="mb-3 d-flex"
          onClick={() => {
            if (isDisabled) handleShow()
          }}
        >
          <Form.Check
            className="tc-check cursor-pointer"
            type="checkbox"
            id="default-checkbox"
            label="I agree to the"
            disabled={isDisabled}
            checked={isTermsConditionAccepted}
            onChange={(e) => {
              if (isTermsConditionAccepted)
                dispatch(setTermApproved(e.target.checked))
            }}
          />
          {/* <span>Terms and Conditons</span> */}
          <Button className="tc-text-btn" onClick={handleShow}>
            Terms and Conditons
          </Button>
        </div>
      </Form>
    </>
  )
}

const TermsAndCModal: React.FC = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useAppSelector(selectCurrentUser)
  const [acceptSeekerTnC, { isLoading: seekerLoading }] =
    useSeekerTermsAndConditionMutation()
  const [acceptProviderTnC, { isLoading: providerLoading }] =
    useProviderTermsAndConditionMutation()
  function handleClose() {
    searchParams.delete("modal")
    navigate(location.pathname, { replace: true })
  }
  function handleAceeptAndCloseModal() {
    dispatch(setTermApproved(true))
    handleClose()
  }
  function handleCheckbox() {
    if (user && !user?.termsAccepted) {
      const payload = { userType: user.role }
      if (user.role == "Seeker")
        acceptSeekerTnC(payload)
          .unwrap()
          .then(() => {
            handleAceeptAndCloseModal()
          })
          .catch((error: any) => {
            toast.error(error?.message)
          })
      else
        acceptProviderTnC(payload)
          .unwrap()
          .then(() => {
            handleAceeptAndCloseModal()
          })
          .catch((error) => {
            toast.error(error?.message)
          })
    } else handleAceeptAndCloseModal()
  }

  return (
    <>
      <Modal
        show={searchParams.get("modal") == MODALNAME.TERMS_MODAL}
        handleClose={handleClose}
        // size="lg"
        backdrop="static"
        dialogClassName="modal-90w"
        keyboard={false}
        backdropClassName="modal-backdrop-blur"
      >
        <Modal.Header className=" tc-header-items  d-flex justify-content-between">
          <Modal.Title className="tc-header-text">
            Terms and Conditions
          </Modal.Title>
          {user == null && (
            <Button variant="outline" className="" onClick={handleClose}>
              <RxCross2 />
            </Button>
          )}
        </Modal.Header>
        <Modal.Body className="p-0">
          <Suspense>
            <TermsModalContent content={termsandConditionContent}/>
          </Suspense>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-between">
          <div>
            {user === null && (
              <Button
                className="send-btn me-4 bg-transparent color-primary"
                variant="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
            )}
            <Button
              variant="primary"
              className="send-btn"
              disabled={seekerLoading || providerLoading}
              onClick={handleCheckbox}
            >
              {" "}
              Accept
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default TermsAndCModal
