import React, { useEffect } from 'react'
import { Container, Modal, ModalBody, Row } from 'react-bootstrap'
import Confetti from "react-confetti"
import { useAppDispatch } from '../../../app/store';

interface props {
    show: boolean;
    handleClose: ()=> void;
    data: any;
}

const AdCreatedModal = ({ show, handleClose, data }: props) => {
    const dispatch = useAppDispatch()
    useEffect(()=>{
        if(show) {
            setTimeout(() => {
                handleClose()
            }, 5000);
        }
    },[show])
  return (
    <Modal 
    show={show}
    // onBackdropClick={handleClose}
    centered>
        <ModalBody>
            <Container className="confetti-container">
                  <Confetti
                      numberOfPieces={500}
                      recycle={false}
                      gravity={0.3}
                      height={window.innerHeight - 400}
                      width={500}
                      className="z-1"
                  />
                <Row className='my-5 mx-2'>
                    <p className='fw-bold fs-3 text-center w-100 p-0'>🎉 Your Ad has been created</p>
                    <p className='fw-normal fs-5 text-center w-100'> You'll be notified once it's live</p>
                </Row>
            </Container>
        </ModalBody>
    </Modal>
  )
}

export default AdCreatedModal