import { configureStore, combineReducers } from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { api } from "../features/Auth/authApi"
import auth from "../features/Auth/authSlice"
import SeekerFunnelSlice from "../features/ServiceFunnel/FeatSeekerFunnel/seekerFunnelSlice"
import authModal, { closeModal } from "../features/Auth/ModalHandler/modalSlice"
import ProviderDashboardSlice from "../features/featProviderDashboard/FeatProviderDashboardSlice"
import seekerDashboardSlice from "../features/featSeekerDashboard/seekerDashboardSlice"
import imageVideoSlice from "../features/ServiceFunnel/ProviderImgVideoSlice/ProviderImgVideoSlice"
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import storage from "redux-persist/lib/storage"
import ProviderFunnelSlice from "../features/ServiceFunnel/FeatProviderFunnel/providerFunnelSlice"
import { seekerDashboardApi } from "../features/featSeekerDashboard/seekerDashboardApi"
import { providerDashboardApi } from "../features/featProviderDashboard/FeatProviderApi"
// import authModalSlice from "../features/Auth/ModalHandler/";
import { chatApi } from "../features/chat/ChatApi"
import exploreSeekers from "../features/featSearchAdvts/SearchAdvtSlice"
import { searchAdvtsApi } from "../features/featSearchAdvts/SearchAdvtsApi"
import { locationApi } from '../features/locationFunc/locationApi'
import { notificationApi } from '../features/notification/notificationApi'
import { setupListeners } from "@reduxjs/toolkit/query"
import ChatSlice from "../features/chat/ChatSlice"
import notificationSlice from "../features/notification/notificationSlice"
import { classCountsApi } from "../features/commonDashboardFeats/classRequestsCountApi"
import { firebaseAuthReducer } from '../features/firebaseAuth/firebaseAuthSlice'; 
import { socketApi } from "../features/Auth/socketApi"
import searchSlice from '../features/search/searchSlice';
import { toast } from "react-toastify"

const persistConfig = {
  key: "root",
  whitelist: [
    "funnelData",
    "auth",
    "seekerFunnel",
    "authProvider",
    "providerFunnel",
    // "seekerDashboardSlice",
    "ProviderDashboardSlice",
  ],
  version: 1,
  storage,
}
const unauthorizedMiddleware = (store: any) => (next: any) => (action: any) => {
  if (action.type.endsWith('rejected')) {
    // Assuming your error structure has a status field indicating the HTTP status
    let status = action.error?.status || action.payload?.status;
    if (status === 401) {
      // Dispatch a logout action or handle the error globally
      if (store.getState().auth.seekerToken ||
        store.getState().auth.providerToken) {
          store.dispatch(api.endpoints.logout.initiate())
          store.dispatch(closeModal())
          toast.dismiss()
          toast.error('Unusual activity detected',{delay: 1000, autoClose: 3000})
        }
      // Optionally, redirect or handle other UI effects
    }
  }
  return next(action);
};
const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [providerDashboardApi.reducerPath]: providerDashboardApi.reducer,
  [seekerDashboardApi.reducerPath]: seekerDashboardApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [searchAdvtsApi.reducerPath]: searchAdvtsApi.reducer,
  [locationApi.reducerPath]: locationApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [classCountsApi.reducerPath]: classCountsApi.reducer,
  [socketApi.reducerPath]: socketApi.reducer,
  firebaseAuth: firebaseAuthReducer,

  auth,
  seekerDashboardSlice,
  authModal,
  seekerFunnel: SeekerFunnelSlice,
  providerFunnel: ProviderFunnelSlice,
  providerSlice: ProviderDashboardSlice,
  imgVideoSlice: imageVideoSlice,
  searchSlice,
  ChatSlice,
  exploreSeekers,
  notificationSlice,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredPaths: ['postsApi'],  },
    }).concat([
      api.middleware,
      providerDashboardApi.middleware,
      seekerDashboardApi.middleware,
      chatApi.middleware,
      searchAdvtsApi.middleware,
      locationApi.middleware,
      notificationApi.middleware,
      classCountsApi.middleware,
      unauthorizedMiddleware,
      socketApi.middleware
    ]),
})

export const persistor = persistStore(store)
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type RootState = ReturnType<typeof store.getState>
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
setupListeners(store.dispatch)
