import { useState } from "react";
import '../skeletonLoaders/skeleton.css'
const ImageWithLoader = ({ src, alt }: any) => {
    const [loading, setLoading] = useState(true);
  
    const handleImageLoad = () => {
      setLoading(false);
    };
  
    return (
      <div className="image-container">
        {loading && <div className='skelton-img'/>}
        <img
          id="image"
          src={src}
          alt={alt}
          onLoad={handleImageLoad}
          onError={() => setLoading(false)}
          className={`${loading ? ' d-none' : 'd-block'}`}
        />
      </div>
    );
  };

export default ImageWithLoader