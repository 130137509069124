import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const siteKey = import.meta.env.VITE_RECAPTA_KEY;

interface Prop {
    show: boolean;
    onChange: (token: string | null) => void;
    onErrored: () => void;
    onExpired: () => void;
    className?: string;
}

const RecaptchaComponent = ({ show, onChange, onErrored, onExpired, className }: Prop) => {
    return (
        <div className={` ${show ? 'd-flex' : 'd-none'} justify-content-center align-items-center ${className ? className : ''}`}>
            <ReCAPTCHA
                sitekey={siteKey}
                onChange={onChange}
                onErrored={onErrored}
                onExpired={onExpired}
            />
        </div>
    );
}

export default RecaptchaComponent;
