import React, { useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"
import { motion } from "framer-motion"
import { fadeIn } from "../../../../Variants"
import "../LandingPage.css"
import {
  GiMusicSpell,
  GiNotebook,
  GiPiercingSword,
  GiSpotedFlower,
} from "react-icons/gi"
import { GrYoga } from "react-icons/gr"
import { GiMeditation } from "react-icons/gi"
import { MdSunnySnowing } from "react-icons/md"
import { PiFlowerLotusThin } from "react-icons/pi"
import LandingSearch from "../../../searchBars/LandingSearchBar"
import { setSelectedSearchText } from "../../../../features/featSearchAdvts/SearchAdvtSlice"
import { useAppDispatch } from "../../../../app/store"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import ReactGA from "react-ga4";

const BannerSearchSection = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const path = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const subjects = [
    { name: "Yoga", key: "Yoga", icon: <img  className="banner-search-icon" src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/018-yoga-pose%201.png" alt="" title="Yoga" /> },
    { name: "Ayurveda", key: "Ayurveda - Wellness and Healing Practices", icon: <img  className="banner-search-icon" style={{ width: "30px", height: "22px" ,objectFit:"cover" }} src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/ayurveda-icon.png" alt=""  title="Ayurveda"/> },
    { name: "Astrology", key: "Astrology and Counselling", icon: <img className="banner-search-icon" src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/pentagram-img.png" alt="" title="Astrology"/> },
    { name: "Vedic Rituals", key: "Vedic Rituals and Practices", icon:<img className="banner-search-icon" src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/vedic-rituals-img.png" alt="" title="Vedic Rituals" /> },
    { name: "Sanskrit", key: "Sanskrit Language Learning and Literature", icon: <img className="banner-search-icon" src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/sanskrit-subject.png" alt="" title="Sanskrit"/> },
    // { name: "Dance and Music", key: "Dance", icon: <img className="banner-search-icon" src="https://test-luxterior.s3.ap-south-1.amazonaws.com/other/art-and-musicc.png" alt="" title="Dance and Music"/> },
    { name: "Vastu", key: "Vedic Architecture - Vastu Shastra", icon: <img className="banner-search-icon" src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/vastu-sastra.png"  style={{ color: "#292625"}} alt="" title="Vastu"/> },
    { name: "Meditation", key: "Meditation and Well-being", icon: <img className="banner-search-icon" src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/016-mediation%20pose%201.png" alt="" title="Meditation" /> },
    { name: "Art and Music", key: "Art and Music", icon: <img className="banner-search-icon" src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/026-tabla%201.png" alt="" title="Art and Music" /> },
    // { name: "Other", key: "Other", icon: <img className="banner-search-icon" src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/026-tabla%201.png" alt="" title="Other" /> },
  ]

  const [selectedSubject, setSelectedSubject] = useState<string | null>(null)
  useEffect(() => {
    if (selectedSubject) {
      if (path?.pathname === "/") {
        navigate(`/results?vedicSubject=${selectedSubject}`)
      } else {
        const vedicSub = searchParams.get("vedicSubject")
        if (vedicSub !== selectedSubject) {
          searchParams.set("vedicSubject", selectedSubject)
          searchParams.set("pageNo", "1")
          setSearchParams(searchParams)
        }
      }
    }
  }, [selectedSubject])

  useEffect(()=>{
    const vedicSubject = searchParams.get('vedicSubject')
    if(vedicSubject) {
      setSelectedSubject(vedicSubject)
    }
  },[searchParams])
  return (
    <div>
      <div className="inner-hero">
        <Row className="justify-content-center">
          <Col md={11}>
            <motion.div
              variants={fadeIn("down", 0.1)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
            >
              <h1 id="home" className="hero-header mb-3">
                What You Seek Is Seeking You
              </h1>
              <p className="hero-text mb-md-5 mb-4">
                “Discover Your Path: Connect with sources you are seeking on
                <span className="primary-text "> VedicHom</span>”
              </p>

              <LandingSearch />
              <div className="keywords-wrapper mt-2">
                <div className="inner-keyword-wrapper">
                  {subjects.map((subject, index) => (
                    <motion.button
                      initial={{ opacity: 0 }}
                      viewport={{ once: true }}
                      whileInView={{ opacity: 1 }}
                      transition={{ duration: 0.3, delay: 0.05 * index }}
                      id="yourButtonId"
                      key={index}
                      className={`keyword-btn ${
                        (selectedSubject === subject.key ||selectedSubject === subject.name) ? "selected" : ""
                      }`}
                      onClick={() => {
                        dispatch(setSelectedSearchText(subject.name));
                        setSelectedSubject(subject.name);
                        // Google Analytics event tracking
                        ReactGA.event({
                          category: 'Subject',
                          action: `Select ${subject.name}`,
                          label: subject.name,
                        });
                      }}
                    >
                      {subject.icon} <span>{subject.name}</span>
                    </motion.button>
                  ))}
                </div>
              </div>
            </motion.div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BannerSearchSection
