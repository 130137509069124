import { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
import Select from "react-select"
import ProviderStepper from "../mastersStepper/ProviderStepper"
import LandingHeader from "../../../navbars/TopNavbar"
import "./ClassInfo.css"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import {
  setIsStepValid,
  updateFunnel,
} from "../../../../features/ServiceFunnel/FeatProviderFunnel/providerFunnelSlice"
import thumbs from "../../../../assets/images/thumbs-up 1.png"
import { IoCloseOutline } from "react-icons/io5"
import { AiOutlineInfo } from "react-icons/ai"
import { Prev } from "react-bootstrap/esm/PageItem"
import presentation1 from "../../../../assets/images/presentation 1.svg"
import arrowUp from "../../../../assets/img/arrow-up.png"
import arrowDown from "../../../../assets/img/arrow-down.png"
import infoIcon from "../../../../assets/img/infoIcon.png"
import group from "../../../../assets/images/Group.svg"
import solo from "../../../../assets/images/singleSvg.svg"
import video from "../../../../assets/images/video.svg"
import { string } from "yup"
import ToggleButton from "../../../buttons/ToggleButton"
import CustomTooltip from "../../../buttons/CustomTooltip"
import { RxValue } from "react-icons/rx"
import { RESPONSE_TIME, RESPONSE_TIME_LIST } from "../../../../constants"
import { LiaRupeeSignSolid } from "react-icons/lia"
import { toast } from "react-toastify"
import { GiDivert } from "react-icons/gi"
import { useDebounce } from "../../../../utils/debouncer"

interface PricesInterface {
  realTime: {
    group: {
      hourlyFee: number | null
      firstClassFree: boolean
    }
    private: {
      hourlyFee: number | null
      firstClassFree: boolean
    }
  }
  virtual: {
    group: {
      hourlyFee: number | null
      firstClassFree: boolean
    }
    private: {
      hourlyFee: number | null
      firstClassFree: boolean
    }
  }
}

// interface responseTimeType {
//   value: number | undefined,
//   key: string,
//   limits: {
//     size: number,
//     value: number
//   }
// }
// type ResponseLimitKey = 'Hours' | 'Days' | 'Weeks';
// const responseLimits: Record<ResponseLimitKey, { size: number; value: number }> = {
//   Hours: { size: 2, value: 24 },
//   Days: { size: 1, value: 7 },
//   Weeks: { size: 1, value: 5 }
// };

const ClassInfo = () => {
  const dispatch: any = useAppDispatch()
  //const [responseTime, _responseTime] = useState<responseTimeType>({value: undefined, key: 'Hours', limits: {size: 2, value: 24}})
  const [modeOfClass, setModeOfClass] = useState({
    realTime: false,
    virtual: false,
  })
  const [responseError, _responseError] = useState<string|null>(null)
  const [PriceError, ifPriceError] = useState<boolean>(false)

  const [prices, setPrices] = useState<PricesInterface>({
    realTime: {
      group: { hourlyFee: null, firstClassFree: true },
      private: { hourlyFee: null, firstClassFree: true },
    },
    virtual: {
      group: { hourlyFee: null, firstClassFree: true },
      private: { hourlyFee: null, firstClassFree: true },
    },
  })
  const [pricesCheckBox, setPricesCheckBox] = useState({
    realTime: {
      groupCheckBox: false,
      privateCheckBox: false,
    },
    virtual: {
      groupCheckBox: false,
      privateCheckBox: false,
    },
  })
  const [pricesError, SetpricesError] = useState({
    realTime: {
      group: false,
      private: false,
    },
    virtual: {
      group: false,
      private: false,
    },
  })
  const [ClassFields, setClassFields] = useState({
    modeOfClass: "",
    HourlyRate: "",
  })
  const providerFunnelDatas = useAppSelector(
    (state) => state.providerFunnel.providerFunnelData,
  )
  const [infoactive, setInfoActive] = useState(false)

  const handleInfoActive = () => {
    setInfoActive(!infoactive)
  }

  useEffect(() => {
    if (providerFunnelDatas?.modeOfClass && providerFunnelDatas?.hourlyRate) {
      setClassFields({
        modeOfClass: providerFunnelDatas.modeOfClass,
        HourlyRate: providerFunnelDatas.hourlyRate,
      })
    }
  }, [providerFunnelDatas])

  // function responseStringToObject(input: string): { key: string; value: string } {
  //   const response = input.split(' ');
  //   if (input.includes('Within')) {
  //     return { value: '1', key: (response.at(-1) || '') + 's' };
  //   } else {
  //     return { value: response[0], key: response.at(-1) || '' };
  //   }
  // }
  const handleSetModeofClass = (value: any) => {
    setModeOfClass((prev: any) => ({ ...prev, [value]: !prev[value] }))
  }
  const funnelData = useAppSelector((state) => state.providerFunnel)
  const data = funnelData?.providerFunnelData

  const numberInputOnWheelPreventChange = (
    e: React.WheelEvent<HTMLInputElement>,
  ) => {
    // Prevent the input value change
    const targetInput = e.target as HTMLInputElement
    targetInput.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      targetInput.focus()
    }, 0)
  }

  // function handleResponsetypeChange (e: any) {
  //   let currentValue: string = e.target.value;
  //   _responseTime((prev)=>{return({
  //     ...prev,
  //     limits: responseLimits[(currentValue as ResponseLimitKey)],
  //     key: e.target.value
  //   })})
  // }
  function handleResponsetypeChange (e: any) {
    dispatch(updateFunnel({responseTime: e.target.value}))
  }
  // function handleResponseValueChange (currentValue: string) {
  //   let numericCurrentValue = parseInt(currentValue)
  //   if (currentValue?.length > responseTime.limits.size) _responseError(`Response time can not be more than ${responseTime.limits.size} digits`)
  //   else if(numericCurrentValue > responseTime.limits.value) _responseError(`Response time can not be more than ${responseTime.limits.value} ${responseTime.key}`)
  //   else if(numericCurrentValue <=0) _responseError(`Response time can not be zero or negetive`)
  //   else {
  //     if(numericCurrentValue>1)  dispatch(updateFunnel({ responseTime: `${currentValue} ${responseTime.key}` }))
  //     else dispatch(updateFunnel({ responseTime: `Within a ${responseTime.key.slice(0,-1)}` }))
  //     _responseError(null)
  //   }
  // }
  function handleLecturesToggle (value: boolean) {
    dispatch(updateFunnel({recordedLect: value}))
  }

  // useEffect(()=>{if(responseTime.value)handleResponseValueChange(isNaN(responseTime.value) ? '' :responseTime.value.toString())},[responseTime])
  //price datastructure handling

  const toastErrorMessage = ()=>{
    toast.clearWaitingQueue()
    toast.error('Your monthly fee cannot exceed 1 lakh. ')
  }

  const debounceToastErrorMessage = useDebounce(toastErrorMessage,500);

  const handlePriceChange = (
    mode: any,
    classType: any,
    field: any,
    value: any,
  ) => {
    const currentValue = parseInt(value) || 0
    if(currentValue>99999) {
      debounceToastErrorMessage()
      return
    }
    if(currentValue % 1 !== 0) {
      toast.dismiss()
      toast.error('Price can not be a decimal value ')
      return
    }
    if (field === "hourlyFee") {
      if (value === "") {
        handlePricesErrorChange(mode, classType, false)
        dispatch(setIsStepValid(true))
      } else if (parseInt(value) <= 0) {
        handlePricesErrorChange(mode, classType, true)
        dispatch(setIsStepValid(false))
      } else {
        handlePricesErrorChange(mode, classType, false)
        dispatch(setIsStepValid(false))
      }
    }

    setPrices((prevPrices: any) => {
      const newdata = {
        ...prevPrices,
        [mode]: {
          ...prevPrices[mode],
          [classType]: {
            ...prevPrices[mode]?.[classType],
            [field]: parseInt(value),
          },
        },
      }
      dispatch(updateFunnel({ prices: {...newdata} }))
      return newdata
    })
  }
  //error handling
  const handlePricesErrorChange = (
    mode: "realTime" | "virtual",
    type: "group" | "private",
    value: boolean,
  ) => {
    SetpricesError((prevPricesError) => ({
      ...prevPricesError,
      [mode]: {
        ...prevPricesError[mode],
        [type]: value,
      },
    }))
  }
  //checkbox handling
  const handlePricesCheckBoxChange = (
    mode: "realTime" | "virtual",
    type: "groupCheckBox" | "privateCheckBox",
    value: boolean,
  ) => {
    if (!value) {
      setPrices((prevPrices: any) => ({
        ...prevPrices,
        [mode]: {
          ...prevPrices[mode],
          [type === "groupCheckBox" ? "group" : "private"]: null,
        },
      }))
    }

    setPricesCheckBox((prevPricesCheckBox) => ({
      ...prevPricesCheckBox,
      [mode]: {
        ...prevPricesCheckBox[mode],
        [type]: value,
      },
    }))
  }
  useEffect(()=>{
    if((providerFunnelDatas?.prices as any)?.realTime?.group?.hourlyFee !== undefined &&
    (providerFunnelDatas?.prices as any)?.realTime?.group?.hourlyFee !== null &&
    (providerFunnelDatas?.prices as any)?.realTime?.group !== undefined &&
    (providerFunnelDatas?.prices as any)?.realTime?.group !== null ) {
      setPricesCheckBox((prevPricesCheckBox) => ({...prevPricesCheckBox, realTime: {...prevPricesCheckBox.realTime, groupCheckBox: true}}))
      handlePriceChange("realTime","group","hourlyFee", (providerFunnelDatas?.prices as any)?.realTime?.group?.hourlyFee)
    }
    if((providerFunnelDatas?.prices as any)?.realTime?.private?.hourlyFee !== undefined &&
    (providerFunnelDatas?.prices as any)?.realTime?.private?.hourlyFee !== null &&
    (providerFunnelDatas?.prices as any)?.realTime?.private !== undefined &&
    (providerFunnelDatas?.prices as any)?.realTime?.private !== null ) {
      setPricesCheckBox((prevPricesCheckBox) => ({...prevPricesCheckBox, realTime: {...prevPricesCheckBox.realTime, privateCheckBox: true}}))
      handlePriceChange("realTime","private","hourlyFee", (providerFunnelDatas?.prices as any)?.realTime?.private?.hourlyFee)
    }
    if((providerFunnelDatas?.prices as any)?.virtual?.group?.hourlyFee !== undefined &&
    (providerFunnelDatas?.prices as any)?.virtual?.group?.hourlyFee !== null &&
    (providerFunnelDatas?.prices as any)?.virtual?.group !== undefined &&
    (providerFunnelDatas?.prices as any)?.virtual?.group !== null ) {
      setPricesCheckBox((prevPricesCheckBox) => ({...prevPricesCheckBox, virtual: {...prevPricesCheckBox.virtual, groupCheckBox: true}}))
      handlePriceChange("virtual","group","hourlyFee", (providerFunnelDatas?.prices as any)?.virtual?.group?.hourlyFee)
    }
    if((providerFunnelDatas?.prices as any)?.virtual?.private?.hourlyFee !== undefined &&
    (providerFunnelDatas?.prices as any)?.virtual?.private?.hourlyFee !== null &&
    (providerFunnelDatas?.prices as any)?.virtual?.private !== undefined &&
    (providerFunnelDatas?.prices as any)?.virtual?.private !== null ) {
      setPricesCheckBox((prevPricesCheckBox) => ({...prevPricesCheckBox, virtual: {...prevPricesCheckBox.virtual, privateCheckBox: true}}))
      handlePriceChange("virtual","private","hourlyFee", (providerFunnelDatas?.prices as any)?.virtual?.private?.hourlyFee)
    }
  },[])

  useEffect(() => {
    const RealTimeGroupError =  prices?.realTime?.group?.hourlyFee !== null ? isNaN(prices?.realTime?.group?.hourlyFee) ? true : prices?.realTime?.group?.hourlyFee < 1 : true
    const RealTimePrivateError =   prices?.realTime?.private?.hourlyFee !== null ? isNaN(prices?.realTime?.private?.hourlyFee) ? true : prices?.realTime?.private?.hourlyFee < 1 : true

    const VirtualGroupError = prices?.virtual?.group?.hourlyFee !== null ? isNaN(prices?.virtual?.group?.hourlyFee) ? true : prices?.virtual?.group?.hourlyFee < 1 : true
    const VirtualPrivateError = prices?.virtual?.private?.hourlyFee !== null ? isNaN(prices?.virtual?.private?.hourlyFee) ? true : prices?.virtual?.private?.hourlyFee < 1 : true
    
    if (!pricesCheckBox?.realTime?.groupCheckBox && !pricesCheckBox?.realTime?.privateCheckBox && !pricesCheckBox?.virtual?.groupCheckBox && !pricesCheckBox?.virtual?.privateCheckBox) dispatch(setIsStepValid(true))
    else if (
      (pricesCheckBox?.realTime?.groupCheckBox ? pricesError?.realTime?.group || RealTimeGroupError: false) ||
      (pricesCheckBox?.realTime?.privateCheckBox ? pricesError?.realTime?.private || RealTimePrivateError: false) || 
      (pricesCheckBox?.virtual?.groupCheckBox ? pricesError?.virtual?.group || VirtualGroupError: false) || 
      (pricesCheckBox?.virtual?.privateCheckBox ? pricesError?.virtual?.private || VirtualPrivateError: false) ||
      providerFunnelDatas?.responseTime === RESPONSE_TIME.none
    ) {dispatch(setIsStepValid(true)); ifPriceError(true)} // if true then step is invalid
    else { 
      dispatch(setIsStepValid(false)); ifPriceError(false)}
  }, [prices, pricesCheckBox, pricesError, providerFunnelDatas])

  return (
    <>
      <section className="py-lg-1 funnel-section">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="">
                <div className="mb-5">
                  <form>
                    <label className="green-text mb-2 font-14 d-flex gap-2 justify-content-start align-items-center">
                      <p className=" text-black fw-bolder fs-5">Select </p>
                      <p className=" fs-6 fw-bolder mt-1 ">Mode of class</p>
                      <p>*</p>
                    </label>
                    {PriceError&&<p className=" text-danger">Please fill all the fields appropriately </p>}
                    <div className="d-flex flex-column gap-2">
                      <div
                        className={`class-mode-box cursor-pointer ${
                          modeOfClass?.realTime ? "active" : ""
                        }`}
                        onClick={() => handleSetModeofClass("realTime")}
                      >
                        <div className="d-flex ms-3 align-items-center gap-2">
                          <img src={presentation1} alt="presentation" />
                          <p className=" fw-semibold text-black my-auto">
                            Real Time
                          </p>
                        </div>
                        <img
                          src={`${modeOfClass?.realTime ? arrowDown : arrowUp}`}
                          alt="arrowUp"
                          className="class-mode-arrow-btn"
                        />
                      </div>
                      <div
                        className={`lower-content d-flex flex-column gap-2 ${
                          modeOfClass?.realTime ? "show" : ""
                        }`}
                      >
                        <p className="d-flex gap-1">
                          Class <span className="green-text">Type</span>{" "}
                          <span className=" text-black-50 fs-12 mt-1 mt-md-0">
                            (You can select both options)
                          </span>{" "}
                        </p>
                        <div className="group-checkbox-container ">
                          <div className="d-flex align-items-center gap-3  mb-3 ">
                            <input
                              type="checkbox"
                              id="groupClass"
                              className="class-checkbox"
                              checked={pricesCheckBox?.realTime?.groupCheckBox}
                              onClick={() =>
                                handlePricesCheckBoxChange(
                                  "realTime",
                                  "groupCheckBox",
                                  !pricesCheckBox?.realTime?.groupCheckBox,
                                )
                              }
                            />
                            <div className="d-flex gap-1">
                              <p className=" text-black my-auto">Group Class</p>
                              <img src={group} alt="group" />
                            </div>
                          </div>
                        </div>
                        {pricesCheckBox?.realTime?.groupCheckBox && (
                          <div className="d-flex justify-content-between">
                            <p className=" fw-bold f-sm-14">
                              Hourly Rate
                              <p
                                className="hourly-rate-text"
                                style={{ fontSize: "10px" }}
                              >
                                Enter your hourly charges
                              </p>
                            </p>
                            <div>
                            <div
                              className="mb-1 px-3 position-relative">
                              <input
                                type="number"
                                name="realTime_Group_HourlyRate"
                                placeholder="999..."
                                className={`provider-field rate-field w-100 outline-0 font-14 py-2 shadow-none rounded-4 ${(PriceError && pricesCheckBox?.realTime?.groupCheckBox && prices?.realTime?.group?.hourlyFee == null ) ? 'outline-blink--infinite' : ''}`}
                                style={{ paddingRight: "3rem" }}
                                min={1}
                                disabled={
                                  !pricesCheckBox?.realTime?.groupCheckBox
                                }
                                defaultValue={(providerFunnelDatas?.prices as any)?.realTime?.group?.hourlyFee}
                                value={ prices?.realTime?.group?.hourlyFee as any
                                }
                                onChange={(e) => {
                                  handlePriceChange(
                                    "realTime",
                                    "group",
                                    "hourlyFee",
                                    e.target.value,
                                  )
                                }}
                                onWheel={numberInputOnWheelPreventChange}
                              />
                              <span
                                style={{
                                  position: "absolute",
                                  right: "2rem",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  height: "24px",
                                }}
                                className="font-14"
                              >
                                <LiaRupeeSignSolid />/hr
                              </span>
                              
                            </div>
                            <div>{pricesError?.realTime?.group && (
                                <div className="text-danger price-negative-text">
                                  Rates cannot be zero or negative
                                </div>
                              )}</div>
                            </div>
                            
                          </div>
                        )}

                        {/* private class */}
                        <div className="group-checkbox-container ">
                          <div className="d-flex align-items-center gap-3 mb-3 ">
                            <input
                              type="checkbox"
                              id="privateClass"
                              className="class-checkbox"
                              checked={pricesCheckBox?.realTime?.privateCheckBox}
                              onClick={() =>
                                handlePricesCheckBoxChange(
                                  "realTime",
                                  "privateCheckBox",
                                  !pricesCheckBox?.realTime?.privateCheckBox,
                                )
                              }
                            />
                            <div className="d-flex">
                              <p className=" text-black my-auto">
                                Private Class
                              </p>
                              <img src={solo} alt="group" />
                            </div>
                          </div>
                        </div>
                        {pricesCheckBox?.realTime?.privateCheckBox && (
                          <div className="d-flex justify-content-between">
                            <p className=" fw-bold f-sm-14 ">
                              Hourly Rate
                              <p
                                className="hourly-rate-text"
                                style={{ fontSize: "10px" }}
                              >
                                Enter your hourly charges
                              </p>
                            </p>
                            <div>
                            <div
                              className="mb-4 px-3 position-relative"
                            >
                              <input
                                type="number"
                                name="hourlyRate"
                                placeholder="999..."
                                className={`provider-field rate-field w-100 outline-0 font-14 py-2 shadow-none rounded-4 ${(PriceError && pricesCheckBox?.realTime?.privateCheckBox && prices?.realTime?.private?.hourlyFee == null ) ? 'outline-blink--infinite' : ''}`}
                                style={{ paddingRight: "3rem" }}
                                disabled={
                                  !pricesCheckBox?.realTime?.privateCheckBox
                                }
                                defaultValue={(providerFunnelDatas?.prices as any)?.realTime?.private?.hourlyFee}
                                min={1}
                                value={
                                  prices?.realTime?.private?.hourlyFee as any
                                }
                                onChange={(e) => {
                                  handlePriceChange(
                                    "realTime",
                                    "private",
                                    "hourlyFee",
                                    e.target.value,
                                  )
                                }}
                                onWheel={numberInputOnWheelPreventChange}
                              />
                              <span
                                style={{
                                  position: "absolute",
                                  right: "2rem",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  height: "24px",
                                }}
                                className="font-14"
                              >
                                <LiaRupeeSignSolid />/hr
                              </span>
                             
                            </div>
                            <div> {pricesError?.realTime?.private && (
                                <div className="text-danger price-negative-text">
                                  Rates cannot be zero or negative
                                </div>
                              )}</div>
                            </div>
                            
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <div
                        className={`class-mode-box cursor-pointer ${
                          modeOfClass.virtual ? "active" : ""
                        }`}
                        onClick={() => handleSetModeofClass("virtual")}
                      >
                        <div className="d-flex ms-3 align-items-center gap-2">
                          <img src={video} alt="presentation" />
                          <p className=" fw-semibold text-black my-auto">
                            Virtual
                          </p>
                        </div>
                        <img
                          src={`${modeOfClass.virtual ? arrowDown : arrowUp}`}
                          alt="arrowUp"
                          className=" class-mode-arrow-btn"
                        />
                      </div>
                      <div
                        className={`lower-content d-flex flex-column gap-2 ${
                          modeOfClass.virtual ? "show" : ""
                        }`}
                      >
                        <p className="d-flex gap-1">
                          Class <span className="green-text">Type</span>{" "}
                          <span className=" text-black-50 fs-12 mt-1 mt-md-0">
                            (You can select both options)
                          </span>{" "}
                          {/* <CustomTooltip content={" "}/> */}
                        </p>
                        <div className="group-checkbox-container ">
                          <div className="d-flex align-items-center gap-3 mb-3 ">
                            <input
                              type="checkbox"
                              id="virtual_groupClass"
                              className="class-checkbox"
                              checked={pricesCheckBox?.virtual?.groupCheckBox}
                              onClick={() =>
                                handlePricesCheckBoxChange(
                                  "virtual",
                                  "groupCheckBox",
                                  !pricesCheckBox?.virtual?.groupCheckBox,
                                )
                              }
                            />
                            <div className="d-flex gap-1">
                              <p className=" text-black my-auto">Group Class</p>
                              <img src={group} alt="group" />
                            </div>
                          </div>
                        </div>
                        {pricesCheckBox?.virtual?.groupCheckBox && (
                          <div className="d-flex justify-content-between">
                            <p className=" fw-bold f-sm-14">
                              Hourly Rate
                              <p
                                className=" hourly-rate-text"
                                style={{ fontSize: "10px" }}
                              >
                                Enter your hourly charges
                              </p>
                            </p>
                            <div><div
                              className="mb-1 px-3"
                              style={{ position: "relative" }}
                            >
                              <input
                                type="number"
                                name="hourlyRate"
                                placeholder="999..."
                                defaultValue={(providerFunnelDatas?.prices as any)?.virtual?.group?.hourlyFee}
                                disabled={
                                  !pricesCheckBox?.virtual?.groupCheckBox
                                }
                                className={`provider-field rate-field w-100 outline-0 font-14 py-2 shadow-none rounded-4 ${(PriceError && pricesCheckBox?.virtual?.groupCheckBox && prices?.virtual?.group?.hourlyFee  == null ) ? 'outline-blink--infinite' : ''}`}
                                style={{ paddingRight: "3rem" }}
                                min={1}
                                value={(providerFunnelDatas?.prices as any)?.virtual?.group?.hourlyFee ||
                                  prices?.virtual?.group?.hourlyFee as any}
                                onChange={(e) => {
                                  handlePriceChange(
                                    "virtual",
                                    "group",
                                    "hourlyFee",
                                    e.target.value,
                                  )
                                }}
                                onWheel={numberInputOnWheelPreventChange}
                              />
                              <span
                                style={{
                                  position: "absolute",
                                  right: "2rem",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  height: "24px",
                                }}
                                className="font-14"
                              >
                                <LiaRupeeSignSolid />/hr
                              </span>
                             
                            </div>
                            <div> {pricesError?.virtual?.group && (
                                <div className="text-danger price-negative-text">
                                  Rates cannot be zero or negative
                                </div>
                              )}</div></div>
                          </div>
                        )}

                        {/* private class */}
                        <div className="group-checkbox-container  mb-3">
                          <div className="d-flex align-items-center gap-3 ">
                            <input
                              type="checkbox"
                              id="privateClass"
                              className="class-checkbox"
                              checked={pricesCheckBox?.virtual?.privateCheckBox}
                              onClick={() =>
                                handlePricesCheckBoxChange(
                                  "virtual",
                                  "privateCheckBox",
                                  !pricesCheckBox?.virtual?.privateCheckBox,
                                )
                              }
                            />
                            <div className="d-flex gap-1">
                              <p className=" text-black my-auto">
                                Private Class
                              </p>
                              <img src={solo} alt="group" />
                            </div>
                          </div>
                        </div>
                        {pricesCheckBox?.virtual?.privateCheckBox && (
                          <div className="d-flex justify-content-between">
                            <p className=" fw-bold f-sm-14">
                              Hourly Rate
                              <p
                                className="hourly-rate-text"
                                style={{ fontSize: "10px" }}
                              >
                                Enter your hourly charges
                              </p>
                            </p>
                            
                            <div> <div
                              className="mb-1 px-3"
                              style={{ position: "relative" }}
                            >
                              <input
                                type="number"
                                name="hourlyRate"
                                placeholder="999..."
                                className={`provider-field rate-field w-100 outline-0 font-14 py-2 shadow-none rounded-4 ${(PriceError && pricesCheckBox?.virtual?.privateCheckBox && prices?.virtual?.private?.hourlyFee == null ) ? 'outline-blink--infinite' : ''}`}
                                style={{ paddingRight: "3rem" }}
                                min={1}
                                disabled={
                                  !pricesCheckBox?.virtual?.privateCheckBox
                                }
                                defaultValue={(providerFunnelDatas?.prices as any)?.virtual?.private?.hourlyFee}
                                value={prices?.virtual?.private?.hourlyFee as any
                                }
                                onChange={(e) => {
                                  handlePriceChange(
                                    "virtual",
                                    "private",
                                    "hourlyFee",
                                    e.target.value,
                                  )
                                }}
                                onWheel={numberInputOnWheelPreventChange}
                              />
                              <span
                                style={{
                                  position: "absolute",
                                  right: "2rem",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  height: "24px",
                                }}
                                className="font-14"
                              >
                                <LiaRupeeSignSolid />/hr
                              </span>
                              
                            </div>
                            <div>{pricesError?.virtual?.private && (
                                <div className="text-danger price-negative-text">
                                  Rates cannot be zero or negative
                                </div>
                              )}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                    <div className="green-text mt-3 font-14 d-flex gap-2 justify-content-between align-items-center">
                      <div className= "d-flex" >
                      <p className=" text-black fw-bolder fs-5 mb-0">Recorded</p>
                      <p className=" fs-6 fw-bolder ms-2 mt-1 mb-0"> Lectures</p>
                      </div>
                      
                      <ToggleButton defaultVal={providerFunnelDatas.recordedLect} onChange={handleLecturesToggle}/>
                        
                        
                    </div>
                    
                      <Row
                        className="mb-3 w-100"
                        style={{ position: "relative" }}
                      >
                        <p className="col-10  ">Do you also provide recorded video lectures</p> 
                      </Row>
                    </div>
                    <div>
                    <label className="green-text font-14 d-flex gap-2 justify-content-start align-items-center">
                      <p className=" text-black fw-bolder fs-5 mb-0">Response </p>
                      <p className=" fs-6 fw-bolder mt-1 mb-0 ">Time</p>
                      <p className="text-danger mb-0">*</p>
                    </label>
                    <p>What is your average response time to requests <CustomTooltip content={" What is your average response time any requests "}/></p>
                      <div>
                        <Form.Select
                        defaultValue={providerFunnelDatas.responseTime} 
                        onChange={(e)=>{handleResponsetypeChange(e)}}
                        className="response-hours-input w-100 outline-0 font-14 py-2 shadow-none rounded-4 " >
                        {RESPONSE_TIME_LIST.map((RESPONSES)=>{
                          if(RESPONSES.key == RESPONSE_TIME.none) return (<option value={RESPONSES.key} disabled hidden>{RESPONSES.value}</option>)
                          return (<option value={RESPONSES.key}>{RESPONSES.value}</option>)
                        })}
                        </Form.Select>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Col>
            <Col lg={1}></Col>
            <Col lg={5}>
              <button className="popupinfo-btn" onClick={handleInfoActive}>
                <AiOutlineInfo />
              </button>
              <div
                className={
                  infoactive
                    ? "information-wrapper active"
                    : "information-wrapper"
                }
              >
                <div className="text-end mb-4">
                  <button
                    className="close-popup d-lg-none"
                    onClick={handleInfoActive}
                  >
                    <IoCloseOutline />
                  </button>
                </div>
                <div className="make-it-better">
                  <div
                    className="d-flex gap-2 mb-5 "
                    style={{ height: "2rem" }}
                  >
                    <img
                      src={thumbs}
                      alt="thumbs-up"
                      style={{ height: "2.5rem" }}
                    />
                    <h3 className="make-heading text-black fw-bolder fs-3 mt-2">
                    Did You Know?
                    </h3>
                  </div>
                  <p className="font-lg-16 mb-0">
                  <strong>Stand Out with Visuals 🎥 —</strong> Inform users whether you offer in-person or online classes, and select your fees based on individual or group sessions.
                  </p>
                  <ul>
                    <li><strong>Recorded lectures</strong> are available, so be sure to inform users about them.</li>
                    <li> <strong>Inform users</strong> of your typical response time for queries. Once you start receiving inquiries, our algorithm will automatically track your response time, so be prompt—don’t keep your users waiting.</li>

                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default ClassInfo
