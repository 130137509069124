import { useCallback } from 'react';

export function useDebounce(callback: (...args: any[]) => void, delay: number) {
  const debouncedCallback = useCallback(
    debounce((...args) => callback(...args), delay),
    [callback, delay]
  );

  return debouncedCallback;
}

function debounce(func: (...args: any[]) => void, wait: number) {
  let timeout: NodeJS.Timeout;

  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
