import React from 'react'
import "./NoResultsFound.css"
import NosearchFoundImg from "../../../../assets/img/no-search-found-img.png"
import { Button } from 'react-bootstrap'

const NoResultsFound = ({handleReset}: any) => {
  return (
    <div className='no-search-result-box ad-card d-flex flex-column align-items-center  gap-3 justify-content-center'>
        <img src={NosearchFoundImg} alt=""  width={213}  height={83} title='No Results Found'/>

        <div >
            <h2 className='no-serach-head-text'>No Data Found</h2>
            <p className='text-align-center'>Sorry, the keyword you entered cannot be found,  <span className='d-md-block '> please check again or search  with another keyword. </span></p>

         <div className='d-flex align-items-center justify-content-center'> <Button onClick={handleReset}>Retry</Button></div> 
        </div>


    </div>
  )
}

export default NoResultsFound