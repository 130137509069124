import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useScrollTracking from '../../features/googleAnalytics/useScrollTracking';
import ReactGA from 'react-ga4';

const AnalyticsMeasurer = () => {
    const location = useLocation();
    useScrollTracking();

    useEffect(() => {
        // Track page views
        const pageTitle = document.title; // Get the current page title
        ReactGA.set({ page: location.pathname, title: pageTitle });
        ReactGA.send('pageview'); 

        const startTime = Date.now();
        return () => {
            const timeSpent = Math.floor((Date.now() - startTime) / 1000); // Time in seconds
            ReactGA.event({
                category: 'Engagement',
                action: 'Time on Page',
                label: location.pathname,
                value: timeSpent,
            });
        };
    }, [location]);

    useEffect(() => {
        // Global click event listener
        const handleClick = (event: { target: any; }) => {
            const clickedElement = event.target;
            if (['BUTTON', 'A', 'P'].some(tag => tag === clickedElement.tagName)) {
                const elementLabel = clickedElement.textContent || clickedElement.getAttribute('aria-label') || 'Unknown';
                ReactGA.event({
                    category: 'User',
                    action: 'Click',
                    label: `${elementLabel} - ${location.pathname}`, // Include the page in the label
                });
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [location]);

    return <div />; 
};

export default AnalyticsMeasurer;
