import React, { useEffect, useState } from "react"
import { Modal, Button } from "react-bootstrap"
import "./PaymentSuccessfully.css"
import Confetti from "react-confetti"
import paymentdone from "../../../assets/img/paymentdonedaimond.png"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useAppSelector } from "../../../app/hooks"
import { selectCurrentUser } from "../../../features/Auth/authSlice"
import {
  dateReturnFormat,
  dateSeparator,
  getDateConverted,
  getTimeDifferenceFromServerTime,
} from "../../../utils/timeDifferene/getTimeDifference"
import { PREMIUM_PASSES, PassType } from "../../../constants"

const PaymentSuccessfullyModal = ({ show }: any) => {
  const [searchParams] = useSearchParams()
  const { user, seekerToken } = useAppSelector(selectCurrentUser)
  const navigate = useNavigate()
  const location = useLocation()
  const [plan, setPlan] = useState<number | null>(null)
  function handleClose() {
    searchParams.delete("payment")
    navigate(location.pathname, { replace: true })
  }
  function handleGotoDashboard() {
    searchParams.delete("payment")
    navigate(`${seekerToken ? "/seeker-dashboard" : "/source-dashboard"}`, {
      replace: true,
    })
  }
  function handleCheck() {
    navigate(
      `${
        seekerToken
          ? `/payment-sub/${user?._id}/subscription_plan`
          : `/payment_pro/${user?._id}/subscription_plan`
      }`,
      { replace: true },
    )
  }
  useEffect(() => {
    const durationInMonths =
      PREMIUM_PASSES[user?.subscriptionDetails[0]?.packageName as PassType]
        ?.expireInMonths
    if (!isNaN(durationInMonths)) setPlan(durationInMonths * 30)
    return () => {
      setPlan(null)
    }
  }, [user])


  return (
    <>
      <Modal
        show={show}
        onHide={() => handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        animation={false}
        className="modal fade"
      >
        <Modal.Body className="payment-success-body">
          <div className="confetti-container">
          <Confetti
          numberOfPieces={500}
          recycle={false}
          gravity={0.1} // Adjust gravity to control the falling speed
          height={window.innerHeight - 200}
          width={800}
          className="z-1"
        />
          </div>
          <div className="payment-success-content z-3">
            <img
              src={paymentdone}
              alt="Congratulations"
              className="payment-success-image"
            />
            <h2>Congratulations</h2>
            {user?.isPremium &&
              user?.subscriptionDetails?.length > 1 &&
              plan && (
                <>
                  <p>{`Enjoy the next ${plan} days of extended membership.`}</p>
                  <div className="upgrade-plan-btn-text">{`Valid till ${getDateConverted(
                    user?.subscriptionDetails[0]?.expiryAt,
                    dateSeparator.dash,
                    dateReturnFormat.Day_Month,
                  )}`}</div>
                </>
              )}
            <div className="pay-get-text">
              {seekerToken
                ? "Discover Exclusive Content and Classes"
                : "Your ad will be highlighted and you can see who sent you class requests."}
            </div>
            {/* <button onClick={handleCheck} className="pay-info-button">Membership info &gt;</button> */}
            <Button
              className="payment-done-button"
              onClick={handleGotoDashboard}
            >
              Go to dashboard
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default PaymentSuccessfullyModal
