import React from 'react'
import AnalyticsMeasurer from '../googleAnalyticsMeasure'
import { Outlet } from 'react-router-dom'
import VerificationModal from '../dasboard/commonDashboard/generalInformation/verificationModal/VerificationModal'
import ModalControl from '../modals/commonModal/ModalControl'
import useVersionCheck from '../../utils/versioncheckHook/useVersionCheck'
import UtilityNotifiation from '../UtilityNotification'

const RootWrapper = () => {
  const { isUpdated, message, showUpdatePrompt, refreshPage } = useVersionCheck();
  return (
    <>
        <Outlet/>
        <VerificationModal />
        <ModalControl />
        <AnalyticsMeasurer/>
        <UtilityNotifiation message={message||'Update '} show={!isUpdated && showUpdatePrompt } onAction={refreshPage} actionText={'Update'} />
    </>
  )
}

export default RootWrapper