import React, { useRef, useEffect, useState } from 'react';
import { Form, Button, OverlayTrigger, Popover, PopoverBody, Row, Col, Spinner } from 'react-bootstrap';
import { useAutoCompliteWithAIMutation } from '../../features/featProviderDashboard/FeatProviderApi';
import { useAppSelector } from '../../app/hooks';
import premimumStar from "../../assets/images/premium-1.svg";
import { useDebounce } from '../../utils/debouncer';
import { PiMagicWandDuotone } from 'react-icons/pi';
import { toast } from 'react-toastify';

export enum SECTION_KEY {
    TITLE = 'Title',
    ABOUT_CLASS = 'aboutClass',
    ABOUT_YOU = 'aboutYou'
}

interface CustomTextareaProps {
  value: string;
  onChange: (value: string, name: string) => void;
  setFocusField: (fieldName: string) => void;
  name: string;
  className?: string;
  style?: React.CSSProperties;
  vedicSubject: string[];
  subSkill: string[];
  section: SECTION_KEY;
}

const CustomTextarea: React.FC<CustomTextareaProps> = ({
  value,
  onChange,
  setFocusField,
  name,
  className,
  style,
  vedicSubject,
  subSkill,
  section
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [autofillTrigger, { data, error, isLoading }] = useAutoCompliteWithAIMutation();
  const { user } = useAppSelector((state) => state.auth);
  const isPremium = true; // Assuming premium status is true for demonstration

  const [searchTextResponse, setSearchTextResponse] = useState<string[]>([]);
  const [isFocused, setIsFocused] = useState<boolean>(false); // State for tracking focus
  const [placeholderText, setPlaceholderText] = useState<string | null>(null); // State for placeholder text
  const [currentValue, setCurrentValue] = useState<string>(value); // State to track the current value
  const [previousValue, setPreviousValue] = useState<string>(value); // State to track previous value

  // THIS SECTION CREATES AN INITIAL FETCH
  useEffect(() => {
    if (isPremium) {
      const subject = (vedicSubject as unknown as string) || '';
      const skills = subSkill?.join(',');
      if(value && value.length < 1) autofillTrigger({ vedicSubject: subject, subSkill: skills, section })
    }
  }, [autofillTrigger, isPremium, vedicSubject, subSkill, section]);

  useEffect(()=>{
    if (data) setSearchTextResponse(data?.data);
  },[data, error])
  const handleAutofill = () => {
    if (!isPremium) return;
    var subject = (vedicSubject as unknown as string) || '';
    var skills = subSkill?.join(',');
    autofillTrigger({ vedicSubject: subject, subSkill: skills, section })
      .unwrap()
      .then((response) => {
        if (textareaRef.current) {
          textareaRef.current.focus();
        }
      })
      .catch((err) => {
        toast.dismiss();
        toast.error('Failed to fetch autofill data');
      });
  };

  const handleAutofillDebounced = useDebounce(handleAutofill, 300);

  const handlePlaceholderPreview = (item: string) => {
    setPreviousValue(currentValue); // Save the current value
    setPlaceholderText(item); // Set new placeholder text
    setCurrentValue(''); // Clear the current input value
  };

  const restoreOriginalValue = () => {
    setPlaceholderText(null); // Clear placeholder text
    setCurrentValue(value); // Restore the previous value
  };

  const handleClickItem = (item: string, e: React.MouseEvent<HTMLParagraphElement>) => {
    e.preventDefault(); // Prevent default behavior
    setPlaceholderText(null); // Clear placeholder text
    setCurrentValue(item);
    setPreviousValue(item);
    onChange(item, name); // Pass the updated value to the onChange handler
    textareaRef.current?.blur()
  };

  return (
    <div style={{ position: 'relative' }}>
      <Form.Control
        as="textarea"
        rows={10}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setCurrentValue(e.target.value);
          onChange(e.target.value, name);
        }}
        onFocus={() => {
          setFocusField(name);
          setIsFocused(true);
        }}
        onBlur={() => {
          setFocusField('');
          restoreOriginalValue();
          setIsFocused(false);
        }}
        placeholder={placeholderText || ''} // Use placeholder text when available
        className={className}
        style={style}
        ref={textareaRef}
      />
      <div className='position-absolute end-0 bottom-0 m-2'>
        <OverlayTrigger
          placement="top"
          overlay={
            !isPremium ? (
              <Popover id="button-tooltip-2">
                <PopoverBody>
                  <Row>
                    <Col sm={8} className='text-center'>You need to be a premium member to enjoy this feature</Col>
                    <Col sm={3} className='justify-content-center align-content-center'>
                      <img src={premimumStar} alt="Premium" />
                    </Col>
                  </Row>
                </PopoverBody>
              </Popover>
            ) : (
              <></>
            )
          }
        >
          <span>
            <Button 
              variant="secondary"
              onClick={handleAutofillDebounced}
              disabled={isLoading}
              className='send-btn autofill-btn p-2'
            >
              {isLoading ? <Spinner size='sm'/> : <><span className='d-none d-md-inline'>Autofill </span> <PiMagicWandDuotone /> </>}
            </Button>
          </span>
        </OverlayTrigger>
      </div>
      {isPremium && isFocused && (
        <div className="search-select-teacher canvas-box canvas-box-2 z-3 ">
          {
          isLoading ? <div className='d-flex justify-content-center align-items-center'><Spinner size='sm'/></div>
          :
          searchTextResponse.length > 0 ?
          (searchTextResponse.map((item: string, index: number) => (
            <p
              key={index}
              className="tab text town locate choice-locate autofill-text"
              onMouseEnter={() => handlePlaceholderPreview(item)}
              onMouseLeave={() => restoreOriginalValue()}
              onMouseDown={(e) => handleClickItem(item, e)} // Use onMouseDown to handle item click
            >
              {item}
            </p>
          )))
          : error ? 
          <p className='text-danger'>{(error as any)?.data?.message}</p>
          : <p className='autofill-text'>Click on auto fill to generate custom heading</p>
           }
        </div>
      )}
    </div>
  );
};

export default CustomTextarea;
