import { motion, easeInOut } from 'framer-motion';
import React, { useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import { ItemWithISODate } from '../../Types';
import ChatApprovedCard from './ChatApprovedCard';
import ChatDialogues from './ChatDialogues';
import SelectedChatInfo from './SelectedChatInfo';
import UnlockPremiumChat from './UnlockPremiumChat';
import sendIcon from "../../assets/img/send-icon.svg";
import styles from "./ChatUi.module.css";
import EmojiPicker from 'emoji-picker-react';
import EmojipIck from '../emojiPicker';
import { useAppSelector } from '../../app/hooks';
import { selectCurrentUser } from '../../features/Auth/authSlice';


// Define the props for the ChatScreen component
interface ChatScreenProps {
  selectedChatInfo: ItemWithISODate | undefined;
  chatRef: React.RefObject<any>;
  chatboxBottomDivRef: React.RefObject<HTMLDivElement>;
  msg: string;
  uid: string;
  handleSendMessage: ({ requestId, uid, msg }: { requestId: string; uid: string; msg: string }) => void;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleKeyPress: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  handleBackNav: () => void;
  handleEmojiChange: (value: any) => void;
  showEmojiPicker: boolean,
  setShowEmojiPicker: React.Dispatch<React.SetStateAction<boolean>>,
}

const ChatScreen: React.FC<ChatScreenProps> = ({
  selectedChatInfo,
  chatRef,
  chatboxBottomDivRef,
  msg,
  uid,
  handleSendMessage,
  handleChange,
  handleKeyPress,
  handleBackNav,
  handleEmojiChange,
  showEmojiPicker,
  setShowEmojiPicker,
}) => {
  const [searchParams] = useSearchParams();
  const { seekerToken, providerToken, user } = useAppSelector(selectCurrentUser)
  useEffect(()=>{if(providerToken)console.log(providerToken && user?.isPremium , user?.subscriptionDetails[user?.subscriptionDetails?.length - 1].accLimit > 0,'userDAta')},[user])
  return (
    <>
      {/* Actual chat screen where chat is displayed */}
      <div className={`${styles["total-area"]}`}>
      <SelectedChatInfo selectedChatInfo={selectedChatInfo} handleBackNav={handleBackNav}/>
        <motion.div
          initial={{ translateX: -150, opacity: 0.1 }}
          whileInView={{ translateX: 0, opacity: 1 }}
          exit={{ translateX: -150, opacity: 0 }}
          transition={{ duration: 0.3, delay: 0, easings: easeInOut }}
          className={`${
            styles["chat-area"]
          }  p-2 p-lg-3 pt-0 py-lg-0 mb-2 bg-white position-relative ${
            searchParams.get("requestId") == null ? "d-none d-lg-block" : ""
          }`}
        >
          {searchParams.get("requestId") === selectedChatInfo?.requestId ? (
            searchParams.get("requestStatus") === "Pending" ? (
              providerToken && (user?.subscriptionDetails[0].accLimit > 0) ? <ChatApprovedCard selectedChatInfo={selectedChatInfo} />
              : <><UnlockPremiumChat /></>
            ) : (
              <>
                <div
                  className={`${
                    selectedChatInfo?.chatLock === "true"
                      ? styles["chat-blur"]
                      : ""
                  }`}
                >
                  <ChatDialogues
                    selectedChatInfo={
                      selectedChatInfo || ({} as ItemWithISODate)
                    }
                    refference={chatRef}
                    reff={chatboxBottomDivRef}
                  />
                </div>
                {/* <button
                  type="button"
                  className={`btn ${styles["back-icon"]}`}
                  title="back"
                  onClick={handleBackNav}
                >
                  <FaArrowLeft size={20} style={{ color: "#000" }} />
                </button> */}

                <Row
                  className={`position-absolute bottom-0 mb-1 ms-1 ms-lg-0  bg-white  ${
                    styles["rounded-xl"]
                  } ${styles["w-98"]} ${
                    selectedChatInfo?.chatLock === "true"
                      ? `pe-none ${styles["chat-blur"]}`
                      : ""
                  }`}
                >
                  <Col className="ps-0">
                    <div
                      className={`position-relative m-auto my-1 ${styles["message-field-group"]}`}
                    >
                      <Form.Control
                        as="textarea"
                        value={msg}
                        className={`${styles["message-field"]} ${styles["custom-scroll"]}`}
                        placeholder="Type a message"
                        onChange={handleChange}
                        disabled={selectedChatInfo?.chatLock === "true"}
                        onKeyDown={handleKeyPress}
                        ref={chatRef}
                      />
                      <button className={`btn border-0 ${styles["emoji-btn"]}`} onClick={()=>{setShowEmojiPicker((prev)=>!prev)}}>
                        😊
                      </button>
                      <EmojipIck handleEmojiChange={handleEmojiChange} onReactionClick={undefined} onSkinToneChange={undefined} show={showEmojiPicker}/>
                    </div>
                  </Col>
                  <Col xs="auto" className="d-flex align-items-center ps-0">
                    <button
                      className={`${styles["send-btn--type-2"]}`}
                      disabled={selectedChatInfo?.chatLock === "true"}
                      onClick={() =>
                        handleSendMessage({
                          requestId: searchParams.get("requestId") || "",
                          uid,
                          msg,
                        })
                      }
                    >
                      <img src={sendIcon} alt="Send" />
                    </button>
                  </Col>
                </Row>
              </>
            )
          ) : (
            <>
              <UnlockPremiumChat />
            </>
          )}
        </motion.div>
      </div>
    </>
  );
};

export default ChatScreen;
