const IS_LAUNCHED = import.meta.env.VITE_IS_LAUNCHED
enum MODALNAME {
  CLOSED = "CLOSED",
  LOGIN = "LOGIN",
  SIGNUP = "SIGNUP",
  RESET_PASSWORD = "RESET",
  FORGET_PASSWORD = "FORGET",
  CONFIRM_PASSWORD = "CONFIRM",
  FORGET_PASSWORD_TUTOR = "FORGET_TUTOR",
  FORGET_PASSWORD_SEEKER = "FORGET_SEEKER",
  FORGET_PASSWORD_AFTER_LOGIN_TUTOR = "FORGET_TUTOR_AFTER_LOGIN",
  FORGET_PASSWORD_AFTER_LOGIN_SEEKER = "FORGET_SEEKER_AFTER_LOGIN",
  RESET_PASSWORD_TUTOR = "RESET_TUTOR",
  RESET_PASSWORD_SEEKER = "RESET_SEEKER",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  OTP_VERIFICATION = "OTP_VERIFICATION",
  OTP_VERIFICATION_PHONE = "OTP_VERIFICATION_PHONE",
  SCHEDULE_SESSION_LOGIN = "SCHEDULE_SESSION_LOGIN",
  RESET_PASSWORD_OTP_SEEKER = "RESET_PASSWORD_OTP_SEEKER",
  RESET_PASSWORD_OTP_PROVIDER = "RESET_PASSWORD_OTP_PROVIDER",
  RESET_PASSWORD_OTP_SEEKER_AFTER_REGISTERATION = "RESET_PASSWORD_OTP_SEEKER_AFTER_REGISTERATION",
  RESET_PASSWORD_OTP_PROVIDER_AFTER_REGISTERATION = "RESET_PASSWORD_OTP_PROVIDER_AFTER_REGISTERATION",
  OTP_VERIFICATION_AFTER_REGISTERATION_PHONE = "OTP_VERIFICATION_AFTER_REGISTERATION_PHONE",
  OTP_VERIFICATION_AFTER_REGISTERATION_EMAIL = "OTP_VERIFICATION_AFTER_REGISTERATION_EMAIL",
  REPORT_AD = "reportModal",
  REPORT_CHAT = "REPORT_CHAT",
  TERMS_MODAL = 'termsConditionModal',
  SHARE_MODAL = 'SHARE_MODAL',
  AD_CREATED = 'AD_CREATED',
  REQUEST_PAYMENT = 'REQUEST_PAYMENT',
  REFFERAL_ENTRY = 'REFFERAL_ENTRY',
  CONFIRM_LOGOUT = "CONFIRM_LOGOUT",
  SHARE_PLATFORM_MODAL = "SHARE_PLATFORM_MODAL",
}

enum USERTYPE {
  SEEKER = "SEEKER",
  PROVIDER = "PROVIDER",
  SEEKER_LOGIN = "SEEKER_LOGIN",
  TUTOR_LOGIN = "TUTOR_LOGIN",
  // SEEKER_RESET = "SEEKER_RESET",
  // TUTOR_RESET = "TUTOR_RESET",
}

enum SESSION_PREFERENCE {
  VIRTUAL = "Virtual",
  REAL_TIME = "Real Time",
  ANY = "Any",
}

// enum MODALNAME {
//   CLOSED = "CLOSED",
//   LOGIN = "LOGIN",
//   SIGNUP = "SIGNUP",
//   RESET_PASSWORD = "RESET",
//   FORGET_PASSWORD = "FORGET",
//   CONFIRM_PASSWORD = "CONFIRM",
// }

// enum MYADS {
// SOURSEMYADS = SOURSEMYADS,
// SEEKER

// }
enum ADVT_MODALS {
  TEACHING = "Teaching",
  COURSE = "Course",
  ABOUT_YOU = "Aboutyou",
  ABOUT_SESSION = "Aboutsession",
  CLASS_FORMAT = "class",
  LANGUAGE = "language",
  PRICING = "pricing",
  TITLE = "title",
  YOUTUBE_VIDEO = 'youtybeVideo',
  CLOSED = "CLOSED",
  DELETE = "DELETE",
  VIDEOEDIT = 'videoEdit'
}

enum REQUEST_STATUS {
  PENDING = "Pending",
  REJECTED = "Rejected",
  APPROVED = "Accept",
  APPROVE = "Approve",
  REJECT = "Reject",
  DISABLED = "Disabled"
}
export { MODALNAME, USERTYPE, SESSION_PREFERENCE, ADVT_MODALS, REQUEST_STATUS }


export const InitialFilterState = {
  firstClassFree: {
      order: 0,
      type: 'check',
      key: 'firstClassFree',
      label: 'First Class Free',
      selectedValue: null,
      range: { minimum: { value: 0 }, maximum: { value: 0 } },
      options: {
          firstClassFree: {
              key: 'true',
              label: 'First Class Free',
              value: false,

          }
      },
  },
  // aroundMe: {
  //     order: 1,
  //     type: 'check-mobile',
  //     key: 'aroundMe',
  //     label: 'Location',
  //     options: {
  //         aroundMe: {
  //             key: 'aroundMe',
  //             label: 'Around me',
  //             value: false,
  //         },
  //     }
  // },
  classType: {
      order: 2,
      type: 'checkbox',
      key: 'classType',
      label: 'Mode of class',
      selectedValue: null,
      options: {
          realTime: {
              key: 'realTime',
              label: 'Real Time',
              value: false,
          },
          virtual: {
              key: 'virtual',
              label: 'Virtual',
              value: false,
          },
          any: {
              key: 'any',
              label: 'Any',
              value: false,
          },
      },
  },
  groupOrPrivate: {
      order: 3,
      type: 'checkbox',
      key: 'groupOrPrivate',
      label: 'Batch Type',
      selectedValue: null,
      options: {
          private: {
              key: 'private',
              label: 'Private',
              value: false,
          },
          group: {
              key: 'group',
              label: 'Group',
              value: false,
          },
          any: {
              key: 'any',
              label: 'Any',
              value: false,
          },
      },
  },
  priceRange: {
      order: 4,
      type: 'range',
      key: 'priceRange',
      label: 'Price Range',
      selectedValue: null,
      range: { minimum: { value: 0 }, maximum: { value: 12000 } },
      options: {
          minimum: {
              key: 'min',
              label: 'Minimum',
              value: 0,
          },
          maximum: {
              key: 'max',
              label: 'Maximum',
              value: 1200,
          },
      },
  },
  sortOrder: {
      order: 5,
      type: 'right-multi-checkbox',
      label: 'Sort By',
      key: ['sortOrder', 'sortBy'],
      sortType: 'Price',
      selectedValue: null,
      options: {
          ascending: {
              key: 'ascending',
              paramsKey: 'sortOrder',
              label: 'Price-Low to High',
              value: false,
          },
          descending: {
              key: 'descending',
              paramsKey: 'sortOrder',
              label: 'Price-High to Low',
              value: false,
          },
          relevance: {
            key: 'relevance',
            paramsKey: 'sortBy',
            label: 'Relevance',
            value: false,
        },
      },
  }
}
export enum PassType {
    Monthly = "Monthly",
    Annual = "Annual",
  }
  
  interface PassDetails {
    name: string;
    amount: number;
    expireInMonths: number;
    accLimit: number;
    period: string,
  }
  
  export const PREMIUM_PASSES: Record<PassType, PassDetails> = {
    [PassType.Monthly]: {
      name: PassType.Monthly,
      amount: 199,
      expireInMonths: 1,
      accLimit: 100,
      period: 'Month',
    },
    [PassType.Annual]: {
      name: PassType.Annual,
      amount: 1499,
      expireInMonths: 12,
      accLimit: 2000,
      period: 'Year',
    },
  };

export enum RESPONSE_TIME {
  none = '',
  One_hour = "Within an Hour",
  Six_hour = "6 Hours",
  One_day = "Within a Day",
  Three_days = "3 Days",
  One_Week = "Within a Week",
  Two_week = "2 Weeks",
  One_month = "Within a Month",
}
export enum RESPONSE_TIME_Values {
  none = 'Please select your response time',
  One_hour = "Within an Hour",
  Six_hour = "Within 6 Hours",
  One_day = "Within a Day",
  Three_days = "Within 3 Days",
  One_Week = "Within a Week",
  Two_week = "Within 2 Weeks",
  One_month = "Within a Month",
}


type RESPONSE_TIME_ITEMS = {value: RESPONSE_TIME_Values, key: RESPONSE_TIME}

export const RESPONSE_TIME_LIST: RESPONSE_TIME_ITEMS[] = Object.keys(RESPONSE_TIME).map(key => ({
  value: RESPONSE_TIME_Values[key as keyof typeof RESPONSE_TIME_Values],
  key: RESPONSE_TIME[key as keyof typeof RESPONSE_TIME],
}));

export enum TEACHING_EXP {
  NEW = "New",
  EXPERIENCED = "Experienced",
  AMBASSADOR = "Ambassador",
  STAR_TEACHER = "Super Teacher",
}

export enum AD_STATUS {
  PENDING = "Pending",
  CLOSED = "Closed",
  Active = "Active"
}

export enum SOCIAL_LOGIN_TYPES {
  GOOGLE = "GOOGLE"
}

export enum menuItem {
  root = "dashboard",
  myAds = "my-advt",
  myRequests = "my-requests",
  reviews = "my-evaluations",
  myAccount = "my-account",
}

export const dummyReviews = [
  {
    imgSrc: "https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/kanak-narayana-vedichom.jpg",
    name: "Kanak Narayana",
    dep: "Classical Indian singer",
    content: `VedicHom has truly transformed my career as a classical singer. With their platform, I've been able to reach a global audience eager to learn and appreciate our traditional art form. It's not just about sharing my skills; it's about preserving and promoting our cultural heritage. Thanks to VedicHom, I'm not only spreading the beauty of classical music but also earning a sustainable income doing what I love. Their innovative approach is breathing new life into age-old practices, propelling us onto the global stage like never before. Grateful for this opportunity to shine brighter and connect with enthusiasts worldwide.`,
  },
  {
    imgSrc: "https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/Priyanka-jain-ayurvedic-vedichom.jpg",
    name: "Harshita",
    dep: "Ayurvedic Practitioner",
    content: `VedicHom has been a game-changer for Ayurvedic doctors like me. It breaks down barriers, allowing us to connect globally and share the powerful healing practices of ancient Bharat with those seeking natural remedies. This platform has given a new lease of life to traditional Ayurvedic practitioners of all walks of life by linking us with seekers seeking remedies where the modern sciences have limitations. It's a hub where seekers can access effective remedies invented by ancient yogis, and monks. VedicHom isn't just a platform; it's a bridge that brings together the best of our scientific heritage for the benefit of all who seek wellness. It's truly empowering for both practitioners and seekers.`,
  },
  {
    imgSrc: 'https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/Dmitry%20%281%29.png', //certificate img 1
    name: "Shobhit Bhatt",
    dep: "Astrologer",
    content: `VedicHom has been a game-changer for my astrology practice. Coming from a small village in northern Bharat, I never imagined reaching a global audience. But after joining VedicHom, everything changed. I've gone from serving a limited community to connecting with thousands of seekers worldwide. Registering as an astrologer on VedicHom has opened doors I never knew existed. Now, I'm guiding and mentoring individuals from all over the world, helping them find astrological solutions to life's challenges. VedicHom isn't just a platform; it's a global stage for astrologers like me. I'm incredibly grateful for the opportunity to share my knowledge and make a positive impact on people's lives, regardless of where they're from. If you're an astrologer looking to expand your reach, I highly recommend joining VedicHom. Pandit Ram Gopal Shastri, Astrologer`,
  },
  {
    imgSrc: 'https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/chitra-kolkata.jpg', //certificate img 2
    name: "Chitra Sharma",
    dep: "Yoga Tutor",
    content: `VedicHom has transformed how Ayurvedic doctors connect with the world. It breaks down barriers, enabling us to share ancient Bharat’s healing practices globally. This platform revitalizes traditional Ayurveda by linking practitioners with seekers who need natural remedies where modern science falls short. VedicHom is a bridge that unites our rich heritage with today’s wellness needs, empowering both practitioners and those in search of holistic healing.`,
  },
  {
    imgSrc: "https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/ravi.png", //certificate img 2
    name: "Ravi Trivedi",
    dep: "Vastu Consultant",
    content: `Joining VedicHom has been a blessing for my Vastu practice. Through this platform, I can offer the wisdom of Vastu Shastra to people across the world. It's wonderful to see how aligning homes with cosmic energies helps people find harmony and balance. VedicHom has truly expanded the reach of this ancient science`,
  },
  {
    imgSrc: "https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/emily.png", //certificate img 2
    name: "Emily Richardson",
    dep: "Ayurveda Enthusiast",
    content: `Coming from Ireland, I had always been fascinated by Ayurveda, but it was difficult to find authentic practitioners. VedicHom has allowed me to learn directly from experts in Bharat, gaining personalized insights into Ayurvedic practices. The platform has made it possible for me to connect with practitioners and explore natural healing methods that have positively impacted my life.`
  },
  {
    imgSrc: "https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/sarita.png", //certificate img 2
    name: "Sarita Shastri",
    dep: "Sanskrit Scholar",
    content: `VedicHom has allowed me to connect with students who are passionate about learning Sanskrit, the language of ancient wisdom. Teaching Sanskrit to a global audience has been immensely rewarding. The platform provides a bridge between the past and the present, making Sanskrit accessible to learners from all walks of life.`  },
];


export enum DeviceType {
  iOS = 'iOS',
  Android = 'Android',
  WindowsPhone = 'Windows Phone',
  Mobile = 'Mobile',
  Tablet = 'Tablet',
  Desktop = 'Desktop'
}

export const dummyFaqItems = [
  {
    id: 1,
    question: 'What is VedicHom?',
    answer: 'VedicHom is a global platform connecting sources from various Vedic arts and sciences—yoga, astrology, ayurveda, classical music, Sanskrit, and other ancient disciplines—with seekers. Sources can register for free, set their own fees and schedules, and teach on their own terms and style.'
  },
  {
    id: 2,
    question: 'How do I register as a source on VedicHom?',
    answer: 'You can register by signing up on the platform and filling out your profile with details about the subjects you teach, skills you like to share your experience, and availability'
  },
  {
    id: 3,
    question: 'Is there a fee to register as a source?',
    answer: 'No, registration is free. However, with the free plan, you can accept up to 3 seeker requests. To accept unlimited requests, a nominal monthly subscription is required.'
  },
  {
    id: 4,
    question: 'What is the prelaunch offer for sources?',
    answer: 'As part of the prelaunch offer, registered sources can accept unlimited seeker requests, create ads for their profile on the platform, and benefit from VedicHom\'s advertising efforts on social media and Google Ads.'
  },
  {
    id: 5,
    question: 'Can I set my own fees and schedule?',
    answer: 'Yes, as a source, you have full control over your fees, availability, and class schedules.'
  },
  {
    id: 6,
    question: 'How does VedicHom help promote my profile?',
    answer: 'VedicHom advertises source profiles across various platforms, including social media and Google Ads, to help you reach a larger audience of seekers.'
  },
  {
    id: 7,
    question: 'How do I receive payments from seekers?',
    answer: 'Sources manage their payments directly with seekers. VedicHom does not handle financial transactions between sources and seekers.'
  },
  {
    id: 8,
    question: 'Are there any limits to the number of seekers I can teach?',
    answer: 'With the free plan, you can accept up to 3 seeker requests. To teach more seekers, you will need to subscribe to a monthly plan, which removes the limitation.'
  },
  {
    id: 9,
    question: 'What kind of courses can I offer on VedicHom?',
    answer: (
      <div className="p-1 mt-1">
    <h4>VedicHom Course Offerings</h4>
    <p style={{ fontSize: '1rem' }}>
      VedicHom offers a diverse range of courses, workshops, and consultancy services spanning Vedic arts and sciences, including:
    </p>

    <section>
      <h5>Spiritual and Philosophical Studies</h5>
      <ul style={{ fontSize: '0.9rem' }}>
        <li>Vedanta and Upanishads</li>
        <li>Bhagavad Gita and other scriptures</li>
        <li>Yoga Philosophy (Raja Yoga, Hatha Yoga, Kundalini Yoga)</li>
        <li>Meditation and Mindfulness</li>
        <li>Ayurvedic Lifestyle and Wellness</li>
      </ul>
    </section>

    <section>
      <h5>Vedic Sciences</h5>
      <ul style={{ fontSize: '0.9rem' }}>
        <li>Jyotish (Vedic Astrology)</li>
        <li>Vastu Shastra (Ancient Indian Architecture)</li>
        <li>Ayurvedic Medicine and Herbalism</li>
        <li>Vedic Mathematics</li>
      </ul>
    </section>

    <section>
      <h5>Artistic Expressions</h5>
      <ul style={{ fontSize: '0.9rem' }}>
        <li>Classical Music (Carnatic or Hindustani)</li>
        <li>Classical Dance (Bharatanatyam, Kathak, Odissi)</li>
        <li>Vedic Chanting and Mantra Recitation</li>
        <li>Sanskrit Language and Literature</li>
        <li>Indian Painting and Sculpture</li>
      </ul>
    </section>

    <section>
      <h5>Wellness and Self-Development</h5>
      <ul style={{ fontSize: '0.9rem' }}>
        <li>Yoga Asana and Pranayama</li>
        <li>Ayurvedic Cooking and Nutrition</li>
        <li>Mindfulness and Stress Management</li>
        <li>Vedic Counseling and Life Coaching</li>
        <li>Chakra Balancing and Energy Healing</li>
      </ul>
    </section>

    <section>
      <h5>Language and Culture</h5>
      <ul style={{ fontSize: '0.9rem' }}>
        <li>Sanskrit Language Courses</li>
        <li>Vedic History and Culture</li>
        <li>Indian Mythology and Folklore</li>
        <li>Vedic Rituals and Ceremonies</li>
      </ul>
    </section>

    <section>
      <h5>Consultancy Services</h5>
      <ul style={{ fontSize: '0.9rem' }}>
        <li>Vedic Astrology Consultations</li>
        <li>Ayurvedic Health Consultations</li>
        <li>Vedic Life Coaching and Mentoring</li>
        <li>Spiritual Guidance and Counseling</li>
        <li>Vastu Shastra Consultations for Home and Office</li>
      </ul>
    </section>

    <section>
      <h5>Bhajans, Kathas, and Discourses</h5>
      <ul style={{ fontSize: '0.9rem' }}>
        <li>Bhagavad Gita Discourses</li>
        <li>Ramayana and Mahabharata Kathas</li>
        <li>Bhajans and Devotional Songs</li>
        <li>Vedic Chanting and Mantra Recitation Sessions</li>
        <li>Spiritual Storytelling and Reflections</li>
      </ul>
    </section>

    <section>
      <h5>Specialized Courses</h5>
      <ul style={{ fontSize: '0.9rem' }}>
        <li>Vedic Astrology for Beginners</li>
        <li>Ayurvedic Nutrition and Detox</li>
        <li>Yoga Therapy for Specific Health Conditions</li>
        <li>Vedic Chanting for Emotional Healing</li>
        <li>Sanskrit for Spiritual Seekers</li>
      </ul>
    </section>

    <section>
      <h5>Learning Options</h5>
      <p style={{ fontSize: '0.9rem' }}>On VedicHom, teachers can offer:</p>
      <ul style={{ fontSize: '0.9rem' }}>
        <li>Live or pre-recorded courses</li>
        <li>Workshops</li>
        <li>One-on-one sessions</li>
        <li>Group coaching</li>
        <li>Webinars</li>
        <li>Q&A sessions</li>
      </ul>
      <p style={{ fontSize: '0.9rem' }}>
        Catering to diverse learning styles and preferences. If your subject is niche, feel free to create your own category when setting up your profile.
      </p>
    </section>
  </div>
    )
  },
  {
    id: 10,
    question: 'What support does VedicHom provide if I face technical issues?',
    answer: 'You can contact VedicHom\'s support team for assistance with technical issues related to the platform, such as profile setup or class scheduling.'
  },
  {
    id: 11,
    question: 'Who is a source and who is a seeker?',
    answer: 'A source is a teacher or expert who offers courses or knowledge in their area of expertise. A seeker is a learner or student looking to gain knowledge or skills in the areas provided by the sources.'
  },
];

export const isLaunched  = IS_LAUNCHED ? IS_LAUNCHED=='true' && true : false
export const APP_ENV = import.meta.env.VITE_APP_ENV
export const TARGET_DATE = new Date('2024-10-20T12:00:00');
export const MAX_AD_IMAGE_SIZE_MB = 2;
export const MAX_AD_VIDEO_SIZE_MB = 5;

interface MediaItem {
  src: string;
  type: "image" | "video";
  className?: string;
}

export const mediaItems: MediaItem[] = [
  {
    src: "https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/yoga-pose.jpg",
    type: "image",
    className: "video",
  },
  {
    src: "https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/maile-guitar.jpg",
    type: "image",
    className: "video",
  },
  {
    src: "https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/astro-paper.jpg",
    type: "image",
    className: "video",
  },
  {
    src: "https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/female-ayurveda.jpg",
    type: "image",
    className: "video",
  },
  {
    src: "https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/drummer-percussion-indian-music-performer-art-beat-2023-11-27-04-53-53-utc%20%281%29.mp4",
    type: "video",
    className: "video",
  },
  {
    src: "https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/group-meditation-img.jpg",
    type: "image",
    className: "video",
  },
  {
    src: "https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/beautiful-woman-wearing-img%20%281%29.jpg",
    type: "image",
    className: "video",
  },
  {
    src: "https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/sitting-girl%20%281%29.mp4",
    type: "video",
    className: "video",
  },
  {
    src: "https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/yoga-girl%20%281%29.mov",
    type: "video",
    className: "video",
  },
  {
    src: "https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/south-asian-woman-img.jpg",
    type: "image",
    className: "video",
  },
];