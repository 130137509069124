import React, { useEffect } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import styles from "./aboutusPage.module.css" // import styles from "./aboutusPage.module.css"

const AboutUs: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])
  return (
    <>
      <div className={`${styles.header}  ${styles.sectionMargin}`}>
        <Container>
          <header>
            <h1>About Us</h1>
          </header>
        </Container>
      </div>

      <Container className="mt-2 mt-lg-5">
        <Row>
          <Col className={styles.aboutUs}>
            <p>
              VedicHom is founded to share and seek the timeless knowledge and
              wisdom of the rich ocean of Vedic Sciences. Our platform bridges
              the gap between passionate seekers and knowledgeable sources,
              allowing seekers to discover what they are searching for and
              providing sources the opportunity to share their insights and earn
              in the process.
            </p>
            <p>
              In vibrant cultures across the globe ancient Vedic sciences hold
              immense value, and VedicHom serves as a digital bridge connecting
              enthusiasts with authentic sources of knowledge.
            </p>
            <p>
              We shine a spotlight on renowned artisans, yoga gurus, Ayurvedic
              healers, classical (Shastriya) dancers, musicians and singers,
              astrologers, Vastu practitioners, Vedic scholars, and many other
              renowned practitioners and mentors from various Vedic traditions,
              giving them the global recognition they deserve.
            </p>
            <p>
              VedicHom is not just about making connections; it's about reviving
              & reinstating traditions, empowering sources, and satisfying the
              insatiable curiosity of seekers worldwide.
            </p>
            <p>
              Whether you're a seeker yearning for deeper understanding or a
              source eager to contribute, VedicHom is your destination.
              Together, we can harness the power of Vedic knowledge to enrich
              lives and inspire generations to come.
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="mt-2 mt-lg-5">
        <Row>
          <Col className={styles.aboutUs}>
            <h2>VISION</h2>
            <p>
              VedicHom envisions a world where the timeless wisdom of Vedic
              Sciences is accessible and celebrated globally.
            </p>
            <p>
              As a pioneering digital platform, we are committed to building a
              vibrant community that preserves and promotes these ancient
              traditions of Vedic sciences and practices.
            </p>
            <p>
              By connecting seekers with authentic sources, we empower knowledge
              sharing, enrich lives, and inspire future generations to embrace
              the enduring value of Vedic rich and scientific heritage.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AboutUs
