import { Message } from "../../features/chat/ChatApi"
import { AnimatePresence, motion} from "framer-motion"
import styles from "./ChatUi.module.css"
import { FiCheck } from "react-icons/fi"
import { LiaCheckDoubleSolid } from "react-icons/lia";
import RenderProfile from "../../utils/RenderProfilePic/RenderProfilePic"
import { useAppSelector } from "../../app/hooks"
import { selectCurrentUser } from "../../features/Auth/authSlice"
import { Spinner } from "react-bootstrap"
import { chatAnimation, chatInAnimation } from '../../Variants'; 
import { bottomToTopAnimation, leftToRightAnimation }  from '../../Variants'; 
import ChatDialogues from "./ChatDialogues";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../app/firebase";
import { formatTimeAgo } from "../../utils/timeDifferene/getTimeDifference";
import { useEffect } from "react";

interface DialogueProp {DialogueData:Message, isLoggedInUser:boolean , senderSubText:boolean ,  selectedChatInfo: { [key: string]: string }
}
const Dialogue = ({senderSubText, DialogueData, isLoggedInUser , selectedChatInfo}:DialogueProp) =>{
    const { date, senderId, senderMsg, read, id } = DialogueData
    const { providerToken, seekerToken } = useAppSelector(selectCurrentUser)
// setDoc(doc(db, "requests", request.requestId), { unread: false }, { merge: true }) } 
    const dateTime = formatTimeAgo(date)

  return <>
  <AnimatePresence >
       {isLoggedInUser ? (
                    <motion.div
                      key={DialogueData.id} 
                      id={DialogueData.id} 
                      variants={DialogueData.localOnly ? bottomToTopAnimation(0.3) : chatInAnimation(0.3)}
                      initial="hidden"
                      animate="show"
                      className={`${styles["user-message"]} ${styles["message"]}`}
                    >
                      <motion.div
                        initial={{ opacity: 0, y: 50 }} 
                        animate={{
                          opacity: 1,
                          y: 0,
                          transition: { duration: 0.5 }
                        }} 
                      >
                        <div className={` ${styles["message-content"]}`}>
                          {senderMsg}
                        </div>
                        <div className={`${styles["lh-11"]} text-end d-flex mt-1`}>
                          <span
                            className={`${styles["views-text"]} text-white me-2`}
                          >
                            {DialogueData.localOnly?<> .....</>:  
                            <>
                           {
                            DialogueData.read === "true" ? <LiaCheckDoubleSolid /> :<FiCheck />}
                          </>}</span>
                          <span
                            className={`${styles["views-text"]} text-white`}
                          >{seekerToken && DialogueData.messageId && !DialogueData.localOnly && (
                            <span>
                              {Array.isArray(selectedChatInfo.unreadCount) &&
                                selectedChatInfo.unreadCount.includes(DialogueData?.messageId)
                                ? "unseen"
                                : "seen"}
                            </span>
                          )}
                          
                          {providerToken && DialogueData.messageId && !DialogueData.localOnly && (
                            <span>
                              {Array.isArray(selectedChatInfo.unreadSourceCount) &&
                                selectedChatInfo.unreadSourceCount.includes(DialogueData?.messageId)
                                ? "unseen"
                                : "seen"}
                            </span>
                          )}
                          <span>{dateTime.time}</span>
                            {/* {dateNow} */}
                          </span>
                        </div>
                      </motion.div>
                    </motion.div>
                  ) : (
                    <div
                      className={`d-flex position-relative  ${styles["chat-message"]}`}
                    >
                     {/* { messages[index - 1]?.id === message?.id && <div ref={messagesEndRef} />} */}
                      {!senderSubText && (
                        <div
                          className={` position-absolute px-sm-1 pb-1 ${styles["chat-imgbx-sm"]} position-relative`}
                        >
                          <RenderProfile
                            size="sm"
                            senderNameValue={
                              providerToken
                                ? selectedChatInfo.seekerName || ""
                                : selectedChatInfo.providerName || ""
                            }
                            senderProfilePic={
                              providerToken
                                ? selectedChatInfo.seekerProfilePic || ""
                                : selectedChatInfo.providerProfilePic || ""
                            }
                          />
                          <div
                            className={`${styles["time-text"]} d-flex justify-content-center`}
                          >
                            {/* {dateNow} */}
                          </div>
                        </div>
                      )}
                      <div
                        className={` ${styles["other-message"]} ${styles["message"]}`}
                      >
                        <div className={` ${styles["message-content"]}`}>
                          {senderMsg}
                        </div>
                        <div className={`${styles["lh-11"]} text-end`}>
                          <span className={`${styles["views-text"]}`}>
                            {" "}
                            {dateTime.time}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
    </AnimatePresence>    
    </>
}


export default Dialogue 