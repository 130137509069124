import React, { ChangeEvent, useEffect, useState } from "react"
import { Button, Col, Row, Spinner } from "react-bootstrap"
import {
  useGetLocationMutation,
  useGetLocationSuggestionMutation,
} from "../../../features/locationFunc/locationApi"
import SpinnerMain from "../../../components/spinner/SpinnerMain"
import "./homeSeacrhLocModal.css"
import { IoClose } from "react-icons/io5"
import {
  FaLocationArrow,
  FaPersonWalkingDashedLineArrowRight,
} from "react-icons/fa6"
import { toast } from "react-toastify"
import { motion } from "framer-motion"
import { FiVideo } from "react-icons/fi"
import { leftToRightAnimation } from "../../../Variants"
import { useSearchParams } from "react-router-dom"
import { useDebounce } from "../../../utils/debouncer"
import { useAppDispatch } from "../../../app/store"
import { setLocation } from "../../../features/featSearchAdvts/SearchAdvtSlice"

type SearchLocationInputProps = {
  isdisabled: boolean
  defaultValue: string
  setAtParent: (place: string) => void
  placeholder?: string
  updateAtParent?: any
}

interface Coordinates {
  latitude: number
  longitude: number
}

interface PositionError {
  message: string
}

export const HomeSeachLocModal = (prop: SearchLocationInputProps) => {
  const [isActive, setIsActive] = useState<boolean>(false)
  const [location, setLocationInput] = useState<string | null>(null)
  const [isSelected, setIsSelected] = useState<boolean>(true)
  const [placeholder, setPlaceholder] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [locationsList, setlocationsList] = useState<any>([])
  const [mouseHover1, _mouseHover1] = useState<boolean>(false)
  const [mouseHover2, _mouseHover2] = useState<boolean>(false)
  const [disable, _disable] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (prop.placeholder) {
      setPlaceholder(prop.placeholder)
    }
  }, [prop.placeholder])

  const [search, { data, isLoading: locSearchLoading, error: locSearchError }] =
    useGetLocationSuggestionMutation()
  const [
    getLocation,
    { data: CurlocationData, error, isLoading: loadingCurLoc },
  ] = useGetLocationSuggestionMutation()
  const [getMyCoordinates] = useGetLocationMutation()
  useEffect(() => {
    setLocationInput(prop.defaultValue)
  }, [prop.defaultValue])

  const handleOnBlurOrCancle = () => {
    if (isSelected) {
      setIsActive(false)
    } else {
      setIsActive(false)
    }
  }
  const debouncedLocationSearch = useDebounce(searchLocation, 140)
  function searchLocation (location: string) {
    search({ name: location })
  }
  useEffect(() => {
    if (data) setlocationsList((data as any)?.data?.Results)
  }, [data])
  useEffect(()=>{
    if(location && location?.length>3 && data) dispatch(setLocation((data as any)?.data?.Results[0]?.Place?.SubRegion || (data as any)?.data?.Results[0]?.Place?.Region)) 
    else dispatch(setLocation(location || ''))  
  },[location])
  const handleSearch = () => {
    if (location && location.length > 3 && !disable) {
      debouncedLocationSearch(location)
    } else {
      setlocationsList([])
      _disable(false)
    }
  }

  useEffect(() => {
    handleSearch()
    setIsSelected(false)
  }, [location])

  const handleLocationClick = (place: any) => {
    _disable(true)

    prop.setAtParent(place?.SubRegion || place?.Region) //change it to prevent undefined
    if(prop.updateAtParent) prop.updateAtParent()
    setLocationInput(place?.SubRegion || place?.Region)
    setIsSelected(true)
    setIsActive(false)
  }

  const selectCurrevtLocation = (place: any) => {
    setlocationsList([])
    _disable(true)
    prop.setAtParent(place?.SubRegion|| place?.Region) //change it to prevent undefined
    if(prop.updateAtParent) prop.updateAtParent()
    setLocationInput("Around me")
    setIsSelected(true)
    setIsActive(false)
    searchParams.delete('classType')
    searchParams.set('aroundMe',(place?.SubRegion|| place?.Region))
    setSearchParams(searchParams)
  }

  const handleLocationfetch = () => {
    setIsLoading(true)
    setIsActive(true)
    getMyCoordinates()
      .unwrap()
      .then((res: any) => {
        getLocation({ coordinates: res })
      })
      .catch((error) => {
        toast.dismiss()
        toast.error(error?.data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (CurlocationData?.data?.Results[0])
      selectCurrevtLocation(CurlocationData?.data?.Results[0]?.Place)
  }, [CurlocationData])

  function handleOnlineClick(event: any): void {
    setlocationsList([])
    _disable(true)
    prop.setAtParent("")
    if (prop.updateAtParent) prop.updateAtParent()
    setLocationInput("Online")
    setIsSelected(true)
    setIsActive(false)
    searchParams.delete("aroundMe")
    searchParams.set("classType", "virtual")
    setSearchParams(searchParams)
  }

  function handleOnChange(e: ChangeEvent<HTMLInputElement>): void {
    setLocationInput(e.target.value);
  }

  return (
    <>
      <div className="location-frame-container">
        <Row className=" location-btn-custmize">
          {(loadingCurLoc || isLoading) && (
            <SpinnerMain className=" position-absolute d-md-inline-block text-center w-100 bg-light bg-opacity-50" />
          )}
          <input
            onFocus={() => {
              setIsActive(true)
            }}
            onBlur={handleOnBlurOrCancle}
            disabled={prop.isdisabled}
            value={location || ""}
            onChange={handleOnChange}
            type="text"
            className=" iframe-input form-control w-100 shadow-none pe-5"
            placeholder={placeholder || "Search Location ..."}
            autoComplete="off"
          />
          {/* <Col
            sm={1}
            className="justify-content-center align-items-center closeBtn-wraper"
          >
            {isActive && (
              <Button
                className="closeBtn"
                onClickCapture={() => {
                  setLocationInput("")
                  handleOnBlurOrCancle()
                  setPlaceholder(null)
                }}
              >
                <motion.div
                  initial={{ rotateZ: 0, scale: 0, opacity: 0 }}
                  whileInView={{
                    scale: [0, 1],
                    rotateZ: [0, 270],
                    opacity: [0, 1],
                  }}
                >
                 {location !== "" && <IoClose />} 
                </motion.div>
              </Button>
            )}
          </Col> */}
        </Row>

        {isActive && (
          <ul className="home-location-suggest-box">
            {(location == null ||
              location.length < 4 ||
              locationsList.length <= 0) && (
              <>
                <li
                  key={0}
                  onMouseDownCapture={handleLocationfetch}
                  className="loc-suggestbox tab d-flex align-items-center"
                  onMouseEnter={() => _mouseHover1(true)}
                  onMouseLeave={() => _mouseHover1(false)}
                >
                  <motion.div
                    variants={leftToRightAnimation(0, 0.4)}
                    initial="hidden"
                    animate={mouseHover1 ? "show" : "hidden"}
                  >
                    {mouseHover1 && (
                      <FaPersonWalkingDashedLineArrowRight
                        size={20}
                        opacity={mouseHover1 ? 1 : 0}
                        className="search-icon-hover"
                      />
                    )}
                  </motion.div>
                  <div className=" text town choice-webcam  rounded-3 w-100 ps-1">
                    {"Around me"}
                  </div>
                </li>
                <li
                  key={1}
                  onMouseDownCapture={handleOnlineClick}
                  onMouseEnter={() => _mouseHover2(true)}
                  onMouseLeave={() => _mouseHover2(false)}
                  className="loc-suggestbox tab d-flex align-items-center"
                >
                  <motion.div
                    variants={leftToRightAnimation(0, 0.4)}
                    initial="hidden"
                    animate={mouseHover2 ? "show" : "hidden"}
                  >
                    {mouseHover2 && (
                      <FiVideo
                        opacity={mouseHover2 ? 1 : 0}
                        size={20}
                        className="search-icon-hover"
                      />
                    )}
                  </motion.div>
                  <div className=" text town choice-webcam  rounded-3 w-100 ps-1">
                    {"Online"}
                  </div>
                </li>
              </>
            )}
            {locSearchLoading && (
              <div className="d-flex justify-content-center align-items-center p-2">
                <SpinnerMain className="d-flex justify-content-center" />{" "}
              </div>
            )}
            {locSearchError && (
              <div className="d-flex justify-content-center align-items-center p-2">
                <span className=" text-danger ">{"Some error occoured"}</span>
              </div>
            )}
            {(location && location?.length>3)&&
            <>
            {locationsList?.map((result: any, index: number) => (
              <li
                className="loc-suggestbox"
                key={index}
                onMouseDownCapture={() => {
                  handleLocationClick(result?.Place)
                }}
              >
                {result?.Place?.Label}
              </li>
            ))}
            </>
            }
          </ul>
        )}
      </div>
    </>
  )
}
