import { createSlice } from "@reduxjs/toolkit"

const adId = null
const imageVideoSlice = createSlice({
  name: "imageVideoSlice",
  initialState: { adId } as {adId: string | null},
  reducers: {
    setAdvertisementId: (state, action) => {
      state.adId = action.payload
    },
    resetAdvertID: (state) => {
      state.adId = null
    }
  },
})

export const { setAdvertisementId, resetAdvertID } = imageVideoSlice.actions

export default imageVideoSlice.reducer
