import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import dummy from '../../assets/images/dummy-profile-pic.jpg'

type RenderProfileProps = {
  senderNameValue: string;
  senderProfilePic: string;
  customClass?: string;
  size?: 'sm'|'xs'|'lg'|'xl'|'md'|'dd';
};

const getInitials = (name: string): string => {
  const parts = name.split(" ");
  return parts.length > 1
    ? parts[0].charAt(0).toUpperCase() + parts[parts.length - 1].charAt(0).toUpperCase()
    : parts[0].charAt(0);
};

const RenderProfile: React.FC<RenderProfileProps> = ({ senderNameValue, senderProfilePic, customClass, size }) => {
  const senderName = senderNameValue || "No Name";
  const colors = [
    { light: "#ff9999", dark: "#780000" },
    { light: "#6699ff", dark: "#002878" },
    { light: "#66ff99", dark: "#005e1f" },
    { light: "#ffff99", dark: "#7e7e00" },
    { light: "#ff99ff", dark: "#7f007f" },
  ];
  const letterIndex = senderName.toUpperCase().charCodeAt(0) - 65;
  const validLetterIndex = letterIndex >= 0 && letterIndex < 26 ? letterIndex : 0; // Ensure valid letter index
  const colorIndex = Math.floor(validLetterIndex / 5) % colors.length;
  const { light, dark } = colors[colorIndex];
  const [imageError, setImageError] = useState<boolean>(false);

  return !imageError && senderProfilePic ? (
    <Image
      src={senderProfilePic || dummy}
      alt={getInitials(senderName)}
      onError={() => setImageError(true)}
      className={customClass ? customClass : 'notification-profile-pic custom-border'}
    />
  ) : (
    <div className={`${size? `size--${size}`:'size--md'} ${customClass ? customClass + ' ' : 'initials-background'}`} style={{ background: light }}>
      <p className={`${size? `size--${size}`:'size--md'} ${customClass ? 'fw-bolder h-100 p-0 m-0 text-center justify-content-center align-content-center ' : 'initials fw-bolder custom-border'}`} style={{ color: dark }}>
        {getInitials(senderName)}
      </p>
    </div>
  );
};

export default RenderProfile;
