import { Step } from "react-joyride";
import { menuItem } from "../../constants";
import image from '../../assets/images/yoga-girl.png'
import "./InstructionComponent.css"

export const publicScreenDemo: Step[] = [
    {
        target: '#seeker-learn-card',
        content: "Explore as a vedic student and connect with Tutors",
        disableBeacon: true,
        disableOverlayClose: true,
        event: "click"
    },
    {
        target: '#source-learn-card',
        content: 'Join as a vedic guide/tutor, share your knowledge with students and earn',
    },
    {
        target: '#signIn-button-navbar',
        content: 'Sign in to add your account',
    },
    // {
    //     target: '#signUp-source-button-navbar',
    //     content: 'Create your acount as ',
    //     placement: 'bottom',
    // },
    {
        target: 'body', // Target the body to center the welcome screen
        content: (
          <div>
            <img className="welcome-modal-img" src={"https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/girl-illustrior-yoga.png"}/>
            <span>Welcome to VedicHom</span>
            <p>We're glad to have you here.</p>
          </div>
        ),
        disableBeacon: true,
        disableOverlayClose: true,
        placement: 'center',
        event: "click",
        hideFooter: true
      },
];



export const menuItemDemo: Step[] = [

    {
        target: 'body', // Target the body to center the welcome screen
        content: (
          <div>
            <img className="welcome-modal-img" src={"https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/girl-illustrior-yoga.png"}/>
            <h2>Welcome to VedicHom</h2>
            <p>We're glad to have you onboard. Here are some quick tips to start your journey</p>
          </div>
        ),
        disableBeacon: true,
        disableOverlayClose: true,
        placement: 'center',
        event: "click"
      }, 
    {
        target: `#progress-status-heading`,
        content: 'This is your progress status',
        spotlightClicks: true,
        placement: 'top',
    },
    {
        target: `#class-count-overview`,
        content: 'Check your class status at a glance from here',
        spotlightClicks: true,
    },
    {
        target: `#${menuItem.myAccount}`,
        content: 'Complete and check your account details',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
        //   hideCloseButton: true,
        //   hideFooter: true,
          placement: 'right',
          event: 'click',
    },
    {
        target: `#tab-example-tab-tab1`,
        content: 'This will give you profile overview ',
        spotlightClicks: true,
    },
    {
        target: `#accountInformation`,
        content: 'This section contains your account information',
        spotlightClicks: true,
    },
    {
        target: `#${menuItem.myRequests}`,
        content: 'Check and communicate about your class requests',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
    },
    {
        target: `#${menuItem.myAds}`,
        content: 'You can create, update and check your advertisements from here',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
    },
  ];

export const menuItemMobileDemo: Step[] = [
    {
        target: 'body', // Target the body to center the welcome screen
        content: (
          <div>
            <h2>Welcome to VedicHom</h2>
            <p>We're glad to have you here. Click "Start" to start the tour.</p>
          </div>
        ),
        disableBeacon: true,
        disableOverlayClose: true,
        placement: 'center',
        event: "click"
      }, 
    {
        target: `#progress-status`,
        content: 'This is my progress status',
        spotlightClicks: true,
    },
    {
        target: `#class-count-overview`,
        content: 'Check your class status at a glance from here',
        spotlightClicks: true,
    },
    {
        target: `#${menuItem.myAccount}-mobile`,
        content: 'Complete and check your account details',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
    },
    {
        target: `#tab-example-tab-tab1`,
        content: 'This will give you profile overview ',
        spotlightClicks: true,
    },
    {
        target: `#profile-account-info`,
        content: 'This section contains your account information',
        spotlightClicks: true,
    },
    {
        target: `#${menuItem.myRequests}-mobile`,
        content: 'Check and communicate about your class requests',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
    },
    {
        target: `#${menuItem.myAds}-mobile`,
        content: 'You can create, update and check your advertisements from here',
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
    },
  ];

export const options = {
    arrowColor: '#ffff',
    backgroundColor: '#fff',
    overlayColor: 'rgba(0, 0, 0, 0.4)',
    primaryColor: '#FB6D48',
    textColor: 'black',
    zIndex: 10000,
  }