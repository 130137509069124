import { Form } from "react-bootstrap"
import { Controller } from "react-hook-form"
import Select, { Options, StylesConfig } from "react-select"
import CreatableSelect from "react-select/creatable"
import "../../../form/FormInput.css"
import FormLabel from "../../../form/FormLabel"
import { useEffect, useRef, useState } from "react"
import { DeviceType } from "../../../../constants"
import { detectDeviceType } from "../../../../utils/commonFunctions/CommonFunctions"

interface FormSelectProps {
  name: string
  title: string
  errors?: any
  control?: any
  options: any[]
  isMulti?: boolean
  placeholder?: string
  className?: string
  disabled?: boolean
  onChange?: (selectedOptions: any) => void
  value: string[],
  instructionName?: string,
  MAX_LENGTH: number,
  isLoading?: boolean,
  MAX_ITEMS_ALLOWED: number,
}

function FormMultiSelectRef({
  errors,
  title,
  value,
  name,
  control,
  options : data,
  isMulti,
  placeholder = "",
  className = "",
  onChange,
  disabled,
  instructionName,
  MAX_LENGTH,
  isLoading,
  MAX_ITEMS_ALLOWED
}: FormSelectProps) {
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);
  const [options, setSelectOptions] = useState<any[]>(data);
  const inputRef = useRef<HTMLDivElement>(null);
  useEffect(() => {setSelectOptions(data)}, [data])
  const instructionOption = {
    label: `Please add your ${instructionName || 'option'} if it's not listed`,
    value: "instructions",
    isDisabled: true,
  };

  const validateInput = (value: string, type: "keypress" | "input", allowedKeys?: string[]): string | null => {
    const regex = /^[a-zA-Z\s]*$/; // Allow only alphabets and spaces
  
    if (type === "input") {
      if (value.length > MAX_LENGTH) {
        return `${title} name cannot exceed ${MAX_LENGTH} characters`;
      }
      if (!regex.test(value)) {
        return "Only alphabets and spaces are allowed.";
      }
    }
  
    if (type === "keypress") {
      const specialKeys = allowedKeys || [
        "Enter",
        "Backspace",
        "Delete",
        "ArrowUp",
        "ArrowDown",
        "ArrowLeft",
        "ArrowRight",
        "Tab",
        "CapsLock",
        "Shift",
        "Control",
        "Alt",
        "Escape",
        "Meta",
      ];
      if (!regex.test(value) && !specialKeys.includes(value)) {
        return "Only alphabets and spaces are allowed.";
      }
    }
  
    return null; // No errors
  };

  const handleInputChange = (inputValue: string) => {
    const error = validateInput(inputValue, "input");
    setError(error);
    if (!error) {
      setInputValue(inputValue);
    }
  };

  const customStyles: StylesConfig<any, true> = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),

    input: (base) => ({
      ...base,
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? '#FB6D48'
        : state.isFocused
        ? '#fff3f3'
        : base.backgroundColor,
      color: state.isSelected ? '#fff' : base.color,
    }),
  };
  const isValidNewOption = (inputValue: string) => {
    // Trim the input to remove leading or trailing whitespaces
    const trimmedInput = inputValue.trim();

    // Check for alphabets, length <= MAX_LENGTH, and absence in options
    const regex = /^[\p{L}\s]+$/u; // Matches any Unicode letters (including Hindi) and spaces
    const isUnique = !options.some(
      (option) => option.value.toLowerCase() === trimmedInput.toLocaleLowerCase()
    );

    return (
      regex.test(trimmedInput) &&
      trimmedInput.length <= MAX_LENGTH &&
      isUnique
    );
  };
  function handleOnBlur(e: string, field: any) {
    const inputValue = e.trim(); // Get the input value and trim any extra spaces
    const optionsLabel = options.map((option: any) => option.label.toLowerCase()); // Prepare a list of option labels for comparison

    // If the input value is not already in the options list, add it to the `value` array
    if (inputValue && !optionsLabel.includes(inputValue.toLowerCase()) && isValidNewOption(inputValue)) {
      const newOption = { value: inputValue, label: inputValue }; // Create a new option object
      const updatedValue = field.value ? [...field.value, newOption]:[newOption]; // Append the new option to the existing selected values
      setSelectOptions((pev)=>([...pev, newOption]));
        field.onChange(updatedValue); // Update the form field with the new value
        if (onChange) onChange(updatedValue); // Call the external onChange handler if provided
    }
    else {
      setError(null); // Clear the error message if everything is valid
    }
  }
  const handleCreateOption = (inputValue: string, field: any) => {
    handleOnBlur(inputValue, field);
  };
  const handleOnFocus = () => {
    if (inputRef?.current && detectDeviceType() === DeviceType.Mobile) {
      inputRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }
  };
  return (
    <>
      <Form.Group className={`${className} `}>
        <FormLabel title={''} />
        {error&&<p className=" text-danger fs-6">{error}</p>}
        <Controller
          name={name}
          rules={{ required: "This is a required Field" }}
          control={control}
          render={({ field }) => (
            <div className={!className ? "multi-select-custom " : ""} ref={inputRef}>
              <CreatableSelect
                menuPortalTarget={document.body}
                options={[instructionOption,...options]}
                value={value}
                placeholder="Select one or mutiple"
                isMulti
                menuPlacement="bottom"
                // onMenuClose={()=>setNotAvailable(false)}
                onChange={(selectedOptions) => {
                  field.onChange(selectedOptions)
                  if (onChange) {
                    onChange(selectedOptions)
                  }
                }}
                maxMenuHeight={200}
                inputValue={inputValue}
                onInputChange={(e)=>{handleInputChange(e)}}
                styles={customStyles}
                isDisabled={disabled}
                classNamePrefix="custom-sub-scrollbar"
                hideSelectedOptions={true}
                noOptionsMessage={()=>'Option you are looking for is not available or being used'}
                isValidNewOption={isValidNewOption}
                onCreateOption={(value)=>{handleCreateOption(value, field)}} // Enable dynamic option creation
                onFocus={handleOnFocus}
                isLoading={isLoading}
                closeMenuOnSelect={(value && value?.length < MAX_ITEMS_ALLOWED)?false:true}
                // onBlur={(e) => handleOnBlur(e.target.value, field)} // Pass `field` to handleOnBlur
              />
            </div>
          )}
        />
        {errors && errors[name] ? (
          <div className="invalid-feedback d-block">
            {errors[name]["message"]}
          </div>
        ) : null}
      </Form.Group>
    </>
  )
}

export default FormMultiSelectRef
