import React, { useState } from 'react'
import onlineBadge from "../../assets/img/onlinerightIcon.svg"
import hybridBadge from "../../assets/img/hybridBadgeIcon.svg"
import recordBadge from "../../assets/img/record-video-badge.svg"
import { motion } from 'framer-motion'

interface props { isOpened?: boolean}
export const OnlineBadge = ({isOpened}: props) => {
    const [isHover, setHover] = useState<boolean>(false)
    return (
        <div onMouseEnter={()=>{setHover(true)}} onMouseLeave={()=>{setHover(false)}} className="onlineIcon-Badge d-flex align-items-center justify-content-between"> <img src={onlineBadge} alt="" />{(isOpened || isHover)&&<div className="cards-badge-font">Online Classes</div>}</div>)
}

export const HybridBadge = ({isOpened}: props) => {
    const [isHover, setHover] = useState<boolean>(false)
    return (
        <div
       onMouseEnter={()=>{setHover(true)}} onMouseLeave={()=>{setHover(false)}} className="transitionSm hybridIcon-Badge d-flex align-items-center justify-content-between"> <img src={hybridBadge} alt="" />
        {(isOpened || isHover)&&<div
        
         className="hybrid-badge-font">Hybrid Classes</div>}</div>
    )
}

export const RecordBadge = ({isOpened}: props) => {
    const [isHover, setHover] = useState<boolean>(false)
    return (<div onMouseEnter={()=>{setHover(true)}} onMouseLeave={()=>{setHover(false)}} className="hybridIcon-Badge d-flex align-items-center justify-content-between"> <img src={recordBadge} alt="" />{(isOpened || isHover)&&<div className="reco-badge-font">Recorded lectures</div>}</div>
    )
}