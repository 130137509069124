import React from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'
import { useAppSelector } from '../../../app/hooks'
import ProfileCardStatic from '../../cards/staticProfileCard/ProfileCardStatic'
import ReviewDisplayCard from '../../cards/commonReviewDisplayCard/ReviewDisplayCard'
import { RxCross1 } from 'react-icons/rx'
import { IoStar } from 'react-icons/io5'

interface props {
    show: boolean
    setShow: (show: boolean) => void
    profileData: any
    reviews: any
}

const AllReviewsModal = (prop: props) => {
  return (
    <>
    <Modal
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    scrollable
    show={prop.show}
    onHide={()=>{prop.setShow(!prop.show)}}
    >
    <Modal.Header>
    <div className=' d-flex gap-2 align-items-center ms-3'>
    <div className='d-flex justify-content-center align-items-center gap-1 fs-5'>{prop?.profileData?.data.adDetail?.providerId?.rating} <IoStar className='color-primary'/></div>
    {prop?.reviews&& ` & ${prop?.reviews?.length} Reviews`}
    </div>
    <Button 
    className=' bg-light-subtle  bg-opacity-25  text-dark border-0 position-absolute end-0 top-0 me-4 mt-2 rounded-5'
    onClick={()=>{prop.setShow(false)}}>
        <RxCross1 />
    </Button>
    </Modal.Header>
    <Modal.Body className='p-0 p-md-2 custom-scrollbar'>
    <Container className='h-100 pt-0 p-md-5'>
    <Row className='h-100'>
    {prop?.reviews&&
    (<Col lg={12} className="review-wrapper">
        {prop.reviews.map((item: any, index: number) => (
            <ReviewDisplayCard item={item} index={index} />),)}
    </Col>)}
    </Row>
    </Container>
    </Modal.Body>
    </Modal>
    </>
  )
}

export default AllReviewsModal