import React from 'react'
import { useEffect, useRef } from "react";
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FaStar } from 'react-icons/fa'
import { DeviceType, TEACHING_EXP } from '../../constants'
import { HybridBadge, OnlineBadge, RecordBadge } from '../badges/ProfileBadhges'
import CardDropdoenOptions from './CardDropdoenOptions'
import ImageWithLoader from './ImageWithLoader'
import hearticon from "../../assets/images/heartIcon.svg"
import hearticonWhite from "../../assets/images/heartIconWhite.svg"
import dummy from "../../assets/images/dummy-profile-pic.jpg"
import profileverified from "../../assets/img/profile-verified.svg"
import { motion, useAnimation, useInView } from "framer-motion";
import { detectDeviceType } from '../../utils/commonFunctions/CommonFunctions';


interface propsType {
    id: string
    index: number | string
    setIsMutedCard: any
    data: any
    customMessages: any
    activeItems: any
    user: any
    handleToggle: any
    selectCard: any
    modeOfClass: any
    batchType: any
    isMutedCard: string
}

const ProviderCard = ({id, index, setIsMutedCard, data, 
    customMessages, activeItems, user, handleToggle,
    selectCard, modeOfClass, batchType, isMutedCard }: propsType) => {
        const controls = useAnimation();
        const ref = useRef(null);
        const isInView = useInView(ref, { amount: 0.9 });
        
      
        const cardVariants = {
        initial: { opacity: 0.5, scale: 0.5 },
            inView: { opacity: [0.8, 1], scale: [0.8, 1] },
          exit: { opacity: 0.5, scale: 0.5 }
        };
        
      
    
    return (
        <>
<motion.div
      key={id}
      className="position-relative mb-1 sticky-for-app"
      ref={ref}
      onMouseEnter={() => setIsMutedCard(id)}
                onMouseLeave={() => setIsMutedCard("")}
                {...(detectDeviceType() == DeviceType.Mobile && {
                    variants: cardVariants,
                    initial: "initial",
                    whileInView: 'inView',
                    exit: 'exit',
                    transition: { duration: 0.5 } 
                  })}
    >
                <Row className="card-dropdown-menu  option-fav d-flex justify-content-between align-items-center cursor-pointer">
                    <Col xs={6} className="p-0">
                        <CardDropdoenOptions id={id} />
                    </Col>
                    <Col
                        xs={6}
                        className="align-items-center justify-content-end d-flex"
                    >
                        <OverlayTrigger
                            key={`overlay-${id}`}
                            placement="left"
                            overlay={
                                <Tooltip id={`tooltip-${id}`}>
                                    {customMessages[index] || data?.isFavourite
                                        ? "Remove from favourites!"
                                        : user.providerToken || user.seekerToken
                                            ? "Add to favourites!"
                                            : "Login First"}
                                </Tooltip>
                            }
                        >
                            <div
                                className="fav-icon cursor-pointer position-relative"
                                onClick={() => handleToggle(index, data?._id)}
                                id={`fav-icon-${index}`}
                            >
                                {activeItems[index] && user?.seekerToken ? (
                                    <img src={hearticonWhite} alt="showWhiteHeart" />
                                ) : (
                                    <img src={hearticon} alt="showWhiteHeart" />
                                )}
                            </div>
                        </OverlayTrigger>
                    </Col>
                    <div className="position-absolute cards-badges-items-box">
                        {data?.isHybrid && (
                            <div className="mb-1">
                                <HybridBadge />
                            </div>
                        )}
                        {data?.isOnline && (
                            <div className="mb-1">
                                <OnlineBadge />
                            </div>
                        )}
                        {data?.recordedLect && (
                            <div className="mb-1">
                                {" "}
                                <RecordBadge />
                            </div>
                        )}
                    </div>
                </Row>
                <div
                    onClick={() => selectCard(id)}
                    className={`provider-card cursor-pointer h-100 ${data?.providerData?.isPremium && "prime-styles"
                        }`}
                >
                    <div
                        className={`fav-card guide-card ${data?.providerData?.isPremium && "prime-styles2"
                            }`}
                    >
                        <div className="fav-img position-relative">
                            {data?.profileVideo ? (
                                <video
                                    muted={data._id === isMutedCard ? false : true}
                                    loop
                                    preload={"auto"}
                                    autoPlay
                                    poster={data?.profileImage || dummy}
                                    playsInline // for safari to not let fullscreen video playback
                                >
                                    <source src={data?.profileVideo} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <ImageWithLoader src={data?.profileImage || dummy} alt={data?.profileImage} />
                            )}
                            <div className="fav-ast-name w-100 d-flex justify-content-between">
                                <div>
                                    <h2 className="ast-name mb-1">
                                        {(data?.providerData?.firstName ||
                                            data?.providerId?.firstName) +
                                            " " +
                                            (data?.providerData?.lastName ||
                                                data?.providerId?.lastName || '')}
                                    </h2>
                                    <p className="ast-loc">
                                        <span>{data?.city}</span>
                                    </p>
                                </div>
                                {data?.providerData?.isMobileNoVerified && (
                                    <div className="verified-icon verified-icon-2">
                                        <img
                                            src={profileverified}
                                            title="Verified Profile"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="px-4 py-3">
                            <div className=""></div>
                            <div className="d-flex justify-content-between ">
                                <p className="ast-ocp mb-3 mh-2 text-truncate">
                                    {data?.vedicSubject}
                                </p>
                                {data?.providerData?.rating > 0 && (
                                    <div className="d-flex align-items-start justify-content-end gap-1 text-nowrap  ">
                                        <FaStar color="#ffd700" size={16} />
                                        <span className="total-rating text-black">
                                            {data?.providerData?.rating}
                                            {data?.providerData?.reviewCount && (
                                                <small className="reviews text-black">{`(${data?.providerData?.reviewCount} reviews)`}</small>
                                            )}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <p className="ast-ocp ast-about font-13 mb-3 mh-3">
                                {data?.aboutClass}
                            </p>
                            <div className="d-flex justify-content-between align-items-end mb-0 mb-sm-3">
                                <div>
                                    <strong>
                                        <span className=" text-start p-0">
                                            {modeOfClass && batchType ? (
                                                `₹ ${modeOfClass === "any" && batchType === "any"
                                                    ? data?.avgPrice
                                                    : modeOfClass === "realTime"
                                                        ? batchType === "group"
                                                            ? data?.prices?.realTime?.group?.hourlyFee
                                                            : batchType === "private"
                                                                ? data?.prices?.realTime?.private
                                                                    ?.hourlyFee
                                                                : data?.avgPrice
                                                        : modeOfClass === "virtual"
                                                            ? batchType === "group"
                                                                ? data?.prices?.virtual?.group?.hourlyFee
                                                                : batchType === "private"
                                                                    ? data?.prices?.virtual?.private
                                                                        ?.hourlyFee
                                                                    : data?.avgPrice
                                                            : data?.avgPrice
                                                }/hr`
                                            ) : (
                                                <>
                                                    <div className=" d-flex flex-column ">
                                                        <small className=" fs_6 text-truncate	">
                                                            Starting at
                                                        </small>
                                                        <div className="d-flex">
                                                            <div className="text-truncate common-card-price-input">
                                                                ₹ {data?.avgPrice}{" "}
                                                            </div>
                                                            <span>/hr</span>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </span>
                                    </strong>
                                    {(data?.prices?.realTime?.group?.firstClassFree ||
                                        data?.prices?.realTime?.private?.firstClassFree ||
                                        data?.prices?.virtual?.group?.firstClassFree ||
                                        data?.prices?.virtual?.private?.firstClassFree) && (
                                            <span className="d-block first-class">
                                                1st class free *
                                            </span>
                                        )}
                                </div>
                                <div className="tutor-card-btn-exp">
                                    <span
                                        className={`badge-amb ${data?.providerData?.rank === TEACHING_EXP.NEW
                                                ? "badge-new"
                                                : data?.providerData?.rank === TEACHING_EXP.EXPERIENCED
                                                    ? "badge-experienced"
                                                    : data?.providerData?.rank === TEACHING_EXP.STAR_TEACHER
                                                        ? "badge-super-teacher"
                                                        : data?.providerData?.rank === TEACHING_EXP.AMBASSADOR
                                                            ? "badge-ambassador"
                                                            : "badge-new"
                                            } text-center`}
                                    >
                                        {data?.providerData?.rank || "New"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </motion.div>
        </>
    )
}

export default ProviderCard