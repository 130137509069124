import * as React from "react"

import star from "../../assets/img/solar_star-broken.svg"
import locationIcon from "../../assets/img/location.svg"
import findSearch from "../../assets/img/clarity_search-line.svg"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { Button, Col, Container, Form, Offcanvas, Row } from "react-bootstrap"
import { useAppDispatch } from "../../app/store"
import { useSearchAdvtsMutation } from "../../features/featSearchAdvts/SearchAdvtsApi"
import {
  locationState,
  setIsSelectedLocation,
  setLocation,
  setSearchText,
  setSearchType,
  setSelectedSearchText,
} from "../../features/featSearchAdvts/SearchAdvtSlice"
import { useAppSelector } from "../../app/hooks"
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { setSeekerLandingPageSearchData } from "../../features/featSeekerDashboard/seekerDashboardSlice"
import { landingSearchBarSchema } from "../../utils/yupSchema/Schema"
import { TbRuler } from "react-icons/tb"
import headerlogo from "../../assets/img/logo.png"

import { FaPersonWalkingDashedLineArrowRight } from "react-icons/fa6"
import { FiVideo } from "react-icons/fi"
import { SearchLocationInputBar } from "../modals/editProfile/locationUpdate/EditLocModal"
import { HomeSeachLocModal } from "../modals/homeSeacrhLocModal/homeSeacrhLocModal"
import { useEffect } from "react"
import { useDebounce } from "../../utils/debouncer"
import useGeoLocationTracker from "../../features/googleAnalytics/useGeoLocationTracker"

interface searchProps {}

const LandingSearch = (props: searchProps) => {
  const [locationField, setLocationField] = React.useState("")
  const [locationDropdown, setLocationDropwdown] = React.useState(false)
  const [searchDropDown, setSearchDropDown] = React.useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const inputRef = React.useRef<HTMLInputElement>(null)
  const formRef = React.useRef<HTMLFormElement>(null)
  const { seekerToken } = useAppSelector((state: any) => state.auth)
  const dispatch = useAppDispatch()
  const [searchBar, setSearchBar] = React.useState<boolean>(false)
  const [isOffcanvasVisible, setOffcanvasVisible] =
    React.useState<boolean>(false)
  const [search, { data: searchTextResponse, error, isSuccess }] =
    useSearchAdvtsMutation()
  const {
    searchType,
    searchText,
    selectedSearchText,
    isSelectedLocation,
    selectedLocation,
  } = useAppSelector(locationState)
  const handleSearchText = (text: string) => {
    dispatch(setSearchText(text))

    // if (!selectedSearchText) search({ searchText: text })
  }

  const handleSearchType = (text: string) => {
    dispatch(setSearchType(text))
  }

  useEffect(() => {
    if (selectedSearchText) {
      inputRef?.current?.focus()
    }
  }, [selectedSearchText])
  //use debouncer
  function searchSubject(searchText: string) {
    search({ searchText: searchText })
  }
  const searchTextareaRef = React.useRef<HTMLInputElement>(null)
  const debouncedSubjectSearch = useDebounce(searchSubject, 300)
  useEffect(() => {
    if (searchText) debouncedSubjectSearch(searchText)
  }, [searchText])
  const [searchParams, setSearchParam] = useSearchParams()
  const trackLocation = useGeoLocationTracker()
  const vedicSubjectParam = searchParams.get("vedicSubject")
  const onSubmit = (details: any) => {
    setOffcanvasVisible(false);
    const location: string | undefined = selectedLocation ? selectedLocation === 'Online' ?  "" : selectedLocation === 'Around me' ? searchParams.get('aroundMe') || '' : selectedLocation : undefined;
    if(location) trackLocation(location, `search ${selectedSearchText || searchText}`)
      const payload: Record<string, string> = {
      vedicSubject: selectedSearchText || searchText || "",
      ...(location && { aroundMe: location }) 
    };
  
    navigate(
      {
        pathname: "/results",
        search: `?${createSearchParams({ ...payload, pageNo: "1" })}`,
      },
      {
        replace: true,
      }
    );
  };

  const { handleSubmit, setValue, register, control } = useForm<any>({
    resolver: landingSearchBarSchema,
  })

  useEffect(() => {
    if (vedicSubjectParam) dispatch(setSelectedSearchText(vedicSubjectParam))
    else dispatch(setSelectedSearchText(""))
  }, [searchParams])
  function handleLocationSelect(place: string): void {
    dispatch(setLocation(place))
    searchParams.set("aroundMe", place)
    setSearchParam(searchParams)
  }
  useEffect(()=>{
    if(document.activeElement) {
      if(searchTextareaRef?.current && document.activeElement.id === searchTextareaRef?.current?.id) {
        
      }
      else setSearchDropDown(false)
    }
  },[document.activeElement])
  return (
    <>
      <Offcanvas
        show={isOffcanvasVisible}
        onHide={() => {
          setOffcanvasVisible(false)
        }}
        placement="bottom"
        className="filter-canvas"
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title className='fc-top-name'>
            <p>TeXT</p>
          </Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3 pt-1">
          <Container as={"form"} onSubmit={handleSubmit(onSubmit)}>
            <Row className="gap-4">
              <Col xs={12}>
                <div className="filter-section rounded-2">
                  <div className="find-vedic position-relative d-flex align-items-center w-100">
                    <img src={headerlogo} className="star-icon" />
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      //{...register("vedicSubject")}
                      name="VedicSubject"
                      placeholder="Find Your Vedic Expert"
                      className="form-control find-field pe-2"
                      //  value={ !!selectedSearchText  ?  selectedSearchText   : !!searchText || searchText === null ? searchText || "" : vedicSubjectParam || "" }
                      value={selectedSearchText || searchText}
                      onFocus={() => {
                        setLocationDropwdown(false)
                        setSearchDropDown(true)
                      }}
                      onChange={(e: any) => handleSearchText(e.target.value)}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="location-box-canvas">
                  <HomeSeachLocModal
                    isdisabled={false}
                    defaultValue={""}
                    setAtParent={handleLocationSelect}
                  />
                </div>
              </Col>
                <>
                  {searchDropDown && searchTextResponse?.length ? (
                    <>
                      {searchTextResponse && (
                        <div className={`search-select-teacher canvas-box`}>
                          {searchTextResponse.map(
                            (item: any, index: number) => (
                              <>
                                <p
                                  key={index}
                                  className="tab text town locate choice-locate"
                                  onClickCapture={() => {
                                    dispatch(setSelectedSearchText(item?.label))
                                    setSearchDropDown(false)
                                  }}
                                >
                                  {item?.label}
                                </p>
                              </>
                            ),
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              <Col xs={12} className="align-self-baseline ">
                <div className="flex-none dropdown_loc">
                  <Button
                    type="submit"
                    className="find-btn rounded-2 py-2 d-flex align-items-center justify-content-center w-100"
                  >
                    <img src={findSearch} className="me-1" />
                    <span className="fw-bold font-12">Find</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
      <form
        ref={formRef}
        className="d-flex justify-content-center mb-0 mb-md-4"
        onClick={() => {
          if (window.innerWidth < 768 && location.pathname.includes("results"))
            setOffcanvasVisible(!isOffcanvasVisible)
        }}
        onSubmit={handleSubmit(onSubmit)}
        // onBlur={()=>setLocationDropwdown(false)}
      >
        <div
          className={
            searchBar || selectedSearchText
              ? "filter-section landing-search-bar w-100 active"
              : "filter-section landing-search-bar w-100"
          }
          onClick={() => setSearchBar(true)}
        >
          <div className="find-vedic position-relative d-flex align-items-center w-100" >
            <img src={headerlogo} className="star-icon" />
            <Form.Control
            id='searchTextarea'
              type="text"
              autoComplete="off"
              {...register("vedicSubject")}
              name="VedicSubject"
              placeholder="Find Your Vedic Expert"
              className="form-control find-field pe-2"
              ref={searchTextareaRef}
              //  value={ !!selectedSearchText  ?  selectedSearchText   : !!searchText || searchText === null ? searchText || "" : vedicSubjectParam || "" }
              value={selectedSearchText || searchText}
              onFocus={() => {
                setLocationDropwdown(false)
                setSearchDropDown(true)
              }}
              onChange={(e: any) => handleSearchText(e.target.value)}
            />
            <>
              {searchDropDown && searchTextResponse?.length ? (
                <>
                  {(searchText.length > 0 && searchTextResponse) && (
                    <div className={`search-select-teacher `}>
                      {searchTextResponse.map((item: any, index: number) => (
                        <>
                          <p
                            key={index}
                            className="tab text town locate choice-locate"
                            onClickCapture={() => {
                              dispatch(setSelectedSearchText(item?.label))
                              setSearchDropDown(false)
                            }}
                          >
                            {item?.label}
                          </p>
                        </>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          </div>

          <div className={"find-location align-items-center w-100 ms-auto"}>
            <img src={locationIcon} className="location-icon" />
            <div className=" position-absolute top-0 start-8">
              <HomeSeachLocModal
                isdisabled={false}
                defaultValue={""}
                setAtParent={handleLocationSelect}
              />
            </div>
          </div>
          <div className="flex-none dropdown_loc">
            <button
              type="submit"
              className="find-btn d-flex align-items-center justify-content-center"
            >
              <img src={findSearch} className="me-md-3" />
              <span className="d-none d-md-block ">Find</span>
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export default LandingSearch
