import React, { ReactNode } from 'react'
import { useAppSelector } from '../../app/hooks';
import { selectCurrentUser } from '../../features/Auth/authSlice';
import { Navigate } from 'react-router-dom';
import { USERTYPE } from '../../constants';

interface ProviderFunnelPrivacyProp {
    children: ReactNode;
}

const ProviderFunnelPrivacy = ({children}: ProviderFunnelPrivacyProp) => {
    const {providerToken} = useAppSelector(selectCurrentUser)
  return (
    <>{providerToken ? children : <Navigate to={'/'} />}</>
  )
}

export default ProviderFunnelPrivacy