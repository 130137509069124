import { createApi, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../app/store";
import { APP_ENV } from "../../constants";

const BASE_URL = APP_ENV &&  ['testing','development'].includes(APP_ENV as string) ? import.meta.env.VITE_REACT_TEST_API_URL : import.meta.env.VITE_REACT_APP_API_URL 

export type Location = {
  latitude: number;
  longitude: number;
};

interface GeoLocationProp {
  name?: string;
  coordinates?: Location;
}

const getCurrentPosition = (): Promise<Location> => {
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const { latitude, longitude } = position.coords;
          resolve({ latitude, longitude });
        },
        (error: GeolocationPositionError) => {
          reject(new Error("Failed getting location. Try resetting location settings."));
        }
      );
    } else {
      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
};

export const locationApi = createApi({
  reducerPath: "locationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/`,
    prepareHeaders: (headers, { getState }) => {
      const token =
        (getState() as RootState).auth.seekerToken ||
        (getState() as RootState).auth.providerToken;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getLocation: builder.mutation<Location, void>({
      queryFn: async (): Promise<{ data: Location } | { error: FetchBaseQueryError }> => {
        try {
          const location = await getCurrentPosition();
          return { data: location };
        } catch (error: any) {
          return { error: {  status: 'CUSTOM_ERROR',
            data: error.message,
            error: error.message  } };
        }
      },
    }),
    getLocationSuggestion: builder.mutation({
      query: (arg: GeoLocationProp) => ({
        url: `static-data/get-location-suggestion?${arg.name ? `search=${arg.name}` : ''}${arg.coordinates ? `&coordinates=${arg.coordinates.longitude},${arg.coordinates.latitude}` : ''}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetLocationMutation, useGetLocationSuggestionMutation } = locationApi;
