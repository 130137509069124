import { youtubevideoState } from "../../Types";

const convertToEmbedUrl = (url: string): youtubevideoState => {
  try {
    // Check if the URL is already in the embed format
    if (url.startsWith("https://www.youtube.com/embed/")) {
      // Extract the video ID from the embed URL
      const videoId = url.split("/").pop() || "";

      // Validate the video ID (should be 11 characters long)
      if (videoId.length === 11 && /^[a-zA-Z0-9_-]*$/.test(videoId)) {
        return { url, isValid: true };
      } else {
        throw new Error("Invalid YouTube URL");
      }
    }

    // Parse the URL
    const urlObj = new URL(url);
    let videoId = "";

    // Check if the URL is a valid YouTube URL
    if (
      urlObj.hostname === "youtu.be" ||
      urlObj.hostname === "www.youtube.com" ||
      urlObj.hostname === "youtube.com"
    ) {
      if (urlObj.hostname === "youtu.be") {
        videoId = urlObj.pathname.slice(1);
      } else {
        videoId = urlObj.searchParams.get("v") || "";
      }

      // Validate the video ID (should be 11 characters long)
      if (videoId.length === 11 && /^[a-zA-Z0-9_-]*$/.test(videoId)) {
        return { url: `https://www.youtube.com/embed/${videoId}`, isValid: true };
      } else {
        throw new Error("Invalid YouTube URL");
      }
    } else {
      throw new Error("Invalid YouTube URL");
    }
  } catch (error) {
    return { url, isValid: false };
  }
};

export { convertToEmbedUrl };
