import { useEffect, useState } from "react"
import {
  Container,
} from "react-bootstrap"
import "./PaymentSub.css"
import { Link, Navigate, Outlet, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useAppSelector } from "../../../app/hooks"
import { selectCurrentUser } from "../../../features/Auth/authSlice"
import { providerDashboardApi } from "../../../features/featProviderDashboard/FeatProviderApi"
import { seekerDashboardApi, usePaymentVerifyMutation } from "../../../features/featSeekerDashboard/seekerDashboardApi"
import { store } from "../../../app/store"

const PaymentSubWrapper = () => {
  const {id} = useParams()
  const {user, seekerToken} = useAppSelector(selectCurrentUser)
  return (
    // user?.isPremium && id  ? <Navigate to={`/${user.seekerToken ? "seeker-dashboard" : "source-dashboard"  }${seekerToken ? `/my-requests?requestStatus=Accept?requestId=${id}`:""}`} replace/>:
    // commented to test upgrade plan
     <>
      <section className="explore-section pb-5 pt-90  mt-5 mt-xl-0 ">
        <Container>
            <Outlet/>
        </Container>
      </section>
    </>
  )
}

export default PaymentSubWrapper
