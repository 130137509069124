import React, { lazy, Suspense } from 'react'
import { termsandConditionContent } from '../../../assets/content/termsandConditionscontent'
import SpinnerSecondary from '../../spinner/SpinnerSecondary'
import { privacyPolicyContentContent } from '../../../assets/content/privacyPolicyContent'
import { refundPolicyContent } from '../../../assets/content/refundPolicyContent'
import { Tabs, Tab, Col, Container, Row } from 'react-bootstrap'
const TermsModalContent = lazy(() => import('../../termsModalContent/TermsModalContent'))
import './style.css'


const TermsAndPolicyPage = () => {
    return (
      <Container className='my-5 py-5'>
        <Row>
          <Col>
            <Suspense fallback={<SpinnerSecondary />}>
              <Tabs
                defaultActiveKey="terms"
                id="terms-policy-tabs"
                className={`mb-3`}
                justify
              >
                <Tab eventKey="terms" title="Terms & Conditions">
                  <TermsModalContent class_name={'m-1'} content={termsandConditionContent} />
                </Tab>
                <Tab eventKey="privacy" title="Privacy Policy">
                  <TermsModalContent class_name={'m-1'} content={privacyPolicyContentContent} />
                </Tab>
                <Tab eventKey="refund" title="Refund Policy">
                  <TermsModalContent class_name={'m-1'} content={refundPolicyContent} />
                </Tab>
              </Tabs>
            </Suspense>
          </Col>
        </Row>
      </Container>
    );
  };
  
  export default TermsAndPolicyPage;