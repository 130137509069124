import React, { useEffect, useState } from 'react'
import { useGetFAQForProviderQuery, useReplyFAQMutation } from '../../../../features/featProviderDashboard/FeatProviderApi'
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Accordion, Button, Spinner, Form, Pagination, Row, Col, Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CustomBackNavButton  from '../../../buttons/custumBackNavButton/CustomBackNavButton';
import { number } from 'yup';
import { LuPencilLine } from 'react-icons/lu';
import { HiPencilSquare } from 'react-icons/hi2';
import "./FaqScreen.css"
import ErrorPage from '../../../ErrorPage';

const FaqScreen = () => {
    const [searchParams] = useSearchParams();
    const adId: string|null = searchParams.get('AdID');;
    const [faqs, setFaqs] = useState<any[]>([])
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [totalPages, setTotalPages] = useState<number>(1)
    const [expandStates, setExpandStates] = useState<boolean[]>(new Array(10).fill(false));
    const [eventKeys, setEventKeys] = useState<string[]>(Array.from({ length: 10 }, (_, index) => index.toString()));
    const [isEditing, setIsEditing] = useState<boolean|number>(false)
    const toggleExpand = (index: number) => {
        setExpandStates(prevExpandStates => {
          const newExpandStates = prevExpandStates.map((state, i) => i === index);
          return newExpandStates;
        });
      };
    const [answer, setAnswer] = useState<string[]>([])
  const [currentSendIndex, setCurrentSendIndex] = useState<number>(-1)
    const pagelimit = 10
    const { data: FAQdata, isSuccess: FAQSuccess, isLoading: FAQLoading, error: faqFailed, refetch } =
    useGetFAQForProviderQuery( {adId: adId, page: currentPage, pageLimit: pagelimit} ,{skip: ( adId == null)})
    const [
        ReplyFAQ,
        {
            data: ReplyFAQData,
            isLoading: ReplyFAQLoading,
            isSuccess: ReplyFAQSuccess,
        },
    ] = useReplyFAQMutation()

    const handleSendClick = (index: number) => {
        toast.dismiss()
        if (answer[index] === undefined || (answer as any)[index] === "") {
          toast.error("Please enter your Answer")
        } else {
          setCurrentSendIndex(index)
          ReplyFAQ({
            faqId: FAQdata?.data[index]?._id,
            answer: answer[index],
          }).unwrap().then(()=>{
            setIsEditing(false)
            refetch()
        })
          .catch((error: any)=>toast.error(error.data.message))
        }
      }

    const handleAnswerChange: (index: number, value: string) => void = (
        index,
        value,
      ) => {
        if (value === "") {
          toast.dismiss()
          toast.error("Please enter your Answer")
          const newAnswer: string[] = [...answer]
          newAnswer[index] = ""
          setAnswer(newAnswer as any)
        } else {
            
          const newAnswer: string[] = [...answer]
          newAnswer[index] = value
          setAnswer(newAnswer as any)
        }
      }
      const handlePageChange = (page: number) => {
        setCurrentPage(page)
      }
    useEffect(()=>{
        window.scrollTo(0,0);
    },[])
    useEffect(()=>{
        if(FAQdata){
            setFaqs(FAQdata?.data || [])
            setTotalPages(FAQdata?.count?.totalPages)
            FAQdata?.data?.map((item: any, index: number)=>{
                if(item?.answer || item?.answer!=='') {
                    const tempEventKeys = eventKeys; tempEventKeys[index]=='0';
                    setEventKeys(tempEventKeys);
                }
            })
        }
    },[FAQdata])
    function handleFaqEdit (index: number) {
        setIsEditing(index);
        const tempAnsArray = answer; 
        tempAnsArray[index] = faqs[index]?.answer;
        setAnswer(tempAnsArray)
    }
    return (
        <>
        <div className="ad-card ">
        <CustomBackNavButton/>
        {faqFailed && <><ErrorPage/></>}
        {FAQLoading&&<>
        <div className='ad-card  mt-2 min-vh-100'/>
        </>}
            {faqs?.length > 0 && (
                <div className="ad-card  mt-2  mb-4">
                    <div className="ad-heading">
                        {" "}
                        Frequently <span className='faq-head-text'>Asked Questions</span>
                    </div>
                    <Accordion flush alwaysOpen
                    defaultActiveKey={eventKeys}
                    className="faq-accordion" >
                    {faqs?.map(
                        (faq: any, index: number) => {
                            return (
                                <div className="faq-section mt-2 ad-card mb-1" key={index}>
                                    {/* <Accordion flush
                                        className="faq-accordion"
                                    > */}
                                        <Accordion.Item eventKey={index.toString()} className='px-0'> 
                                            <Accordion.Header className="faq-head p-2 px-0  " onClick={() => toggleExpand(index)}>
                                                <div className={expandStates[index] ? '': 'faq-head-context' }
                                                ><span className='faq-que-text'>Question - </span>{faq?.question}</div>
                                            </Accordion.Header>
                                            <div className='d-flex'>
                                            <div>Your Answer  </div>
                                            {faq?.answer&&
                                                <div className={` text-info ms-2 ${(isEditing === index)&&'text-info-emphasis text-decoration-underline cursor-pointer ms-2 '}`}
                                                    onClick={()=>{handleFaqEdit(index)}}><HiPencilSquare className='cursor-pointer' size={22} color='#fb6d48' />

                                                    </div>
                                                }
                                            </div>
                                                 
                                            {!faq?.answer || (isEditing === index)  ? (
                                                <Accordion.Body className="faq-body faq-ans-box">
                                                    <Form
                                                        onSubmit={(e) => e.preventDefault()}
                                                    >
                                                        <Form.Group controlId="questionInput">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Type your question here..."
                                                                value={answer[index]}
                                                                onChange={(e) =>
                                                                    handleAnswerChange(
                                                                        index,
                                                                        e.target.value,
                                                                    )
                                                                }
                                                                onKeyDown={(e) => {
                                                                    if (e.key === "Enter") {
                                                                        e.preventDefault()
                                                                        handleSendClick(index)
                                                                    }
                                                                }}
                                                            />
                                                        </Form.Group>

                                                        <Button
                                                            className="mt-2 secondary-button"
                                                            variant="primary"
                                                            onClick={(e) =>
                                                                handleSendClick(index)
                                                            }
                                                        >
                                                            {ReplyFAQLoading &&
                                                                currentSendIndex === index ? (
                                                                <Spinner className="spinner-small" />
                                                            ) : 
                                                                (typeof(isEditing)=='number')?'Update':
                                                                "Send"
                                                            }
                                                        </Button>
                                                    </Form>
                                                </Accordion.Body>
                                            ) : (
                                                <>
                                                    <Accordion.Body className='faq-ans-box'>
                                                        <p
                                                        className={expandStates[index] ? '' : 'faq-head-context'}
                                                        >{faq?.answer}</p>
                                                    </Accordion.Body>
                                                </>
                                            )}
                                        </Accordion.Item>
                                    {/* </Accordion> */}
                                </div>
                            )
                        },
                    )}
                    </Accordion>
                </div>
            )}
                {totalPages > 1 && (
                    <Pagination className="py-2">
                        {currentPage > 1 && (
                            <Pagination.Prev className='custom-pagination' onClick={() => handlePageChange(currentPage - 1)} />
                        )}
                        {[...Array(totalPages)].map((_, index) => {
                            if (
                                index === 0 || // Always show the first page
                                index === totalPages - 1 || // Always show the last page
                                Math.abs(index - currentPage) <= 2 || // Show pages near the current page
                                index === 1 || // Show the second page
                                index === totalPages - 2 // Show the second to last page
                            ) {
                                return (
                                    <Pagination.Item
                                        key={index + 1}
                                        active={index + 1 === currentPage}
                                        onClick={() => handlePageChange(index + 1)}
                                        className={index + 1 === currentPage ? "custom-pagination-active" : "custom-pagination"}
                                    >
                                        {index + 1}
                                    </Pagination.Item>
                                );
                            } else if (index === 2 || index === totalPages - 3) {
                                return <Pagination.Ellipsis key={index} className='custom-pagination' />;
                            }
                            return null;
                        })}
                        {currentPage < totalPages && (
                            <Pagination.Next className='custom-pagination' onClick={() => handlePageChange(currentPage + 1)} />
                        )}
                    </Pagination>
                )}

            </div>
        </>
    )
}

export default FaqScreen