import { useEffect, useState } from "react"
import {
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Modal,
  Tabs,
  Tab,
} from "react-bootstrap"
import mailicon from "../../../assets/img/mail-icon.svg"
import passwordicon from "../../../assets/img/password-icon.svg"
import facebookimage from "../../../assets/img/facebook-icon.png"
import googleimage from "../../../assets/img/google-icon.png"
// import SignUp from "../../../../features/Auth/signup/SignUp"
import usericon from "../../../assets/img/user-icon.svg"
import "../authComponent.css"
import { useAppSelector } from "../../../../app/hooks"

import { MODALNAME, USERTYPE } from "../../../../constants"

import SignupSeeker from "./SignupSeeker"
import { useAppDispatch } from "../../../../app/store"
import { closeModal, setModalName } from "../../../../features/Auth/ModalHandler/modalSlice"
import OTP from "../../../Verification/Number/Otp"
import { useNavigate, useSearchParams } from "react-router-dom"

interface signUpProps {}

const SignUpAlt = ({}: signUpProps) => {
  const navigate = useNavigate()
  const modalName = useAppSelector((state: any) => state.authModal.modalName)
  const dispatch = useAppDispatch()
  const [activeTab, setActiveTab] = useState("PROVIDER")
  const [searchParams, setSearchParams] = useSearchParams()

  function closeSignUpModal () {
    if(searchParams.get('reffralId') || searchParams.get('loginSeeker')) {
      searchParams.delete('loginSeeker')
      searchParams.delete('reffralId')
      setSearchParams()
      navigate(location.pathname)
    }
    dispatch(closeModal())
  }

  useEffect(()=>{
    if(searchParams.get('loginSeeker')!==null) {
      dispatch(setModalName(MODALNAME.SIGNUP))
    }
  },[searchParams])

  return (
    <>
      <Modal
        animation
        centered
        show={modalName === MODALNAME.SIGNUP}
        className="login-modal p-0"
        size="lg"
        aria-labelledby="exampleModalToggleLabel"
        fullscreen = 'sm-down'
      >
        <Modal.Dialog className="w-100 my-0 mx-0">
          <div className="pb-1 popup-content">
            <Modal.Header className="popup-header border-0">
              <Modal.Title
                className="popup-heading"
                id="exampleModalToggleLabel"
              ></Modal.Title>
              <Button
                variant="secondary"
                onClick={() => closeSignUpModal()}
                size="sm"
                className="btn-close"
              ></Button>
            </Modal.Header>
            <SignupSeeker userType={activeTab} />
          </div>
        </Modal.Dialog>
      </Modal>
    </>
  )
}

export default SignUpAlt
