import React, { useEffect, useState } from 'react'
import premimumStar from "../../../../assets/images/premium-1.svg"
import { useAppSelector } from '../../../../app/hooks'
import { PREMIUM_PASSES, PassType, USERTYPE } from '../../../../constants'
import { useNavigate } from 'react-router-dom'
import { dateReturnFormat, dateSeparator, getDateConverted, getTimeDifferenceFromServerTime } from '../../../../utils/timeDifferene/getTimeDifference'

function CommonUpgradePlanCard() {
  const {user} = useAppSelector((state)=>state.auth)
  const navigate = useNavigate()
  function handleTap(): void {
    navigate(`${(user?.role as string).toUpperCase()===USERTYPE.SEEKER ?'/payment-sub':'/payment_pro'}/${user?._id}/subscription_plan`)
  }

  return (
    <div className="pt-0 pt-md-2">
    <div className="">
   <a className="upgarde-card">
     <p className="upgrade-title text-black">
     <img className="diamond-icon" src={premimumStar} alt="premimumStar" height={33} width={33} /> 
       Premium
     </p>
     <p className="upgrade-text">
     Your Active Plan      </p>
     <div className="upgarde-card-boy"></div>
     <button className="upgrade-plan-btn mt-2" onClick={handleTap}>
     {(user?.isPremium && user?.subscriptionDetails?.length >= 1) && user?.subscriptionDetails[0]?.packageName && 
     <>
     {/* {`₹ ${PREMIUM_PASSES[user?.subscriptionDetails[0]?.packageName as PassType]?.amount}`} */}
     <div className="upgrade-plan-btn-text">{`Valid till ${getDateConverted(user?.subscriptionDetails[0]?.expiryAt, dateSeparator.dash, dateReturnFormat.Day_Month)}`}</div>
     </>
     }
     {/* <div className="upgrade-plan-btn-text"> Connect with  <br /> unlimited {user?.role?.toUpperCase()==USERTYPE.SEEKER ?'tutor': 'students'}</div> */}
     </button> 
   </a>
 </div>
    </div>
  )
}

export default CommonUpgradePlanCard