import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { refferalCodeSchema } from '../../../utils/yupSchema/Schema';
import { Maybe } from 'yup';
import { Button, Modal, ModalBody, Form, Row, Col, Spinner } from 'react-bootstrap';
import { useCheckCouponQuery, useRegisterWithReferenceMutation } from '../../../features/Auth/authApi';
import { toast } from 'react-toastify';
import "./referral.css"
import { USERTYPE } from '../../../constants';
import { RiVerifiedBadgeFill } from 'react-icons/ri';
import { useDebounce } from '../../../utils/debouncer';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectCurrentUser } from '../../../features/Auth/authSlice';
import { closeModal } from '../../../features/Auth/ModalHandler/modalSlice';
interface props {
    show: boolean;
    handleClose: () => void;
    data: any;
}
interface formData {
    refferalCode: string
}
const RefferalUserModal = ({ show, handleClose, data }: props) => {
    const location = useLocation()
    const dispatch =  useAppDispatch()
    const navigate = useNavigate()
    const [registerWithRefCode, { isLoading }] = useRegisterWithReferenceMutation()
    const [CouponCode, setCode] = useState<string | null>(null)
    const { user } = useAppSelector(selectCurrentUser)
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch, setError, clearErrors
    } = useForm<formData>({
        resolver: refferalCodeSchema,
    })
    const refferalCode = watch('refferalCode')
    const setCouponCodeDebounced = useDebounce(setCode, 890)
    useEffect(()=>{setCouponCodeDebounced(refferalCode)},[refferalCode])
    const {error: CouponVarificationFail, isLoading: loading, isError, isSuccess} = useCheckCouponQuery({couponCode: CouponCode,userType: data?.userType == USERTYPE.SEEKER ? 'Seeker' : 'Provider'},
        {
            skip: CouponCode === undefined || CouponCode === null ? true : CouponCode.length < 3 || data?.userType === undefined,
            refetchOnMountOrArgChange: true
        }
    )
    useEffect(()=>{
        if(isError) {
            setError("refferalCode", {
                type: "manual",
                message: (CouponVarificationFail as any)?.data?.message,
              });
        }
        else clearErrors('refferalCode')
    },[CouponVarificationFail])
    function handleCloseModal() {
        registerWithRefCode({
            userId: data?.userId,
            couponCode: 'null',
            userType: data?.userType == USERTYPE.SEEKER ? 'Seeker' : 'Provider'
        }).unwrap().then((data: any)=>{
           
        }).catch((error: any)=>{
            toast.error(error.data.message)
        })
        .finally(()=>{
            reset()
            handleClose()
            if(data.userType !== USERTYPE.SEEKER) navigate('/source-dashboard/my-advt')
        })
    }
    function onsubmit(FormData: formData) {
        registerWithRefCode({
            userId: data.userId,
            couponCode: FormData.refferalCode,
            userType: data.userType == USERTYPE.SEEKER ? 'Seeker' : 'Provider'
        }).unwrap()
            .then((res: any) => {
                toast.success(res.message)
                handleClose()
                reset()
            })
            .catch((error: any) => {
                toast.error(error.data.message)
            })
    }
    useEffect(()=>{
        if (!(user?.activeReferral === undefined ) && show) {
            dispatch(closeModal())
        }
    },[user])
    function preventBlankSpaceAtStart(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, value: string, key: keyof formData) {
        if (e.key === " " && value.trim() === "") {
          e.preventDefault();
          setError(key, {
            type: "manual",
            message: "Cannot start with a blank space.",
          });
        } else {
          clearErrors(key); 
        }
  }
    return (
        <Modal
            show={show}
            backdrop={'static'}
            onHide={handleCloseModal}
            centered>
            <Modal.Header closeButton className='pb-0' />
            <ModalBody className='referral-card'>
                <div className='d-flex align-items-center justify-content-center '>
                    <img src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/refer-friend.png" alt="" /></div>
                <h5>Referral Code</h5>

                <Form onSubmit={handleSubmit(onsubmit)} className='p-0 p-mb-2'>
                    <Row>
                        <Form.Label>Enter your referral code</Form.Label>
                        <Col xs={isSuccess ? 11 : 12} md={isSuccess ? 7 : 8} ><Form.Group>
                        <Form.Control {...register('refferalCode')}
                        onKeyDown={(e) => {
                            const input = e.target as HTMLInputElement;
                            const value = input.value;
                            preventBlankSpaceAtStart(e, value, 'refferalCode')
                          }}
                                placeholder='' className='ref-input' />
                            {errors.refferalCode && <p className=' text-danger'>{errors.refferalCode.message}</p>}
                        </Form.Group></Col>
                        {isSuccess && <Col xs={1} className='m-0 p-0 justify-content-center align-content-center align-items-center'>
                        <RiVerifiedBadgeFill size={32} color='green' />
                        </Col>}
                        <Col lg={4} md={4}>
                            <Button disabled={isLoading} type='submit' className='schedule-session-btn ref-btn-2 mt-2 mt-md-0'>{isLoading?<Spinner size='sm'/>:'Submit'}</Button>
                        </Col>
                    </Row>

                </Form>
            </ModalBody>
        </Modal>
    )
}

export default RefferalUserModal