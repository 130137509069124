import React from 'react'
import { Button, Col, Container, Dropdown, Form, Row } from 'react-bootstrap'
import { RxCrossCircled } from 'react-icons/rx'
import MultiRangeSlider from '../../../buttons/MultiRangeSlider'

interface props {
    isSelected: boolean,
    selectedValue: string | null,
    data: any,
    updateRangeValues: any,
    minimum: number,
    maximum: number,
    onApply: any,
    clearFilter: any,
}

const RangeSelector = (prop: props) => {
  function clearFilter () {
    prop.clearFilter(prop.data.key)
    document.getElementById(prop.data.key)?.click()
  }
  return (
    <>
    {prop.isSelected
    ?
    <button className={`dropdown-menu_show ${prop.isSelected?'primary-bg text-light':''}`} onClick={()=>{prop.clearFilter(prop.data.key)}}>
    {prop.selectedValue?.includes(',') ? prop.selectedValue?.replace(',', '-') : prop.selectedValue}
    <RxCrossCircled style={{ marginLeft: "5px" }} size={20} />
    </button>
    :<Dropdown key={prop.data.key} id={prop.data.key}>
        <Dropdown.Toggle
        className='dropdown-menu_show '
        variant="secondary">
        {prop.data.label}
        </Dropdown.Toggle>
        <Dropdown.Menu>
        <Container className='m-2'>
        <Row className='mb-3 ps-1 ms-1'>
        {prop.data.label}
        </Row>
        <Row>
          <MultiRangeSlider 
          min={prop.minimum} 
          max={prop.maximum} 
          onChange={({ min, max }: any) => {prop.updateRangeValues(prop.data.key, min, max)}}/>
        </Row>
        </Container>
        <Row className="p-0 p-md-3 " >
        <Col md={6}>
        <Button
        variant="link"
        onClick={clearFilter}
        className="filter-cancel ms-2">
        Clear
        </Button>
        </Col>
        <Col md={6}>
        <Button
        variant="link"
        onClick={()=>prop.onApply(prop.data.key)}
        className="filter-cancel ms-4">
        Apply
        </Button>
        </Col>
        </Row>
        </Dropdown.Menu>
    </Dropdown>}</>
  )
}

export default RangeSelector