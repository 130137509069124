import React from 'react'
import { Container, Row } from 'react-bootstrap'
import MultiRangeSlider from '../../../buttons/MultiRangeSlider'

interface props {
    data: any,
    updateRangeValues: any,
    minimum: number,
    maximum: number,
    onApply: any,
}

const OffcanvasRangeselector = (prop: props) => {
  return (
    <>
    <Container className='my-2' key={prop.data.key}>
    <Row className='filter-heading'>
    {prop.data.label}
    </Row>
    <Row className='pt-3 pb-2 '>
        <MultiRangeSlider 
          min={prop.minimum} 
          max={prop.maximum} 
          onChange={({ min, max }: any) => {prop.updateRangeValues(prop.data.key, min, max)}}/>
        </Row>
    </Container>
    </>
  )
}

export default OffcanvasRangeselector