import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import error from "../../assets/images/error-404.png";

interface ErrorProps {
  message: string;
}

const ErrorChat = ({ message }: ErrorProps) => {

  const handleReset = () => {
    localStorage.clear(); 
    sessionStorage.clear(); 
    window.location.reload();
  };

  return (
    <Container className="text-center mt-5">
      <Row className="flex-column justify-content-center align-items-center">
        <Col md={6}>
          <Image src={error} alt="Error" fluid />
          <h1 className="mt-3">Oops! Something went wrong.</h1>
          <p>{message}</p>
        </Col>
        <Col md={6}>
          <Button className="send-btn" onClick={handleReset}>Refresh</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorChat;