import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { notificationApi } from "./notificationApi";
import { getCounts } from "../../utils/notificationUtils/notificationHandler";
import { NotificationTokenType } from "../../Types";
import { RootState } from "../../app/store";
interface Request {
  notifications: Object[],
  unseenNotifications: number,
  archived: Object[],
  requests: number,
  data: {
    total: number | string,
    pending: number | string,
    approved: number | string,
  },
  loading: boolean,
  error: boolean,
  notificationToken: NotificationTokenType,
}

const initialState: Request = {
  notifications: [],
  unseenNotifications: 0,
  archived: [],
  requests: 0,
  data: {
    total: '0',
    pending: '0',
    approved: '0',
  },
  loading: false,
  error: false,
  notificationToken: null,
};

const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState,
  reducers: {
    updateNotifications: (state, action) => {
      state.notifications = action.payload.data;
      if (action.payload?.data) {
        const { acceptCount, pendingCount } = getCounts(action.payload?.data[0]?.data?.totalRequests);
        state.data.pending = pendingCount.toString();
        state.data.approved = acceptCount.toString();
        state.data.total = (acceptCount + pendingCount).toString();
      }
    },
    updateArchiveNotifications: (state, action) => {
      state.archived = action.payload.data;
    },
    updateUnseenNotifications: (state, action) => {
      state.unseenNotifications = action.payload.data;
    },
    updateRequestsData: (state, action) => {
      state.requests = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(notificationApi.endpoints.fetchNotificationInstance.matchFulfilled, (state, action: any) => {
        state.loading = false;
        const { acceptCount, pendingCount } = getCounts(action.payload[0]?.data?.totalRequests);
        state.data.pending = pendingCount.toString();
        state.data.approved = acceptCount.toString();
        state.data.total = (acceptCount + pendingCount).toString();
      })
      .addMatcher(notificationApi.endpoints.fetchNotificationInstance.matchPending, (state) => {
        state.loading = true;
      })
      .addMatcher(notificationApi.endpoints.fetchNotificationInstance.matchRejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      .addMatcher(notificationApi.endpoints.fetchToken.matchFulfilled, (state, action) => {
        state.notificationToken = action.payload.data;
      })
      .addMatcher(notificationApi.endpoints.fetchToken.matchRejected, (state, action: PayloadAction<any>) => {
        if (action.payload?.error?.data !== undefined) {
          state.notificationToken = action.payload.error.data;
        }
      });      
  },
});

export default notificationSlice.reducer;
export const { updateNotifications, updateArchiveNotifications, updateUnseenNotifications, updateRequestsData } = notificationSlice.actions;

export const selectRequests = (state: RootState) => state.notificationSlice;
export const allNotifications = (state: RootState) => state.notificationSlice.notifications;
export const unseenNotifications = (state: RootState) => state.notificationSlice.unseenNotifications;
export const archivedNotifications = (state: RootState) => state.notificationSlice.archived;
export const readRequests = (state: RootState) => state.notificationSlice.requests;
export const getRequestsCount = (state: RootState) => state.notificationSlice.data;
export const getCurrentNotificationToken = (state: RootState) => state.notificationSlice.notificationToken
