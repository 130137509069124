import React from 'react'
import "./ErrorPage.css"
import errorpageImg from "../../src/assets/img/errorpage.png.png"
import { useNavigate } from 'react-router-dom'

interface props {
  navigation?: string
  buttonText?: string
  errorMessage?: string
}
const ErrorPage = ({ navigation = '/', buttonText, errorMessage }: props) => {
  const handleNavigation = () => {
    window.location.href = navigation; // Navigate by updating window.location
  };

  return (
    <> <div className="errorpage-wrapper">
      <div className='errorBoxCard  '>
        <h1 className='' >404</h1>
        <img src={errorpageImg} className='img-fluid' title='errorImg' alt="errorImg"  width="200" height="150" />
        <h2  className='text-silver' >Something went wrong !</h2>
       
        <p >{errorMessage || "Page not found"}</p>
        <button className='schedule-session-btn' onClick={handleNavigation}>
            {buttonText ? buttonText : 'Go to Home'}
          </button>
      </div>

    </div>
    </>
  )
}

export default ErrorPage