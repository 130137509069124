// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import {
  Auth,
  GoogleAuthProvider,
  getAuth,
  signInWithCustomToken,
} from "firebase/auth"
import { getDatabase } from "@firebase/database"
import "firebase/messaging"
import { getMessaging, Messaging } from "firebase/messaging"
import { APP_ENV } from "../constants"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD-3t6RMltIa6iyETkkZk1R_aWk8F7ry6A",
  authDomain: "vedichom-chat.firebaseapp.com",
  databaseURL: "https://vedichom-chat-default-rtdb.firebaseio.com",
  projectId: "vedichom-chat",
  storageBucket: "vedichom-chat.appspot.com",
  messagingSenderId: "602450569615",
  appId: "1:602450569615:web:660c0dce681d4fb8a603fa",
  measurementId: "G-90P95JRR6B"
};


// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const db = getFirestore()
export const database = getDatabase(app)
export const auth = getAuth(app)
export const signinChat = async (token: string) => await signInWithCustomToken(auth, token)
let messaging: Messaging | null = null;
if (APP_ENV !== 'development') {
  messaging = getMessaging(app);
}

export { messaging };
