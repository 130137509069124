import React from "react"
import { Modal } from "react-bootstrap"
import "./VideoModal.css"
interface CardProps {
  show: boolean
  handleClose: () => void
  handleVideoChange: (event: any) => void
  handleSkipVideoModal: () => void
}
function VideoModal({
  show,
  handleClose,
  handleVideoChange,
  handleSkipVideoModal,
}: CardProps) {
  return (
    <>
      <Modal show={show} centered size="lg">
        <div className="VideoModal">
          <p
            className="d-flex justify-content-end  fs-5 pe-3 mt-3 mb-4 cursor-pointer skip-btn"
            onClick={handleSkipVideoModal}
          >
            SKIP
          </p>
          <div className="text mx-5 mt-3 bg">
            <h4 className="w-75 mb-3">
              Uploading Video will help you to reach more people
            </h4>
            <p>
              Want to stand out in a sea of profiles? Showcase your precision
              skills through captivating video—it's your ticket to more
              connection requests.. Stop blending in and start standing out..
              Upload a video showcasing your precision skills to attract more
              attention and connection requests than ever before..
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <input
              type="file"
              className="d-none"
              id="video-upload"
              accept="video/mp4,video/mkv, video/x-m4v,video/*"
              onChange={handleVideoChange}
            />
            <label
              htmlFor="video-upload"
              className="schedule-btn2 btn btn-primary my-4"
              onClick={handleClose}
            >
              Upload video
            </label>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default VideoModal
