 export const fadeIn  = (direction: string, delay: any) =>{
    return{
        hidden:{
            y: direction === 'up' ? 40 : direction === 'down' ? -40 : 0,
            x : direction === 'left' ? 40 : direction === 'right' ? -40 : 0,
            opacity: 0,
        },
        show:{
            y:0,
            x: 0,
            opacity:1,
            transition:{
                type: 'tween',
                duration:0.3,
                delay: delay,
                ease:[0.25, 0.25, 0.25, 0.75]
            },

        }
    }
 }
 export const chatAnimation = (delay: number) => {
    return {
      hidden: {
        opacity: 0,
        y: 50,
        x: -50,
      },
      show: {
        opacity: [0.2, 0.5, 1],
        y: [50, 0, 0],
        x: [-50, -50, 0],
        transition: {
          duration: 0.5,
          delay: delay,
          ease: 'easeOut',
        },
      },
    };
 };
 export const bottomToTopAnimation = (delay: number) => {
    return {
      hidden: {
        opacity: 0,
        y: 50,
        x: -50, // Start at -50 on the x-axis
      },
      show: {
        opacity: [0.2, 0.5, 1],
        y: [50, 0, 0],
        x: [-50, -50, -50], // Animate from -50 to 0 on the x-axis
        transition: {
          duration: 0.5,
          delay: delay,
          ease: 'easeOut',
        },
      },
    };
  };
 export const leftToRightAnimation = (delay: number, duration?: number) => {
    return {
      hidden: {
        opacity: 0,
        x: -50,
      },
      show: {
        opacity: [0.2, 0.5, 1],
        x: [-50, 0, 0],
        transition: {
          duration: duration || 0.5,
          delay: delay,
          ease: 'easeOut',
        },
      },
    };
  };

  export const chatInAnimation = (delay: number, duration?: number) => {
    return {
      hidden: {
        opacity: 0,
        y: 50,   // Start at 50 on the y-axis
        x: -50,  // Start at -50 on the x-axis
      },
      show: {
        opacity: [0.2, 0.5, 1],
        y: [50, 0, 0],  // Move vertically from 50 to 0
        x: [-50, 0, 0], // Move horizontally from -50 to 0
        transition: {
          duration: duration || 0.5,
          delay: delay,
          ease: 'easeOut',
        },
      },
    };
  };
  