import React, { useState } from "react"
import { Row, Col, Button, Container } from "react-bootstrap"
import { GoDotFill } from "react-icons/go"
import RenderProfile  from "../../../../utils/RenderProfilePic/RenderProfilePic"
import LockedNotification from "./LockedNotificationCard"
import headerlogo from "../../../../assets/images/logo.png"


interface NotificationCardProps {
  notification: NotificationData
  index: number
  handleOnClick: (index: number, id: string,  category: string, navigationId?: string) => void
  acceptNotification: any
  ignoreNotification: (index: number, id: string) => void
  ifIgnored?: boolean
  isPremium?: boolean
}

interface NotificationData {
  id: string
  data: {
    isSeen: boolean
    senderName: string
    senderProfilePic: string
    title: string
    date: string
    category: string
    navigationId: string
  }
}

const NotificationCard: React.FC<NotificationCardProps> = ({
  notification,
  index,
  handleOnClick,
  acceptNotification,
  ignoreNotification,
  ifIgnored,
  isPremium
}) => {
  return (
    <>
    {(notification.data.category === 'Request Received Non-Premium' && !isPremium) ?
    <><LockedNotification key={index} notification={notification}/></>
  :<Container key={index} className={`notification-card pt-2 px-0 px-md-4  pb-2 cursor-pointer ${
      notification.data.isSeen ? "" : "unseen-bg"
    }`}>
    <Row
      onClick={(e) => {
        handleOnClick(index, notification.id, notification.data?.category, notification.data.navigationId)
      }}
    >
      <Col
        xs={2}
        md={1}
        className="d-flex justify-content-center align-items-start"
        f={{ width: "3rem" }}
      >
        <RenderProfile  senderNameValue={notification?.data?.senderName}
        senderProfilePic={notification?.data?.senderName
        ? notification?.data.senderProfilePic
        : headerlogo} 
                 />
      </Col>
      <Col>
        <Row>
          {notification.data.category == "Payment Success" ? 
                  <Col as="p" className="mb-0 noti-title">{notification.data.category}</Col>
                  :
                  <Col as="p" className="mb-0 noti-title">{notification.data.title}</Col>
                  }
        </Row>
        <div className="d-flex text-black-50 justify-content-start align-items-center gap-2 noti-date">
          <div className="mb-0">{notification?.data?.date}</div>
          {/* <GoDotFill className="dot-img mt-1 d-none d-lg-block" />
          <div className="mb-0">{notification.data.category}</div> */}
        </div>
      </Col>
    </Row>
    <Row>
    {(notification.data.category === "Request Received" || notification.data.category === "Request Received Non-Premium") && (
      <Col sm={'auto'} className="notification-btn-box">
        <Button
          className="notification-btn-sm my-1 text-center mx-3"
          onClick={() => {
            acceptNotification(index, notification.id, notification.data.navigationId)
          }}
        >Accept
        </Button>
        {(!ifIgnored)&&<Button
          className="notification-btn-sm notification-btn-sm--ignore my-1  text-center"
          onClick={() => {
            ignoreNotification(index, notification.id)
          }}
        >Ignore
        </Button>}
      </Col>
    )}
    <Col sm onClick={(e) => { handleOnClick(index, notification.id, notification.data?.category, notification.data.navigationId)}}/>
    </Row>
    </Container>}
  </>
  )
}

export default NotificationCard
