import React, { useEffect, useState } from "react"
import "../Providerdashboard/Dashboardcomponent/DashboardComponent.css"
import premimumStar from "../../../assets/images/premium-1.svg"
import { handlePayment } from "../../ScheduleSession/paymentGatewayfunction"
import { useCreateSeekerRequestMutation, usePaymentCheckoutMutation, usePaymentKeyQuery, usePaymentVerifyMutation } from "../../../features/featSeekerDashboard/seekerDashboardApi"
import { isLaunched, USERTYPE } from "../../../constants"
import { useNavigate } from "react-router-dom"
import { dashProps } from "../../../Types"
import CommonUpgradePlanCard from "../../cards/commonCard/commonUpgradePlanCard/CommonUpgradePlanCard"
import PaymentSuccessfullyModal from "../../modals/PaymentSuccessfully/PaymentSuccessfullyModal"
import lockIcon from "../../../assets/img/lock-icon.png"


const UpgradeCard = ({userType,
  seekerProfileData,
  sourceProfileData,
  isLoading,
  authUser:auth}: dashProps) => {
      const isPremium = userType === USERTYPE.SEEKER ?  seekerProfileData?.data?.isPremium : sourceProfileData?.data?.isPremium
      const discountPercentage = USERTYPE.SEEKER ? undefined : undefined
  const navigate = useNavigate()
  const [isMouseIn, setMouseIn] = useState<boolean>(false)
  function handleClick(event: any): void {
    
  }

  return (
    <>
    <div className={ `premium-card-lock `}
    onMouseEnter={()=>{setMouseIn(true)}}
    onMouseLeave={()=>{setMouseIn(false)}}>
        <div 
          className={`premium-card-overlay d-flex align-items-center justify-content-center ${(!isLaunched && isMouseIn) ? 'premium-card-overlay-blur' : ''}`}
          onClick={handleClick}
        ><div className=" d-flex align-items-center justify-content-center flex-column">
        <img className="premium-card-lock-img" src={lockIcon} alt="" />
        <p>Coming soon</p></div>
        </div>
        {/* {(!isLaunched && isMouseIn) && <img className="premium-card-lock-img" src={lockIcon} alt="" />} */}
   {isPremium ? 
   <CommonUpgradePlanCard/>
   :<>
    <div className="">
      <a className="upgarde-card user-select-none ">
        <p className="upgrade-title text-black">
        <img className="diamond-icon" src={premimumStar} alt="premimumStar" height={33} width={33} /> 
        {userType===USERTYPE.PROVIDER ? 'Upgrade to premium':" Get student pass " }
        </p>
        <p className="upgrade-text">
        {userType === USERTYPE.PROVIDER ? "Unlock exclusive features with an upgrade today!":
        discountPercentage 
        ? (<p className="">{`You have ${discountPercentage}% discount from refferal`}</p>)
        : (<p className="">Unlock student pass features today</p>) }
        
        </p>
        <div className="upgarde-card-boy"></div>
        <button className="upgrade-button mt-2" onClick={()=>{
    
    navigate(`/${userType === USERTYPE.PROVIDER? `payment_pro/${sourceProfileData?.data?._id}` : `payment-sub/${seekerProfileData?.data?._id}`}?planType=Monthly&price=199&period=Month&existing=true`)

             
          }
        }>
        {userType==='PROVIDER' ? "Upgrade Now" : "Get now" }
          
          </button> 
      </a>
     </div>
    </>}
    </div>
    </>
  )
}
export default UpgradeCard
