/*
  REGIS_SUCCESS:"Registration Success",
  EMAIL_VERIFIED:"Email Verified",
  EMAIL_UPDATED:"Email Updated",
  MOBILE_UPDATED:"Mobile Number Updated",
  PASS_RESET:"Password Reset",
  REQUEST_SENT:"Request Sent",
  REQUEST_RECEIVED:"Request Received",
  REQUEST_ACCEPTED:"Request Accepted",
  FAQ_ASKED:"Faq Asked",
  FAQ_REPLIED:"Faq Replied", 
  MOBILE_VERIFIED: "Mobile Verified",*/

import { USERTYPE } from "../../constants";


export const handleRedirect = (path: string, id?: string, userType?: USERTYPE, navId?: string ) => {

    let TYPE: string= " ";
    if(userType){
        if( userType === USERTYPE.SEEKER) TYPE = "seeker-dashboard";
        else TYPE = "source-dashboard";
    }
    const params = new URLSearchParams({
        tabKey: 'tab2',
        id: navId || ''
    });
  
    
    switch (path) {
        case "Registration Success":
            return({path:`/${TYPE}`})
        case "Email Verified":
            return({path:`/${TYPE}/my-account`})
        case "Mobile Verified":
            return({path:`/${TYPE}/my-account`})
        case "Email Updated":
            return({path:`/${TYPE}/my-account`})
        case "Mobile Number Updated":
            return({path:`/${TYPE}/my-account`})
        case "Password Reset":
            return({path:`/${TYPE}/my-account`})
        case "Request Sent":
            return({path:`/${TYPE}/my-requests`})
        case "Request Received":
            return({path:`/${TYPE}/my-requests`})
        case "Request Received Non-Premium":
            return({path:`/${TYPE}/my-requests`})
        case "Request Accepted":
            return({path:`/${TYPE}/my-requests`})
        case "Faq Asked":
            return({path:`/${TYPE}/my-advt?adid=${navId?.split(',')[0]}`})
        case "Faq Replied":
            return({path:`/teacher-profile?advertId=${navId?.split(',')[0]}`})
        case "Payment Success":
            return ({path: `/${TYPE}/my-account`, state:'tab3'})
        case "Review Replied":
            params.set('tabKey', 'tab2')
            return ({path: `/${TYPE}/my-evaluations?${params.toString()}`})
        case "Review Given":
            return ({path: `/${TYPE}/my-evaluations?${params.toString()}`})
        case "Advertisement Approved":
            return({path:`/${TYPE}/my-advt?adid=${navId}`})
        default:
            return({path:`/${TYPE}/my-account`})
    }
}