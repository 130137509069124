import { useEffect, useLayoutEffect, useRef, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
  Spinner,
} from "react-bootstrap"
import "./ScheduleSession.css"
import webcam from "../../../assets/img/cam-icon.svg"
import reviewimage from "../../../assets/img/certified-image2.png"
import starimage from "../../../assets/img/star-active-icon.svg"
import cardimage from "../../../assets/img/card-image.png"
import heartimage from "../../../assets/img/heart.svg"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import playbutton from "../../../assets/img/play-icon.svg"

import { Navigate, useNavigate, useParams } from "react-router-dom"

import leftarrow from "../../../assets/img/left-arrow.svg"
import MeditationSingUp from "../../../assets/img/meditation_sign_up.jpg"
import ScheduleCard from "../scheduleCard/ScheduleCard"
import FormInput from "../../form/FormInput"
import {
  useGetProviderDetailsQuery,
  usePostCreateRquestMutation,
} from "../../../features/Auth/authApi"
import { useAppSelector } from "../../../app/hooks"
import {
  setMessageNotificationClick,
  setScheduleRequestPayload,
  setSeekerRequest,
  setSeekerScheduleSessionData,
} from "../../../features/featSeekerDashboard/seekerDashboardSlice"
import { useAppDispatch } from "../../../app/store"
import { MODALNAME, SESSION_PREFERENCE, USERTYPE } from "../../../constants"
import { setModalName } from "../../../features/Auth/ModalHandler/modalSlice"
import {
  useCreateSeekerRequestMutation,
  useGetseekerProfileQuery,
} from "../../../features/featSeekerDashboard/seekerDashboardApi"
import { useProviderAccountDetailsQuery } from "../../../features/featProviderDashboard/FeatProviderApi"
import LoginSeeker from "../../modals/AuthComponents/loginModal/LoginSeeker"
import { toast } from "react-toastify"
import { checkConditions } from "../../../utils/ifObjectExists/ifObjectExists"
import SpinnerSecondary from "../../spinner/SpinnerSecondary"
import ErrorPage from "../../ErrorPage"

type Props = { userType?: USERTYPE }

interface UserDetails {
  fullname: any
  email: string
  phone: string
  address: string
  myself: string
  date: string
  sessionFormat: string
  sessionPreference: string
  requestMessage: string
}
const initFocousState = {
  sessionMessage: false
}
const initialErrorState = {
  sessionForWhom: "",
  date: "",
  sessionPreference: "",
  sessionMessage: "",
  nameError: "",
  emailError: "",
  phoneError: ""
}
// interface props { }
const DEFAULT_REQUEST_MESSAGE = "I am writing to express my sincere interest in enrolling in your course for the upcoming term. Having thoroughly researched your course structure and learning objectives, I am confident that your expertise aligns perfectly with my academic and professional aspirations."
const ScheduleSession = ({ userType }: Props) => {
  const { id } = useParams()

  const userData = useAppSelector((state) => state.auth)
  const { seekerToken, providerToken } = useAppSelector(
    (state: any) => state.auth,
  )
  const emailField = useRef<HTMLInputElement>(null)
  const nameField = useRef<HTMLInputElement>(null)
  const phoneField = useRef<HTMLInputElement>(null)
  const addressField = useRef<HTMLInputElement>(null)
  const messageField = useRef<HTMLTextAreaElement>(null)

  const token = useAppSelector((state) => state.auth.seekerToken)

  const { data: userProfileData, isLoading: getLoading } = seekerToken
    ? useGetseekerProfileQuery()
    : useProviderAccountDetailsQuery(null, {skip:!!token})

   
  const loginUserData = useAppSelector((state) => state.seekerDashboardSlice)
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [dateformat, setDateFormat] = useState<string>("soon")
  const  { isError, error, data, isFetching } = useGetProviderDetailsQuery(id)
  const [isHover, _isHover] = useState<boolean>(false)
  const [isAllowed, _isAllowed] = useState<boolean>(false)
  const [wordCount, setWordCount] = useState<number>(0)
  const [visible, setVisible] = useState(false);
  const [focousedInput, _focousedInput] = useState({
    sessionMessage: false
  })
  const [errors, setErrors] = useState(initialErrorState)
  const handleScroll = () => {
    if (window.scrollY > 550) { // Adjust scroll threshold as needed
      setVisible(false);
    } else {
      setVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [selectSession, setSelectSession] = useState({
    virtual: false,
    realTime: false,
    mySelf: true,
    someOneElse: false,
    soon: dateformat === "soon" ? true : false,
    any: false,
    group: false,
    private: false,
  })
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [request, { data: reqData, isSuccess, error: reqError }] =
    useCreateSeekerRequestMutation()

  const [fields, setFields] = useState<UserDetails>({
    fullname: selectSession.mySelf ? userData?.user?.firstName : "",
    email: "",
    phone: "",
    address: "",
    myself: "",
    date: "",
    sessionFormat: "",
    sessionPreference: "",
    requestMessage: "",
  })

  useEffect(()=>{
    if(data)setSelectSession((prevSession) => ({
      ...prevSession,
      virtual: !checkConditions(data?.data?.adDetail?.prices.realTime, 'group') && ! checkConditions(data?.data?.adDetail?.prices.realTime, 'private'),
      realTime: !checkConditions(data?.data?.adDetail?.prices.virtual, 'group') && !checkConditions(data?.data?.adDetail?.prices.virtual, 'private'),
      private: (checkConditions(data?.data?.adDetail?.prices.realTime, 'private') || checkConditions(data?.data?.adDetail?.prices.virtual, 'private'))&&(!checkConditions(data?.data?.adDetail?.prices.virtual, 'group')&&!checkConditions(data?.data?.adDetail?.prices.realTime, 'group')),
      group: (checkConditions(data?.data?.adDetail?.prices.realTime, 'group') || checkConditions(data?.data?.adDetail?.prices.virtual, 'group'))&&(!checkConditions(data?.data?.adDetail?.prices.virtual, 'private')&&!checkConditions(data?.data?.adDetail?.prices.realTime, 'private'))
    }))
  },[data])

  useEffect(()=>{
    window.scrollTo(0,0); 
    setFields({...fields, requestMessage: DEFAULT_REQUEST_MESSAGE})
    const words = (DEFAULT_REQUEST_MESSAGE as string).split(" ")?.length
    setWordCount(words)
  },[])
  //6 months limit
  const next1Month = new Date()
  next1Month.setMonth(next1Month.getMonth() + 1)

  useEffect(() => {
    function checkForSomeoneElse () {
      if(errors.sessionForWhom !== '' || errors.date !== '' || errors.sessionPreference !== '' ||
        errors.sessionMessage !== '' || errors.nameError !== '' || errors.emailError !== '' || errors.phoneError !== ''
      ) return false
      else return true
    }
    _isAllowed(((selectSession.any) ||
    (selectSession.realTime &&
      (selectSession.private || selectSession.group))
      ||
     (selectSession.virtual &&  
       (selectSession.private || selectSession.group)
     )) && (wordCount>10 && fields.requestMessage?.length<=480) && (selectSession.someOneElse ? checkForSomeoneElse() : true))
    //  setErrors({...errors,sessionMessage})
  }, [selectSession, wordCount, errors])

  useEffect(()=>{
    if (selectSession?.someOneElse) {
      setFields((prev) => ({
        ...prev,
        fullname: "",
        email: "",
        phone: "",
      }))
      if(nameField?.current) {
        nameField.current.focus()
        // nameField.current.scrollIntoView()
      }
      setErrors((prev)=>{
        return({
          ...prev,
          nameError: "Let's start filling the form ",
          emailError: ' ',
          phoneError: ' '
        })
      })
    }
  },[selectSession.someOneElse])

  useEffect(()=>{
    if (selectSession?.mySelf === true) {
      setFields((prev) => ({
        ...prev,
        fullname:
        userData?.user?.firstName +
          " " +
          userData?.user?.lastName,
        email: userData?.user?.email,
      }))
      setErrors((prev)=>({
        ...prev,
        emailError: '',
        nameError: '',
        phoneError: '',
      }))
    }
  },[selectSession?.mySelf])


  // handlechnage for inputs

  const handleChange = (e: any) => {
    const { name, value } = e.target
    setFields((pre) => ({ ...pre, [name]: value }))
    if (name === "requestMessage") {
      const words = (value as string).split(" ")?.length
      setWordCount(words)
    }
    setIsValidEmail(true)
  }

  // submit all with api
  const onSubmit = async () => {
    let newErrors = selectSession?.mySelf ? initialErrorState : {
      ...initialErrorState, errors
    }
    if (
      newErrors.sessionForWhom ||
      newErrors.date ||
      newErrors.sessionPreference ||
      newErrors.sessionMessage ||
      newErrors.nameError ||
      newErrors.emailError
    ) {
      setErrors(newErrors)
    } else {
      const payload = {
        adId: id,
        sessionPreference: (() => {
          if (selectSession.any === true) {
            return "Any"
          } else if (selectSession.virtual === true) {
            return "Virtual"
          } else if (selectSession.realTime === true) {
            return "Real Time"
          } else {
            return "Any"
          }
        })(),
        date: dateformat,
        sessionForWhom: (() => {
          if (selectSession.mySelf === true) {
            return "Myself"
          } else if (selectSession.someOneElse === true) {
            return "Someone Else"
          } else {
            return ""
          }
        })(),
        requestMessage: fields?.requestMessage,
      }
      if (userProfileData?.data?.isPremium === false) {
        dispatch(setScheduleRequestPayload(payload as any))
        navigate(`/payment-sub/${id}?planType=Monthly&price=199&period=Month`)
      } else if (userProfileData?.data?.isPremium === true) {
        const response = await request(payload as any)
        try {
          if ((response as any)?.data?.data) {
            dispatch(
              setSeekerRequest({ data: (response as any)?.data?.data } as any),
            )
            dispatch(setMessageNotificationClick((response as any)?.data))
            navigate(`/seeker-dashboard/my-requests?requestStatus=Accept&requestId=${(response as any)?.data?.data._id}`, {
              state: {
                id: (response as any)?.data?.data?._id,
                status: "Accept",
              },
            })
          }
        } catch (error) {
          console.error(error)
        }
      }
      dispatch(setSeekerRequest(payload as any))
      setIsValidEmail(true)
    }
  }
  useLayoutEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault(); // Prevent default behavior (page reload)
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  
  function focouseStateHandler (e: any,key: string) {
    _focousedInput({...initFocousState, [key]: e?.type=='focus'})
  }
  function nameValidator(value: string): string {
    if(value?.length>3) return ''
    else return 'Please enter the Full name'
  }

  function handleEmailError(value: string): string {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(value)) {
      return '';
    } else {
      return 'Please enter a valid email address';
    }
  }

  function handlePhoneNumberError(value: string): string | null {
    const basicPhoneNumberRegex = /^[\d-]{10,15}$/; 
    const internationalPhoneNumberRegex = /^[+][\d-]{11,16}$/; 
  
    if (value.startsWith('+')) {
      if (value.length < 11) {
        return 'Please enter at least 11 characters for an international number';
      } else if (value.length > 16) {
        return 'Please enter no more than 16 characters for an international number';
      } else if (!internationalPhoneNumberRegex.test(value)) {
        return 'Please enter a valid international number';
      }
    } else {
      if (value.length < 10) {
        return 'Please enter at least 10 characters';
      } else if (value.length > 15) {
        return 'Please enter no more than 15 characters';
      } else if (!basicPhoneNumberRegex.test(value)) {
        return 'Please enter a valid phone number';
      }
    }
  
    return ''; 
  }  

  return (
    <>
      {token === null ? (
        <Navigate to={`/teacher-profile?advertId=${id}`} replace/>
      ) : (
        <section className="explore-section section-padding py-5">
          {isFetching?<><SpinnerSecondary/></>:
          data ?
          <Container fluid="lg">
            <div className="inner-explore">
              <Row>
                <Col xl={4} lg={5} className="mb-lg-0 mb-3 px-lg-3 px-0">
                  <ScheduleCard
                    seekerData={data?.data}
                    types={"seeker"}
                    key={""}
                    data={undefined}
                    classType = {{realTime: selectSession.realTime, 
                      virtual: selectSession.virtual, 
                      any: selectSession.any,
                      group: selectSession.group, private: selectSession.private}}
                  />
                </Col>
                <Col xl={8} lg={7}>
                  <div className="schedule-section ps-0 ps-sm-4">
                    <h2 className="sch-text mb-2">Schedule</h2>
                    <h4 className="mb-4 session-text fw-normal">
                      Your Session with{" "}
                      <span className="fw-semibold">
                        {data?.data?.adDetail?.providerId?.firstName}
                      </span>
                    </h4>

                    <div className="mb-3">
                      <h3 className="font-16 fw-semibold">
                        Pick your session preference <span className=" text-danger ">*</span>
                      </h3>
                      <div>
                        <div className="d-inline-block">
                          <input
                            onChange={() => {
                              if(!(data?.data?.adDetail.prices.virtual.private || 
                              data?.data?.adDetail.prices.virtual.group)) {return}
                              setSelectSession((prevSelectSession) => ({
                                ...prevSelectSession,
                                virtual: prevSelectSession.virtual ? prevSelectSession.virtual : !prevSelectSession.virtual,
                                realTime: false,
                                any: false,
                                group: !checkConditions(data?.data?.adDetail?.prices.virtual, 'private'),
                                private: !checkConditions(data?.data?.adDetail?.prices.virtual, 'group'),
                              }))
       
                            }}
                            type="checkbox"
                            name="selectSession"
                            className={`radio-btn`}
                            id="webcam-radio"
                            disabled={!(
                              checkConditions(data?.data?.adDetail?.prices.virtual, 'private')
                              ||
                              checkConditions(data?.data?.adDetail?.prices.virtual, 'group'))}
                            checked={selectSession.virtual}
                          />
                          <label
                            htmlFor="webcam-radio"
                            className={`label-radio `}
                          >
                            Virtual
                          </label>
                        </div>
                        <div className="d-inline-block">
                          <input
                            onChange={() => {
                              if(!( checkConditions(data?.data?.adDetail?.prices.realTime, 'private')
                                ||
                              checkConditions(data?.data?.adDetail?.prices.realTime, 'group'))) {return}
                              setSelectSession((prevSelectSession) => ({
                                ...prevSelectSession,
                                realTime: prevSelectSession.realTime ? prevSelectSession.realTime : !prevSelectSession.realTime,
                                virtual: false,
                                any: false,
                                group: !checkConditions(data?.data?.adDetail?.prices.realTime, 'private'),
                                private: !checkConditions(data?.data?.adDetail?.prices.realTime, 'group'),
                              }))

                            }}
                            type="checkbox"
                            name="selectSession"
                            className={`radio-btn`}
                            id="realtime-radio"
                            disabled={!(
                              checkConditions(data?.data?.adDetail?.prices.realTime, 'private')
                              ||
                              checkConditions(data?.data?.adDetail?.prices.realTime, 'group')
                              )}
                            checked={selectSession.realTime}
                          />
                          <label
                            htmlFor="realtime-radio"
                            className={`label-radio `}
                          >
                            Real Time
                          </label>
                        </div>
                        <div className="d-inline-block">
                          <input
                            onChange={() => {
                              if(!(
                                (checkConditions(data?.data?.adDetail?.prices.virtual, 'private')
                                ||checkConditions(data?.data?.adDetail?.prices.virtual, 'group'))
                                &&(checkConditions(data?.data?.adDetail?.prices.realTime, 'private')
                                ||checkConditions(data?.data?.adDetail?.prices.realTime, 'group'))
                              ))
                                {return}
                              setSelectSession((prevSelectSession) => ({
                                ...prevSelectSession,
                                any: prevSelectSession.any ? prevSelectSession.any : !prevSelectSession.any,
                                realTime: false,
                                virtual: false,
                                group: true,
                                private: true,
                              }))

                            }}
                            type="checkbox"
                            name="selectSession"
                            className={`radio-btn`}
                            id="any-radio"
                            disabled={!(
                              (checkConditions(data?.data?.adDetail?.prices.virtual, 'private')
                              || checkConditions(data?.data?.adDetail?.prices.virtual, 'group'))
                              && (checkConditions(data?.data?.adDetail?.prices.realTime, 'private')
                              || checkConditions(data?.data?.adDetail?.prices.realTime, 'group'))
                            )}
                            checked={selectSession.any}
                          />
                          <label htmlFor="any-radio" className={`label-radio `}>
                            Any
                          </label>
                        </div>
                      </div>
                      <div style={{ color: "red" }}>
                        {errors.sessionPreference && (
                          <p>{errors.sessionPreference}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <h3 className="font-16 fw-semibold"> Select your preferred mode of class
                      {!selectSession.any&&<span className=" text-danger "> *</span>}</h3>
                        <div className="d-inline-block me-2">
                          <div className="d-inline-block">
                          <input
                            onChange={() => {
                              setSelectSession((prevSelectSession) => ({
                                ...prevSelectSession,
                                group: false,
                                private: prevSelectSession.private ? prevSelectSession.private : !prevSelectSession.private,
                              }))

                            }}
                            type="checkbox"
                            name="selectSession"
                            className={`radio-btn`}
                            id="private-radio"
                            disabled={(selectSession.any) || !(
                              selectSession.virtual &&
                              checkConditions(data?.data?.adDetail?.prices.virtual, 'private')
                              || 
                              selectSession.realTime &&
                              checkConditions(data?.data?.adDetail?.prices.realTime, 'private')
                            )}
                            checked={selectSession.private || selectSession.any}
                          />
                          <label
                            htmlFor="private-radio"
                            className={`label-radio ${selectSession.any&&'selected-disable'}`}
                          >
                            Private
                          </label>
                        </div>
                        <div className="d-inline-block">
                          <input
                            onChange={() => {
                              
                              setSelectSession((prevSelectSession) => ({
                                ...prevSelectSession,
                                group: prevSelectSession.group ? prevSelectSession.group : !prevSelectSession.group,
                                private: false,
                              }))

                            }}
                            type="checkbox"
                            name="selectSession"
                            className={`radio-btn`}
                            id="Group-radio"
                            disabled={(selectSession.any) || !(
                              selectSession.virtual &&
                              checkConditions(data?.data?.adDetail?.prices.virtual, 'group')
                              || 
                              selectSession.realTime &&
                              checkConditions(data?.data?.adDetail?.prices.realTime, 'group')
                            )}
                            checked={selectSession.group || selectSession.any}
                          />
                          <label
                            htmlFor="Group-radio"
                            className={`label-radio ${selectSession.any&&'selected-disable'}`}
                          >
                            Group
                          </label>
                        </div>
                        </div>
                    </div>
                    <div className="mb-3">
                      <h3 className="font-16 fw-semibold">Date</h3>
                      <div className="d-flex">
                        <div className="d-inline-block me-2 ">
                          <input
                            type="date"
                            name="date"
                            className="date-field form-control"
                            id="schedule-date"
                            onChange={(e) => {
                              setDateFormat(e.target.value)
                              setSelectSession((prev) => ({
                                ...prev,
                                soon: false,
                              }))
                              setErrors((pre) => ({
                                ...pre,
                                date: "",
                              }))
                            }}
                            min={new Date().toISOString().split("T")[0]}
                            max={next1Month.toISOString().split("T")[0]}
                          />
                        </div>
                        <div className="d-inline-block">
                          <input
                            type="radio"
                            name="date"
                            className="radio-btn"
                            id="soon-radio"
                            checked={selectSession.soon}
                            onChange={() => {
                              setSelectSession((prev) => ({
                                ...prev,
                                soon: true,
                              }))
                              setDateFormat("soon")
                              const dateInput =
                                document.getElementById("schedule-date")
                              if (dateInput) {
                                ;(dateInput as any).value = ""
                              }
                            }}
                          />
                          <label htmlFor="soon-radio" className="label-radio">
                            Soon
                          </label>
                        </div>
                      </div>
                      <div style={{ color: "red" }}>
                        {errors.date && <p>{errors.date}</p>}
                      </div>
                    </div>
                    <div className="mb-3">
                      <h3 className="font-16 fw-semibold">
                        Who is this Session for
                      </h3>
                      <div>
                        <div className="d-inline-block">
                          <input
                            type="radio"
                            name="session-for"
                            className="radio-btn"
                            id="self-radio"
                            checked={selectSession.mySelf}
                            onChange={() => {
                              setSelectSession({
                                ...selectSession,
                                mySelf: !selectSession.mySelf,
                                someOneElse: false,
                              })

                              setErrors((pre) => ({
                                ...pre,
                                sessionForWhom: "",
                              }))
                            }}
                          />

                          <label htmlFor="self-radio" className="label-radio">
                            My Self
                          </label>
                        </div>
                        <div className="d-inline-block">
                          <input
                            type="radio"
                            name="sessionfor"
                            className="radio-btn"
                            id="someone-radio"
                            checked={selectSession.someOneElse}
                            onChange={(e) => {
                              setSelectSession({
                                ...selectSession,
                                someOneElse: !selectSession.someOneElse,
                                mySelf: false,
                              })
                              
                              if(e.target.checked) setErrors((pre) => ({
                                ...pre,
                                sessionForWhom: "",
                              }))
                            }}
                          />
                          <label
                            htmlFor="someone-radio"
                            className="label-radio"
                          >
                            Someone else
                          </label>
                        </div>
                      </div>
                      <div style={{ color: "red" }}>
                        {errors.sessionForWhom && (
                          <p>{errors.sessionForWhom}</p>
                        )}
                      </div>
                    </div>
                    <div className="mb-2">
                      <h3 className="font-16 fw-semibold">Contact Info{selectSession.someOneElse && <span className="text-danger"> *</span>}</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <input
                              type="text"
                              name="fullname"
                              placeholder="Full Name"
                              className="schedule-field shadow-none form-control"
                              ref={nameField}
                              onKeyDown={(e)=>{
                                if(e.key === 'Enter' && fields.fullname.length > 0 && errors.nameError === '') emailField.current?.focus()
                              }}
                              onChange={(e)=>{
                                handleChange(e)
                                setErrors((prev: any)=>({
                                  ...prev,
                                  nameError: nameValidator(e.target.value)
                                }))
                              }}
                              value={
                                selectSession.mySelf
                                  ? userData?.user?.firstName
                                  : fields.fullname
                              }
                              disabled={selectSession.mySelf}
                            />
                            {errors.nameError && <p className="text-danger">{errors.nameError}</p>}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <input
                              type="email"
                              placeholder="Email"
                              className="schedule-field shadow-none form-control"
                              name="email"
                              ref={emailField}
                              onKeyDown={(e)=>{
                                if(e.key === 'Enter' && fields.email.length > 0 && errors.emailError === '') phoneField.current?.focus()
                              }}
                              onChange={(e)=>{
                                handleChange(e)
                                setErrors((prev: any)=>({
                                  ...prev,
                                  emailError: handleEmailError(e.target.value)
                                }))
                              }}
                              value={
                                selectSession.mySelf
                                  ? userData?.user?.email
                                  : fields.email
                              }
                              disabled={selectSession.mySelf}
                            />
                          </div>
                          {!isValidEmail && (
                            <p
                              className="text-danger"
                            >
                              Invalid email address
                            </p>
                          )}
                          {errors.emailError && <p className="text-danger">{errors.emailError}</p>}
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <input
                              type="number"
                              placeholder="Phone Number"
                              className="schedule-field shadow-none form-control"
                              name="phone"
                              ref={phoneField}
                              onKeyDown={(e)=>{
                                if(e.key === 'Enter' && fields.phone.length > 0 && errors.phoneError === '') addressField.current?.focus()
                              }}
                              onChange={(e)=>{
                                handleChange(e)
                                setErrors((prev: any)=>({
                                  ...prev,
                                  phoneError: handlePhoneNumberError(e.target.value)
                                }))
                              }}
                              value={
                                selectSession.mySelf
                                  ? userData?.user?.mobileNo
                                  : fields.phone
                              }
                              disabled={selectSession.mySelf}
                            />
                            {errors.phoneError&&<p className="text-danger">{errors.phoneError}</p>}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <input
                              type="text"
                              placeholder="Address"
                              className="schedule-field shadow-none form-control"
                              name="address"
                              onChange={handleChange}
                              onKeyDown={(e)=>{
                                if(e.key === 'Enter') messageField.current?.focus()
                              }}
                              ref={addressField}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <h3 className="font-16 fw-semibold">
                        Tell {data?.data?.adDetail.providerId.firstName} what
                        you want to consult{" "}
                        <span className="optional-text color-primary">*</span>
                      </h3>
                      <div className="row">
                        {(wordCount >=1 && wordCount<=10 && focousedInput.sessionMessage)&&(<p> Describe with more than 10 words</p>)}
                        <div className="col-md-12">
                          <textarea
                            className="schedule-textarea shadow-none form-control"
                            name="requestMessage"
                            value={fields?.requestMessage}
                            placeholder=" I am writing to express my ..."
                            onChange={handleChange}
                            ref={messageField}
                            maxLength={500}
                            onFocus={(e) => {
                              focouseStateHandler(e, 'sessionMessage')
                            }}
                            onBlur={(e) => {
                              focouseStateHandler(e, 'sessionMessage')
                            }}
                          />
                        </div>
                        {(fields?.requestMessage?.length >= 500 && focousedInput.sessionMessage ) && (
                          <p className=" text-danger ">{"You have reached the maximum character limit for this field."}</p>
                        )}
                      </div>
                      <p className="letter-count-info ">{fields?.requestMessage?.length}/500</p>
                    </div>
                    {!userData?.seekerToken ? (
                      <button
                      disabled={!isAllowed}
                        className="schedule-btn"
                        onClick={() => dispatch(setModalName(MODALNAME.LOGIN))}
                      >
                        Next
                      </button>
                    ) : (
                      <>
                      <button 
                      onMouseLeave={()=>{_isHover(false)}}
                      onMouseEnter={()=>{_isHover(true)}}
                      className={`next-btn ${isAllowed ?'': 'next-btn--disabled'}`}
                      onClick={(e)=>{
                        
                        if(isAllowed){onSubmit()}
                        else{
                          toast.dismiss()
                          toast.error('Please fill all the required fields first ')
                        }
                      }}>
                        Next
                      </button>
                      {data?.data?.ifRequestAlreadyExist?.requestSent && <div className="text-center cursor-pointer my-2" onClick={()=>{navigate('/seeker-dashboard/my-requests')}}>Request already sent<span className="primary-text"> Click here</span> to check it out</div>}
                      </>
                    )}
                    {(isHover && !isAllowed) && <div className=" d-inline-flex w-100 justify-content-center align-items-center ">
                      <p className=" small-font-size text-danger ">Please fill all the requiered fields </p></div>}
                  </div>
                  {
                    <div className="error d-flex justify-content-center ">
                      {" "}
                      <h6>{(reqError as any)?.data?.message} </h6>
                    </div>
                        }
                        <div
                    className={`schedule-btn-mobile d-lg-none ${
                      visible ? "visible" : ""
                    }`}
                  >
                                        <>
                      <button 
                      onMouseLeave={()=>{_isHover(false)}}
                      onMouseEnter={()=>{_isHover(true)}}
                      disabled={!isAllowed}
                      className={`next-btn ${isAllowed ? '': 'next-btn--disabled'}`}
                      onClick={(e)=>{
                        
                        if(isAllowed){onSubmit()}
                        else{
                          toast.dismiss()
                          toast.error('Please fill all the required fields first ')
                        }
                      }}>
                        Next
                      </button>
                      </>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
          : error && <>
          <ErrorPage buttonText='Go Back'/>
          </>}
        </section>
      )}
    </>
  )
}

export default ScheduleSession
