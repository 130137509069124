import React, { useEffect } from 'react'
import { Card , Button} from 'react-bootstrap'
import styles from "./ChatUi.module.css"
import refimg from "../../../src/assets/img/ref-imgicon.png"
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppSelector } from '../../app/hooks'
import { selectCurrentUser } from '../../features/Auth/authSlice'
import { useAcceptDeleteRequestMutation } from '../../features/featProviderDashboard/FeatProviderApi'
import { toast } from 'react-toastify'

const ChatApprovedCard = ({selectedChatInfo}: any) => {
  const {providerToken, seekerToken} = useAppSelector(selectCurrentUser)
  const [acceptRequest, { data: reqData, isSuccess: success, isError }] = useAcceptDeleteRequestMutation()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  function navigateBack () {
    searchParams.delete('requestId')
    setSearchParams(searchParams)
    navigate(location.pathname + location.search)
    // navigate(location.pathname, {replace: true})
  }
  function navigateAfterAccept () {
    searchParams.set('requestStatus','Accept')
    setSearchParams(searchParams)
  }
  useEffect(()=>{
    if(success) {
      toast.success('Request accepted')
      navigateAfterAccept()
    }
    else if(isError) toast.error('Failed to process your request')
  },[success, isError])
  
  return (
    <>
    <Card  
    className={`${styles["chat-aprd-card"]} my-2`}>

        <Card.Body className="d-flex gap-5 p-0">
        <div className='my-4'> 
          <div className='mb-2'>{`${providerToken
    ? selectedChatInfo.seekerName
    : selectedChatInfo.providerName} would like to take class with you`}</div>
          <div className='d-flex gap-3 mt-3'>
              <Button onClick={() => {
                acceptRequest({
                  requestId: selectedChatInfo?.requestId,
                  action: "Accept",
                })
              }} className={`${styles["chat-aprd-btn"]}`}>Approve</Button>
            <Button onClick={navigateBack} className={`${styles["chat-rjct-btn"]}`}>Ignore</Button>
            </div>
        </div>
        <div className={`${styles["chat-aprd-right-box"]}`}>
            <img src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/editor%20%282%29%201.png" alt="" className={`${styles["chat-aprd-img"]}`}/>
        </div>
          
        </Card.Body>
      </Card></>
    
  )
}

export default ChatApprovedCard