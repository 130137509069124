import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { passwordRegex } from "../regexPattern/Pattern"

const phoneRegExp = /^\+[1-9]\d{0,12}$/;
const skypeRegex = /^[a-zA-Z][a-zA-Z0-9\.,\-_]{5,31}$/;
const liveCidEmailRegex = /^live:\.cid\.[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const liveCidIdRegex = /^live:\.cid\.[a-zA-Z0-9]+$/;

// generalInfoSchema in my Account section
export const generalInfoSchema = yupResolver(
  yup.object().shape({
    fname: yup
    .string()
    .required("Please enter first name")
    .min(3, "First Name must be at least 3 characters long")
    .max(26, "First Name cannot exceed 26 characters")
    .test(
      "no-consecutive-spaces",
      "First Name cannot contain two consecutive spaces",
      (value) => !/\s{2}/.test(value),
    )
    .test(
      "no-leading-space",
      "First Name cannot start with a space",
      (value) => !/^ /.test(value),
    ),

    // .test(
    //   "no-consecutive-spaces",
    //   "First Name cannot contain two consecutive spaces",
    //   (value) => !/\s{2}/.test(value),
    // )
    // .test(
    //   "no-leading-space",
    //   "First Name cannot start with a space",
    //   (value) => !/^ /.test(value),
    // )
    // .test(
    //   "no-only-spaces",
    //   "First Name cannot contain only spaces",
    //   (value) => !/^\s*$/.test(value),
    // ),

    lname: yup
      .string()
      .required("Please enter last name")
      .min(3, "Last name must be at least 3 characters long")
      .max(26, "Last name cannot exceed 26 characters")
      .test(
        "no-consecutive-spaces",
        "Last Name cannot contain two consecutive spaces",
        (value) => !/\s{2}/.test(value),)
      .test(
        "no-leading-space",
        "Last Name cannot start with a space",
        (value) => !/^ /.test(value),),

    gender: yup.string(),
    landline: yup
      .string()
      .notRequired() // Make the field optional
      .test(
        "is-not-empty",
        "Landline number must be at least 6 digits long",
        function (value) {
          // If the value is not empty, check its length
          if (value && value.trim().length > 0) {
            return value.trim().length >= 6
          }
          // If the value is empty, skip validation
          return true
        },
      )
      .max(12, "Landline number cannot exceed 12 characters"),
      skype: yup
      .string()
      .notRequired()
      .test(
        "skype-id",
        "Skype ID must be between 6 and 32 characters and can contain letters, numbers, and certain symbols. It can also start with 'live:.cid.' followed by an email address or an alphanumeric identifier.",
        (value) => {
          if (!value) return true; // Allow empty values
          return skypeRegex.test(value) || liveCidEmailRegex.test(value) || liveCidIdRegex.test(value);
        }
      ),
    telegram: yup
      .string()
      .notRequired()
      .test(
        "telegram-id",
        "Telegram ID must be between 5 and 32 characters and can contain letters, numbers, and underscores. It can also start with 'https://t.me/'.",
        (value) => {
          if (!value) return true; // Allow empty values
          const regex = /^[a-zA-Z0-9_]{5,32}$/;
          const telegramUrlRegex = /^https:\/\/t\.me\/[a-zA-Z0-9_]{5,32}$/;
          return regex.test(value) || telegramUrlRegex.test(value);
        }
      ),
    dob: yup.string(),
  }),
)
export const contactInfoPhoneSchema = yupResolver(
  yup.object().shape({
    phone: yup
      .string()
      .nullable()
      .notRequired()
      .test(
        "length-check",
        "Phone number must be between 6 and 12 digits long",
        (value) => !value || (value.length >= 6 && value.length <= 12)),
    countryCode: yup.string()
      .nullable()  
      .notRequired()
      .test(
        "required-if-phone",
        "Please select your country code",
        function (value) {
          const { phone } = this.parent;
          return !phone || !!value;  
        }
      ),
  })
);
export const contactInfoEmailSchema = yupResolver(
  yup.object().shape({
    email: yup.string().trim().email("Invalid Email"),
  })
);
//   customerDashboardMyAccountSchema
export const customerDashboardMyAccountSchema = yupResolver(
  yup.object().shape({
    postalAddress: yup.string().required("Please Enter your Postal Address"),
  }),
)
//   providerDashboardMyAccountSchema
export const providerDashboardMyAccountSchema = yupResolver(
  yup.object().shape({
    postalAddress: yup.string().required("please enter your postal address"),
  }),
)
//   Write Review Customer Dashboard
export const customerDashboardWriteReviewSchema = yupResolver(
  yup.object().shape({
    review: yup.string().required("Please enter a review to deliver")
    .max(300, "Review cannot exceed 300 characters"),
  }),
)
//   providerDashboardReplyReviewSchema
export const providerDashboardReplyReviewSchema = yupResolver(
  yup.object().shape({
    replymsg: yup.string().required("please enter a message to deliver"),
  }),
)
//   providerLoginSchema
export const providerLoginSchema = yupResolver(
  yup.object().shape({
    email: yup.string().email().required("Please enter an email"),
    password: yup.string().required("Please Enter your password"),
  }),
)
//   providerSignupSchema
export const providerSignupPhoneSchema = yupResolver(
  yup.object().shape({
    firstName: yup
      .string()
      .required("Please enter first name")
      .min(3, "First Name must be at least 3 characters long")
      .max(26, "First Name cannot exceed 26 characters")
      .test(
        "no-consecutive-spaces",
        "First Name cannot contain two consecutive spaces",
        (value) => !/\s{2}/.test(value),
      )
      .test(
        "no-leading-space",
        "First Name cannot start with a space",
        (value) => !/^ /.test(value),
      ),
    lastName: yup
      .string()
      .required("Please enter last name")
      .min(3, "Last Name must be at least 3 characters long")
      .max(26, "Last Name cannot exceed 26 characters")
      .test(
        "no-consecutive-spaces",
        "Last Name cannot contain two consecutive spaces",
        (value) => !/\s{2}/.test(value),)
      .test(
        "no-leading-space",
        "Last Name cannot start with a space",
        (value) => !/^ /.test(value),),
    mobileNo: yup
      .string()
      .required("Please enter the Phone number")
      .min(6, "Phone Number must be at least 6 characters long")
      .max(16, "Phone Number cannot exceed 16 characters"),
    // email: yup.string().email().required("Please enter an email"),
    // email: yup
    //   .string()
    //   .trim()
    //   .email("Invalid email")
    //   .required("Please enter an email"),

    password: yup
      .string()
      .required('Please enter your password')
  .min(8, 'Password must be at least 8 characters long')
  .max(20, 'Password must be at most 20 characters long')
  .test('no-whitespace', 'Password must not contain whitespace characters', value => !/\s/.test(value))
  .test('has-lowercase', 'Password must include at least one lowercase letter', value => /[a-z]/.test(value))
  .test('has-uppercase', 'Password must include at least one uppercase letter', value => /[A-Z]/.test(value))
  .test('has-digit', 'Password must include at least one digit', value => /\d/.test(value))
  .test('has-special-char', 'Password must include at least one special character (@$!%*?&#)', value => /[@$!%*?&#]/.test(value))
  .test('allowed-special-chars', 'Password contains invalid special characters. Only @$!%*?&# are allowed', value => !/[^A-Za-z\d@$!%*?&#]/.test(value))
  .notOneOf(['password', '12345678', 'qwerty', 'abcdefg'], 'Password is too common'),
    countryCode: yup
      .string()
      .required("Please select your country code"),
  referralId: yup.string()
  .trim()
  .notRequired()
  .matches(/^[A-Z0-9]*$/, 'Please enter a valid referal code')
  .max(16, 'Referral code cannot be longer than 16 characters'),
  }),
)
export const providerSignupEmailSchema = yupResolver(
  yup.object().shape({
    firstName: yup
      .string()
      .required("Please enter first name")
      .min(3, "First Name must be at least 3 characters long")
      .max(26, "First Name cannot exceed 26 characters"),
    lastName: yup
      .string()
      .required("Please enter last name")
      .min(3, "Last Name must be at least 3 characters long")
      .max(26, "Last Name cannot exceed 26 characters"),
    // mobileNo: yup
    //   .string()
    //   .required("Please enter the Phone number")
    //   .min(6, "Phone Number must be at least 6 characters long")
    //   .max(16, "Phone Number cannot exceed 16 characters"),
    // email: yup.string().email().required("Please enter an email"),
    email: yup
      .string()
      .trim()
      .email("Invalid email")
      .required("Please enter an email"),

    password: yup
      .string()
      .required('Please enter your password')
  .min(8, 'Password must be at least 8 characters long')
  .max(20, 'Password must be at most 20 characters long')
  .test('no-whitespace', 'Password must not contain whitespace characters', value => !/\s/.test(value))
  .test('has-lowercase', 'Password must include at least one lowercase letter', value => /[a-z]/.test(value))
  .test('has-uppercase', 'Password must include at least one uppercase letter', value => /[A-Z]/.test(value))
  .test('has-digit', 'Password must include at least one digit', value => /\d/.test(value))
  .test('has-special-char', 'Password must include at least one special character (@$!%*?&#)', value => /[@$!%*?&#]/.test(value))
  .test('allowed-special-chars', 'Password contains invalid special characters. Only @$!%*?&# are allowed', value => !/[^A-Za-z\d@$!%*?&#]/.test(value))
  .notOneOf(['password', '12345678', 'qwerty', 'abcdefg'], 'Password is too common'),
  referralId: yup.string()
  .trim()
  .notRequired()
  .matches(/^[A-Z0-9]*$/, 'Please enter a valid referal code')
  .max(16, 'Referral code cannot be longer than 16 characters'),
  }),
)
//   forgotPasswordSchema
export const forgotPasswordWithEmailSchema = yup.object().shape({
  email: yup
      .string()
      .trim().email("Invalid email format").required("Please enter your Email")
})
export const forgotPasswordWithPhoneSchema = yup.object().shape({
  mobileNo: yup
      .string().trim()
      .required("Please enter the Phone number")
      .min(6, "Phone Number must be at least 6 characters long")
      .max(16, "Phone Number cannot exceed 16 characters")
})
//   seekerLoginSchema
const emailOrPhoneValidation = yup.string()
  .required("Please enter an email or phone number")
  .min(4, "Please enter an email or phone number")
  .test('is-valid', 'Please enter a valid email or phone number', value => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\d{10}$/;

    if (emailPattern.test(value.trim())) {
      return true; // It's a valid email
    } else if (phonePattern.test(value.trim())) {
      return true; // It's a valid phone number
    }

    return false;
  });

export const seekerLoginSchema = yupResolver(
  yup.object().shape({
    emailOrPhone: emailOrPhoneValidation,
    password: yup.string().required("Please enter your Password"),
  })
);
//   resetPasswordSchema
export const resetPasswordSchema = yupResolver(
  yup.object().shape({
    password: yup
      .string()
      .required('Please enter your password')
  .min(8, 'Password must be at least 8 characters long')
  .max(20, 'Password must be at most 20 characters long')
  .test('no-whitespace', 'Password must not contain whitespace characters', value => !/\s/.test(value))
  .test('has-lowercase', 'Password must include at least one lowercase letter', value => /[a-z]/.test(value))
  .test('has-uppercase', 'Password must include at least one uppercase letter', value => /[A-Z]/.test(value))
  .test('has-digit', 'Password must include at least one digit', value => /\d/.test(value))
  .test('has-special-char', 'Password must include at least one special character (@$!%*?&#)', value => /[@$!%*?&#]/.test(value))
  .test('allowed-special-chars', 'Password contains invalid special characters. Only @$!%*?&# are allowed', value => !/[^A-Za-z\d@$!%*?&#]/.test(value))
  .notOneOf(['password', '12345678', 'qwerty', 'abcdefg'], 'Password is too common'),
    confirmPassword: yup
      .string()
    .required("Please enter your Confirm password")
    .oneOf([yup.ref<string>('password')], 'New Passwords must match'),
  }),
)
//   seekerSignupSchema
export const seekerSignupWithPhoneSchema = yupResolver(
  yup.object().shape({
    firstName: yup
      .string()
      .required("Please enter first name")
      .min(3, "First Name must be at least 3 characters long")
      .max(26, "First Name cannot exceed 26 characters")
      .test(
        "no-consecutive-spaces",
        "First Name cannot contain two consecutive spaces",
        (value) => !/\s{2}/.test(value),
      )
      .test(
        "no-leading-space",
        "First Name cannot start with a space",
        (value) => !/^ /.test(value),
      ),
    lastName: yup
      .string()
      .required("Please enter last name")
      .min(3, "Last Name must be at least 3 characters long")
      .max(26, "Last Name cannot exceed 26 characters")
      .test(
        "no-consecutive-spaces",
        "Last Name cannot contain two consecutive spaces",
        (value) => !/\s{2}/.test(value),
      )
      .test(
        "no-leading-space",
        "Last Name cannot start with a space",
        (value) => !/^ /.test(value),
      ),
    phone: yup.string().trim()
    .required("Please enter the phone number")
    .min(6, "Phone Number must be at least 6 characters long"),
    countryCode: yup
    .string()
    .required("Please select your country code"),
    password: yup
      .string()
      .required('Please enter your password')
  .min(8, 'Password must be at least 8 characters long')
  .max(20, 'Password must be at most 20 characters long')
  .test('no-whitespace', 'Password must not contain whitespace characters', value => !/\s/.test(value))
  .test('has-lowercase', 'Password must include at least one lowercase letter', value => /[a-z]/.test(value))
  .test('has-uppercase', 'Password must include at least one uppercase letter', value => /[A-Z]/.test(value))
  .test('has-digit', 'Password must include at least one digit', value => /\d/.test(value))
  .test('has-special-char', 'Password must include at least one special character (@$!%*?&#)', value => /[@$!%*?&#]/.test(value))
  .test('allowed-special-chars', 'Password contains invalid special characters. Only @$!%*?&# are allowed', value => !/[^A-Za-z\d@$!%*?&#]/.test(value))
  .notOneOf(['password', '12345678', 'qwerty', 'abcdefg'], 'Password is too common'),
    referralId: yup.string()
    .trim()
    .notRequired()
    .matches(/^[A-Z0-9]*$/, 'Please enter a valid referal code')
    .max(16, 'Referral code cannot be longer than 16 characters'),
  }),
)
export const seekerSignupWithEmailSchema = yupResolver(
  yup.object().shape({
    firstName: yup
      .string()
      .required("Please enter first name")
      .min(3, "First Name must be at least 3 characters long")
      .max(26, "First Name cannot exceed 26 characters")
      .test(
        "no-consecutive-spaces",
        "First Name cannot contain two consecutive spaces",
        (value) => !/\s{2}/.test(value),
      )
      .test(
        "no-leading-space",
        "First Name cannot start with a space",
        (value) => !/^ /.test(value),
      ),
    lastName: yup
      .string()
      .required("Please enter last name")
      .min(3, "Last Name must be at least 3 characters long")
      .max(26, "Last Name cannot exceed 26 characters")
      .test(
        "no-consecutive-spaces",
        "Last Name cannot contain two consecutive spaces",
        (value) => !/\s{2}/.test(value),
      )
      .test(
        "no-leading-space",
        "Last Name cannot start with a space",
        (value) => !/^ /.test(value),
      ),
    email: yup.string().trim().email().required("Please enter an email"),
    password: yup
      .string()
      .required('Please enter your password')
  .min(8, 'Password must be at least 8 characters long')
  .max(20, 'Password must be at most 20 characters long')
  .test('no-whitespace', 'Password must not contain whitespace characters', value => !/\s/.test(value))
  .test('has-lowercase', 'Password must include at least one lowercase letter', value => /[a-z]/.test(value))
  .test('has-uppercase', 'Password must include at least one uppercase letter', value => /[A-Z]/.test(value))
  .test('has-digit', 'Password must include at least one digit', value => /\d/.test(value))
  .test('has-special-char', 'Password must include at least one special character (@$!%*?&#)', value => /[@$!%*?&#]/.test(value))
  .test('allowed-special-chars', 'Password contains invalid special characters. Only @$!%*?&# are allowed', value => !/[^A-Za-z\d@$!%*?&#]/.test(value))
  .notOneOf(['password', '12345678', 'qwerty', 'abcdefg'], 'Password is too common'),
    referralId: yup.string()
    .notRequired()
    .matches(/^[A-Z0-9]*$/, 'Please enter a valid referal code')
    .max(16, 'Referral code cannot be longer than 16 characters'),
  }),
)
// changePasswordSchema
export const changePasswordSchema = yupResolver(
  yup.object().shape({
    password: yup.string().required("Please enter your old password"),
    newpassword: yup
      .string()
      .required('Please enter your new password')
  .min(8, 'Password must be at least 8 characters long')
  .max(20, 'Password must be at most 20 characters long')
  .test('no-whitespace', 'Password must not contain whitespace characters', value => !/\s/.test(value))
  .test('has-lowercase', 'Password must include at least one lowercase letter', value => /[a-z]/.test(value))
  .test('has-uppercase', 'Password must include at least one uppercase letter', value => /[A-Z]/.test(value))
  .test('has-digit', 'Password must include at least one digit', value => /\d/.test(value))
  .test('has-special-char', 'Password must include at least one special character (@$!%*?&#)', value => /[@$!%*?&#]/.test(value))
  .test('allowed-special-chars', 'Password contains invalid special characters. Only @$!%*?&# are allowed', value => !/[^A-Za-z\d@$!%*?&#]/.test(value))
  .notOneOf(['password', '12345678', 'qwerty', 'abcdefg'], 'Password is too common'),
    confirmPassword: yup
    .string()
    .required("Please enter your Confirm password")
    .oneOf([yup.ref<string>('newpassword')], 'New Passwords must match'),
  }),
)
// editAboutAdSchema
export const editAboutAdSchema = yupResolver(
  yup.object().shape({
    aboutYou: yup.string(),
  }),
)
// editAdPriceSchema
export const editAdPriceSchema = yupResolver(
  yup.object().shape({
    hourlyFees: yup
      .string()
      // .min(1, "Hourly fees cannot be 0 and less than 0")
      // .required("This is a required Field")
      .max(100000, "Maximum length exceeded (100,000)"),
    hourlyFeesPrivate: yup
      .string()
      // .min(1, "Hourly fees cannot be 0 and less than 0")
      // .required("This is a required Field")
      .max(100000, "Maximum length exceeded (100,000)"),
    totalClasses: yup
      .string()
      .required("This is a required Field")
      .min(1, "Hourly fees cannot be 0 and less than 0")
      .max(100000, "Maximum length exceeded (100,000)"),

    packageFees: yup
      .string()
      .required("This is a required Field")
      .min(1, "Hourly fees cannot be 0 and less than 0")
      .max(100000, "Maximum length exceeded (100,000)"),

    webcamCharges: yup
      .string()
      .required("This is a required Field")
      .min(1, "Hourly fees cannot be 0 and less than 0")
      .max(100000, "Maximum length exceeded (100,000)"),
    travelCost: yup
      .string()
      .required("This is a required Field")
      .min(1, "Hourly fees cannot be 0 and less than 0")
      .max(100000, "Maximum length exceeded (100,000)"),
    additionaldetails: yup.string().required("This is a required Field"),
  }),
)
// editAdSessionSchema
export const editAdSessionSchema = yupResolver(
  yup.object().shape({
    classSession: yup.string(),
  }),
)
// editAdTitle
export const editAdTitle = yupResolver(
  yup.object().shape({
    newTitle: yup.string(),
  }),
)
// topNavBarSchema
export const topNavBarSchema = yupResolver(
  yup.object().shape({
    VedicSubject: yup.string(),
  }),
)
// landingSearchBarSchema
export const landingSearchBarSchema = yupResolver(
  yup.object().shape({
    VedicSubject: yup.string(),
  }),
)
// topNavSearchBarSchema
export const topNavSearchBarSchema = yupResolver(
  yup.object().shape({
    VedicSubject: yup.string(),
  }),
)
export const footerSubscribemail = yupResolver(
  yup.object().shape({
    email: yup.string().email(),
  }),
)
export const generatePaymentSchema = yupResolver(
  yup.object().shape({
    amount: yup.number()
    .required('Amount is required')
    .min(0, 'Amount cannot be negative')
    .max(100000, 'Amount cannot exceed 1 lakh'),
  }),
)
export const refferalCodeSchema = yupResolver(
  yup.object().shape({
    refferalCode: yup
      .string().trim()
      .required('Please submit your referal code')
      .matches(/^[A-Z0-9]*$/, 'Please enter a valid referal code')
      .max(12, 'Referral code cannot be longer than 12 characters'),
  })
)
export const reportAdSchema = yupResolver(
  yup.object().shape({
    category: yup.string().required('Category is required'),
    message: yup.string()
      .required('Message is required')
      .test('minWords', 'Message should be at least 10 words long', (value) => {
        if (value) {
          const wordCount = value.trim().split(/\s+/).length; // Split by spaces and filter out empty strings
          return wordCount >= 10;
        }
        return false;
      }),
    image: yup.mixed()
      .nullable(),
  })
);

