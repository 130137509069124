// import { Form } from 'react-bootstrap';
// import './ToggleButton.css'
// interface toggleProps {
//   defaultVal?:boolean,
//   isLoading?:boolean,
// }
// const ToggleButton = ({defaultVal, isLoading}: toggleProps ) => {
//   return (
//     <Form.Check // prettier-ignore
//     type="switch"
//     id="custom-switch"
//     disabled={isLoading}
//     defaultChecked={defaultVal || false}
//     checked={defaultVal || false}
//   />
// //   <div id="button-tgl" className="button-tgl">
// //   <input className="checkbox" type="checkbox" />
// //   <div className="knobs"></div>
// //   <div className="layer"></div>
// // </div>
//   );
// };

// export default ToggleButton

import { Form } from "react-bootstrap"
import "./ToggleButton.css"
import { useEffect, useState } from "react"

interface ToggleProps {
  defaultVal?: boolean
  isLoading?: boolean
  onChange?: (value: boolean) => void // Add onChange prop
  label?: string
  disabled?: boolean
}

const ToggleButton = ({ defaultVal, isLoading, onChange, label, disabled }: ToggleProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.checked)
    }
  }

  return (
    <div className="notify-toggle">
      <Form.Check
        type="switch"
        id="custom-switch"
        label={label}
        disabled={isLoading || disabled}
        checked={defaultVal}
        defaultChecked={defaultVal}
        onChange={handleChange}
        className="p-0"
      />
    </div>
  )
}

export default ToggleButton
