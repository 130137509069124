import { Form } from "react-bootstrap"
import FormLabel from "./FormLabel"
import "./FormInput.css"
import { useEffect, useState } from "react"
import eyeOpen from "../../assets/images/figma-eye-open.png"
import eyeClose from "../../assets/images/figma-eye-close.png"

interface FormInputProps {
  name: string
  title: string
  register?: any
  errors?: any
  placeholder?: string
  type?: string
  className?: string
  rows?: number
  extra?: JSX.Element
  watch?: any
}

interface LoginUser {
  email: string
  password: string
}

function FormInput({
  errors,
  title,
  name,
  register,
  placeholder,
  type = "text",
  className,
  rows = 3,
  extra,
  watch,
  ...rest
}: FormInputProps | any) {
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  useEffect(() => {
    let timeout: any | null = null;
    if (showPassword) {
      timeout = setTimeout(() => {
        setShowPassword(false);
      }, 2000); //adjust accordingly
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [showPassword,watch]);

 // useEffect(()=>{setShowPassword(false)},[watch])

  return (
    <>
    <Form.Group className={className}>
      <FormLabel title={title} />

      {type === "number"? (
        <Form.Control
        // isInvalid={Boolean(_.get(errors, name))}
        placeholder={placeholder}
        className="popup-field-wrapper"
        rows={rows}
        maxLength={16}
        {...rest}
        {...register(name)}
        type="text"
        onKeyDown={(e) => {
          if (
            !(
              (e.key >= "0" && e.key <= "9") ||
              e.key === "Backspace" ||
              e.key === "Delete"
            )
          ) {
            e.preventDefault()
          }
        }}
      />
      )
      :type === "textarea" ? (
        <Form.Control
          // isInvalid={Boolean(_.get(errors, name))}
          placeholder={placeholder}
          as={type}
          rows={rows}
          {...rest}
          {...register(name)}
        />
      ) : type === "password" ? (
        <div className="custom-password-input position-relative">
          <Form.Control
            // isInvalid={Boolean(_.get(errors, name))}
            placeholder={placeholder}
            className="popup-field-wrapper"
            type={showPassword ? "text" : "password"}
            {...rest}
            {...register(name)}
          />
          <img
            className="eye-icon ms-5"
            onClick={()=>{if(watch && watch!=='')togglePasswordVisibility()}}
            src={showPassword ? eyeOpen : eyeClose}
            alt="Toggle Password Visibility"
          />
        </div>
      ) : (
        <Form.Control
          // isInvalid={Boolean(_.get(errors, name))}
          placeholder={placeholder}
          type={type}
          className="popup-field-wrapper"
          {...rest}
          {...register(name)}
          onKeyDown={(e) => {
            if(watch?.length>1) return
            else if(e.key==' ') e.preventDefault()
          }}
        />
      )}

      {/* {errors && _.get(errors, name) ? (
        <Form.Control.Feedback type="invalid">
          {_.get(errors, name).message}
        </Form.Control.Feedback>
      ) : null}
      {extra} */}
    </Form.Group>
    {errors&&<p className=" text-danger">{errors?.message}</p>}
    </>
  )
}

export default FormInput
