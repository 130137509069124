import React, { useEffect } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import SpinnerSecondary from "../../components/spinner/SpinnerSecondary"
import { useAppSelector } from "../../app/hooks"
import { useAppDispatch } from "../../app/store"
import { logout, selectCurrentUser } from "../../features/Auth/authSlice"
import { useTokenStatusQuery } from "../../features/Auth/authApi"
import { USERTYPE } from "../../constants"

interface PrivateProps {
  children: React.ReactNode
  userType?:USERTYPE
}

const PrivateRoute = ({ children, userType }: PrivateProps) => {

  const { seekerToken, providerToken } = useAppSelector(selectCurrentUser)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const pollIntervalValue = 3600 * 1000
  const { data, isSuccess } = useTokenStatusQuery({
    pollingInterval: pollIntervalValue,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  })

  useEffect(() => {
    if (isSuccess && data?.isTokenExpired) {
      dispatch(logout())
    }
  }, [isSuccess, data])
 // Additional code for token decoding and expiration check if needed

 return seekerToken || providerToken ?  children : <Navigate to={'/'} replace/>
}

export default PrivateRoute
