import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../../app/store"
import { api } from "../authApi"
import { MODALNAME, USERTYPE } from "../../../constants"
import { providerDashboardApi } from "../../featProviderDashboard/FeatProviderApi"

const slice = createSlice({
  name: "authModal",
  initialState: { modalName: MODALNAME.CLOSED } as {
    modalName: MODALNAME
    data: any
  },
  reducers: {
    setModalName: (state, action: PayloadAction<MODALNAME>) => {
      state.modalName = action.payload
    },

    setModal: (state, action) => {
      state.modalName = action.payload.name
      state.data = action.payload.data
    },

    closeModal: (state: any) => {
      state.modalName = MODALNAME.CLOSED
      state.data = null
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        
        state.modalName = MODALNAME.CLOSED
      },
    ),
      builder.addMatcher(
        providerDashboardApi.endpoints.providerlogin.matchFulfilled,
        (state, { payload }) => {
          if(payload.token) state.modalName = MODALNAME.CLOSED
          // state.user = payload.result.user;
        },
      ),
      builder.addMatcher(
        api.endpoints.emailVerify.matchFulfilled,
        (state, { payload }) => {
          state.modalName = MODALNAME.VERIFY_EMAIL

          // state.user = payload.result.user;
        },
      ),
      builder.addMatcher(
        api.endpoints.providerEmailverify.matchFulfilled,
        (state, { payload }) => {
          state.modalName = MODALNAME.VERIFY_EMAIL
          // state.user = payload.result.user;
        },
      )
  },
})

export default slice.reducer
export const { closeModal, setModalName, setModal } = slice.actions

export const currentModalName = (state: RootState) => state.authModal
