import React, { lazy, Suspense, useEffect, useState } from "react"
import "./commonCard/CommmonCard.css"
import {
  createSearchParams,
  useNavigate,
} from "react-router-dom"
import { useAddToFavMutation } from "../../features/featSeekerDashboard/seekerDashboardApi"
import { useAppSelector } from "../../app/hooks"
import { useAppDispatch } from "../../app/store"
import {
  setModal,
  setModalName,
} from "../../features/Auth/ModalHandler/modalSlice"
import { MODALNAME } from "../../constants"
import { toast } from "react-toastify"
import "../cards/commonCard/CommmonCard.css"
import ExploreSkeletonCard from "../skeletonLoaders/ExploreSkeletonCard"
import ProviderCard from "./ProviderCard"
interface providerProps {
  data: any
  modeOfClass?: string | null
  batchType?: string | null
}

const ProviderCards = ({ data, modeOfClass, batchType }: providerProps) => {

  const user = useAppSelector((state: any) => state.auth)
  const dispatch = useAppDispatch()
  const [isMutedCard, setIsMutedCard] = useState<string>("")
  const [activeItems, setActiveItems] = useState([])
  const [customMessages, setCustomMessages] = useState([])
  const [id, setId] = useState<number>()
  const navigate = useNavigate()

  const [addToFavourites, { data: favData, isError, isLoading, isSuccess }] =
    useAddToFavMutation()

  const selectCard = (advertId: any) => {
    setId(id)
    navigate(`/teacher-profile?${createSearchParams({ advertId })}`);
  }

  const handleToggle = async (index: any, id: any) => {
    if (user.seekerToken || user.providerToken) {
      const res = await addToFavourites(id)
      if (res) {
        toast.dismiss()
        toast.success((res as any)?.data?.message)
      }
    } else {
      dispatch(setModalName(MODALNAME.LOGIN))
    }
    setActiveItems((prevActiveItems: any) => {
      const updatedItems = [...prevActiveItems] as any
      updatedItems[index] = !updatedItems[index]
      return updatedItems
    })

    if (!activeItems[index]) {
      setCustomMessages((prevMessages: any) => {
        const updatedMessages = [...prevMessages] as any
        return updatedMessages
      })
    }
  }

  useEffect(() => {
    if (data) {
      setActiveItems(data?.map((item: any) => item?.isFavourite ?? false))
    }
  }, [data])

  const idSet = new Set()

  return (
    <>
      {data?.length > 0 &&
        data?.map((data: any, index: number) => {
          const id = data._id
          if (idSet.has(id)) {
            return null // Skip rendering the component
          }
          idSet.add(id)
          return (
            
            <ProviderCard id={id} index={index} setIsMutedCard={setIsMutedCard} 
            data={data} customMessages={customMessages} activeItems={activeItems} 
            user={user} handleToggle={handleToggle} selectCard={selectCard}
            modeOfClass={modeOfClass} batchType={batchType} isMutedCard={isMutedCard}/>
           
          )
        })}
    </>
  )
}

export default ProviderCards