import React, { useEffect } from 'react'
import { Button, Form, Modal, ModalBody } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { generatePaymentSchema } from '../../../utils/yupSchema/Schema';
import { useLocation } from 'react-router-dom';
import { MdOutlinePayments } from 'react-icons/md';
interface props {
    show: boolean;
    handleClose: () => void;
    data: any;
}
interface formData {
    amount: number
}
const REquestPaymentModal = ({ show, handleClose, data }: props) => {
    const location = useLocation()
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm<formData>({
        resolver: generatePaymentSchema,
      })
    function handleCloseModal () {
        handleClose()
        reset()
    }
    function onsubmit (value: formData) {
        console.log(value.amount)
    }
    // useEffect(()=>handleCloseModal(),[location])
    const handleKeyPress = (event: any) => {
        // Prevent '-' or '+' input
        if (event.key === '-' || event.key === '+') {
          event.preventDefault();
        }
      };
    return (
        <Modal
            show={show}
            onBackdropClick={handleCloseModal}
            onHide={handleCloseModal}
            centered>
                <Modal.Header closeButton/>
            <ModalBody>
                <Form onSubmit={handleSubmit(onsubmit)} className='p-0 p-mb-2'>
                    <Form.Group>
                        <Form.Label>Enter amount</Form.Label>
                        <Form.Control {...register('amount')} onKeyDownCapture={handleKeyPress} type='number'/>
                        {errors.amount&&<p className=' text-danger'>{errors.amount.message}</p>}
                    </Form.Group>
                    <Button  type='submit' className='schedule-session-btn mt-3'><MdOutlinePayments size={20} className="me-2" />Send Payment Request</Button>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default REquestPaymentModal