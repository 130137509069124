const base_url = 'https://www.vedichom.com'
export const termsandConditionContent = (
    <>
            <div>
          <div className="tc-topic-text">INTRODUCTION</div>
          <p className="tc-text-info">
            These Terms of Use ("Terms") govern your use of the{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>
            website, located at{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            , any mobile applications, and services ("Site") provided by{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            Pvt Ltd. having its registered office at{" "}
            <a
              href="https://www.google.com/maps/search/?api=1&query=5Bix+IT+Solutions+Inc+1%2C+Plot+no.+D%2C+Prime+Tower%2C+108D%2C+Phase+7%2C+Industrial+Area%2C+Balongi%2C+Sahibzada+Ajit+Singh+Nagar%2C+Punjab+160055"
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              {" "}
              5Bix IT Solutions Inc,{" "}
            </a>
            Plot no. D, Prime Tower, 108D, Phase 7, Industrial Area, Sahibzada
            Ajit Singh Nagar, Punjab 160055
          </p>
          <p className="tc-text-info">
            PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS SITE,
            DOWNLOADING, INSTALLING OR USING OUR WEB OR MOBILE APPLICATION. BY
            ACCESSING, REGISTERING OR USING THIS SITE, YOU AGREE TO BE BOUND BY
            THESE TERMS, INCLUDING THE DISCLAIMERS SET FORTH BELOW AND THE
            PRIVACY POLICY. IF YOU ARE NOT WILLING TO BE BOUND BY THESE TERMS OF
            USE, INCLUDING THE DISCLAIMERS AND PRIVACY POLICY, YOU MAY NOT
            ACCESS OR USE THIS SITE OR DOWNLOAD OUR WEB OR MOBILE APPLICATION.
          </p>
          <p className="tc-text-info">
            WE RESERVE THE RIGHT, AT OUR DISCRETION, TO MODIFY, ADD, OR REMOVE
            PORTIONS OF THESE TERMS AT ANY TIME AND EACH SUCH CHANGE SHALL BE
            EFFECTIVE IMMEDIATELY UPON POSTING. PLEASE CHECK THESE TERMS
            PERIODICALLY FOR CHANGES. YOUR CONTINUED USE OF THIS SITE FOLLOWING
            THE POSTING OF CHANGES TO THESE TERMS WILL MEAN YOU ACCEPT THOSE
            CHANGES.
          </p>
        </div>
        <div>
          <div className="tc-topic-text">GENERAL</div>
          <div className="tc-topic-text">1. General Terms</div>
          <p className="tc-text-info">
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            provides this Site to users seeking tutoring, Mentoring and
            consultancy services ("Seekers") and to users seeking to provide
            tutoring, Mentoring and consultancy services ("Sources"), and to any
            other entity like “Tuition, Mentoring or consultancy Centers" on
            whose behalf users accept these Terms. The term "you" or "You" or
            "User" or "Users" shall refer to Seekers, Sources or any person or
            entity (Tuition, Mentoring or Consultancy Centers) who views, uses,
            accesses, browses or submits any content or material to the Site.
            The Terms are effective upon acceptance and govern the relationship
            between you and us including the provision of any of the Services on
            our Site. In the event the Terms conflict with any other
            document/policy/content on our Site, the Terms will prevail over
            them, for the purposes of usage of the Site.
          </p>
          <p className="tc-text-info">
            Any express waiver or failure to exercise promptly any right under
            the Terms will not create a continuing waiver or any expectation of
            nonenforcement. If you do not agree to be bound by the Terms and the
            Privacy Policy, you may not use our Site in any way and should exit
            immediately.
          </p>
          <p className="tc-text-info">
            If any provision of the Terms is held invalid by any law or
            regulation of any government, or by any court or arbitrator, the
            parties agree that such provision will be replaced with a new
            provision that accomplishes the original business purpose, and the
            other provisions of the Terms will remain in full force and effect.
          </p>
        </div>
        <div>
          <div className="tc-topic-text">2. Services</div>
          <p className="tc-text-info">
            You are responsible for your acts and omissions. The site is only a
            venue or E-Plateform to connect Seekers and Sources, in the event
            that you have any dispute with other users, you release{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>
            (and our parents, subsidiaries, officers, directors, shareholders,
            employees, agents, joint ventures, consultants, successors and
            assigns) from any and all claims, demands and damages (actual and
            consequential) of every kind and nature, known and unknown,
            suspected and unsuspected, disclosed and undisclosed, arising out of
            or in any way connected with such disputes. As independent
            contractors or Sources hired by Seekers or Users, Consultants,
            Coaches, Tutors or representatives of the Source centres control the
            methods, materials and all aspects of the lessons.
          </p>
          <p className="tc-text-info">
            We only support the Sources or Their Centers’ decisions and choices
            that you make. These decisions and choices are yours. We cannot make
            decisions for you. We only help you to find good Sources and connect
            with Sources in the form of Tutors, Mentors, Coaches, and
            Consultants. On our Site, you can ask and find informational
            questions and related educational answers by Sources. Seekers are
            responsible for selecting the right Source, Mentor, Consultant,
            Coach or tuition centre for their needs. In making hiring decisions,
            Seekers should review and investigate each source's self-reported
            credentials, education, and experience, as well as reviews from
            other students. ALL SOURCE SESSIONS SHOULD BE SUPERVISED BY A
            RESPONSIBLE ADULT.
          </p>
          <p className="tc-text-info">
            The information provided in{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            , regarding Sources and their tuition, coaching, consultancy or
            mentoring centres, courses, etc. has been compiled by assimilating
            data from their credentials as posted by Sources, to promote their
            skills, education and experience. In no way, are we{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            Pvt Ltd. responsible for the Sources’ posted credentials accuracy
            and neither we{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            Pvt Ltd claim authenticity in their posted credentials. The details
            provided are for general information only. We update the data
            regularly to ensure that the information you receive from our
            website is accurate and authentic as posted and claimed by the
            Sources. For additional requirements of Seekers and Sources,
            education-related information displayed on our website, we recommend
            you contact the respective sources and their centres by due
            diligence and on your prudence and risk.
          </p>
          <p className="tc-text-info">
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            Pvt Ltd. controls and operates the Site from various locations and
            makes no representation that this website is appropriate or
            available for use in all locations and geographies.
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            services may not be available in your location, and available
            services may vary among locations. In addition,{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>
            will attempt to deliver all correspondence sent via the Site's
            messaging and E-mail system.{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            Pvt Ltd. does not, however, guarantee the delivery of all messages
            and correspondence.
          </p>
          <p className="tc-text-info">
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            Pvt Ltd. reserves the right to withdraw or modify aspects of the{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            services, or the entirety of it, when and where we have legal or
            commercial reasons to do so. There may also be times when the{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            services become inaccessible as a result of technical difficulties
            experienced by{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            or on the Internet; we will, however, use reasonable skill and care
            to overcome these difficulties where they are within our control.
          </p>
        </div>
        <div>
          <div className="tc-topic-text">3. Restricted Activities</div>
          <p className="tc-text-info">
            You acknowledge and agree that{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            services are provided for the purpose of facilitating assimilation
            and dissemination of ancient Vedic and contemporary Vedic practices,
            rituals, knowledge, education and contemporary practices and
            teachings of modern-day fitness, education, knowledge and mentoring
            and not for any illegal and unethical purposes. You agree not to use
            the Site to hire tutors for illegal purposes. Further, you agree not
            to use{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>
            services for any purpose that violates the honesty policy or other
            conduct policies of your country, location, faith and religious
            beliefs, institution or workplace. You agree not to use the Site to:
            (a) violate or encourage the violation of any local, state,
            national, or international law; (b) stalk, harass, or harm another
            individual; (c) collect or store personal data about other users of
            our Site; (d) impersonate any person or entity, or otherwise
            misrepresent your affiliation with a person or entity; (e) interfere
            with or disrupt the Site or services or networks connected to the
            Site, or disobey any requirements, procedures, policies, or
            regulations of networks connected to the Site. You agree not to use
            the Site or Mobile Application to send any message that is unlawful,
            libellous, defamatory, abusive, sexually explicit, threatening,
            vulgar, obscene, profane, racially offensive, or otherwise
            objectionable, as determined by{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            in its sole discretion.
          </p>
          <p className="tc-text-info">
            Without our written consent, you may not: (i) reproduce, duplicate,
            copy, sell, resell, or exploit for any commercial purpose, any
            portion of the Site, use of the Site, or access to the Site; (ii)
            allow, enable, or otherwise support the transmission of mass
            unsolicited, commercial advertising or solicitations via e-mail
            (spam); (iii) use any high volume, automated, or electronic means
            (including without limitation robots, spiders, scripts, or other
            automated devices) to access the Site or monitor or copy our Web
            pages or the content contained thereon; (iv) deep-link to the Site
            for any purpose; or (d) frame the Site, place pop-up windows over
            its pages, or otherwise affect the display of its pages.
          </p>
        </div>
        <div>
          <div className="tc-topic-text">4. Eligibility and conduct</div>
          <p className="tc-text-info">
            If you are a minor i.e. under the age of 18 years, you shall
            register as a User of the Website only after the consent of your
            immediate adult members of your family and shall transact on or use
            the Website on their supervision. If you are below the age of 18,
            you can use the Site or Services only in conjunction with, and under
            the supervision of, your parent or guardian who has agreed to the
            Terms. If you are the parent or legal guardian of a child under the
            age of 18, you may use the Site or Services on behalf of such minor
            child. By using the Site or Services on behalf of a minor child, you
            represent and warrant that you are the parent or legal guardian of
            such child. If you do not qualify under these terms, do not use the
            Site or Services. Membership in the Services is void where
            prohibited by applicable law, and the right to access the Site is
            revoked in such jurisdictions. By using the Site and/or the
            Services, you represent and warrant that you have the right,
            authority, and capacity to enter into these Terms and to abide by
            all of the Terms set forth herein. You agree: (a) to provide
            accurate, current, and complete information about you as may be
            prompted by a registration form on the website ("Registration
            Data"); (b) to maintain the security of your password and
            identification; (c) to maintain and promptly update the Registration
            Data and any information you provide to{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            , to keep it accurate, current and complete; and (e) to accept all
            risks of unauthorized access to information and Registration Data.
          </p>
          <p className="tc-text-info">
            You may submit feedback regarding your experiences with Sources and
            their services on our website or Mobile Application, submit
            inquiries concerning possible seeking needs and participate in the
            other interactive and community features of the Site (collectively
            "Submitted Content"). It is important that you act responsibly when
            providing Submitted Content. In providing feedback, please give
            clear, honest information about the Sources or Sources Centers and
            your experiences, but do not use inappropriate language, make
            gratuitous personal criticisms or comments or provide information
            that others could use to determine your identity. When participating
            in other interactive or community aspects of the Service, please do
            not post any information that another user or Sources and their
            Sources Centers may use to identify you as an individual, but please
            do include all relevant information in a concise manner to help us
            provide you with a helpful response.
          </p>
          <p className="tc-text-info">
            You acknowledge and agree that by submitting the Submitted Content
            to us, you (i) grant us a nonexclusive, royalty-free, perpetual,
            transferable, irrevocable and fully sublicensable right to use,
            reproduce, modify, adapt, translate, distribute, publish, create
            derivative works from and publicly display and perform the Submitted
            Content throughout the world in any media, now known or hereafter
            created without attribution, (ii) grant us the right to pursue at
            law any person or entity that violates Your and/or our rights in
            Your Submitted Content, and (iii) forever waive any and all of Your
            rights, including but not limited to moral rights, if any, in and to
            Your Submitted Content, including, without limitation, any all
            rights or requirements of attribution or identification of You as
            the author of the Submitted Content or any derivative thereof. You
            further acknowledge and agree that Your Submitted Content is
            non-confidential and do not contain proprietary information.
          </p>
          <p className="tc-text-info">
            Except as described in our Privacy Policy. We will not be required
            to treat any of Your Submitted Content as confidential, and you
            acknowledge and agree that we may use in connection with our
            business any of the concepts and ideas contained in Submitted
            Content without compensation to you and we will not incur any
            liability to You as a result of any similarities between concepts
            and ideas contained in Submitted Content and our future operations
            and business. Except as otherwise outlined in these Terms, we will
            be entitled to use the Submitted Content for any commercial or other
            purpose whatsoever without compensation to You or any other person.
          </p>
          <p className="tc-text-info">
            Submitted Content, including personally identifiable information
            contained therein, is made voluntarily and is subject to the Privacy
            Policy. You are responsible for Submitted Content and agree to
            defend us, indemnify and hold us harmless from any damages, losses,
            costs, or expenses, including reasonable attorneys’ fees, which we
            may incur as a result of Submitted Content.
          </p>
          <p className="tc-text-info">
            With respect to Submitted Content, you acknowledge, warrant and
            agree that:
          </p>
          <p className="tc-text-info">
            a) If Submitted Content concerns Sources and their Centers,
            Submitted Content will be true and accurate, only concern or relate
            to the care or service that You, Your minor child or Your ward
            received from the Source and their centres being reviewed, that You
            are not related to or employed by the Sources being reviewed and
            that You are not impersonating any other person;
          </p>
          <p className="tc-text-info">
            b) If You are a Source or representing Sources Centers submitting or
            editing any Submitted Content, Your Submitted Content will be true
            and accurate, You are the Source specifically identified in the
            Content being submitted or edited, and Your Submissions will only
            concern You and the services that You provide.
          </p>
          <p className="tc-text-info">
            c) If You are a person authorized by a Source or their source
            centres to submit or edit Submitted Content specifically related to
            that Source or their source centres, Submitted Content will be true
            and accurate, You are authorized by the Sources and their Centers
            identified in the Submitted Content or edited to make such edits and
            Submitted Content will only concern that Sources and their Centers
            and the services provided by that Sources and their Centers.
          </p>
          <p className="tc-text-info">
            d) You have the right to make Submitted Content without violating
            the copyright, trademark, patent or other intellectual property
            rights of any person or entity, and Submitted Content will not
            contain any content, personally identifiable information (other than
            the name of the Sources and their Centers being reviewed),
            information, or materials of any kind that relates or refers to any
            other person or entity other than the person or entity being
            reviewed;
          </p>
          <p className="tc-text-info">
            e) Submitted Content will not violate any law, or in any manner
            infringe or interfere with the rights of others, including but not
            limited to the use of names, information, or materials that (i)
            libel, defame, or invade the privacy of any third party, (ii) are
            obscene or pornographic, (iii) harmful, threatening, offensive,
            abusive, harassing, vulgar, false or inaccurate, racially, sexually,
            ethnically or are otherwise objectionable or otherwise contrary to
            the laws of any place where such Submissions may be accessed, (iv)
            constitute personal attacks on other individuals, (v) infringe the
            intellectual property, trade secret or proprietary rights of any
            third party, (vi) promote criminal or other illegal activity, (vii)
            promote or advertise any person, product or service or solicit
            funds, or (viii) are deemed confidential by any contract or policy.
          </p>
          <p className="tc-text-info">
            You are permitted to post only one Content regarding the Source or
            Their Centers, entity, procedure or subject during the period your
            subscription is active as mentioned in the{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            website and mobile application.{" "}
          </p>
          <p className="tc-text-info">
            We reserve the right to publish your Submitted Content as part of
            the Service and to also to remove your Submitted Content for any
            reason. We are not, however, responsible for any failure or delay in
            removing Submitted Content.
          </p>
          <p className="tc-text-info">
            We do not provide the Submitted Content on the Site. It is the
            seekers or users of the Site provide Content on the Site. The
            Sources and their Centers determine which questions to answer; they
            are not employees or agents of{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            Pvt Ltd. but are independent service providers using the Site to
            provide their expert knowledge to seekers or users of the Site.
          </p>
          <p className="tc-text-info">
            We are not involved in any conversations between users and do not
            refer you to endorse or recommend particular Source or their
            Centers. You understand and acknowledge that we cannot and do not
            edit, modify, filter, screen, monitor, endorse or guarantee the
            content on Site. We shall not be liable for any acts or omissions of
            Sources and their Centers, Content, the ability of Source and their
            Centers to answer questions or the ability of Seekers to pay for
            their services. We do not ensure that seekers or users will complete
            a transaction.
          </p>
          <p className="tc-text-info">
            By using the Site, you represent and warrant that you currently meet
            and will continue to meet the following eligibility conditions
            ("Eligibility Conditions") for as long as you use the Site: (a) you
            have and will at all times comply with all laws and regulations; (b)
            you have the right, authority and capacity to enter into these Terms
            of Use and to abide by all of terms and conditions in these Terms of
            Use; (c) neither you, nor anyone in your home: (i) has been the
            subject of a complaint, restraining order or any other legal action
            involving violence, abuse, neglect, fraud, larceny, or any offense
            that involves endangering the safety of others; (ii) has been
            convicted of a crime of any nature, including any felony or
            misdemeanor of any kind, including without limitation any sexual,
            child abuse or domestic violence offenses; and/or (iii) has been
            and/or is currently required to register as a sex offender in any
            jurisdiction or with any government entity; and (d) neither you, nor
            anyone in your home, is currently out on bail or on your own
            recognizance pending trial, relating to any felony or misdemeanor
            charges of any kind, including without limitation sexual, child
            abuse or domestic violence offenses; and (e) not a active or passive
            individual of extremist faith groups, cults (as banned by their law
            of land) to promote any anti - Vedic agenda, further to hurt the
            religious sentiments of any faith, belief and practices.
          </p>
          <p className="tc-text-info">
            By using the Site, you understand and agree that{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            may rely on the above Eligibility Conditions representations and
            warranties as true. You understand and agree that{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            may revise the Eligibility Conditions from time to time and require
            new conditions and certifications and that the User will abide by
            such revised Eligibility Conditions or discontinue using the Site.
          </p>
          <p className="tc-text-info">
            You hereby authorize{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            to verify the above representations and warranties by you and you
            acknowledge and agree that{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>
            shall have the right, but not the obligation, to verify such
            representations and warranties. You agree and understand that such
            verification may include, without limitation, conducting criminal
            background checks, sex offender registry checks, motor vehicle
            records checks, identification verifications, credit checks and/or
            use of available public records. You consent to any collection, use
            or disclosure in order to accomplish such verification. You further
            agree that{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            may take such action as it, in its sole discretion, deems
            appropriate, including without limitation suspending and/or
            terminating your use of the Site, should it determine that you have
            violated any representation or warranty.
          </p>
          <p className="tc-text-info">
            The preceding paragraph notwithstanding, you understand and agree
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            does not conduct background checks of any User (either a Source or
            Seeker). You also understand and agree that{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>
            neither confirms nor denies the validity of the information provided
            by Users and that{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            does not verify that any or all of the Eligibility Conditions are
            met by Users. You understand and agree to make your own decisions
            and assessments about persons to engage and that IT IS YOUR SOLE
            RESPONSIBILITY TO CONDUCT ANY AND ALL BACKGROUND AND REFERENCE
            CHECKS REGARDING OTHER USERS.
          </p>
          <p className="tc-text-info">
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            expressly disclaims, and you expressly release{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>
            from, any and all liability whatsoever for any controversies,
            claims, suits, injuries and/or damages arising from and/or in any
            way related to (a) any inaccuracy, untimeliness or incompleteness
            regarding a User's Eligibility Conditions and/or (b) misstatements
            or misrepresentations made by any User.
          </p>
          <p className="tc-text-info">
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            reserves all of its rights to remove anything objectionable to{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            in its sole discretion. Alleged improprieties by any User may be
            reported to{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            by email at{" "}
            <a
              className="primary-text text-decoration-none"
              href="mailto:namaste@vedichom.com"
            >
              namaste@vedichom.com
            </a>
            or the support section of{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            .
          </p>
        </div>
        <div>
          <div className="tc-topic-text">5. Fees & Refunds</div>
          <p className="tc-text-info">
            {" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            may at its sole discretion revise or change the subscription and
            connect rates without any prior notification. Refunds will be made
            at the sole discretion of{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            . As such there is no refund policy at the{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            site as{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            does not charge any other fees, except a commission percentage of
            15% from the source after registering a successful request by the
            seeker and PLATFORM FEES (SUBSCRIPTION BASED MODEL) AS MENTIONED IN
            WEB-SITE OF VEDICHOM. The fees of services offered by the Sources in
            the platform will be the sole matter between seekers and sources.
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            does not take any responsibility or role in financial transactions
            between seekers and sources, and if any dispute of such nature
            arises, there will be no liability of{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            Pvt Ltd. However, seekers may extend their grievances to our
            knowledge so as to blacklist the source and their centres, if the{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            feels weitghage on users or seekers claim. However, the generation
            of reviews by the Seekers or Users for Sources services and conduct
            will be in force and valid till the time Seekers or Users are not
            using any inappropriate, unlawful language or text violating the
            company policies or personal ethical conduct.
          </p>
        </div>
        <div>
          <div className="tc-topic-text">6. Third party reports</div>
          <p className="tc-text-info">
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            may utilize third-party consumer reporting agencies that perform,
            among other things, criminal background checks, sex offender
            registry checks, motor vehicle records checks, credit checks, and
            identification verifications ("consumer reports").
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            does not endorse or make any representations or warranties regarding
            the reliability of such consumer reports or the accuracy, timeliness
            or completeness of any information in the consumer reports.{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            does not independently verify information in the consumer reports.{" "}
          </p>
          <p className="tc-text-info">
            You hereby consent to{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            collecting, using and disclosing the information in the consumer
            reports. You understand and agree that
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            may, in its sole discretion, review and rely on the information in
            the consumer reports in deciding whether to suspend or terminate a
            User or to investigate a complaint about a User, but that{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            shall not be responsible or liable in any way in the event that any
            information in the consumer reports about any person, including
            without limitation any User, is not accurate, timely or complete.
            Users who are the subject of consumer reports may contact the
            service provider to dispute the accuracy, timeliness or completeness
            of such information.{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            reserves the right to suspend and/or terminate the User based on
            information in the consumer reports or for any other reason in{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            ’s sole discretion.
          </p>
        </div>
        <div>
          <div className="tc-topic-text">
            7. Confidentiality of{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            information
          </div>
          <p className="tc-text-info">
            You may obtain direct access via the Site to certain confidential
            information of{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            and its affiliates, including without limitation technical,
            contractual, product, program, pricing, marketing and other valuable
            information that should reasonably be understood as confidential
            ("Confidential Information"). You must hold Confidential Information
            in strict confidence. All rights, title and interest in the
            Confidential Information remain with
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
               VedicHom{" "}
            </a>{" "}
            and its affiliates.
          </p>
          <p className="tc-text-info">
            The Terms impose no obligation upon you with respect to Confidential
            Information that you can establish by legally sufficient evidence:
            (a) you possessed prior to your receipt from{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            , without an obligation to maintain its confidentiality; (b) is or
            becomes generally known to the public through no act or omission by
            you, or otherwise without violation of the Terms; (c) you obtained
            from a third party who had the right to disclose it, without an
            obligation to keep such information confidential; (d) you
            independently developed without the use of Confidential Information
            and without the participation of individuals who have had access to
            it, or (e) in response to a valid order by a court or other
            governmental body, as otherwise required by law, or as necessary to
            establish the rights of either party under these Terms and as
            disclosed after prior notice to{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            adequate to allow{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            to object to the disclosure.
          </p>
        </div>
        <div>
          <div className="tc-topic-text">8. Terms of transacting business</div>
          <p className="tc-text-info">
            You understand and agree to abide by{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            ’s Source and Seekers payment policies. You agree that you are
            willing to pay all costs associated with the services directly to
            Source by their choice of mode of payment and as agreed between
            Seeker and Source other than the{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            platform fees directly to avail these services at
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            . The costs highlighted by the Sources to provide their services
            include but are not limited to (a) a source’s hourly rate as listed;
            (b) travel and transportation fees (if applicable and agreed) (c)
            cancellation fees as described in a sources’s cancellation policy,
            which can be found on a source’s profile on the Site.{" "}
          </p>
        </div>
        <div>
          <div className="tc-topic-text">9. Advertisements and promotions</div>
          <p className="tc-text-info">
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            may run advertisements and promotions from third parties on the
            Site. Your correspondence or business dealings with, or
            participation in promotions of, advertisers other than{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>
            found on or through the Site, including payment and delivery of
            related goods or services, and any other terms, conditions,
            warranties or representations associated with such dealings, are
            solely between you and such advertiser.{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            is not responsible or liable for any loss or damage of any sort
            incurred as the result of any such dealings or as the result of the
            presence of such non-
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            advertisers on the Site.
          </p>
        </div>
        <div>
          <div className="tc-topic-text">10. Third Party links</div>
          <p className="tc-text-info">
            Placement of information, logos, links or names of Sources and their
            Centers or any other third-party entities on the Site does not
            constitute an endorsement or warranty of their services or opinions
            or their products. You take full responsibility for a decision to
            visit or patronize any such entity and hold{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            harmless from any liability arising from such actions. You further
            acknowledge that no relationship (such as partnership, agent, joint
            venturer, or employee) is created between you and{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            or between any User including Source or their Centers or any other
            third-party entities and{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            by the formation of this Agreement or by your participation on the
            Site.
          </p>
          <p className="tc-text-info">
            We do not control the information provided by Sources and their
            Centers or any other third-party entities or users, which you may
            find to be offensive, harmful, inaccurate, or deceptive.
          </p>
          <p className="tc-text-info">
            YOU ARE RESPONSIBLE FOR EVALUATING THE ACCURACY, COMPLETENESS AND
            USEFULNESS OF ANY OPINION, ANSWER OR OTHER CONTENT AVAILABLE THROUGH
            THE SITE, FROM THIRD PARTIES OR OBTAINED FROM A LINKED SITE. PLEASE
            SEEK THE ADVICE OF PROFESSIONALS, AS APPROPRIATE, REGARDING THE
            EVALUATION OF ANY SPECIFIC OPINION, ANSWER, PRODUCT, SERVICE, OR
            OTHER CONTENT.
          </p>
        </div>
        <div>
          <div className="tc-topic-text">
            11. Text message & PUSH notifications
          </div>
          <p className="tc-text-info">
            {" "}
            We send various text messages and PUSH notifications for new seekers
            and source messages, new message reminders, job opportunities and
            more ("Text Message Program"). Please note that standard data and
            messaging rates may apply for any text message notifications. Please
            contact your mobile phone carrier for details.
          </p>
          <p className="tc-text-info">
            Your mobile phone carrier’s standard data and messaging rates may
            apply to any messages sent to you from us and from us to you. If you
            have any questions about your text or data plan, please contact your
            mobile phone carrier.
          </p>
        </div>
        <div>
          <div className="tc-topic-text">12. Intellectual property rights</div>
          <p className="tc-text-info">
            {" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            grants you permission (which may be revoked at any time for any
            reason or no reason) to use the Site for the services as provided
            herein and in accordance with these Terms of Use and solely for your
            own personal, non-commercial use (except as provided herein),
            provided you do not remove any trademark, copyright or other notice
            contained on such pages. No other use is permitted. You may not, for
            example, incorporate the information, content, or other material in
            any database, compilation, archive or cache. Except as specifically
            authorized by{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            , you may not deep-link to the Site for any purpose or access the
            Site manually or with any robot, spider, web crawler, extraction
            software, automated process or device to scrape, copy, or monitor
            any portion of the Site or any information, content, or material on
            the Site.{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            reserves all of its statutory and common law rights against any
            person or entity who violates this paragraph. You may not link or
            frame to any pages of the Site or any content contained therein,
            whether in whole or in part, without prior written consent from{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            . You may like or follow{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            or share links to the Site via social networking technology
            referenced on the Site. Any rights not expressly granted herein are
            reserved.
          </p>
          <p className="tc-text-info">
            Except as expressly authorized by{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            or by Content providers, you agree not to reproduce, modify, rent,
            lease, loan, sell, distribute, mirror, frame, republish, download,
            transmit, or create derivative works of the Content of others, in
            whole or in part, by any means. You must not modify, decompile, or
            reverse engineer any software{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            discloses to you, and you must not remove or modify any copyright or
            trademark notice, or other notice of ownership.
          </p>
          <p className="tc-text-info">
            "
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            Trademarks" means all names, marks, brands, logos, designs, trade
            dress, slogans and other designations{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            uses in connection with its products and services. You may not
            remove or alter any{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            Trademarks, or co-brand your own products or material with{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            Trademarks, without{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            ’s prior written consent. You acknowledge{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            's rights in{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            Trademarks and agree that any use of{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            ’s Trademarks by you shall inure to{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            's sole benefit. You agree not to incorporate any
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            Trademarks into your trademarks, service marks, company names,
            internet addresses, domain names, or any other similar designations,
            for use on or in connection with a computer or Internet-related
            products, services or technologies.
          </p>
          <p className="tc-text-info">
            Unless explicitly stated herein, nothing in these Terms shall be
            construed as conferring any license to intellectual property rights,
            whether by estoppels, implication, or otherwise. Permission is
            granted to display, copy, distribute and download Content owned by
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            on this Site provided that: (a) the copyright notice pertaining to
            the Content remains, and a permission notice (e.g., "used with
            permission") is added to such Content; (b) the use of such Content
            is solely for personal and non-commercial use; (c) such Content will
            not be copied or posted on any networked computer or published in
            any medium, except as explicitly permitted by valid permission or
            license covering such materials; and (d) no modifications are made
            to such Content. This permission terminates automatically without
            notice if you breach any of the terms or conditions in this
            document. Upon termination, you must immediately destroy any
            downloaded and/or printed Content.
          </p>
        </div>

        <div>
          <div className="tc-topic-text">13. Termination and suspension</div>
          <p className="tc-text-info">
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            reserves the right to suspend (deactivate) and/or terminate (delete)
            any user with or without notice at any time in its sole discretion,
            for any reason or no reason. You agree that, if you are suspended
            and/ or terminated, you will make no further use of the Site after
            termination (deletion of profile) or during suspension (deactivation
            of profile).{" "}
          </p>
        </div>
        <div>
          <div className="tc-topic-text">14. Disclaimer of warranties</div>
          <p className="tc-text-info">
            USE OF THE SITE IS AT YOUR SOLE RISK UNLESS OTHERWISE EXPLICITLY
            STATED. THE SITE, INCLUDING THE INFORMATION, SERVICES, AND CONTENT,
            IS PROVIDED ON AN "AS IS", "AS AVAILABLE", AND "WITH ALL FAULTS"
            BASIS.{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            DISCLAIMS ALL EXPRESS OR IMPLIED CONDITIONS, REPRESENTATIONS, AND
            WARRANTIES OF ANY KIND, INCLUDING ANY IMPLIED WARRANTY OR CONDITION
            OF MERCHANTABILITY, SATISFACTORY QUALITY, OR FITNESS FOR A
            PARTICULAR PURPOSE. VEDICHOM MAKES NO REPRESENTATIONS, WARRANTIES,
            CONDITIONS OR GUARANTEES AS TO THE USEFULNESS, QUALITY, SUITABILITY,
            TRUTH, ACCURACY, OR COMPLETENESS OF THE SITE.
          </p>
          <p className="tc-text-info">
            VEDICHOM MAKES NO WARRANTY OR REPRESENTATIONS THAT: (A) THE SITE
            WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (B) THE
            RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE WEBSITE WILL BE
            ACCURATE OR RELIABLE; (C) THE QUALITY OF ANY PRODUCTS, SERVICES,
            CONTENT, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED FROM
            TUTORS WILL MEET YOUR EXPECTATIONS OR REQUIREMENTS; OR (D) ANY
            ERRORS IN THE SITE WILL BE CORRECTED.
          </p>
          <p className="tc-text-info">
            YOU ASSUME ALL RISK FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS
            OF DATA THAT RESULTS FROM OBTAINING ANY CONTENT FROM THE SITE,
            INCLUDING ANY DAMAGES RESULTING FROM COMPUTER VIRUSES.
          </p>
        </div>
        <div>
          <div className="tc-topic-text">15. Limitation of liability</div>
          <p className="tc-text-info">
            THE SITE OFFERS A MARKETPLACE FOR THOSE SEEKING TUTORING,
            EDUCATIONAL OR CONSULTANCY SERVICES TO CONNECT WITH THOSE SEEKING TO
            PROVIDE THESE SERVICES. YOU UNDERSTAND AND AGREE THAT VEDICHOM HAS
            NO CONTROL OVER THE ACTS OR OMISSIONS OF ANY USER AND THAT VEDICHOM
            MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE QUALITY OF THE
            SERVICES PROVIDED BY ANY USER. YOU UNDERSTAND AND AGREE THAT
            VEDICHOM IS NOT RESPONSIBLE FOR THE PERFORMANCE OR CONDUCT, WHETHER
            ONLINE OR OFFLINE, OF ANY USER OF THE SITE. AS SUCH, VEDICHOM
            EXPRESSLY DISCLAIMS ANY AND ALL LIABILITY WHATSOEVER FOR ANY
            CONTROVERSIES, CLAIMS, SUITS, INJURIES AND/OR DAMAGES ARISING FROM
            AND/OR IN ANY WAY RELATED TO THE SITE.
          </p>
          <p className="tc-text-info">
            USERS OF THE SITE TRANSACT BETWEEN THEMSELVES. VEDICHOM WILL NOT BE
            INVOLVED IN ANY USER INTERACTIONS. VEDICHOM IS NOT RESPONSIBLE FOR
            DISPUTES, CLAIMS, LOSS, INJURY, OR DAMAGE OF ANY KIND THAT MIGHT
            ARISE DURING AND AFTER USER INTERACTION.
          </p>
          <p className="tc-text-info">
            YOU UNDERSTAND AND AGREE THAT VEDICHOM HAS NO CONTROL OVER THE ACTS
            OR OMISSIONS OF ANY USER ON OR OFF THE SITE AND THAT VEDICHOM MAKES
            NO REPRESENTATIONS OR WARRANTIES ABOUT THE QUALITY OF THE SERVICES
            PROVIDED BY ANY USER. YOU UNDERSTAND AND AGREE THAT VEDICHOM IS NOT
            RESPONSIBLE FOR THE PERFORMANCE OR CONDUCT, WHETHER ON OR OFF THE
            SITE, OF ANY USER. AS SUCH, VEDICHOM EXPRESSLY DISCLAIMS, AND EACH
            USER EXPRESSLY RELEASES VEDICHOM FROM, ANY AND ALL LIABILITY
            WHATSOEVER FOR ANY CONTROVERSIES, CLAIMS, SUITS, INJURIES AND/OR
            DAMAGES ARISING FROM AND/OR IN ANY WAY RELATED TO THE SITE,
            INCLUDING WITHOUT LIMITATION ANY ACTS AND/OR OMISSIONS OF USERS ON
            OR OFF THE SITE.
          </p>
          <p className="tc-text-info">
            TO THE FULL EXTENT PERMITTED BY LAW, VEDICHOM IS NOT LIABLE FOR ANY
            DIRECT, INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR
            EXEMPLARY DAMAGES (INCLUDING WITHOUT LIMITATION LOSS OF BUSINESS,
            REVENUE, PROFITS, GOODWILL, USE, DATA, ELECTRONICALLY TRANSMITTED
            ORDERS, OR OTHER ECONOMIC ADVANTAGE) ARISING OUT OF OR IN CONNECTION
            WITH THE SITE, EVEN IF VEDICHOM HAS PREVIOUSLY BEEN ADVISED OF, OR
            REASONABLY COULD HAVE FORESEEN, THE POSSIBILITY OF SUCH DAMAGES,
            HOWEVER THEY ARISE, WHETHER IN BREACH OF CONTRACT OR IN TORT
            (INCLUDING NEGLIGENCE), INCLUDING WITHOUT LIMITATION DAMAGES DUE TO:
            (A) THE USE OF OR THE INABILITY TO USE THE SITE; (B) THE COST OR
            PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY
            GOODS, DATA, INFORMATION, OR SERVICES PURCHASED OR OBTAINED, OR
            MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO, THROUGH OR FROM THE
            SITE; (C) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SITE,
            INCLUDING WITHOUT LIMITATION UNAUTHORIZED ACCESS TO OR ALTERATION OF
            TRANSMISSION OR DATA, MALICIOUS OR CRIMINAL BEHAVIOR, OR FALSE OR
            FRAUDULENT TRANSACTIONS; OR (D) CONTENT OR INFORMATION USERS MAY
            DOWNLOAD, USE, MODIFY, OR DISTRIBUTE. THESE LIMITATIONS SHALL APPLY
            NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED
            REMEDY. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
            LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE
            ABOVE LIMITATIONS MAY NOT APPLY TO YOU. IN NO EVENT SHALL VEDICHOM
            TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION,
            WHETHER IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO, NEGLIGENCE)
            OR OTHERWISE, EXCEED (A) THE AMOUNT PAID BY YOU TO VEDICHOM OR A 
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            PARTNER, IF ANY, OR (B) AUD 5000 (WHICHEVER IS LESS).
          </p>
          <p className="tc-text-info">
            YOU AND VEDICHOM AGREE THAT THE WARRANTY DISCLAIMERS AND LIMITATIONS
            OF LIABILITY IN THESE TERMS OF USE ARE MATERIAL, BARGAINED-FOR BASES
            OF THIS AGREEMENT, AND THAT THEY HAVE BEEN TAKEN INTO ACCOUNT IN
            DETERMINING THE CONSIDERATION TO BE GIVEN BY EACH PARTY UNDER THIS
            AGREEMENT AND IN THE DECISION BY EACH PARTY TO ENTER INTO THIS
            AGREEMENT. YOU AND VEDICHOM AGREE THAT THE WARRANTY DISCLAIMERS AND
            LIMITATIONS OF LIABILITY IN THESE TERMS OF USE ARE FAIR AND
            REASONABLE.
          </p>
          <p className="tc-text-info">
            IF YOU ARE DISSATISFIED WITH THE SITE OR DO NOT AGREE TO ANY
            PROVISIONS OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS
            TO DISCONTINUE USING THE SITE, EXCEPT AS MAY BE PROVIDED FOR IN THE
            ILLUSTRATED SECTION OF THIS TERMS AND POLICY.
          </p>
        </div>
        <div>
          <div className="tc-topic-text">16. Assumption of risk</div>
          <p className="tc-text-info">
            {" "}
            You agree and understand that you assume all risks when using the
            Site, including without limitation any and all of the risks
            associated with any online or offline interactions with other Users.
            You agree to take all necessary precautions, including without
            limitation following the recommendations set forth in{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            ’s Source and Seeker Safety Tips, when interacting with other users.
          </p>
        </div>
        <div>
          <div className="tc-topic-text">17. Indemnification</div>
          <p className="tc-text-info">
            You agree to indemnify, defend, and hold harmless{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            and its subsidiaries, affiliates, shareholders, officers, directors,
            agents, licensors, suppliers, other partners, employees, and
            representatives from and against any and all claims, demands, causes
            of action, losses, expenses, damages and/or liabilities, including
            reasonable lawyer's fees and court costs, incurred by{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            in any way related to your (a) acts and/or omissions on or off the
            site; (b) violation of any rights of another, including without
            limitation any alleged infringement of intellectual property or
            other right of any person or entity relating to the Site; (c) breach
            of these terms of use; (d) disputes with or between other Users; (e)
            use and/or misuse of the site, including without limitation any
            information, content and/or materials thereon; (f) violation of any
            applicable law or regulation; (g) inaccurate, untimely, incomplete
            or misleading User information, including without limitation with
            respect to registration, profile or eligibility; (h) misstatements
            and/or misrepresentations; (i) use of links to third party websites,
            including without limitation such websites' availability, terms of
            use, privacy policy, information, content, materials, advertising,
            products and/or services; (j) User information and any acts or
            omissions with respect to such User information; (k) use of any
            information in third-party reports; (l) use of third party payment
            processing services; (m) use of phone support services; and/or (n)
            use of any services or products or any contracts or arrangements
            made or provided based on information, content and/or materials
            obtained on or through the site. You further agree that you will
            cooperate as requested by{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            in the defense of such claims.
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            reserves the right, at its own expense, to assume the exclusive
            defense and control of any matter otherwise subject to
            indemnification by Users, and you shall not, in any event, settle
            any claim or matter on behalf of{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            without the written consent of{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            .
          </p>
        </div>
        <div>
          <div className="tc-topic-text">18. Injunction</div>
          <p className="tc-text-info">
            {" "}
            You agree that any material breach of the Terms will result in
            irreparable harm to{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            for which damages would be an inadequate remedy and, therefore, in
            addition to its rights and remedies otherwise available at law,{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            will be entitled to equitable relief, including both a preliminary
            and permanent injunction, if such a breach occurs. You waive any
            requirement for the posting of a bond or other security if{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            seeks such an injunction.
          </p>
        </div>
        <div>
          <div className="tc-topic-text">
            19. Notices; modification and termination of services; amendment of
            terms
          </div>
          <p className="tc-text-info">
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            may provide notice to Users via email, regular mail, or posting
            notices or links to notices on the Site.{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            reserves the right at any time to modify, suspend or terminate the
            services (or any part thereof), and/or use of or access to them,
            with or without notice.
          </p>
          <p className="tc-text-info">
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            may also delete, or bar access to or use of, all related information
            and files.{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            will not be liable to Users or any third party for any modification,
            suspension, or termination of service, or loss of related
            information.{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            may amend these Terms at any time by posting the amended terms on
            this Site.
          </p>
        </div>
        <div>
          <div className="tc-topic-text">20. Entire agreement</div>
          <p className="tc-text-info">
            These Terms constitute the entire agreement between you and{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>
            relating to their subject matter and cancel and supersede any prior
            versions of the Terms. You may not assign or otherwise transfer the
            Terms, or any right granted hereunder. You also may be subject to
            additional terms and conditions that may apply when you use{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>
            or third-party products or services.{" "}
          </p>
        </div>
        <div>
          <div className="tc-topic-text">FOR SOURCES</div>
          <p className="tc-text-info">
            1. A Source warrants that he/she is, at least, eighteen years of
            age, and has the lawfully necessary educational qualifications
            and/or experience to provide knowledge, coaching, mentoring, tuition
            or services in the subject/s specified by the Source on the Site.{" "}
          </p>
          <p className="tc-text-info">
            2. A Source agrees and understands that he/she is not an employee of
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            and accepts full responsibility for all Income Tax and other amounts
            payable in relation to any fees generated from a service contract
            with Seekers through the Site. We do not issue any working
            experience certificates to our registered members.
          </p>
          <p className="tc-text-info">
            3. A Source understands and accepts that details entered by them on
            the Site will be publicly visible and may appear in search engines
            (Google, Yahoo, Bing etc.) results.
          </p>
          <p className="tc-text-info">
            4. A Source accepts full responsibility for all information
            published by him/her or it on the Site and shall indemnify{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>
            in relation to any liability incurred by them as a result of any
            such information.
          </p>
          <p className="tc-text-info">
            5. A Source undertakes to register for the{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            service, always providing and using accurate and current information
            about him/her - including name, address, telephone number/s,
            qualifications, tutoring experiences and any other details requested
            by{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            .
          </p>
          <p className="tc-text-info">
            6. A Source will not include personal email, contact numbers etc. in
            the form of fields like Tutoring Experience, Teaching Style, or
            other topics. Adding contact details except in specific fields is
            not acceptable under any condition. If Source violates this
            condition,{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            may in its discretion suspend or terminate the account of such Tutor
            or user.
          </p>
          <p className="tc-text-info">
            7. Sources are bound to show proof or disclose their qualification
            details to the reasonable satisfaction of the seekers or parent
            party before entering into a tuition engagement and also if and as
            requested by them, later.
          </p>
          <p className="tc-text-info">
            8.{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            does not guarantee to secure a Source for a Seeker or ascertain any
            service fees on behalf of any Source or Seeker. We do not provide
            any guarantee of the number of total enquiries/business which a
            Source will receive during their subscription period.
          </p>
          <p className="tc-text-info">
            9.{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            is not responsible if you are unable to convert services enquiries
            into work, through connects facility. Please check the total number
            of views before you apply for any services. The more the number of
            views, the lesser the chances of getting responses from seekers.
            Connect facility generates maximum effectiveness if you respond to
            the enquiry on the day it has been posted. However, we would not be
            accountable for any inconvenience caused.
          </p>
          <p className="tc-text-info">
            10.{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            is not responsible if a seeker’s mobile is switched off when you
            call, or if seekers do not respond to your queries
          </p>
          <p className="tc-text-info">
            11. All services posted on our site are verified to the best of our
            knowledge. If the seeker does not disclose any information
            beforehand,{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            will not be responsible for the same.
          </p>
          <p className="tc-text-info">
            12. A seeker will accept full responsibility for the hourly rates or
            prices quoted by them for their services and presented in exact
            detail on the Site.
          </p>
          <p className="tc-text-info">
            13. We encourage all Sources to accept online payment from seekers
            to maintain transparency between DSourc and Seeker.{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            offers an online platform or payment gateway for Sources and Seekers
            to pay for their platform services to keep their subscriptions
            active. This system is secure and provides transparency in financial
            transactions.
          </p>
          <p className="tc-text-info">
            14.{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            charges only 15% of the fees collected from the Seeker as per your
            quoted price or hourly rates. This commission is valid only if the
            payment has been using the{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            payment system. We do not charge any commission if a seeker pays the
            source directly.
          </p>
          <p className="tc-text-info">
            15. The money will be added to your account after the successful
            completion of the tuition sessions, following the satisfaction of
            the seeker and his/her parent/s.
          </p>
          <p className="tc-text-info">
            16. The Seeker will release money only after receiving a request for
            the same from the Source.
          </p>
          <p className="tc-text-info">
            17. A Seeker can always cancel any booking, but only after the first
            session. It is the Source's responsibility to impress and convince
            the seeker party with his/her teaching abilities and methods.
          </p>
          <p className="tc-text-info">
            18. Sources should not start any tutoring session unless the seeker
            agrees to it and funds the session fees through the{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            payment system
          </p>
          <p className="tc-text-info">
            19. In case of cancellation only after the first session, Sources
            will not get any payment even if the sessions have been funded
            beforehand by the students.
          </p>
          <p className="tc-text-info">
            20.{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            will not be responsible for making any kind of payment to Sources if
            the Seekers have not funded the tuition sessions in advance.
          </p>
          <p className="tc-text-info">
            21. Both Seeker and Source can ask to verify each other’s addresses
            and residential details before making any direct payments between
            themselves.{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            will not take any responsibility for any sort of direct payments
            between the two parties.
          </p>
          <p className="tc-text-info">
            22. Source shall indemnify{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            from all claims, liabilities, costs and expenses (actual or
            consequential) of every kind and nature known and unknown, suspected
            and unsuspected, disclosed and undisclosed arising out of any use by
            the Source of the Site.
          </p>
          <p className="tc-text-info">
            23. A source and their centre hereby acknowledge that{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            is not liable to the Source for reimbursement of any fees decided
            upon as a result of these Terms in the event of any cancellation of
            any Contract or Services for any reason.
          </p>
          <p className="tc-text-info">
            24. In responding to feedback of Seeker posted on the Site a Source
            shall not, in any way, show disrespect towards a Seeker or his
            family.
          </p>
          <p className="tc-text-info">
            25. Sources are advised to insist on being paid by Seekers or their
            parents through the totally safe and sure Payment Protection.
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            shall not be responsible for any other kind of monetary
            transaction/s between sources and seekers or their parents.
          </p>
          <p className="tc-text-info">
            26. We are not involved in any kind of interactions that take place
            between the sources and the seekers and hence are not liable for any
            dispute or disagreement or misinterpretations or arguments arising
            between the Seekers and the Sources. The Seekers and the Sources are
            however free to take feedback and suggestions from us to solve the
            issue amicably.
          </p>
          <p className="tc-text-info">
            27. For all commitments/representations of Sourcs to Seeker other
            than those which are mentioned on Site Sources alone would be liable
            and responsible towards Seekers, and shall also keep us indemnified
            and harmless against all such claims/disputes.
          </p>
          <p className="tc-text-info">
            28. Source is expected to conduct himself in a teacher/coach-like
            manner which is generally prevalent and acceptable in a teacher
            (Source) and student (Seeker) like relationship.
          </p>
          <p className="tc-text-info">
            29. All student information and contact details provided on{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>
            are for your personal use only and not be used for any other
            purposes in which you are not directly involved. You cannot sell or
            forward any seeker data to other sources. If any Sourcs is found to
            be engaged in such activities,{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            reserves the right to terminate their membership with immediate
            effect without refunding any amount if paid.
          </p>
        </div>
        <div>
          <div className="tc-topic-text">FOR SEEKERS</div>
          <p className="tc-text-info">
            {" "}
            1. A Seeker undertakes to register for the{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            service, always using accurate and current information about
            himself/herself - including name, email ID, mobile number etc.
          </p>
          <p className="tc-text-info">
            2. During posting or service requirements seekers should enter as
            many details as possible for better responses from Sources and their
            Centres.
          </p>
          <p className="tc-text-info">
            3. Please note that any service requirement posted will be shared
            with our registered and premium list of Sources and their centres
            and they may call your number to discuss about your service
            requirement. Seekers should check tuition profile privacy settings
            properly to avoid unwanted calls on their mobiles from prospective
            sources and their centres.
          </p>
          <p className="tc-text-info">
            4. Seekers must pay a Source through the{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            payment system only, to avoid any kind of misunderstanding and other
            issues with sources in the latter stages. Payments with{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            are safe and will not be released to the source without your
            permission.
          </p>
          <p className="tc-text-info">
            5. Seekers need to book any and minimum of one service session and
            any booking can be cancelled after the first session only. No
            cancellation will be accepted from the 2nd session onwards and full
            payment will be released to the source after the successful
            completion of all sessions, to the satisfaction of the Seekers.
          </p>
          <p className="tc-text-info">
            6.{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            does not support or encourage any direct payment to any Source as
            that could lead to potential fraud and we do not take any
            responsibility for such payments or tutors.
          </p>
          <p className="tc-text-info">
            7. Both seeker and source can ask to verify each other's addresses
            and residential details before making any direct payments between
            themselves.
          </p>
          <p className="tc-text-info">
            8. Seekers are requested not to make a huge advance payment if you
            do not know the source properly or do not have complete proof of
            his/ her current/permanent contact details.
          </p>
          <p className="tc-text-info">
            9.{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            takes responsibility for payment handling only (if funded using the{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            payment system) and not for any source’s social or other behaviour.{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            does not guarantee the tutoring quality and style of a tutor. Seeker
            acknowledges that it is his/her responsibility to verify the
            credentials and qualifications of any source with whom s/he enters
            or proposes to enter into a service contract.
          </p>
          <p className="tc-text-info">
            10. Seekers should understand that Sources are not our employees and
            we cannot take responsibility for verifying their backgrounds and
            behaviors.{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            is just an online marketplace where Sources and their centres can
            advertise their services.
          </p>
          <p className="tc-text-info">
            11. Seekers are advised to insist on paying the Source/s through the
            totally safe and sure Payment Protection Service of{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            We shall not take responsibility for any other kind of monetary
            transaction/s between Seeker and Source.
          </p>
          <p className="tc-text-info">
            12. Homework and Assignment help is intended to provide you with
            guidance on understanding your assignment and not to replace your
            academic efforts designed to be part of your curriculum. Help is
            provided on the understanding and agreement that you will use the
            information provided by the Sources for learning purposes only and
            that you will not submit this information as original seeker work
            for course credit or grade.
          </p>
          <p className="tc-text-info">
            13. Seeker should use caution, good judgment and proper language
            when leaving a Review for a Source. What you post will be a
            permanent part of that Source record. Please restrict your review to
            a Source’s teaching-related skills (communication skills, knowledge,
            patience, clarity etc.)
          </p>
          <p className="tc-text-info">
            14. We are not involved in any kind of interactions that take place
            between the sources and the seekers and hence are not liable for any
            dispute or disagreement or misinterpretations or arguments arising
            between the sources and the seekers. The Seeker and the Sources are
            however free to take feedback and suggestions from us to solve the
            issue amicably.
          </p>
          <p className="tc-text-info">
            15. Seeker is expected to conduct himself in a Student-like manner
            which is generally expected and regarded as ideal Seeker behaviour
            prevalent in the education stream.
          </p>
          <p className="tc-text-info">
            16.{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            would not be liable to offer any compensation or damages of any
            nature to Seeker if{" "}
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            deactivates a Source account or if Source discontinues /chooses to
            not extend service sessions.
          </p>
          <p className="tc-text-info">
            17. Seekers should take enough care and make an intuitive decision
            before taking up an assignment to avoid any kind of repercussions.
            <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}
            would not be responsible and liable for any such unforeseen and
            unfortunate event including but not limited to bodily injury, sexual
            or mental harassment of any nature, if any.
          </p>
        </div>
        </>
)