import React from 'react'

interface props {
    price: string|number
    title: string
}
const CustomModeOfClassCard = (prop: props) => {
    return (
        <>
            <div className="price-feature-box border-0  my-1  ad-card-border ad-card-bgColor cursor-pointer">
                <div className="feature-title">
                    {prop.title}
                </div>
                <div className="d-flex justify-content-between align-items-center feature-details">
                    <p>Hourly Fee:</p>
                    <p>
                        <strong>
                            {`₹ ${prop.price}`}
                        </strong>
                    </p>
                </div>
            </div></>
    )
}

export default CustomModeOfClassCard