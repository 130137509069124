import * as React from "react"

import locationIcon from "../../assets/img/location.svg"
import findSearch from "../../assets/img/clarity_search-line.svg"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { Form } from "react-bootstrap"
import { useAppDispatch } from "../../app/store"
import { useSearchAdvtsMutation } from "../../features/featSearchAdvts/SearchAdvtsApi"
import {
  setIsSelectedLocation,
  setSearchText,
  setSearchType,
  setLocation,
  setSelectedSearchText,
  locationState,
} from "../../features/featSearchAdvts/SearchAdvtSlice"
import { useAppSelector } from "../../app/hooks"
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { setSeekerLandingPageSearchData } from "../../features/featSeekerDashboard/seekerDashboardSlice"
import { topNavSearchBarSchema } from "../../utils/yupSchema/Schema"
import { useEffect } from "react"

interface searchProps {}

const TopNavSearchBar = (props: searchProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [isScrolled, setIsScrolled] = React.useState(false)
  const [ifPathAllowed, setifPathAllowed] = React.useState(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchBar, setSearchBar] = React.useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const [search, { data: searchTextResponse, error, isSuccess }] =
    useSearchAdvtsMutation()
  const {
    searchType,
    searchText,
    selectedSearchText,
    isSelectedLocation,
    selectedLocation,
  } = useAppSelector(locationState)

  const handleSearchText = (text: string) => {
    dispatch(setSearchText(text))
    /*  Unneccesary api hits
    if (!selectedSearchText) {
      search({ searchText: text })
    } */
  }

  const onSubmit = (details: any) => {

    const location: string | undefined = selectedLocation && selectedLocation !== 'Online' ? searchParams.get('aroundMe') || '' : undefined;
      const payload: Record<string, string> = {
      vedicSubject: selectedSearchText || searchText || "",
      ...(location && { aroundMe: location }) 
    };
  
    navigate(
      {
        pathname: "/results",
        search: `?${createSearchParams({ ...payload, pageNo: "1" })}`,
      },
      {
        replace: true,
      }
    );
  };

  const { handleSubmit, setValue, register, control, reset } = useForm<any>({
    resolver: topNavSearchBarSchema,
  })

  const [searchbarShow, setSearchBarShow] = React.useState<boolean>(false)
  useEffect(()=>{
    setifPathAllowed(location?.pathname === "/seeker-dashboard" || location?.pathname === "/seeker-dashboard/")
    setSearchBarShow(location?.pathname === "/" || location?.pathname === "/results" || location?.pathname === "/seeker-dashboard" ||
      location?.pathname === "/explore-more" || location?.pathname === "/seeker-dashboard/")

  },[location.pathname])


  const handleChange = (newAddress: any) => {
    dispatch(setLocation(newAddress))
  }

  // useEffect(() => {
  //   if (selectedSearchText) {
  //     ;(inputRef as any).current.focus()
  //   }
  // }, [selectedSearchText])

  /*  Unneccesary api hits
  // useEffect(() => {
  //   if (searchText) search({ searchText: searchText })
  // }, [searchText])
  */
  // useEffect(() => {
  //   if (searchAdvtsSuccess || searchAdvtsError) {
  //     navigate("/results", { state: { error: searchAdvtsError, data } })
  //     dispatch(setSearchText(""))
  //     dispatch(setSelectedSearchText(""))
  //     dispatch(setLocation(""))
  //   }
  // }, [searchAdvtsSuccess, searchAdvtsError])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])
  return (
    <>
      <form
        className={` ${searchbarShow ? "d-flex justify-content-center": 'd-none'}`}
        onSubmit={handleSubmit(onSubmit)}
      >
        {
        <div
          className={(isScrolled || searchBar || selectedSearchText || ifPathAllowed)
            ? "filter-section top-search-field  w-100 active"
            : "d-none"
          }
          onClick={() => setSearchBar(true)}
        >
          <div className="find-vedic position-relative d-flex align-items-center w-100">
              <Form.Control
              type="text"
              autoComplete="off"
              {...register("VedicSubject")}
              name="VedicSubject"
              placeholder="Find Your Vedic Expert"
              className="form-control find-field pe-2"
              value={selectedSearchText || searchText}
              onChange={(e: any) => handleSearchText(e.target.value)}
            />
          </div>

          {/* <div className={"find-location align-items-center w-100"}>
            <img src={locationIcon} className="location-icon" />
            {selectedLocation === "Online" ||
            selectedLocation === "Around me" ? (
              <input
                //   {...register("Location")}
                ref={inputRef}
                value={selectedLocation}
                onChange={() => dispatch(setLocation(""))}
              />
            ) : (
              <PlacesAutocomplete
                onChange={handleChange}
                onSelect={handleSelect}
                value={selectedLocation}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }: any) => (
                  <div>
                    <input
                      // value={selectedLocation}
                      {...register("Location")}
                      ref={inputRef}
                      {...getInputProps({
                        placeholder: "Search Places",
                        className: "form-control find-field pl-2",
                        // onClick: handleInputClick,
                      })}
                      style={{border: "none", outline: "none"}}
                      />
                  </div>
                )}
              </PlacesAutocomplete>
            )}
            {selectedSearchText && !selectedLocation ? (
              <div className={`search-top-town`}>
                <p
                  className="tab text town locate choice-locate"
                  onClick={() => {
                    dispatch(setLocation("Around me"))
                    dispatch(setIsSelectedLocation(false))

                    // setHoverMouse(false)
                  }}
                >
                  Around me
                </p>
                <p
                  className="tab text town choice-webcam"
                  onClick={() => {
                    dispatch(setLocation("Online"))
                    dispatch(setIsSelectedLocation(false))

                    // setHoverMouse(false)
                  }}
                >
                  Online
                </p>
              </div>
            ) : (
              <></>
            )}
          </div> */}
          <div className="flex-none dropdown_loc">
            <button
              className="find-btn d-flex align-items-center justify-content-center"
              //   onClick={() =>
              //     onSubmit({
              //       Location: selectedLocation,
              //       VedicSubject: selectedSearchText || searchText,
              //       teacherType: searchType,
              //     })
              //   }
            >
              <img src={findSearch}  />
              {/* <span className="d-none d-md-block ">Find</span> */}
            </button>
          </div>
        </div>}
      </form>
    </>
  )
}

export default TopNavSearchBar
