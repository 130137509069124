import { motion } from 'framer-motion'

const ExploreSkeletonCard = ({keyValue}: any) => {
  return (
    <>
   <motion.div 
   key={keyValue}
  className="card-skeleton mt-2"
  initial={{ opacity: 0.4 }}
  animate={{ opacity: 1 }}
  exit={{ opacity: 0}}
  transition={{ duration: 0.1, ease: "easeInOut" }}
>
  <div className=" cursor-pointer h-100">
    <div className="fav-card guide-card">
      <div className='skelton-img'><img src="" alt="" /></div>
      <div className="px-4 py-3 skelton-body">
        <div className="skelton-card-name"></div>
        <div className="d-flex justify-content-between  skelton-card-desc">
          <motion.p className="ast-ocp mb-0 mh-2"></motion.p>
        </div>
        <div className="text-end mb-3">
         
          <div>
            <motion.span className="badge-amb skelton-badge">{"         "}</motion.span>
          </div>
        </div>
      </div>
    </div>
  </div>
</motion.div>

    </>
  )
}

export default ExploreSkeletonCard