import React from "react";
import ErrorPage from "../components/ErrorPage";

interface ErrorBoundaryProps {
  fallback?: React.ReactNode; // Fallback can be optional, or you can use default component
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage: string | null;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    hasError: false,
    errorMessage: null
  };

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render shows the fallback UI.
    return { hasError: true, errorMessage: error.message };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // You can log the error to an error reporting service here
    console.error('ErrorBoundary caught an error', error, errorInfo);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      // Pass the errorMessage as a prop to ErrorPage or fallback component
      if (this.props.fallback) {
        return React.cloneElement(this.props.fallback as React.ReactElement, { errorMessage: this.state.errorMessage });
      }
      // If no custom fallback is passed, default to ErrorPage
      return <ErrorPage buttonText="Go Back" navigation="/" />;
    }

    // Render the children components
    return this.props.children;
  }
}

export default ErrorBoundary;
