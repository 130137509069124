import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { IoIosShareAlt } from 'react-icons/io'
import { MdOutlineReport } from 'react-icons/md'
import { useAppDispatch } from '../../app/store'
import { MODALNAME } from '../../constants'
import { setModal } from '../../features/Auth/ModalHandler/modalSlice'
import { useSearchParams } from 'react-router-dom'

interface propType {
    id: string
}
const CardDropdoenOptions = ({ id }: propType) => {
    const dispatch = useAppDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const handleShow = (id: string) => {
        dispatch(setModal({ name: MODALNAME.SHARE_MODAL, data: { adId: id } }))
    }
    function handleReportAd(id: string) {
        searchParams.set("modal", MODALNAME.REPORT_AD)
        searchParams.set("adId", id)
        setSearchParams(() => {
            return searchParams
        })
    }
    return (
        <>
            <Dropdown>
                <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="button1"
                >
                    <BsThreeDotsVertical color="#636363" size={20} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item
                        onClickCapture={() => { handleReportAd(id) }}
                        className="icon3"
                    >
                        <MdOutlineReport size={20} />
                        Report Ad
                    </Dropdown.Item>
                    <Dropdown.Item
                        className="icon3"
                        onClick={() => handleShow(id)}
                    >
                        <IoIosShareAlt size={20} />
                        Share Ad
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default CardDropdoenOptions