import * as React from "react"
import { InfinitySpin, ThreeDots } from "react-loader-spinner"
interface spinnerProps {
  className?: string
}

const SpinnerMain = ({ className }: spinnerProps) => {
  return (
    <>
      {/* <ThreeDots
        visible={true}
        height="40"
        width="40"
        color="#FB6D48"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass={
          className
            ? className
            : "d-flex justify-content-center align-items-center landing-spinner"
        }
      /> */}
      <div className={`${className ? className : "d-flex justify-content-center align-items-center landing-spinner"}`}>
      <InfinitySpin color="#FB6D48" />
      </div>
    </>
  )
}

export default SpinnerMain
