import React, { useState, useRef, useEffect } from "react"
import "./NewDashnav.css"
import { BiCaretLeft, BiUser, BiCalendar } from "react-icons/bi"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { motion } from "framer-motion"
import dashboardicon from "../../../assets/img/dashboard-icon.svg"
import dashboardicongreen from "../../../assets/img/dashboard-icon-green.svg"
import charticon from "../../../assets/img/chart.svg"
import charticongreen from "../../../assets/img/chart-green.svg"
import requesticon from "../../../assets/img/request-icon.svg"
import requesticongreen from "../../../assets/img/request-icon-green.svg"
import evalutionicon from "../../../assets/img/stariconwhite.svg"
import evalutionicongreen from "../../../assets/img/evolution-green.svg"
import settingicon from "../../../assets/img/settingicon.svg"
import settingicongreen from "../../../assets/img/settingicongreen.svg"
import headerlogo from "../../../assets/img/logo.png"
import headerlogoLg from "../../../assets/img/vedic-logo-large.svg"
import { isLaunched, menuItem, MODALNAME, USERTYPE } from "../../../constants"
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import lockImg from "/src/assets/img/lock-icon.png"
import { GoDotFill } from "react-icons/go"

const menuItems = [
  {
    id: menuItem.root,
    icon: <img src={dashboardicon} className="icon" />,
    text: "Dashboard",
    to: "",
  },
  {
    id: menuItem.myAds,
    icon: <img src={charticon} className="icon" />,
    text: "My ads",
    to: "my-advt",
  },
  {
    id: menuItem.myRequests,
    icon: <img src={requesticon} className="icon" />,
    text: "Requests",
    to: "my-requests",
  },
  {
    id: menuItem.reviews,
    icon: <img src={evalutionicon} className="icon" />,
    text: "Reviews",
    to: "my-evaluations",
  },
  {
    id: menuItem.myAccount,
    icon: <img src={settingicon} className="icon" />,
    text: "My Account",
    to: "my-account",
  },
]

interface props {
  userType: USERTYPE
  sidebarActive: boolean
  setSidebarActive: any
}

const NewDashnav: React.FC<props> = ({
  userType,
  sidebarActive,
  setSidebarActive,
}: props) => {
  const [activeMenuItem, setActiveMenuItem] = useState<number | null>(null)
  const sidebarRef = useRef<HTMLDivElement>(null)
  const menuItemsRef = useRef<Array<HTMLDivElement | null>>([])
  const [hoverTime, setHoverTime] = useState<NodeJS.Timeout | null>(null)
  const navigate = useNavigate()
  const location = useLocation()
  const splitPath = location.pathname.split("/")?.at(2)
  const { isTermsConditionAccepted } = useAppSelector((state: RootState) => state.auth)
  const toggleSidebar = () => {
    setSidebarActive(!sidebarActive)
  }
  const {modalName} = useAppSelector(state=> state.authModal)
  const { unreadPendingRequests, unreadLiveRequests } = useAppSelector((state) => state.ChatSlice,)

  const handleMenuItemClick = (index: number) => {
    setActiveMenuItem(activeMenuItem === index ? null : index)
  }

  useEffect(() => {
    if (sidebarRef.current) {
      sidebarRef.current.classList.toggle("active", sidebarActive)
    }
  }, [sidebarActive])

  function handleNavigate(to: string) {
    const path = `${
      userType === USERTYPE.SEEKER ? "/seeker-dashboard" : "/source-dashboard"
    }/${to}`
    setSidebarActive(true)
    navigate(path)
  }
  const handleMouseEnter = () => {
    setHoverTime(
      setTimeout(() => {
        setSidebarActive(false)
      }, 500),
    )
  }

  const handleMouseLeave = () => {
    if (hoverTime) {
      clearTimeout(hoverTime)
      setHoverTime(null)
    }
    setSidebarActive(true)
  }
  useEffect(() => {
    // Clean up timeout on component unmount
    return () => {
      if (hoverTime) {
        clearTimeout(hoverTime)
      }
    }
  }, [hoverTime])

  return (
    <>
      {!sidebarActive && (
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.15 }}
          className="overlaay_2"
          onClick={toggleSidebar}
        />
      )}
      <div className={(modalName === MODALNAME.TERMS_MODAL || modalName == MODALNAME.REFFERAL_ENTRY || modalName == MODALNAME.VERIFY_EMAIL || !isTermsConditionAccepted)?'d-none':"container-fluid px-0"}>
        <div
          className={`sidebar ${sidebarActive ? "active" : ""}`}
          ref={sidebarRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="dash-logo-container rounded-3 p-1"
            onClick={() => {
              userType === USERTYPE.SEEKER
                ? navigate("/results", { replace: true })
                : userType === USERTYPE.PROVIDER
                ? navigate("/source-dashboard")
                : navigate("/")
            }}
          >
            {!sidebarActive ? (
              <img
                src={headerlogoLg}
                alt="Logo"
                className="cursor-pointer img-fluid dash-logo-lg"
                title="Home"
              />
            ) : (
              <img
                src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/Frame%201171276360.png"
                alt="Logo"
                className="cursor-pointer img-fluid dash-logo"
                title="Home"
              />
            )}
          </div>
          <div
            className={`menu-btn ${sidebarActive ? "rotated" : ""}`}
            onClick={toggleSidebar}
          >
            <BiCaretLeft />
          </div>
          <div className="nav">
            <div className="menu w-100">
              {menuItems.map((item, index) => {
                if (item.id == menuItem.myAds && userType === USERTYPE.SEEKER)
                  return
                return (
                  <div
                    id={item.id}
                    key={index}
                    ref={(el) => (menuItemsRef.current[index] = el)}
                    onClick={() => handleMenuItemClick(index)}
                    className={`menu-item ${
                      splitPath == item.to ? "active" : ""
                    }`}
                  >
                    <a
                      className="Link user-select-none cursor-pointer"
                      onClick={() => handleNavigate(item.to)}
                    >
                      {item.icon}
                      <span className="text user-select-none cursor-pointer">
                        {item.text}
                      </span>
                      {
                      !(item.text.includes('My ads') || item.text.includes('My Account'))  ?
                      // <span className="chat-req-dot"></span>
                      (!isLaunched && <img className="chat-req-lock" src={lockImg} alt="" />)
                       :
                       <></>
                    }

                      { (item.id === menuItem.myRequests && (unreadPendingRequests > 0 || unreadLiveRequests>0) ) &&
                      <span className="chat-req-dot"><GoDotFill /></span>}
                    </a>
                   
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewDashnav
