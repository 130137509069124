import React from "react"
import styles from "./ChatUi.module.css"
import ChatErrorImg from "../../assets/img/chat-error-img.png"
import { Button } from "react-bootstrap"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAcceptDeleteRequestMutation } from "../../features/featProviderDashboard/FeatProviderApi"
import { useAppSelector } from "../../app/hooks"
import { ProviderToken, selectCurrentUser } from "../../features/Auth/authSlice"
import { toast } from "react-toastify"
import { useUserData } from "../../utils/commonFunctions/CommonFunctions"
import { USERTYPE } from "../../constants"

function UnlockPremiumChat() {
  const navigate = useNavigate()
  const { seekerToken, providerToken, user } = useAppSelector(selectCurrentUser)
  const [searchParams, setSearchParams] = useSearchParams()
  const [acceptRequest, { data: reqData, isSuccess: success }] =
    useAcceptDeleteRequestMutation()
  const handleAcceptRequest = () => {
    acceptRequest({
      requestId: searchParams.get("requestId"),
      action: "Accept",
    })
      .unwrap()
      .then((res: any) => {
        navigate(
          `/${
            seekerToken ? "seeker" : "source"
          }-dashboard/my-requests?requestStatus=Accept&requestId=${searchParams.get(
            "requestId",
          )}`,
        )
      })
      .catch((error: any) => {
        toast.error(error?.data?.message)
      })
  }
  return (
    <>
    <div className={`d-lg-block gap-2 d-flex flex-column align-items-center justify-content-center text-center ${styles["chat-btn-box"]} ${styles["connect"]} `}>
      {(user?.isPremium && user?.subscriptionDetails[0].accLimit > 0) ? 
        <>
        <p>{`Hello ${user?.firstName}`}</p>
        <span>Let's connect with new people</span>
        </>
      :
        <>
        <p>
          To unlock chat <span>Buy Premium </span>
        </p>
        <div className=" gap-5 d-flex align-items-center justify-content-center ">
          <Button
            variant="outline-warning w-100"
            className={` ${styles["premium-btn"]} `}
            onClick={() => {
              navigate(
                `/${seekerToken ? "payment-sub" : "payment_pro"}/${
                  searchParams.get("requestId") || null
                }?planType=Monthly&price=199&period=Month&existing=true`,
              )
            }}
          >
            Buy Premium Now
          </Button>
          {searchParams.get("requestId") && providerToken && (
            <Button
              variant="outline-success"
              className={` ${styles["premium-btn"]} `}

              onClick={() => handleAcceptRequest()}
              disabled={user?.subscriptionDetails[0]?.accLimit === 0}
            >
              {
                (user?.subscriptionDetails[0]?.accLimit > 0) ? `${user?.subscriptionDetails[0]?.accLimit} Free Unlock`
                : 'No Credits Left'
              }
            </Button>
          )}
        </div>
        </>
      }</div>
    </>
  )
}

export default UnlockPremiumChat
