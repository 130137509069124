import React, { useState } from 'react';
import styles from './UtilityNotification.module.css';
import { Button, Col, Row } from 'react-bootstrap';
import { RxCross2 } from 'react-icons/rx';

interface UtilityNotifiationProps {
    message: string;
    show: boolean;
    type?: 'info' | 'warning' | 'error' | 'success';
    actionText?: string;
    onAction?: () => void;
    onClose?: () => void;
}

const UtilityNotifiation: React.FC<UtilityNotifiationProps> = ({ message, show, type = 'info', actionText, onAction, onClose }) => {
    const [isOpen, setIsOpen] = useState<boolean>(true);
    
    function handleOnClose() {
        setIsOpen(false);
        if (onClose) onClose();
    }

    return (
        <>
            {(show && isOpen) && (
                <div className={styles.overlay}>
                    <div className={`${styles.notification} ${styles[type]}`}>
                        <Button onClick={handleOnClose} className={styles.closeButton}>
                            <RxCross2 color='#000' size={22} />
                        </Button>
                        <Row className='w-100'>
                            <div className={`${styles.UtilityNotifiationCard}`}>
                                <div className='mb-3'>
                                    <h4>New update</h4>
                                    <div className={`${styles.UtilitymsgText}`}>{message}</div>
                                </div>
                                <Row className='justify-content-end align-items-end'>
                                    <Col lg={2} xs={12} className='d-flex justify-content-end'>
                                        {actionText && onAction && (
                                            <Button onClick={() => { setIsOpen(false); onAction(); }} className={styles.actionButton}>
                                                {actionText}
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        </Row>
                    </div>
                </div>
            )}
        </>
    );
};

export default UtilityNotifiation;
