import { Container, Row, Spinner } from "react-bootstrap"
import "./FavouriteScreen.css"
import LandingHeader from "../../navbars/TopNavbar"
import hearticonWhite from "../../../assets/images/heartIconWhite.svg"
import { useGetFavouritesQuery } from "../../../features/featSeekerDashboard/seekerDashboardApi"
import nofavImage from "../../../assets/img/nofav.gif"
import ProviderCards from "../../cards/providerCards"
const FavouriteScreen = () => {
  const { data, isLoading, isSuccess, error } = useGetFavouritesQuery()
  const finalData = data?.data
  return (
    <>
      <div className="top-nav">
        <LandingHeader />
      </div>
      <section className="section-padding">
        {finalData?.length < 1 ? (
          <div className="d-flex flex-column justify-content-center align-items-center favHead">
            OOPS No one is in your Favourite list
            <span>
              <img src={nofavImage} alt="nofav" height={100} />
            </span>
          </div>
        ) : (
          <div className="favHead">
            Your Favourites{" "}
            <span className="ms-2">
              <img src={hearticonWhite} alt="showWhiteHeart" />
            </span>
          </div>
        )}

        {finalData?.length >= 1 && (
          <Container className="cards-container">
            <div className="favHead">{isLoading && <Spinner />}</div>
            <Row md={3}>
              <ProviderCards
                data={finalData}
               />
            </Row>
          </Container>
        )}
      </section>
    </>
  )
}

export default FavouriteScreen
