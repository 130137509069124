import React from 'react'
import { Button, Col, Container, Dropdown, Form, Row } from 'react-bootstrap'
import { RxCrossCircled } from 'react-icons/rx'

interface props {
    isSelected: boolean,
    selectedValue: string | null,
    data: any,
    onToggle: any,
    onApply: any,
    clearFilter: any,
}

const CheckboxGroup = (prop: props) => {
  function clearFilter () {
    prop.clearFilter(prop.data.key)
    document.getElementById(prop.data.key)?.click()
  }
  return (
    <>
    {prop.isSelected
    ?
    <button className={`dropdown-menu_show px-2 ${prop.isSelected?'primary-bg text-light':''}`} onClick={()=>{prop.clearFilter(prop.data.key)}}>
    {prop.selectedValue}
    <RxCrossCircled style={{ marginLeft: "5px" }} size={20} />
    </button>
    :<Dropdown key={prop.data.key} id={prop.data.key}>
        <Dropdown.Toggle
        className='dropdown-menu_show '
        variant="secondary">
        {prop.data.label}
        </Dropdown.Toggle>
        <Dropdown.Menu>
        <Container className='m-2 fc-option-text'>
        {Object.entries(prop.data.options).map(([key, value], index)=>(
            <><Col key={key}>
            <Form.Check
            id={key}
            label={(value as any).label}
            checked={(value as any).value}
            onChange={(e)=>{prop.onToggle(prop.data.key,key, e.target.checked)}}
            />
            </Col></>
        ))}
        </Container>
        <Row className="p-0 p-md-3 " >
        <Col md={6}>
        <Button
        variant="link"
        className="filter-cancel ms-2"
        onClick={clearFilter}>
        Clear
        </Button>
        </Col>
        <Col md={6}>
        <Button
        variant="link"
        className="filter-cancel ms-4"
        onClick={()=>prop.onApply(prop.data.key)}>
        Apply
        </Button>
        </Col>
        </Row>
        </Dropdown.Menu>
    </Dropdown>}
    </>
  )
}

export default CheckboxGroup