import { FocusEvent, useEffect, useRef, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
import Select, { components, CSSObjectWithLabel, OptionProps, StylesConfig } from "react-select"
import ProviderStepper from "../mastersStepper/ProviderStepper"
import LandingHeader from "../../../navbars/TopNavbar"
import "./SubjectDetail.css"
import { useGetVedicSubjectQuery } from "../../../../features/Auth/authApi"
import { RootState, useAppDispatch } from "../../../../app/store"
import { useNavigate } from "react-router-dom"

import {
  resetProviderFunel,
  selectVedicSubject,
  setIsStepValid,
  updateFunnel,
} from "../../../../features/ServiceFunnel/FeatProviderFunnel/providerFunnelSlice"
import FormMultiSelect from "../../../form/FormMultiSelect/FormMultiSelect"
import { useForm } from "react-hook-form"
import { useAppSelector } from "../../../../app/hooks"
import thumbs from "../../../../assets/images/thumbs-up 1.png"
import { IoCloseOutline } from "react-icons/io5"
import { AiOutlineInfo } from "react-icons/ai"
import CustomTooltip from "../../../buttons/CustomTooltip"
import FormMultiSelectRef from "../components/Inputfield"
import CreatableSelect from "react-select/creatable"
import { detectDeviceType } from "../../../../utils/commonFunctions/CommonFunctions"
import { DeviceType } from "../../../../constants"
import { toast } from "react-toastify"
const serviceOption = [
  // { value: "Teachers", label: "Teachers" },
  { value: "Source", label: "Source" },
  // { value: "Vedic Gurus", label: "Vedic Gurus" },
  // { value: "Mentors", label: "Mentors" },
  // { value: "Guides", label: "Guides" },
]
const MIN_SUB_LENGTH = 5
const MAX_SUB_LENGTH = 30
const MAX_SKILL_CHARACTERS_LENGTH = 15
const SubjectDetail = () => {
  const [selectedOption, setSelectedOption] = useState({
    service: null,
    subjects: null,
    skill: null,
  })

  interface VedicSubType {
    value: string
    label: string
  }

  type StateType = { value: string; label: string }[]
  const [selectedValue, setSelectedValue] = useState<any>([])
  const [skillSet, setSkillSet] = useState<any>([])
  const [vedicSub, setVedicSub] = useState<VedicSubType | null>(null)
  const [sub, setSub] = useState<any[]>([])
  const [onsearchKey, setOnSearchKey] = useState("")
  const [infoactive, setInfoActive] = useState(false)
  const [selectSkillOptions, setSelectSkillOptions] = useState<any[]>([])
  const [errorMsg, setError] = useState<string | null>(null);
  const inputRef = useRef<HTMLDivElement>(null);


  const handleInfoActive = () => {
    setInfoActive(!infoactive)
  }

  const dispatch = useAppDispatch()
  const { isError, error, data, refetch, isLoading } = useGetVedicSubjectQuery()
  function setAllSkillsAsOptions () {
    const allSubjectData = data?.data?.map((subData: any) => subData.skillSet); 
    if(allSubjectData && allSubjectData.length > 0) {
      const mergedArray = allSubjectData.reduce((acc: any, array: any) => [...acc, ...array], []);
      setSelectSkillOptions(mergedArray.map((skill: any) => ({
        value: skill,
        label: skill,
      })))
    }
  }
  useEffect(() => {
    if (data?.data) setSub(data.data)
}, [data]);

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<any>({
    mode: "onBlur",
  })
  const providerFunnelData = useAppSelector(
    (state: any) => state.providerFunnel.providerFunnelData,
  )

  function resetAll () {
    setSkillSet([])
    setVedicSub(null)
    // setError(null)
    dispatch(updateFunnel({ ...providerFunnelData, vedicSubject: [], skillSet: []}))
  }
  const validateInput = (value: string, type: "keypress" | "input", allowedKeys?: string[]): string | null => {
    const regex = /^[a-zA-Z\s]*$/; // Allow only alphabets and spaces
  
    if (type === "input") {
      if (value.length > MAX_SUB_LENGTH) {
        return `Subject name cannot exceed ${MAX_SUB_LENGTH} characters.`;
      }
      if (!regex.test(value)) {
        return "Only alphabets and spaces are allowed.";
      }
      if (value.startsWith(" ")) {
        return "Subject name cannot start with a white space.";
      }
    }
  
    if (type === "keypress") {
      const specialKeys = allowedKeys || [
        "Enter",
        "Backspace",
        "Delete",
        "ArrowUp",
        "ArrowDown",
        "ArrowLeft",
        "ArrowRight",
        "Tab",
        "CapsLock",
        "Shift",
        "Control",
        "Alt",
        "Escape",
        "Meta",
      ];
      if (!regex.test(value) && !specialKeys.includes(value)) {
        return "Only alphabets and spaces are allowed.";
      }
    }
  
    return null; // No errors
  };
  
  const handleSelectChange = (
    selectedValues: Array<{ value: string; label: string }>,
    setSelectedValue: React.Dispatch<React.SetStateAction<StateType>>,
    updateField: string,
  ) => {
    const payload = selectedValues.map((option) => option.value)
    if(payload?.length> 5) {
      toast.dismiss()
      toast.error(`You can only add upto 5 ${updateField}`)
      return
    }
    setSelectedValue(selectedValues)
    dispatch(updateFunnel({ ...providerFunnelData, aboutClass: '', aboutYou: '', title: '', [updateField]: payload }))
  }

  const searchSkillSetBySubject = (subject: any) => {
    if(subject?.skillSet) setSelectSkillOptions(
      subject.skillSet.map((skill: any) => ({
        value: skill,
        label: skill,
      })))
    else setAllSkillsAsOptions()
  }
  useEffect(() => {
    const serviceOptions =
      Array.isArray(providerFunnelData?.services) &&
      providerFunnelData?.services?.map((serviceVal: any) => ({
        value: serviceVal,
        label: serviceVal,
      }))

    setSelectedValue(serviceOptions)

    if(providerFunnelData?.vedicSubject && providerFunnelData?.vedicSubject?.length > 0) {
      setVedicSub((prev) => {
      return({
      ...prev,
      value: providerFunnelData?.vedicSubject,
      label: providerFunnelData?.vedicSubject,
    })})}
  }, [providerFunnelData])
  useEffect(() => {
    dispatch(setIsStepValid(true))
    if (skillSet.length > 0 && selectedValue.length > 0 && vedicSub) {
      dispatch(setIsStepValid(false))
    }
  }, [skillSet.length, selectedValue.length, vedicSub])

  useEffect(() => {
    const skillSetOption = Array.isArray(providerFunnelData?.skillSet) &&
      providerFunnelData?.skillSet.map((skillSetVal: any) => ({
        value: skillSetVal,
        label: skillSetVal,
      }))
    setSkillSet(skillSetOption)
  }, [])
  useEffect(()=>{
    setAllSkillsAsOptions()
    if(providerFunnelData.vedicSubject && providerFunnelData.vedicSubject?.length > 0 && sub?.length>0 && selectSkillOptions?.length < 1) {
      const subjectData = sub.find(
        (item: any) => item.label === providerFunnelData.vedicSubject,
      )
      if(subjectData?.skillSet && subjectData.skillSet.length > 0) {
        const skillset = subjectData.skillSet.map((skill: any) => ({
        value: skill,
        label: skill,
      }))
      setSelectSkillOptions(skillset)
    }
    }
  },[sub])

  const handleInputChange = (inputValue: string) => {
    const error = validateInput(inputValue, "input");
    setError(error);
    if (!error) {
      setOnSearchKey(inputValue);
    }
  };
  


  const handleCreateOption = (inputValue: string) => {
    // Create a new option and update the state
    const newOption = { label: inputValue.trim(), value: inputValue.trim() };
    setSub([...sub, newOption]);
    dispatch(updateFunnel({vedicSubject: newOption.label}),);
    setVedicSub(newOption as VedicSubType | null);
    searchSkillSetBySubject(newOption)
  };
  
  const customStyles: StylesConfig<VedicSubType, false> = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),

    input: (base) => ({
      ...base,
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? '#FB6D48'
        : state.isFocused
        ? '#fff3f3'
        : base.backgroundColor,
      color: state.isSelected ? '#fff' : base.color,
    }),
  };
  
  
  const instructionOption = {
    label: "Please add your subject if it's not listed",
    value: "instructions",
    isDisabled: true,
  };

  const isValidNewOption = (inputValue: string) => {
    // Trim the input to remove leading or trailing whitespaces
    const trimmedInput = inputValue.trim();

    // Check for alphabets, length <= MAX_LENGTH, and absence in options
    const regex = /^[\p{L}\s]+$/u; // Matches any Unicode letters (including Hindi) and spaces
    const isUnique = !sub.some(
      (option) => option.value.toLowerCase() === trimmedInput.toLocaleLowerCase()
    );

    return (
      regex.test(trimmedInput) &&
      trimmedInput.length <= MAX_SUB_LENGTH &&
      isUnique
    );
  };

  function handleOnBlur(value: string): void {
    const inputValue = value.trim();
    if ( inputValue === '' || !isValidNewOption(inputValue)) {
      setVedicSub(null)
    }
    else {
      const newOption = { label: inputValue, value: inputValue };
      setSub([...sub, newOption]);
      dispatch(updateFunnel({vedicSubject: newOption.label,}),);
      setVedicSub(newOption as VedicSubType | null);
      searchSkillSetBySubject(newOption)
    }
  }

  const handleOnFocus = () => {
    if (inputRef?.current && detectDeviceType() === DeviceType.Mobile) {
      inputRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }
    setOnSearchKey(vedicSub?.value||'');
    setVedicSub(null);
  };

  return (
    <>
      <section className="py-lg-1 funnel-section">
        <Container fluid="lg">
          <Row>
            <Col lg={6}>
              <div className=" d-flex flex-column h-100 justify-content-center pb-5 mb-5 ">
                {/* Saved for future usage 
                <div className="pb-4 mb-4">
                  <p className="get-started-text fw-normal">
                    What You are providing{" "}
                    <span className=" text-danger">*</span>
                  </p>
                  <div className="multi-select-custom">
                    <FormMultiSelect
                      title=""
                      placeholder="Select"
                      control={control}
                      options={serviceOption}
                      onChange={(option) => {
                        handleSelectChange(option, setSelectedValue, "services")
                      }}
                      className="multi-select-bx"
                      name="services"
                      value={selectedValue as any}
                    />
                  </div>
                </div> */}
                <div className="pb-4 mb-4">
                  <p className="get-started-text fw-normal">
                    {"Vedic subject you're considering teaching"}
                    <span className=" text-danger">{"*"}</span>
                  </p>
                  {errorMsg&&<p className=" text-danger">{errorMsg}</p>}
                  <div className="multi-select-custom position-relative" ref={inputRef}>
                    {/* {vedicSub?.value.length == 0 && onsearchKey == "" && (
                      <label className="selectlabel">Select one Subject</label>
                    )} */}
                    <CreatableSelect
                    menuPortalTarget={document.body}
                    placeholder={'Select one Subject'}
                    menuShouldScrollIntoView={false}
                      onInputChange={handleInputChange}
                      onChange={(option) => {
                        dispatch(
                          updateFunnel({
                            vedicSubject: (option as any)?.label,
                          }),
                        );
                        setVedicSub(option as VedicSubType | null);
                        searchSkillSetBySubject(option);
                        setSkillSet([])
                        setError(null)
                        // setOnSearchKey(option?.value)
                      }}
                      //onKeyDown={handleKeyDown}
                      onCreateOption={handleCreateOption} // Enable dynamic option creation
                      options={[instructionOption, ...sub]} // Include the instructions option at the top
                      value={vedicSub}
                      inputValue={onsearchKey}
                      styles={customStyles}
                      classNamePrefix="custom-sub-scrollbar"
                      // onBlur={(e) => handleOnBlur(e)} // Pass `field` to handleOnBlur
                      maxMenuHeight={180}
                      isLoading={isLoading}
                      onFocus={handleOnFocus}
                      closeMenuOnSelect={true}
                      onMenuClose={()=>handleOnBlur(onsearchKey)}
                    />
                  </div>
                </div>
              
              <div>
                  <p className="get-started-text fw-normal">
                    {`Choose or Add Skills / Expertise`}<span className=" text-danger">*</span>
                  </p>
                  <div className="multi-select-custom formselect-adjust"> 
                    <FormMultiSelectRef
                      title="Skills"
                      control={control}
                      options={selectSkillOptions}
                      onChange={(option) => {
                        handleSelectChange(option, setSkillSet, "skillSet")
                      }}
                      // className="multi-select-bx"
                      name="skillSet"
                      value={skillSet}
                    // disabled={selectSkillOptions[0].value === ""}
                    instructionName={'skill'}
                    MAX_LENGTH={MAX_SKILL_CHARACTERS_LENGTH}
                    isLoading={isLoading}
                    MAX_ITEMS_ALLOWED={5}
                    />
                  </div>
                </div>
                {/* {isStepValid && (
                  <div className="mt-3 text-danger">
                    All these are required fields*
                  </div>
                )} */}
              </div>
            </Col>
            <Col lg={1}></Col>
            <Col lg={5}>
              <button className="popupinfo-btn" onClick={handleInfoActive}>
                <AiOutlineInfo />
              </button>
              <div
                className={
                  infoactive
                    ? "information-wrapper active"
                    : "information-wrapper"
                }
              >
                <div className="text-end mb-4">
                  <button
                    className="close-popup d-lg-none"
                    onClick={handleInfoActive}
                  >
                    <IoCloseOutline />
                  </button>
                </div>
                <>
                  <div className="make-it-better">
                    <div
                      className="d-flex gap-3 mb-1 "
                      style={{ height: "2rem" }}
                    >
                      <img
                        src={thumbs}
                        alt="thumbs-up"
                        style={{ height: "2.5rem" }}
                      />
                      <h3 className="make-heading text-black fw-bolder fs-3">
                  Did you Know?
                      </h3>
                    </div>
                    
                   <div className="mt-5">
                   <ul>
                      <li >
                   <strong>Ad Visibility</strong> Selecting the right Skills and keyword makes your ad more visible!
                    </li></ul>
                    <ul><li>
                    <strong>Exclusive Opportunity —</strong> Early Sources gain top visibility. Don’t miss your chance to lead!</li>
                    <li><strong>Global Exposure —</strong> Share your knowledge with seekers worldwide. Expand your influence and grow your following effortlessly.</li></ul>
                   </div>
                   
                  </div>
                </>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default SubjectDetail
