import { createAsyncThunk } from '@reduxjs/toolkit';
import { auth } from "../../app/firebase";
import { signInWithCustomToken } from 'firebase/auth';

const MAX_RETRIES = 5;

export const signInChatThunk = createAsyncThunk(
  'firebaseAuth/signInChat',
  async (token: string, { rejectWithValue }) => {
    let attempts = 0;
    while (attempts < MAX_RETRIES) {
      try {
        const user = await signInWithCustomToken(auth, token);
        return user.user.uid;
      } catch (error: any) {
        attempts++;
        if (attempts >= MAX_RETRIES) {
          return rejectWithValue(error.message);
        }
      }
    }
  }
);
