import React from 'react';
import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import { BsFillInfoCircleFill } from 'react-icons/bs';

interface CustomTooltipProps {
  content: string;
  placement?: 'left' | 'right' | 'top' | 'bottom';
  className?: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ content, placement = 'top',className }) => {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {content}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <Button variant="transparent" className={`tooltip-btn-round ms-2 p-0 ${className}`}>
        <BsFillInfoCircleFill />
      </Button>
    </OverlayTrigger>
  );
};

export default CustomTooltip;
