import React, { ReactNode, useContext, useEffect, useState } from "react"
// import { auth, signinChat } from "../../app/firebase"
import SpinnerSecondary from "../../components/spinner/SpinnerSecondary"
import style from "./Chat.module.css"
import { useAppDispatch } from "../../app/store"
import { selectCurrentUser } from "../Auth/authSlice"
import { useAppSelector } from "../../app/hooks"
import ErrorBoundary from "../../errorBoundary/ErrorBoundary"
import ErrorChat from "./ErrorChat"
import { Navigate, matchPath, useLoaderData, useNavigate } from "react-router-dom"
import { onAuthStateChanged } from "firebase/auth"
import { useGetChatTokenQuery } from "../Auth/authApi"
import { firebaseAuthInfo } from "../firebaseAuth/firebaseAuthSlice"
import { signInChatThunk } from "../firebaseAuth/signInChatThunk"
import { useFetchNotificationInstanceQuery } from "../notification/notificationApi"
// import { useGetChatTokenQuery } from './ChatApi';
interface ChatAuthenticatorProps {
  children?: React.ReactNode
}

const ChatAuthenticator = ({ children }: ChatAuthenticatorProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {signedInUser, isLoading, signinError} = useAppSelector(firebaseAuthInfo);
  const { user } = useAppSelector(selectCurrentUser)
  const {
    data: chatToken,
    isSuccess,
    isFetching,
    isError,
    error,
  } = useGetChatTokenQuery(null)
  useFetchNotificationInstanceQuery(user?._id,
    {
     skip: user?._id == undefined || signedInUser == null ||  signedInUser !== user?._id,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    })
  useEffect(() => {
    if (chatToken && signedInUser !== null) {
      dispatch(signInChatThunk(chatToken.data) as any);
    }
  }, [isSuccess, chatToken, dispatch]);

  return (isLoading || isFetching ? <><SpinnerSecondary/>  </>
  : isError || signinError ? <ErrorChat message={"Please Refresh"} />
  : signedInUser && <>{children}</>) 
}

export default ChatAuthenticator
