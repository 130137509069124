import React, { useEffect } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { useAppSelector } from "../../app/hooks"
import { useAppDispatch } from "../../app/store"
import { logout, selectCurrentUser } from "../../features/Auth/authSlice"
import { useTokenStatusQuery } from "../../features/Auth/authApi"
import { USERTYPE } from "../../constants"

interface PrivateProps {
  children: React.ReactNode
  userType  :USERTYPE
}

const PrivatePaymentRoute = ({ children, userType }: PrivateProps) => {

  const { seekerToken, providerToken } = useAppSelector(selectCurrentUser)

  const dispatch = useAppDispatch()
  const pollIntervalValue = 3600 * 1000
  const { data, isSuccess } = useTokenStatusQuery({
    pollingInterval: pollIntervalValue,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  })

  useEffect(() => {
    if (isSuccess && data?.isTokenExpired) {
      dispatch(logout())
    }
  }, [isSuccess, data])
 // Additional code for token decoding and expiration check if needed
if(userType === USERTYPE.SEEKER ){return   seekerToken  ?  children : <Navigate to={'/'} replace/>
}
if(userType === USERTYPE.PROVIDER ){return   providerToken  ?  children : 
    <Navigate to={'/'} replace/>
}
return !seekerToken && !providerToken && <Navigate to={'/'} replace/>
}

export default PrivatePaymentRoute
