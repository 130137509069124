import { Col, Container, Modal, Row } from "react-bootstrap"
import starimage from "../../../assets/img/star-active-icon.svg"
import { toast } from "react-toastify"
import "./ScheduleCard.css"
import { useAppSelector } from "../../../app/hooks"
import dummy from "../../../assets/images/dummy-profile-pic.jpg"
import { BsPencil } from "react-icons/bs"
import { useEffect, useState } from "react"
import getCroppedImg from "../../funnels/ProviderFunnel/uploadPhoto/GetCroppedImg"
import Cropper from "react-easy-crop"
import {
  useUpdateAdvertisementMutation,
  useUploadAdProfileDetailsMutation,
} from "../../../features/featProviderDashboard/FeatProviderApi"
import RenderProfile from "../../../utils/RenderProfilePic/RenderProfilePic"
import CustomBackNavButton from "../../buttons/custumBackNavButton/CustomBackNavButton"

interface providerProps {
  data: any // Adjust the type accordingly
  key: string | number // Adjust the type accordingly
  seekerData: any
  types: string
  classType?: {realTime: boolean, virtual: boolean, any: boolean, group: boolean, private: boolean}
}

const ScheduleCard = ({ data, seekerData, types, classType }: providerProps) => {
  const [MINIMUM_PRICE, SetMinimumPrice] = useState(0)
  const ProviderData: any = useAppSelector((state: any) => state.providerSlice)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [imgSrc, setImgSrc] = useState<any>(null)
  const [finalImg, setFinalImg] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const[priceToSHow, setPriceToSHow] = useState<number|null>(MINIMUM_PRICE)
  const [isFirstClassFree, _isFirstClassFree] = useState<boolean>(false)

  const handleCloseModal = () => setShowModal(false)
  const [uploadPhoto, { data: adData, error }] =
    useUploadAdProfileDetailsMutation()

  const handleCrop = async () => {
    if (imgSrc && finalImg) {
      try {
        const croppedImgUrl = await getCroppedImg(imgSrc, finalImg)
        const blob = await fetch(croppedImgUrl).then((res) => res.blob())
        const formData = new FormData()
        setImgSrc(croppedImgUrl)
        formData.append("profileImage", blob, Math.random().toString())
        const response = await uploadPhoto({
          adData: formData,
          id: data?.data?.allAdvertisements[ProviderData?.id]?._id,
        })
        if (!(response as any)?.error) {
          setShowModal(false)
          setZoom(1)
          toast.success("Photo Uploaded Successfully")
        } else {
          toast.error("Failed to upload cropped image.")
        }
      } catch (error) {
        console.error("Error cropping/uploading image:", error)
        toast.error("An error occurred while cropping/uploading image.")
      }
    }
  }

  useEffect(()=>{
    const realTimeGroup = seekerData?.adDetail?.prices?.realTime?.group?.hourlyFee || 0
    const realTimePrivate = seekerData?.adDetail?.prices?.realTime?.private?.hourlyFee || 0
    const virtualGroup = seekerData?.adDetail?.prices?.virtual?.group?.hourlyFee || 0
    const virtualPrivate = seekerData?.adDetail?.prices?.virtual?.private?.hourlyFee || 0
    const anyPrice = Math.floor((realTimeGroup + realTimePrivate + virtualGroup + virtualPrivate)/2)
    const prices = [realTimeGroup, realTimePrivate, virtualGroup, virtualPrivate]
    SetMinimumPrice(Math.min(...prices))
    if(classType?.any){
      setPriceToSHow(MINIMUM_PRICE); 
      _isFirstClassFree(
        seekerData?.adDetail?.prices?.virtual?.private?.firstClassFree ||
        seekerData?.adDetail?.prices?.realTime?.private?.firstClassFree ||
        seekerData?.adDetail?.prices?.realTime?.group?.firstClassFree ||
        seekerData?.adDetail?.prices?.virtual?.group?.firstClassFree) } 
    if(classType?.realTime){
      if(classType?.group){setPriceToSHow(realTimeGroup); _isFirstClassFree(seekerData?.adDetail?.prices?.realTime?.group?.firstClassFree)}
      else if(classType?.private){setPriceToSHow(realTimePrivate); _isFirstClassFree(seekerData?.adDetail?.prices?.realTime?.private?.firstClassFree)}
      else { setPriceToSHow(null); _isFirstClassFree(seekerData?.adDetail?.prices?.realTime?.group?.firstClassFree || seekerData?.adDetail?.prices?.realTime?.private?.firstClassFree)}
    }
    if(classType?.virtual){
      if(classType?.group){setPriceToSHow(virtualGroup); _isFirstClassFree(seekerData?.adDetail?.prices?.virtual?.group?.firstClassFree)} 
      else if(classType?.private){setPriceToSHow(virtualPrivate); _isFirstClassFree(seekerData?.adDetail?.prices?.virtual?.private?.firstClassFree)}
      else { setPriceToSHow(null); _isFirstClassFree(seekerData?.adDetail?.prices?.virtual?.group?.firstClassFree || seekerData?.adDetail?.prices?.virtual?.private?.firstClassFree)}
    }
  },[classType])

  const handleImageChange = (event: any) => {
    const file = event.target.files[0]

    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        setImgSrc((reader as any).result)
      }
      setShowModal(true)
    }
  }

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setFinalImg(croppedAreaPixels)
  }

  return (
    <>
      <div className="schedule-card">
        <Container fluid="lg">
          <Row>
            <Col lg={12}>
              <div className="inner-teacher-card sticky-card">
                <div className="fav-card bg teacher-card schedule-card-wrapper">
                <CustomBackNavButton/>
                  <div className="fav-img-wrapper position-relative mb-2">
                    <div
                      className="fav-img position-relative"
                      style={{ position: "relative" }}
                    >
                      <img src={seekerData?.adDetail?.profileImage || data?.data?.allAdvertisements[ProviderData?.id]?.profileImage || dummy } alt="profile image"/>
                      {/* <div style={{ position: "absolute" }} className="editAdProfile">

                        <BsPencil className="edit-icon" />
                      </div> */}
                    </div>

                    {types == "seeker" ? (
                      <div className="price-info w-auto ratio-1x1 ">
                        
                        <div className="price-text justify-content-center text-truncate">
                        {(classType?.any || priceToSHow ==  null) &&
                        <>
                        <span style={{fontSize: '1.9rem'}} className=" text-light ">*</span>
                        </>}
                          {'₹ '}
                          {priceToSHow || MINIMUM_PRICE}
                        </div>
                        <span className="hour-text">/hr</span>
                      </div>
                    ) : (
                      data?.data?.allAdvertisements[ProviderData.id]
                        ?.hourlyRate && (
                        <a className="price-info w-auto">
                          {
                            <>
                              <span className="price-text">
                              <span style={{fontSize: '1.9rem'}} className=" text-light ">*</span>
                                <span>₹ </span>
                                {priceToSHow || MINIMUM_PRICE}
                              </span>
                              <span>/hr</span>
                            </>
                          }
                        </a>
                      )
                    )}
                  </div>
                  <div className="info-astrologer">
                    <div className="text-center">
                      {types == "seeker" ? (
                        <h2 className="ast-name mb-1">
                          {seekerData?.adDetail?.providerId?.firstName +
                            " " +
                            seekerData?.adDetail?.providerId?.lastName}
                        </h2>
                      ) : (
                        <>
                        <h2 className="ast-name mb-1">
                          {(ProviderData?.user?.data?.allAdvertisements?.[0]
                            ?.providerId.firstName ||
                            ProviderData?.user?.allAdvertisements?.[0]?.providerId
                              .firstName) +
                            " " +
                            (ProviderData?.user?.data?.allAdvertisements?.[0]
                              ?.providerId.lastName ||
                              ProviderData?.user?.allAdvertisements?.[0]
                                ?.providerId.lastName)}
                        </h2>
                        </>)
                      }
                      {
                      (seekerData?.adDetail?.providerId?.rating?.$numberDecimal > 0)&&
                      (
                      <>
                      {/* rating available */}
                      <div className="rating mb-3">
                        <img src={starimage} />
                        {types == "seeker" ? (
                          <span className="total-rating">
                            {
                              seekerData?.adDetail?.providerId?.rating
                                ?.$numberDecimal
                            }
                          </span>
                        ) : (
                          <span className="total-rating">
                            {
                              data?.data?.allAdvertisements[ProviderData?.id]
                                ?.providerId.rating.$numberDecimal
                            }
                          </span>
                        )}
                        {types == "seeker" ? (
                          <small className="reviews">
                            ({seekerData?.countReviews} Reviews)
                          </small>
                        ) : (
                          <span>
                            <small className="reviews">{`(${data?.data?.totalReviews} Reviews)`}</small>
                          </span>
                        )}
                      </div>
                      </>)
                      }
                    </div>
                  </div>
                  {isFirstClassFree&&<p className="text-center mb-0 primary-text">1st class free</p>}
                </div>
              </div>
            </Col>
            <div>
              <input
                type="file"
                id="file-upload"
                onChange={handleImageChange}
                accept=".jpg, .jpeg, .png"
                className="file-upload-input d-none"
              />
              <Modal show={showModal} style={{ top: "10%" }}>
                {
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "white",
                      padding: "20px",
                      borderRadius: "8px",
                      width: "500px",
                      height: "500px",
                    }}
                  >
                    <div className="w-100" style={{ marginBottom: "20px" }}>
                      <div className="d-flex justify-content-between">
                        <p className="text-black fw-bold">Crop Image</p>
                        <div
                          className="bg-white p-1"
                          onClick={handleCloseModal}
                        >
                          X
                        </div>
                      </div>
                      <hr />
                    </div>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "400px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Cropper
                        image={imgSrc}
                        crop={crop}
                        zoom={zoom}
                        aspect={4 / 3}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                      />
                    </div>
                    <input
                      type="range"
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      onChange={(e) => {
                        setZoom(e.target.value as any)
                      }}
                      className="mt-2"
                    />
                    <button
                      style={{
                        height: "60px",
                        width: "50%",
                        color: "white",
                        background: "#FB6D48",
                        border: "none",
                        marginTop: "20px",
                        borderRadius: "25px",
                      }}
                      onClick={handleCrop}
                    >
                      Crop
                    </button>
                  </div>
                }
              </Modal>
            </div>
            {!(types == "seeker")&&
            <Col lg={12}>
              <div className="update-profile-card my-2 pb-1 max-w-360">
                <label htmlFor="file-upload" className="file-upload-label p-3">
                  Update Ad Media
                </label>
              </div>
            </Col>}
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ScheduleCard
