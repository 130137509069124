import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const useScrollTracking = () => {
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const docHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercent = (scrollTop / docHeight) * 100;

      if (scrollPercent > 50) {
        ReactGA.event({
          category: "Scroll",
          action: `50% Scroll ${window.location.pathname}`, // Accessing current path without useLocation
          label: "User scrolled past 50% of the page"
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
};

export default useScrollTracking;
