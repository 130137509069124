import React, { useEffect, useRef, useState } from "react"
import {
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Stack,
} from "react-bootstrap"
import headerimage from "../../assets/img/header-image.png"
import headerlogo from "../../assets/img/logo.png"
// import Login from "../Modals/Login/Login"
import avatar from "../../assets/img/avatar.png"
import "./TopNav.css"
import { isLaunched, MODALNAME, USERTYPE } from "../../constants"
import { setModalName } from "../../features/Auth/ModalHandler/modalSlice"
import { useAppDispatch } from "../../app/store"
import {
  Link,
  createSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom"

import { useAppSelector } from "../../app/hooks"
import userImg from "../../assets/img/card-image.png"
import deleteicon from "../../assets/img/deleteIcon.svg"
import msgicon from "../../assets/img/msgicon.svg"
import { IoCloseCircle, IoNotificationsSharp } from "react-icons/io5"
import {
  resetFunel,
  reset,
} from "../../features/ServiceFunnel/FeatSeekerFunnel/seekerFunnelSlice"
import AuthCollection from "../../features/Auth/AuthCollection"
import {
  useCloseNotificationMutation,
  useGetChatTokenQuery,
  useGetNotificationsMutation,
  useLogoutMutation,
  useLogoutProviderMutation,
} from "../../features/Auth/authApi"
import { logout, selectCurrentUser, setCurrentUser } from "../../features/Auth/authSlice"
import {
  resetProviderFunel,
  resetProviderStepper,
} from "../../features/ServiceFunnel/FeatProviderFunnel/providerFunnelSlice"
import {
  useAcceptDeleteRequestMutation,
  useProviderAccountDetailsQuery,
  useProviderSearchMutation,
} from "../../features/featProviderDashboard/FeatProviderApi"
import {
  useGetseekerProfileQuery,
  useSeekerSearchMutation,
} from "../../features/featSeekerDashboard/seekerDashboardApi"
import premimumStar from "../../assets/images/premimumStar.png"
import notification from "../../assets/img/notification.svg"
import { Controller, useForm } from "react-hook-form"
import findSearch from "../../assets/img/clarity_search-line.svg"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import dummy from "../../assets/images/dummy-profile-pic.jpg"
import LandingSearch from "../searchBars/LandingSearchBar"
import TopNavSearchBar from "../searchBars/topNavSearchBar"
import { T } from "vitest/dist/types-e3c9754d.js"
import { toast } from "react-toastify"
import {
  setMessageNotificationClick,
  setSeekerLandingPageSearchData,
  setSeekerRequest,
} from "../../features/featSeekerDashboard/seekerDashboardSlice"
import { topNavBarSchema } from "../../utils/yupSchema/Schema"
import { getAuth, signOut } from "firebase/auth"
import { signinChat, app } from "../../app/firebase"
import { BsThreeDotsVertical } from "react-icons/bs"
import { GoDotFill } from "react-icons/go"
import { handleRedirect } from "../../features/notification/handleRedirects"
import DropDownItemMobile from "./components/DropDownItemMobile"
import AccountDetailsMobileView from "./DashNav/AccountDetailsMobileView"
import NotificationDropDown from "../dasboard/commonDashboard/notification/NotificationDropDown"
import {
  allNotifications,
  unseenNotifications,
} from "../../features/notification/notificationSlice"
import { motion } from "framer-motion"
import RenderProfile from "../../utils/RenderProfilePic/RenderProfilePic"
import UserStatus from "../../app/onlinePresence"
import Marquee from "../buttons/MarqueeBox"
import "../pages/LandingPage/LandingPage.css"
import useNavigateAndScroll from "../../utils/commonFunctions/navigateAndScroll"
interface navProps {
  navClass?: string
  sidebarActive?: boolean
}

const TopNavbar = ({ navClass, sidebarActive }: navProps) => {
  const ProviderData: any = useAppSelector((state: any) => state.providerSlice)
  const navBarToggler = useRef<any>(null)
  const notificationDropDownRef = useRef<any>(null)
  const [isOverlayVisible, setOverlayVisible] = useState<boolean>(false)
  const notificationMobilwDropDownRef = useRef<any>(null)

  const auth = useAppSelector((state: any) => state.auth)
  const currentUserRole = auth?.currentUserRole

  const tooltipMessage = auth?.user?.status
  const { seekerToken, providerToken } = useAppSelector(
    (state: any) => state.auth,
  )
  const {
    data: chatToken,
    error,
    refetch: refetchChatToken,
  } = useGetChatTokenQuery(null, {
    skip: !auth.seekerToken || !auth.providerToken,
  })
  const adDetails = useAppSelector((state: any) => state.providerSlice)
  const [isScrolled, setIsScrolled] = useState(false)
  const [msgNotification, setMsgNotification] = useState<Array<T>>([])
  const [unseensList, _unseensList] = useState<Object[]>([])
  const [triggerAnimate, _triggerAnimate] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const unseens = useAppSelector(unseenNotifications)
  const accountDDRef = useRef<any>(null)
  const navigateAndScroll = useNavigateAndScroll();
  useEffect(() => {
    _triggerAnimate(true)
    setTimeout(() => {
      _triggerAnimate(false)
    }, 500)
  }, [unseens])

  // to disable tabs for new user until user has no ad
  const newUserAdvertisementLength =
    adDetails?.newUserAdvertisementLength === -1 ||
    adDetails?.newUserAdvertisementLength === 0
  //  search bar location


  // const { data, isLoading: getLoading } = seekerToken
  //   ? useGetseekerProfileQuery()
  //   : useProviderAccountDetailsQuery()
  // const [
  //   getNotification,
  //   { data: notificationData, isLoading, isError, isSuccess },
  // ] = useGetNotificationsMutation()

  const [
    closeNotification,
    {
      data: closeNotificationData,
      isLoading: closeNotificationloading,
      isError: closeNotificationError,
      isSuccess: closeNotificationSuccess,
    },
  ] = useCloseNotificationMutation()

  const handleNotificationClose = (id: any) => {
    closeNotification({ notificationId: id })
  }
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  // useEffect(() => {
  //   if (seekerToken || providerToken) {
  //     const intervalId = setInterval(() => {
  //       getNotification({
  //         pageNo: 1,
  //         pageLimit: 10,
  //       })
  //     }, 5000)
  //     return () => clearInterval(intervalId)
  //   }
  // }, [])

  useEffect(() => {
    if (auth) {
      if (chatToken) {
        signinChat(chatToken.data)
      }
    }
  }, [auth, chatToken])


  // Notification Part
  const [search, { data: searchedData, isLoading: loading }] = seekerToken
    ? useSeekerSearchMutation()
    : useProviderSearchMutation()

  useEffect(() => {
    const foundItem = searchedData?.data?.find(
      (item: any) =>
        item._id === ProviderData?.ProviderNewMsgNotification?.chatId,
    )
    if (foundItem) {
      const username = ProviderData?.ProviderNewMsgNotification?.username
      setMsgNotification((prevState) => {
        const existingIds = prevState.map((item: any) => item._id)
        const newId = ProviderData?.ProviderNewMsgNotification?.chatId

        if (newId && !existingIds.includes(newId)) {
          return [
            ...prevState,
            {
              title: `You have a new message from ${username}`,
              _id: newId,
              data: foundItem,
            } as any,
          ]
        }

        return prevState
      })
    }
  }, [ProviderData?.ProviderNewMsgNotification])
  const handleExploreMore = async () => {
    const payload = {
      VedicSubject: "",
      pageNo: 1,
    }
    // dispatch(setSeekerLandingPageSearchData(payload as any))
    navigate(
      {
        pathname: "/results",
        search: `?${createSearchParams({ ...payload, pageNo: "1" })}`,
      },
      {
        replace: true,
      },
    )
    // await searchAdvts(payload)
  }


  const signOutUser = () => {
    dispatch(setModalName(MODALNAME.CONFIRM_LOGOUT))
  }

  // Collapse the navbar
  const closeNavBAr = () => {
    if (navBarToggler.current) {
      const navBarCollapse = navBarToggler.current
      if (navBarCollapse.classList.contains("show")) {
        navBarCollapse.classList.remove("show")
      }
    }
  }
  function closeNotificationDropDown() {
    notificationDropDownRef.current.click()
    setOverlayVisible(false)
  }
  function closeMobileNotificationDropDown() {
    notificationMobilwDropDownRef.current.click()
  }
  function toggleDropdown() {
    accountDDRef.current?.click()
  }

  return (
    <>
      <section className={`header-component ${isScrolled ? "scrolled" : ""}`}>
        <Navbar expand="lg" className={navClass} fixed="top">
          <Container fluid className="flex-nowrap">
            <div className="d-flex align-items-center">
              <Navbar.Brand
                className="align-items-center"
                onClick={() => {
                  auth.seekerToken
                    ? navigate("/results", { replace: true })
                    : auth.providerToken
                    ? navigate("/source-dashboard")
                    : navigate("/")
                }}
              >
                <img
                  src={headerlogo}
                  alt="Logo"
                  className={`cursor-pointer img-fluid w-100 ${ ['press-center','site-map', 'about-us'].some(hiddenPath => location.pathname.includes(hiddenPath)) ? ' bg-white' : ''}`}
                  title={tooltipMessage ? " Home " : "Home"}
                  // style={{width:"100px",height:"80px"}}
                />
              </Navbar.Brand>
              <div className={`${location.pathname?.includes('seeker-dashboard') ? !sidebarActive ? 'topnavbar-search search-padding' : 'topnavbar-search' :'topnavbar-search'}`}>
                <TopNavSearchBar />
              </div>
            </div>

            <Navbar.Collapse
              id="navbarTogglerDemo01"
              ref={navBarToggler}
              className="justify-content-end navigation-collapse"
            >
              <Navbar.Toggle
                id="NavbarToggleBtn"
                className="close-navbar border-0 shadow-none"
              >
                <IoCloseCircle />
              </Navbar.Toggle>
              <Nav className="d-lg-flex align-items-center">
                <a className="d-block mb-lg-0 mb-2 me-3 get-help-link text-center">
                  <i className="fa-regular fa-circle-question"></i>
                </a>
                {providerToken || seekerToken ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    {!(location.pathname?.includes('source-signup'))&&<Button
                      variant="transparent"
                      id="signUp-source-button-navbar"
                      className="d-block mb-lg-0 mb-2 header-link me-3"
                      onClick={() => {
                        navigateAndScroll("/source-signup", 0)
                        closeNavBAr()
                      }}
                    >
                      Become a Source
                    </Button>}
                    <Button
                    id="signIn-button-navbar"
                      variant="transparent"
                      onClick={() => {
                        closeNavBAr()
                        if(!isLaunched) dispatch(setCurrentUser(USERTYPE.PROVIDER))
                        dispatch(setModalName(MODALNAME.LOGIN))
                      }}
                      className="d-block mb-lg-0 mb-2 header-link login-link me-3"
                    >
                      Sign In                    </Button>
                  </>
                )}
                {auth?.providerToken &&
                !location.pathname.includes("journey-source") ? (
                  auth?.user?.skills?.length < 3 
                  ?(<Button
                    className="d-block mb-lg-0 mb-2 header-link me-3 get-started"
                    onClick={() => {
                      closeNavBAr()
                      navigateAndScroll("/journey-source", 0, undefined, true)
                    }}
                  >
                    Guide and Earn
                  </Button>)
                  : <></>
                ) : (
                  isLaunched ? (
                    <Button
                      className={`${
                        location?.pathname === "/results"
                          ? "d-none"
                          : "d-block mb-lg-0 mb-2 header-link  me-3 get-started"
                      }`}
                      onClick={() => {
                        closeNavBAr()
                        auth?.seekerToken || auth.providerToken
                          ? handleExploreMore()
                          : navigateAndScroll("/journey", 0, undefined, true)
                      }}
                    >
                      {auth.seekerToken || auth.providerToken ? (
                        <span>Explore More</span>
                      ) : (
                        <>Get Started</>
                      )}{" "}
                    </Button>
                  ) : null
                )}
                {(providerToken || seekerToken) && (
                  <>
                    {isOverlayVisible && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.15 }}
                        className="overlaay"
                        onClick={closeNotificationDropDown}
                      />
                    )}

                    {location.pathname.includes('dashboard') &&<Dropdown
                      className="common-dropdown dropdown-notification-user rounded-bell  notification-panel"
                      title="See Notification"
                      ref={notificationDropDownRef}
                      onToggle={(e) => {
                        setOverlayVisible(e)
                      }}
                    >
                      <Dropdown.Toggle
                        variant="transparent"
                        id="dropdown-basic"
                        className="dropdown-toggle"
                      >
                        <motion.img
                          animate={{
                            scale: triggerAnimate ? [1, 1.1, 1.1, 1] : 1,
                            rotateZ: triggerAnimate ? [0, 52, -25, 0] : 0,
                          }}
                          exit={{ scale: 1, rotateZ: 0 }}
                          transition={{ duration: 0.4 }}
                          src={notification}
                          alt="notification"
                        />
                        {((unseens && unseens > 0) ||
                          msgNotification.length > 0) && (
                          <motion.div
                            initial={{ scale: 1 }}
                            whileHover={{
                              opacity: unseensList?.length > 0 ? 1 : 0,
                            }}
                            transition={{ duration: 1 }}
                            className="notify"
                          />
                        )}
                      </Dropdown.Toggle>

                      {
                        <Dropdown.Menu className="menu-setting custom-scrollbar">
                          <NotificationDropDown
                            closeDropDown={closeNotificationDropDown}
                          />
                        </Dropdown.Menu>
                      }
                    </Dropdown>}
                  </>
                )}

                {/* Add user-related dropdown items here */}
                {auth?.providerToken || auth?.seekerToken ? (
                  <Dropdown className="common-dropdown">
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="dropdown-toggle d-flex flex-row justify-content-center align-items-center "
                    >
                      {/* <img
                        src={auth?.user?.profilePic || dummy}
                        alt={avatar}
                        className="defavatar custom-border"
                      /> */}
                      <RenderProfile
                      size="dd"
                        customClass="defavatar custom-border"
                        senderNameValue={
                          auth?.user?.firstName + " " + auth?.user?.lastName
                        }
                        senderProfilePic={auth?.user?.profilePic}
                      />
                      {/* online status checker  */}
                      {/* <UserStatus/> */}
                      {auth?.user?.isPremium && (
                        <img
                          src={premimumStar}
                          alt="premimumStar"
                          className="premimumStartopnav "
                        />
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="menu-setting topnav-dashboard">
                      <Dropdown.Item className="close-navbar d-lg-none">
                        <IoCloseCircle />
                      </Dropdown.Item>
                      <AccountDetailsMobileView
                        signOutUser={signOutUser}
                        toggleDropdown={toggleDropdown}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <></>
                )}
              </Nav>
            </Navbar.Collapse>
            <div className="d-lg-none d-flex align-items-center">
              {(providerToken || seekerToken) && (
                <Dropdown
                  className="common-dropdown dropdown-notification-user  notification-panel"
                  ref={notificationMobilwDropDownRef}
                >
                  {location.pathname.includes('dashboard') &&
                    <Dropdown.Toggle
                    variant="transparent"
                    id="dropdown-basic"
                    className="dropdown-toggle"
                  >
                    <motion.img
                      animate={{
                        scale: triggerAnimate ? [1, 1.1, 1.1, 1] : 1,
                        rotateZ: triggerAnimate ? [0, 52, -25, 0] : 0,
                      }}
                      exit={{ scale: 1, rotateZ: 0 }}
                      transition={{ duration: 0.4 }}
                      src={notification}
                      alt="notification"
                      width={27}
                    />
                    {((unseens && unseens > 0) ||
                      msgNotification.length > 0) && (
                      <motion.div
                        initial={{ scale: 1 }}
                        whileHover={{
                          opacity: unseensList?.length > 0 ? 1 : 0,
                        }}
                        transition={{ duration: 1 }}
                        className="notify"
                      />
                    )}
                  </Dropdown.Toggle>}
                  <Dropdown.Menu className="menu-setting">
                    <Dropdown.Item className="close-navbar p-0 d-lg-none">
                      <IoCloseCircle />
                    </Dropdown.Item>
                    <NotificationDropDown
                      closeMobileDropdown={closeMobileNotificationDropDown}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              )}

              {/* Add user-related dropdown items here */}
              {auth?.providerToken || auth?.seekerToken ? (
                <Dropdown className="common-dropdown dropdown-user">
                  <Dropdown.Toggle
                    variant="transparent"
                    id="dropdown-basic"
                    className="dropdown-toggle"
                  >
                    <RenderProfile
                    size="dd"
                      customClass="defavatar custom-border"
                      senderNameValue={
                        auth?.user?.firstName + " " + auth?.user?.lastName
                      }
                      senderProfilePic={auth?.user?.profilePic}
                    />
                    {auth?.user?.isPremium && (
                      <img
                        src={premimumStar}
                        alt="premimumStar"
                        className="premimumStartopnav"
                      />
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="menu-setting">
                    <Dropdown.Item
                      className="close-navbar d-lg-none"
                      ref={accountDDRef}
                    >
                      <IoCloseCircle />
                    </Dropdown.Item>
                    <AccountDetailsMobileView
                      signOutUser={signOutUser}
                      toggleDropdown={toggleDropdown}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Navbar.Toggle className="shadow-none border-0" />
              )}
            </div>
          </Container>
          {/* <AuthCollection /> */}
        </Navbar>

        {/* <Login/> */}
      </section>
    </>
  )
}

export default TopNavbar
