export const globalToastStyle = {
  backgroundColor: "#f57a5b",
  color: "#fff",
  padding: "6px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
}
export const progressStyle = {
  backgroundColor: "white",
  color: "#fff",
}
