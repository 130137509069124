import { FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { collection, query, orderBy, getDocs, onSnapshotsInSync, SnapshotListenOptions, onSnapshot, limit, where, QuerySnapshot, startAfter, Timestamp, runTransaction, doc, getCountFromServer, serverTimestamp } from 'firebase/firestore';
import { getTimeDifferenceAsString } from '../../utils/timeDifferene/getTimeDifference';
import { auth, db } from '../../app/firebase';
import { getMessage } from '@reduxjs/toolkit/dist/actionCreatorInvariantMiddleware';
import { store } from '../../app/store';
import { api } from '../Auth/authApi';
import { providerDashboardApi } from '../featProviderDashboard/FeatProviderApi';
import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { ItemWithISODate } from '../../Types';
import { sortArrayByISODate } from '../../utils/commonFunctions/CommonFunctions';
import { updateUnreadLiveRequests, updateUnreadPendingRequests } from './ChatSlice';
type MyQueryResult = {
  data: Array<{ date: string; id: string;[key: string]: any }>;
  error?: FetchBaseQueryError;
};
// Todo later add a list and message adapter

// transformResponse(response: Message[]) {
//   return messagesAdapter.addMany(
//     messagesAdapter.getInitialState(),
//     response
//   )
// },

// const listAdapter = createEntityAdapter({
//   // Assume IDs are stored in a field other than `book.id`
//   selectId: (book: ItemWithISODate) => book.requestId,
//   // Keep the "all IDs" array sorted based on book titles
//   sortComparer: (a, b) => a.title.localeCompare(b.title),
// })
export type Message = {
  id: string;
  date: string,
  read?: string,
  senderId?: string
  senderMsg?: string
  localOnly?: boolean
  unreadSourceCount?:Array<string>
  unreadSeekerCount?:Array<string>
  failed: boolean
  messageId?:string
};
type Recipe = Message[];
export const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  tagTypes: ['getMessages', 'liveRequestList', 'pendingRequestList', 'getOldMessages', 'unlockedRequestList'], // Declare the types of tags used in this API
  endpoints: (builder) => ({
   

    getMessages: builder.query<Recipe, any>({
      queryFn: async (args, queryApi, extraOptions, baseQuery) => {
        const q = query(collection(db, "requests", args.reqId, "chat"),
          orderBy("date", "desc"),
          limit(args.messageLimit),
        )
        // Initial fetch from Firestore
        try {
          const querySnapshot = await getDocs(q);
          const messages = querySnapshot.docs.map((doc: any) => ({
            ...doc.data(),
            date: doc.data().date?.toDate().toISOString(),

          })).reverse();
          return { data: messages };

        } catch (error: any) {
          return { error: error.message };
        }
      },
      async onCacheEntryAdded(
        args,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) {
        try {
          await cacheDataLoaded; // Ensure the cache is loaded before proceeding

          const chatQuery = query(
            collection(db, "requests", args.reqId, "chat"),
            orderBy("date", "desc"),
            limit(args.messageLimit)
          );

          // Subscribe to the query
          const unsubscribe = onSnapshot(chatQuery, (querySnapshot) => {
            const changes = querySnapshot.docChanges();
            // if (changes.length > 0) {
            const newData = changes.map((change) => {
              return {
                ...change.doc.data(),
                id: change.doc.data().id,
                date: change.doc.data().date?.toDate().toISOString(),
                localOnly: false,
                failed: false
                // Uncomment if lastMessageTime needs to be updated:
                // lastMessageTime: change.doc.data().date?.toDate().toString(),
              };
            });

            updateCachedData((draft) => {
              // Assuming 'draft' is an array of messages
              newData.forEach((newMessage) => {
                const index = draft.findIndex((message: Message) => message.id === newMessage.id);
                if (index !== -1) {
                  // If the message exists, update it
                  draft[index] = { ...draft[index], ...newMessage, localOnly: false, failed: false };
                } else {

                  // If the message doesn't exist, add it to the cache
                  draft.push({ ...newMessage, localOnly: false, failed: false });
                }
              });
            });

            // Optional: Dispatch to update the cache using RTK Query API, if needed
            // dispatch(
            //   chatApi.util.upsertQueryData(
            //     "getMessages",
            //     'LIST',
            //     newData
            //   )
            // );
            // }
          }) // Unsubscribe on cache entry removal
          await cacheEntryRemoved
          // unsubscribe();
        } catch (err) {
          console.error("Failed in onCacheEntryAdded lifecycle:", err);
          // Handle errors appropriately here
        }
      },
      // transformResponse: (result:Array<Message> ) => {


      //   return () => result.sort((a:Message, b:Message) => {
      //     const dateA = new Date(a.date);
      //     const dateB = new Date(b.date);
      //     if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
      //       // Handle invalid dates, maybe by not changing order, or logging an error
      //       return 0;
      //     }
      //     return   dateB.getTime() - dateA.getTime()
      //   }) },
      providesTags: (result, error, reqID, args) => {
        result
          ?
          () => [
            ...result?.map(({ id }: Message) => ({ type: 'getMessages', id })),
            { type: 'getMessages', id: 'MESSAGES' },
          ]
          : () => [{ type: 'getMessages', id: 'MESSAGES' }]
        return []
      },
      keepUnusedDataFor: 300,
    }),

    getLiveList: builder.query<ItemWithISODate[], any>({
      queryFn: async function fetchData(args) {
        const q = query(
          collection(db, "requests"),
          where(args?.userIdType, "==", args?.loggedUserId),
          where("requestStatus", "==", args.requestStatus),
          orderBy("lastMessageTime", "desc")
        );
        try {
          const querySnapshot = await getDocs(q);
          const requestList: ItemWithISODate[] = querySnapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              date: doc.data().date?.toDate().toISOString(),
              lastMessageTime: doc.data().lastMessageTime?.toDate().toISOString(),
              id: doc.id,
              requestId: doc.data().requestId
            };
          });

          return { data: requestList };
        } catch (err) {
          console.error(err);
          return { data: [] as ItemWithISODate[] }
        }
        return { data: [{}] as ItemWithISODate[] }

      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        await queryFulfilled
        if (args.userIdType === "providerId") {
          const countUnreadLive = query(
            collection(db, "requests"),
            where(args?.userIdType, "==", args.loggedUserId),
            where("requestStatus", "==", args.requestStatus),
            where("unread", '==', "true"),
            orderBy("lastMessageTime", args.order),
            limit(args?.requestLimit)
          );
          const snapshot = await getCountFromServer(countUnreadLive)
          dispatch(updateUnreadLiveRequests(snapshot.data().count))
        } else {
          const countUnreadLive = query(
            collection(db, "requests"),
            where(args?.userIdType, "==", args.loggedUserId),
            where("requestStatus", "==", args.requestStatus),
            where("unreadSource", '==', "true"),
            orderBy("lastMessageTime", args.order),
            limit(args?.requestLimit)
          );
          const snapshot = await getCountFromServer(countUnreadLive)
          dispatch(updateUnreadLiveRequests(snapshot.data().count))
        }

      }, async onCacheEntryAdded(
        args,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) {
        try {
          await cacheDataLoaded; // Ensure the cache is loaded before proceeding
          const listQuery = query(
            collection(db, "requests"),
            where(args?.userIdType, "==", args?.loggedUserId),
            where("requestStatus", "==", args.requestStatus),
            orderBy("lastMessageTime", "desc")
          );

        
          // Subscribe to the query
          const unsubscribe = onSnapshot(listQuery, (querySnapshot) => {

            const changes = querySnapshot.docChanges();

            // if (changes.length > 0) {
            const newData: ItemWithISODate[] = changes.map((change) => {
              if (change.type === "modified") {

                return {
                  ...change.doc.data(),
                  date: change.doc.data().date?.toDate().toISOString(),
                  id: change.doc.id,
                  lastMessageTime: change.doc.data().lastMessageTime?.toDate().toISOString(),
                  requestId: change.doc.data()?.requestId,
                  modified: true, new: false, deleted: false
                }
              }
              if (change.type === "added") {
                return {
                  ...change.doc.data(),
                  date: change.doc.data().date?.toDate().toISOString(),
                  id: change.doc.id,
                  lastMessageTime: change.doc.data().lastMessageTime?.toDate().toISOString(),
                  requestId: change.doc.data()?.requestId,
                  new: true,
                  modified: false
                  , deleted: false
                };
              }
              if (change.type === 'removed') {
                return {
                  ...change.doc.data(),
                  date: change.doc.data().date?.toDate().toISOString(),
                  id: change.doc.id,
                  lastMessageTime: change.doc.data().lastMessageTime?.toDate().toISOString(),
                  requestId: change.doc.data()?.requestId,
                  new: false,
                  modified: false,
                  deleted: true

                }
              }
              return {} as ItemWithISODate
            });
            updateCachedData((draft) => {
              // Assuming 'draft' is an array of messages
              newData.forEach((newItem: ItemWithISODate) => {
                if (Array.isArray(draft) && draft.length > 0) {
                  const index = draft?.findIndex((listItem: any) => listItem.requestId === newItem.requestId);
                  if (index !== -1) {
                    // If the message exists, update it
                    draft[index] = { ...draft[index], ...newItem , new:false};
                  } else {
                    // If the message doesn't exist, add it to the cache
                    draft.push({ ...newItem });
                    dispatch(chatApi.util.invalidateTags([{ type: "pendingRequestList", id: newItem.id }]))

                  }
                }
              });
            });
            if (args.userIdType === "providerId") {
              const countUnreadLive = query(
                collection(db, "requests"),
                where(args?.userIdType, "==", args.loggedUserId),
                where("requestStatus", "==", args.requestStatus),
                where("unread", '==', "true"),
                orderBy("lastMessageTime", args.order),
                limit(args?.requestLimit)
              );
              getCountFromServer(countUnreadLive).then((snapshot) => {
                dispatch(updateUnreadLiveRequests(snapshot.data().count))
              }).catch((err) => console.error(err))
            } else {
              const countUnreadLive = query(
                collection(db, "requests"),
                where(args?.userIdType, "==", args.loggedUserId),
                where("requestStatus", "==", args.requestStatus),
                where("unreadSource", '==', "true"),
                orderBy("lastMessageTime", args.order),
                limit(args?.requestLimit)
              );

              getCountFromServer(countUnreadLive).then((snapshot) => {
                dispatch(updateUnreadLiveRequests(snapshot.data().count))
              }).catch((err) => console.log(err))
            }

            // Optional: Dispatch to update the cache using RTK Query API, if needed
            // dispatch(
            //   chatApi.util.upsertQueryData(
            //     "getMessages",
            //     'LIST',
            //     newData
            //   )
            // );
            // }
          }) // Unsubscribe on cache entry removal
          await cacheEntryRemoved
          unsubscribe();
        } catch (err) {
          console.error("Failed in onCacheEntryAdded lifecycle:", err);
          // Handle errors appropriately here
        }
      },
      providesTags: (result, error, reqID) => {
        result
          ?
          () => [
            ...result?.map(({ requestId }: any) => ({ type: 'liveRequestList', requestId })),
            { type: 'liveRequestList', id: 'LIST' },
          ]
          : () => [{ type: 'liveRequestList', id: 'LIST' }]
        return []
      },
      //       transformResponse(baseQueryReturnValue :{data:ItemWithISODate[]}):{data:ItemWithISODate[]} {
      // new Promise((resolve, rejeec)=> {

      // })

      //         const sortedArray = sortArrayByISODate(baseQueryReturnValue.data as ItemWithISODate[])
      //      if(baseQueryReturnValue.data.length > 0 )  { return {data:sortedArray}} 
      //      return baseQueryReturnValue 
      //     },
      keepUnusedDataFor: 300
    }),
    getPendingList: builder.query<ItemWithISODate[], any>({
      queryFn: async function fetchData(args, queryApi, extraOptions, baseQuery,) {

        const q = query(
          collection(db, "requests"),
          where(args?.userIdType, "==", args.loggedUserId),
          where("requestStatus", "==", args.requestStatus),
          orderBy("lastMessageTime", args.order),
          limit(args?.requestLimit)

        );

        try {
          const querySnapshot = await getDocs(q);
          const requestList: ItemWithISODate[] = querySnapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              date: doc.data().date?.toDate().toISOString(),
              lastMessageTime: doc.data().lastMessageTime?.toDate().toISOString(),
              requestId: doc.data().requestId,
              id: doc.id
            };
          });
          return { data: requestList };
        } catch (err) {
          console.error(err);
          return { data: [] };
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        await queryFulfilled
        if (args.userIdType === "providerId") {
          const countUnreadPending = query(
            collection(db, "requests"),
            where(args?.userIdType, "==", args.loggedUserId),
            where("requestStatus", "==", args.requestStatus),
            where("unread", '==', "true"),
            orderBy("lastMessageTime", args.order),
            limit(args?.requestLimit)
          );
          const snapshot = await getCountFromServer(countUnreadPending)
          dispatch(updateUnreadPendingRequests(snapshot.data().count))
        } else {
          const countUnreadPending = query(
            collection(db, "requests"),
            where(args?.userIdType, "==", args.loggedUserId),
            where("requestStatus", "==", args.requestStatus),
            where("unreadSource", '==', "true"),
            orderBy("lastMessageTime", args.order),
            limit(args?.requestLimit)
          );
          const snapshot = await getCountFromServer(countUnreadPending)
          dispatch(updateUnreadPendingRequests(snapshot.data().count))
        }

      },
      async onCacheEntryAdded(
        args,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) {
        try {
          await cacheDataLoaded; // Ensure the cache is loaded before proceeding
          const listQuery = query(
            collection(db, "requests"),
            where(args?.userIdType, "==", args?.loggedUserId),
            where("requestStatus", "==", args.requestStatus),
            orderBy("lastMessageTime", args.order),
          );


          // Subscribe to the query
          const unsubscribe = onSnapshot(listQuery, (querySnapshot) => {
            if (args.userIdType === "providerId") {
              const countUnreadPending = query(
                collection(db, "requests"),
                where(args?.userIdType, "==", args.loggedUserId),
                where("requestStatus", "==", args.requestStatus),
                where("unread", '==', "true"),
                orderBy("lastMessageTime", args.order),
                limit(args?.requestLimit)
              );
              getCountFromServer(countUnreadPending).then((snapshot) => {
                dispatch(updateUnreadPendingRequests(snapshot.data().count))
              }).catch((err) => console.error(err))
            } else {
              const countUnreadPending = query(
                collection(db, "requests"),
                where(args?.userIdType, "==", args.loggedUserId),
                where("requestStatus", "==", args.requestStatus),
                where("unreadSource", '==', "true"),
                orderBy("lastMessageTime", args.order),
                limit(args?.requestLimit)
              );

              getCountFromServer(countUnreadPending).then((snapshot) => {
                dispatch(updateUnreadPendingRequests(snapshot.data().count))
              }).catch((err) => console.error(err))
            }
            const changes = querySnapshot.docChanges();
            // console.log(changes.length, "check length")
            // if (changes.length > 0) {
            const newData: ItemWithISODate[] = changes.map((change) => {
              if (change.type === "modified") {

                return {
                  ...change.doc.data(),
                  date: change.doc.data().date?.toDate().toISOString(),
                  id: change.doc.id,
                  lastMessageTime: change.doc.data().lastMessageTime?.toDate().toISOString(),
                  requestId: change.doc.data()?.requestId,
                  modified: true, new: false, deleted: false
                }
              }
              if (change.type === "added") {
                return {
                  ...change.doc.data(),
                  date: change.doc.data().date?.toDate().toISOString(),
                  id: change.doc.id,
                  lastMessageTime: change.doc.data().lastMessageTime?.toDate().toISOString(),
                  requestId: change.doc.data()?.requestId,
                  new: true,
                  modified: false
                  , deleted: false
                };
              }
              if (change.type === 'removed') {
                return {
                  ...change.doc.data(),
                  date: change.doc.data().date?.toDate().toISOString(),
                  id: change.doc.id,
                  lastMessageTime: change.doc.data().lastMessageTime?.toDate().toISOString(),
                  requestId: change.doc.data()?.requestId,
                  new: false,
                  modified: false,
                  deleted: true

                }
              }
              return {} as ItemWithISODate
            });
            updateCachedData((draft) => {
              // Assuming 'draft' is an array of messages
              newData.forEach((newItem: ItemWithISODate) => {
                const index = draft.findIndex((listItem: any) => listItem.requestId === newItem.requestId);
                if (index !== -1) {
                  // If the message exists, update it
                  draft[index] = { ...draft[index], ...{ newItem, new: false } };

                } else {

                  // If the message doesn't exist, add it to the cache
                  draft.push({ ...newItem });
                }
              });
            });

            // Optional: Dispatch to update the cache using RTK Query API, if needed
            // dispatch(
            //   chatApi.util.upsertQueryData(
            //     "getMessages",
            //     'LIST',
            //     newData
            //   )
            // );
            // }
          }) // Unsubscribe on cache entry removal
          await cacheEntryRemoved
          unsubscribe();
        } catch (err) {
          console.error("Failed in onCacheEntryAdded lifecycle:", err);
          // Handle errors appropriately here
        }
      },
      providesTags: (result, error, reqID) => {
        result
          ?
          () => [
            ...result?.map(({ requestId }: any) => ({ type: 'pendingRequestList', requestId })),
            { type: 'pendingRequestList', id: 'LIST' },
          ]
          : () => [{ type: 'pendingRequestList', id: 'LIST' }]
        return []
      },
      keepUnusedDataFor: 300
    }),
  }),
});

export const { useGetMessagesQuery, useGetLiveListQuery, usePrefetch, useGetPendingListQuery } = chatApi;
