import React, { useCallback, useEffect, useState, useRef } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./MultiRangeSlider.css";


interface MultiRangeSliderProps {
  min: number;
  max: number;
  onChange?: any;
}
const MultiRangeSlider = ({ min, max, onChange }:MultiRangeSliderProps ) => {
  const [minVal, setMinVal] = useState<number>(min);
  const [maxVal, setMaxVal] = useState<number>(max);
  const minValRef = useRef<number>(null);
  const maxValRef = useRef<number>(null);
  const range = useRef<string>(null);

  // useEffect(()=>{setMinVal(min);setMaxVal(max)}, [min,max])
  // Convert to percentage
  const getPercent = useCallback(
    (value : any) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+(maxValRef as any).current.value); // Preceding with '+' converts the value from type string to type number

      if (range.current) {
        (range as any).current.style.left = `${minPercent}%`;
        (range as any).current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+(minValRef as any).current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        (range as any).current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal]);

  return (
    <div className="container pb-3 mb-3 px-0 px-md-3">
   
      <div className="slider">
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        ref={minValRef as any}
        onChange={(event) => {
          setMinVal(Math.min(+event.target.value, maxVal - 1));
          onChange({ min: Math.min(+event.target.value, maxVal - 1), max: maxVal })
          // event.target.value = value.toString();
          
        }}
        className={classnames("thumb thumb--zindex-3", {
          "thumb--zindex-5": minVal > max - 100
        })}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        ref={maxValRef as any}
        onChange={(event) => {
          setMaxVal(Math.max(+event.target.value, minVal + 1));
          onChange({ min: minVal, max: Math.max(+event.target.value, minVal + 1) })
          // event.target.value = value.toString();
        }}
        className="thumb thumb--zindex-4 "
      />
        <div className="slider__track " />
        <div ref={range as any} className="slider__range  " />
        <div className="pb-1">
        <div className="slider__left-value ">Min - {minVal}</div>
        <div className="slider__right-value">Max -  {maxVal}</div>
        </div>
        
      </div>
    </div>
  );
};

export default MultiRangeSlider;
