// src/features/searchSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Provider, SearchRequest } from '../../Types';
import { RootState } from '../../app/store';

type argType = SearchRequest | null

interface SearchState {
    data: Provider[];
    totalPages: number;
    pageNo: number;
    totalItems: number;
    arg: argType;
  }
  
  // Initial state
  const initialState: SearchState = {
    data: [],
    totalPages: 0,
    pageNo: 1,
    totalItems: 0,
    arg: null,
  };

const searchSlice = createSlice({
  name: 'searchSlice',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<Provider[]>) => {
      state.data = action.payload;
    },
    appendData: (state, action: PayloadAction<{ data: Provider[]; totalPages: number; pageNo: number; totalItems: number, arg: argType }>) => {
        state.data = [...state.data, ...action.payload.data];
        state.totalPages = action.payload.totalPages;
        state.pageNo = action.payload.pageNo;
        state.totalItems = action.payload.totalItems;
        state.arg = action.payload.arg;
      },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    clearData: (state, action: PayloadAction<SearchRequest>) => {        
        // Destructure the action payload and current state arguments
        const { vedicSubject, aroundMe, classType, priceRange, groupOrPrivate, sortOrder, sortBy, firstClassFree } = action.payload;
        const { arg } = state;
    
        // Check if any of the parameters except 'pageNo' are different from the previous ones
        if (
            arg &&
            (
                arg.vedicSubject !== vedicSubject ||
                arg.aroundMe !== aroundMe ||
                arg.classType !== classType ||
                arg.priceRange !== priceRange ||
                arg.groupOrPrivate !== groupOrPrivate ||
                arg.sortOrder !== sortOrder ||
                arg.sortBy !== sortBy ||
                arg.firstClassFree !== firstClassFree
            )
        ) {
            // Clear data if any of the conditions are met
            state.data = [];
            state.totalPages = 0;
            state.pageNo = 1;
            state.totalItems = 0;
            state.arg = null;
        }
    },    
  },
});

export const { setData, appendData, setPageNo, clearData } = searchSlice.actions;
export default searchSlice.reducer;
export const currentSearchData = (state: RootState) => state.searchSlice;
