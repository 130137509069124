import { useEffect, useState } from 'react';

const useVersionCheck = (interval = 5 * 60 * 1000) => { // Default interval: 5 * 60 * 1000 = 5 minutes
  const [isUpdated, setIsUpdated] = useState(true); // Tracks version status
  const [message, setMessage] = useState<string | null>(null);
  const [showUpdatePrompt, setShowUpdatePrompt] = useState(false); // Controls visibility of update prompt
  const [latestVersion, setLatestversion] = useState<string | null>(null);

  const checkVersion = async () => {
    try {
      const response = await fetch('/version.json', { cache: 'no-cache' });
      const data = await response.json();
      const latestVersionFetched = data?.version;
      const latestMessage = data?.message;
      if(latestVersionFetched) setLatestversion(latestVersionFetched)
      const currentVersion = localStorage.getItem('appVersion');

      // Check if the version has changed
      if (currentVersion && currentVersion !== latestVersionFetched) {
        setIsUpdated(false);
        setShowUpdatePrompt(true); // Show prompt when a new version is available
        setMessage(latestMessage);
      } else {
        // Store the new version and update state
        if (!currentVersion || currentVersion !== latestVersionFetched) {
          localStorage.setItem('appVersion', latestVersionFetched);
        }
        setIsUpdated(true);
        setMessage(latestMessage);
        setShowUpdatePrompt(false);
      }
    } catch (error) {
      console.error('Error fetching version:', error);
    }
  };

  useEffect(() => {
    // Check the version immediately on mount
    checkVersion();

    // Set up interval to re-check version
    const intervalId = setInterval(checkVersion, interval);
    return () => clearInterval(intervalId); // Clean up interval on unmount
  }, [interval]);

  // Reload page without setting a new version here
  const refreshPage = () => {
    if(latestVersion)localStorage.setItem('appVersion', latestVersion);
    window.location.reload();
  };

  return { isUpdated, message, showUpdatePrompt, refreshPage };
};

export default useVersionCheck;
