import { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
// import "./PaymentSub.css"
import razorpay from "../../../assets/img/razorpay.svg"
import checkicon from "../../../assets/img/check-icon.svg"
import leftarrow from "../../../assets/img/left-arrow.svg"
import lockImg from "../../../assets/img/lock.png"
import checkYellow from "../../../assets/img/check-yellow.svg"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import {
  usePaymentCheckoutMutation,
  usePaymentVerifyMutation,
  useInitatePaymentKeyMutation
} from "../../../features/featSeekerDashboard/seekerDashboardApi"
import { handlePayment } from "../paymentGatewayfunction"
import sendReqImg from "../../../assets/images/sendReqImg.png"
import { useAppSelector } from "../../../app/hooks"
import { setSeekerRequest } from "../../../features/featSeekerDashboard/seekerDashboardSlice"
import { useDispatch } from "react-redux"
import premimumStar from "../../../assets/images/premium-icon.svg"
import { HiOutlineArrowSmallLeft } from "react-icons/hi2"
import { handlePaymentPackages } from "../PaymentProviderPackage"
import SpinnerSecondary from "../../spinner/SpinnerSecondary"
import { toast } from "react-toastify"
const ProviderDashboardPayment = () => {
  const [showRequest, setShowRequest] = useState<boolean>(true)
  const [isHovered, setIsHovered] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const { state } = useLocation()
  const { planType, price, period, perks } = state
  const [payment, { data, isLoading }] = usePaymentCheckoutMutation()
  const [paymentVerify, { data: paymentSucccessData, isSuccess }] =
    usePaymentVerifyMutation()

  useEffect(() => {
    payment({ amount: price })
  }, [])

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const [initiatePayment, { isError: paymentKeyError }] =
  useInitatePaymentKeyMutation()  

  return (
    <>
      {isLoading ? (
        <div>
          <SpinnerSecondary />
        </div>
      ) : (
        <section className="explore-section pt-90">
          <Container>
            <div className="inner-explore bg">
              <Row>
                <Col md={4}>
                  <div
                    className="planCardMain active selected-card"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => navigate(`/packagePayment/${id}`)}
                  >
                    <div className="d-flex justify-content-between mb-5">
                      <div>
                        <p className="perks font-14">{planType}</p>
                        <h3 className="perks mb-0">
                          <span>₹</span> {price}
                        </h3>
                        <p className="perks mb-0 fw-light font-14">{period}</p>
                      </div>
                      <img
                        className="mt-2 icon"
                        src={premimumStar}
                        style={{ width: "52px", height: "52px" }}
                        alt="premimumStar"
                      />
                    </div>
                    <div className="perks h-100 d-flex flex-column justify-content-between">
                      {perks.map((text: any, index: any) => (
                        <>
                          <div className="d-flex w-100 gap-2" key={index}>
                            <span className="check-icon-list">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M23 12L20.56 9.22004L20.9 5.54004L17.29 4.72004L15.4 1.54004L12 3.00004L8.6 1.54004L6.71 4.72004L3.1 5.53004L3.44 9.21004L1 12L3.44 14.78L3.1 18.47L6.71 19.29L8.6 22.47L12 21L15.4 22.46L17.29 19.28L20.9 18.46L20.56 14.78L23 12ZM10 17L6 13L7.41 11.59L10 14.17L16.59 7.58004L18 9.00004L10 17Z"
                                  fill="#FB6D48"
                                />
                              </svg>
                            </span>
                            <p className="perks perks-list">{text}</p>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </Col>
                <Col md={8}>
                  <div className="schedule-section ps-0 ps-sm-4">
                    <h2
                      className="member-text fw-light"
                      style={{ color: "#434343" }}
                    >
                      Membership Pass Vaild for{" "}
                      <b className="fw-medium">30 Days</b>
                    </h2>
                    <div>
                      <p>Select payment method</p>
                    </div>
                    <div className="mb-5 d-inline-block me-2">
                      <input
                        type="radio"
                        className="payment-radio"
                        id="razorpay"
                        name="payment-method"
                      />
                      <label className="payment-link" htmlFor="razorpay">
                        <img src={razorpay} className="payment-icon" />
                        {/* <div className="check-icon">
                        <img src={checkicon} />
                      </div> */}
                      </label>
                    </div>
                    <div className="mb-5 d-inline-block me-2">
                      <input
                        type="radio"
                        className="payment-radio"
                        id="paytm"
                        name="payment-method"
                      />
                      <label className="payment-link" htmlFor="paytm">
                        <img src={razorpay} className="payment-icon" />
                        {/* <div className="check-icon">
                        <img src={checkicon} />
                      </div> */}
                      </label>
                    </div>
                    <div className="mb-5 d-inline-block me-2">
                      <input
                        type="radio"
                        className="payment-radio"
                        id="googlepay"
                        name="payment-method"
                      />
                      <label className="payment-link" htmlFor="googlepay">
                        <img src={razorpay} className="payment-icon" />
                        {/* <div className="check-icon">
                        <img src={checkicon} />
                      </div> */}
                      </label>
                    </div>
                    <div>
                      <div className="">
                        <a
                          onClick={() => { planType &&
                            initiatePayment({
                                packageName: planType,
                                requestId: null,
                            })}}
                          className="studentPassBtn d-inline-flex justify-content-center align-items-center text-center mt-4"
                        >
                          Get Membership
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      )}
    </>
  )
}

export default ProviderDashboardPayment
