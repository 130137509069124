import EmojiPicker from 'emoji-picker-react'
import React, { useState } from 'react'
import "./emojiPick.css"

interface props {
    handleEmojiChange: (value: any) => void,
    onReactionClick:any,
    onSkinToneChange:any,
    show: boolean,
}
const EmojipIck = ({handleEmojiChange,onReactionClick, onSkinToneChange, show}:props) => {
    function onEmojiClickHandle (emoji: any) {
        handleEmojiChange(emoji)
    }
  return (
    <>
    <div className={`${show ? '': 'd-none'}`}>
    <EmojiPicker
    className='emojis-card'
    onEmojiClick={(e)=>onEmojiClickHandle(e.emoji)}
    onReactionClick={undefined}
    onSkinToneChange={undefined} />
    </div>
    </>
  )
}

export default EmojipIck