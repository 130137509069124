import React, { useEffect } from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'
import { useDeleteAdMutation } from '../../../features/featProviderDashboard/FeatProviderApi'
import { toast } from 'react-toastify'

interface props {
    show: boolean
    handleClose: any
    adID: string
}

const DeleteAdModal = (prop: props) => {
    const[deleteAd, {isError, isSuccess, error, data, isLoading}] = useDeleteAdMutation()
    useEffect(()=>{
        if(isLoading) return
        else if(isError) {toast.error("Could not delete"); prop.handleClose()}
        else {toast.success(data?.message); prop.handleClose()}
        
    },[isSuccess, isError, isLoading])
  return (
    <>
    <Modal show={prop.show}>
        <Modal.Header className='d-flex align-items-center justify-content-center'>
          <Modal.Title className="delete-heading text-center">Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                <Row className='text-center get-started-text fs-6'>
                    <p>Are you sure to delete this ad?</p>
                </Row>
                <Row className='mt-4'>
                    <Col className=' d-flex   justify-content-center align-items-center' xs={6}>
                    <Button variant="secondary" className='  modal-btn-2' onClick={()=>{prop.handleClose()}}>
                        Cancel
                    </Button>
                    </Col>
                    <Col className=' d-flex  justify-content-center align-items-center' xs={6}>
                    <Button variant="danger" className=' modal-btn-2 delete-btn-2' disabled={isLoading} onClick={()=>{deleteAd({adId: prop.adID})}}>
                        Delete
                    </Button>
                    </Col>
                </Row>
            </Container>
        </Modal.Body>
    </Modal>
    </>
  )
}

export default DeleteAdModal