import React, { useEffect, useState } from "react"
import { Navigate, useSearchParams } from "react-router-dom"
import { useAppSelector } from "../app/hooks"
import {
  selectCurrentUser,
  setCurrentUser,
  updateProviderToken,
  updateSeekerToken,
} from "../features/Auth/authSlice"
import { MODALNAME, USERTYPE } from "../constants"
import { useAppDispatch } from "../app/store"
import { setModalName } from "../features/Auth/ModalHandler/modalSlice"
import { Button } from "react-bootstrap"
import { useGetseekerProfileQuery } from "../features/featSeekerDashboard/seekerDashboardApi"
interface handlerProps {
  userType: USERTYPE
}
const GoogleCallBackHandler = ({ userType }: handlerProps) => {
  const [searchParams] = useSearchParams()
  const { currentUserRole, providerToken, seekerToken } =
    useAppSelector(selectCurrentUser)
  const dispatch = useAppDispatch()
  useEffect(() => {
    const tokenCode: string | null = searchParams.get("code")
    if (tokenCode) {
      if (userType === USERTYPE.PROVIDER) {
        dispatch(updateProviderToken(tokenCode))
      }

      if (userType === USERTYPE.SEEKER) {
        dispatch(updateSeekerToken(tokenCode))
      }
    } else {
      console.error("Code parameter is missing")
    }
  }, [searchParams])
  return (
    <>
      {providerToken || seekerToken ? (
        <Navigate to="/" replace />
      ) : (
        <p>
          Unable to signin with your google account
          <br />
          <Button
            onClick={() => {
              dispatch(setCurrentUser(userType)),
                dispatch(setModalName(MODALNAME.LOGIN))
            }}
          >
            Try Signing in Again!{" "}
          </Button>
        </p>
      )}
    </>
  )
}

export default GoogleCallBackHandler
