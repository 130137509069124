import React from 'react'
import ExploreSkeletonCard from './ExploreSkeletonCard'
import './skeleton.css'
import { useWindowWidth } from '../../utils/commonFunctions/CommonFunctions';


const ExplorePageSkeleton = () => {
  const width = useWindowWidth();
  const getNumberOfCards = (): number => {
    if (width < 769) return 1;
    if (width < 993) return 2;
    return 3;
};

const numberOfCards = getNumberOfCards();
const cardsArray = Array.from({ length: numberOfCards }, (_, index) => (
    <ExploreSkeletonCard key={index} keyValue={index} />
));

return <>{cardsArray}</>;

}

export default ExplorePageSkeleton