import React, { lazy, Suspense, useEffect } from 'react'
const TermsModalContent = lazy(() => import('../../termsModalContent/TermsModalContent'))
import CustomBackNavButton from '../../buttons/custumBackNavButton/CustomBackNavButton'
import SpinnerSecondary from '../../spinner/SpinnerSecondary'
import { privacyPolicyContentContent } from '../../../assets/content/privacyPolicyContent'

const PrivacyPolicyPage = () => {
  useEffect(()=>window.scrollTo(0,0),[])
  return (
    <>
    <div className='m-3 m-md-5 pt-5'>
    <CustomBackNavButton class_name=''/>
    <Suspense fallback={<SpinnerSecondary/>}>
    <TermsModalContent class_name={'m-1'} content={privacyPolicyContentContent} />
    </Suspense>
    </div>
    </>
  )
}

export default PrivacyPolicyPage