import { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
import leftarrow from "../../../../assets/img/left-arrow.svg"
import LandingHeader from "../../../navbars/TopNavbar"
import vedicjourney from "../../../../assets/img/vedic-journey.png"
import { RootState, useAppDispatch } from "../../../../app/store"
import { updateFunnel } from "../../../../features/ServiceFunnel/FeatSeekerFunnel/seekerFunnelSlice"
import { useAppSelector } from "../../../../app/hooks"
import "../SeekerStepOne.css"
import "./seekerStepFour.css"
const SeekerStepFour = () => {
  const [selectedValue, setValue] = useState<string | null>(null)
  const funnelData = useAppSelector((state: any) => state.seekerFunnel)
  const dispatch = useAppDispatch()

  const handleSelectChange = (name: string) => {
    setValue(name)
    const isStepValid = name !=="" ? true : false;
    dispatch(updateFunnel({ ...funnelData, modeOfClass: name, isStepValid }))
  }
  useEffect(()=>{
    if(funnelData.modeOfClass!=='') dispatch(updateFunnel({ ...funnelData, isStepValid: true }))
    else dispatch(updateFunnel({ ...funnelData, isStepValid: false }))
  },[])

  return (
    <>
      <section>
        <Container>
          <div className="inner-fav">
            <Row className="justify-content-center">
              <Col lg={6}>
                <div className="d-flex justify-content-between flex-column">
                  <div>
                    <form>
                      <label className="get-started-text mb-2">
                        How would you prefer the session to take place?
                      </label>
                      <div className="mb-4">
                        <input
                          type="radio"
                          name="place-radio"
                          className="session-radio"
                          id="virtual-place"
                          value={'Virtual'}
                          defaultChecked={funnelData?.modeOfClass=='Virtual'}
                          onChange={(e) => {handleSelectChange(e.target.value)}}
                        />
                        <label
                          className="session-label"
                          htmlFor="virtual-place"
                        >
                          Virtual
                        </label>
                      </div>
                      <div className="mb-4">
                        <input
                          type="radio"
                          name="place-radio"
                          className="session-radio"
                          id="real-time-place"
                          value={"realTime"}
                          defaultChecked={funnelData?.modeOfClass=='realTime'}
                          onChange={(e) => {handleSelectChange(e.target.value)}}
                        />
                        <label
                          className="session-label"
                          htmlFor="real-time-place"
                        >
                          Real time
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="place-radio"
                          className="session-radio"
                          id="any-place"
                          value={'Any'}
                          defaultChecked={funnelData?.modeOfClass=='Any'}
                          onChange={(e) => {handleSelectChange(e.target.value)}}
                        />
                        <label className="session-label" htmlFor="any-place">
                          Any
                        </label>
                      </div>
                    </form>
                  </div>
                  <div></div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  )
}

export default SeekerStepFour
