import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
export interface chatReqData {
  sourcePic?: string
  requestId?: string
  unread: number
  sessionFor: string
  preference: string
  seekerPic: string
}
const ChatSlice = createSlice({
  name: "chat",
  initialState: {
    userChat: null,
    chatToken: null,
    unreadPendingRequests:0,
    unreadLiveRequests:0,
    requestChatData: {
      requestId: "",
      sourcePic: "",
      unread: 0,
      sessionFor: "",
      preference: "",
      seekerPic: "",
    },
  } as {
    userChat: null | any
    chatToken: null | string
    requestChatData: chatReqData
unreadPendingRequests  :number  
unreadLiveRequests  :number 


  },
  reducers: {
    updatechatToken: (state: any, action: PayloadAction<string>) => {
      // state.user = null
      state.chatToken = action.payload
    },

    updateRequest: (state: any, action: PayloadAction<chatReqData>) => {
      // state.user = null
      state.requestChatData = { ...state.requestChatData, ...action.payload }
    },
    updateChatUser: (state: any, action: PayloadAction<any>) => {
      // state.user = null
      state.userChat = action.payload
    },

    updateUnreadPendingRequests: (state: any, action: PayloadAction<any>) => {
      // state.user = null
      state.unreadPendingRequests = action.payload
    },

    updateUnreadLiveRequests: (state: any, action: PayloadAction<any>) => {
      // state.user = null
      state.unreadLiveRequests = action.payload
    },
    setLoading: (state: any, action: any) => {
      state.loading = action.payload
    },
    // setPageCountOnScroll: (state: any, action: any) => {
    //   state.pageNo = action.payload
    // },
  },
})
export default ChatSlice.reducer
export const { setLoading, updateChatUser, updateRequest, updateUnreadPendingRequests, updateUnreadLiveRequests  } = ChatSlice.actions

export const requestData = (state: RootState) => state.ChatSlice.requestChatData
