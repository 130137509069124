import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { MdSignalWifiStatusbarConnectedNoInternet } from 'react-icons/md';

const UserConnectedCheck = () => {
  const [isOffline, setIsOffline] = useState<boolean>(!navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOffline(false);
    };

    const handleOffline = () => {
      setIsOffline(true);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <Modal
      show={isOffline}
      centered
      backdrop="static"
      keyboard={false}
      animation={true}
      aria-live="assertive"
    >
      <Modal.Body className="d-flex flex-column align-items-center justify-content-center p-4">
        <MdSignalWifiStatusbarConnectedNoInternet size={48} color="red" />
        <h5 className="mt-3 mb-0">You are currently offline</h5>
        <p className="text-muted">Please check your internet connection</p>
      </Modal.Body>
    </Modal>
  );
};

export default UserConnectedCheck;
