import React, { useEffect, useState } from 'react'
import ToggleButton from '../../../buttons/ToggleButton'
import { Form } from 'react-bootstrap'

interface props {
    data: any,
    onToggle: any,
    onApply: any,
    isSelected: boolean
}

const CheckBox = (prop: props) => {
  return (
    <Form.Check
    className={`dropdown-menu_show d-flex flex-row gap-0 align-items-center CheckBox  ${prop.isSelected ? 'primary-bg text-light':''}`}
      id={prop.data.key}
      label={prop.data.label}
      checked={prop.isSelected}
      onChange={()=>{prop.onToggle(prop.data.key, prop.data.key, !prop.isSelected); prop.onApply(prop.data.key)}}
    />  
  )
}

export default CheckBox