export const techerProfileLoader = async (): Promise<null> => {
    const href = window.location.href;

    // Set the document title dynamically
    document.title = "VedicHom";

    // Helper function to set or update meta tags
    const setMetaTag = (name: string, content: string) => {
        let meta = document.querySelector(`meta[name='${name}']`);
        if (!meta) {
            meta = document.createElement('meta');
            meta.setAttribute('name', name);
            document.head.appendChild(meta);
        }
        meta.setAttribute('content', content);
    };

    const setOgMetaTag = (property: string, content: string) => {
        let meta = document.querySelector(`meta[property='${property}']`);
        if (!meta) {
            meta = document.createElement('meta');
            meta.setAttribute('property', property);
            document.head.appendChild(meta);
        }
        meta.setAttribute('content', content);
    };

    // Set the general meta tags
    setMetaTag('title', "Unlocking the Secrets of Vedic Wisdom for Modern Living");
    setMetaTag('description', "Grow Your Teaching Reach – Join VedicHom and Connect with Knowledge Seekers Worldwide");
    setMetaTag('keywords', "Vedic wisdom, Yoga, Meditation, Ayurveda, Astrology, Personal growth");

    // Set Open Graph meta tags dynamically
    setOgMetaTag('og:title', "Unlocking the Secrets of Vedic Wisdom for Modern Living");
    setOgMetaTag('og:description', "Grow Your Teaching Reach – Join VedicHom and Connect with Knowledge Seekers Worldwide");
    setOgMetaTag('og:url', href); // Dynamically set the current page URL
    setOgMetaTag('og:type', "website");
    setOgMetaTag('og:image', "https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/metatag.png");
    setOgMetaTag('og:image:alt', "A visual representation of Vedic wisdom and practices.");

    // Set Twitter card meta tags dynamically
    setMetaTag('twitter:card', "summary_large_image");
    setMetaTag('twitter:title', "Unlocking the Secrets of Vedic Wisdom for Modern Living");
    setMetaTag('twitter:description', "Grow Your Teaching Reach – Join VedicHom and Connect with Knowledge Seekers Worldwide");
    setMetaTag('twitter:image', "https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/metatag.png");
    setMetaTag('twitter:image:alt', "A visual representation of Vedic wisdom and practices.");

    return null; // No data to pass to the component
};
