function getCounts(jsonString: string) {
    let acceptCount = 0;
    let pendingCount = 0;

    // Check if the jsonString is empty or not a valid JSON array or object
    if (!jsonString || jsonString === '""' || jsonString === '[]') {
        return { acceptCount, pendingCount };
    }

    // Parse the JSON string
    let data;
    try {
        data = JSON.parse(jsonString);
    } catch (e) {
        console.error("Invalid JSON string");
        return { acceptCount, pendingCount };
    }

    // If data is an object, wrap it in an array
    if (!Array.isArray(data)) {
        data = [data];
    }

    // Extract counts
    data.forEach(item => {
        if (item._id === "Accept") {
            acceptCount = item.count;
        } else if (item._id === "Pending") {
            pendingCount = item.count;
        }
    });
    return { acceptCount, pendingCount };
}

export { getCounts }