import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RootState } from "../../app/store"
import { APP_ENV } from "../../constants"
const BASE_URL = APP_ENV &&  ['testing','development'].includes(APP_ENV as string) ? import.meta.env.VITE_REACT_TEST_API_URL : import.meta.env.VITE_REACT_APP_API_URL 

export const searchAdvtsApi = createApi({
  reducerPath: "searchAdvtsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/`,
    prepareHeaders: (headers, { getState }) => {
      const token =
        (getState() as RootState).auth.seekerToken ||
        (getState() as RootState).auth.providerToken
      if (token) {
        headers.set("authorization", `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ["searchAdvts"],
  endpoints: (builder) => ({
    searchAdvts: builder.mutation<any, any>({
      query: (payload) => ({
        url: `static-data/get-sub-suggestion?subject=${
          payload.searchText ? payload.searchText : payload.selectedSearchText
        }`,
        method: "get",
      }),
      // providesTags:['searchAdvts'],

      transformResponse: (response: any) => {
        return response.data
      },
    }),
  }),
})

export const { useSearchAdvtsMutation } = searchAdvtsApi
