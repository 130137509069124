import ProfileCard from "./ProfileCard"
import { Container, Row, Col } from "react-bootstrap"
import "./newDash.css"
import { isLaunched, menuItem, MODALNAME, SOCIAL_LOGIN_TYPES, USERTYPE } from "../../../constants"
import OverviewPanel from "./DashboardRight"
import { useAppSelector } from "../../../app/hooks"
import { useProviderAccountDetailsQuery } from "../../../features/featProviderDashboard/FeatProviderApi"
import { useGetseekerProfileQuery } from "../../../features/featSeekerDashboard/seekerDashboardApi"
import {
  Navigate,
  Outlet,
  matchPath,
  useLocation,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom"
import TopNavbar from "../../navbars/TopNavbar"
import DashNav from "../../navbars/DashNav/DashNav"
import ChatWrapper from "../../chatUi/ChatWrapper"
import ProfileSection from "./ProfileSection"
import { onAuthStateChanged } from "firebase/auth"
import { auth } from "../../../app/firebase"
import VerificationModal from "../commonDashboard/generalInformation/verificationModal/VerificationModal"
import { dashProps } from "../../../Types"
import { useEffect, useState } from "react"
import NewDashnav from "../../navbars/DashNav/NewDashnav"
import ModalControl from "../../modals/commonModal/ModalControl"
import { AnimatePresence, motion, useAnimate } from "framer-motion"
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';
import { menuItemDemo, menuItemMobileDemo, options } from "../../welcomeInstruction/InstructionComponent"
import { useConnectSocketQuery } from "../../../features/Auth/socketApi"
import { selectCurrentUser } from "../../../features/Auth/authSlice"
import { useAppDispatch } from "../../../app/store"
import { setModal, setModalName } from "../../../features/Auth/ModalHandler/modalSlice"
import PromotionpageWrapper from "../../hoc/PromotionpageWrapper"

const DashWrapper = ({ userType }: { userType: USERTYPE }) => {
  const [sidebarActive, setSidebarActive] = useState<boolean>(true)
  const [isChatScreen, setIsChatScreen] = useState(false)
  const [chatUserData, setChatUserData] = useState<any>()
  const [selectedChatData, setSelectedChatData] = useState<any>({})
  const [scoop, animate] = useAnimate()
  const [serarchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const modalName = useAppSelector((state: any) => state.authModal.modalName)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setChatUserData(user)
      } else {
        return <Navigate to="/" />
      }
    })
    return () => unsubscribe()
  }, [])
  const location = useLocation()
  const [ProfileCardInMobile, setProfileCardInMobile] = useState<boolean>(false)
  // location.pathname === "/source"
  const {
    data: sourceProfileData,
    isLoading: getLoading,
    refetch: refetchProvider,
  } = useProviderAccountDetailsQuery(null, {
    skip: userType === USERTYPE.SEEKER,
  })
  const {
    data: seekerProfileData,
    isLoading,
    refetch: refetchSeeker,
  } = useGetseekerProfileQuery(null, {
    skip: userType === USERTYPE.PROVIDER,
  })
  const authUser = useAppSelector((state: any) => state.auth)
  const { providerToken, seekerToken } = useAppSelector(selectCurrentUser)
  const { data: messages } = useConnectSocketQuery((seekerToken || providerToken), {
    skip: seekerToken === null && providerToken === null,
    refetchOnMountOrArgChange: true
  });
  // error causes
  // useEffect(() => {
  //   if (authUser?.user?.isMobileNoVerified) {
  //     if (userType === USERTYPE.PROVIDER) {
  //       refetchProvider()
  //     } else {
  //       refetch()
  //     }
  //   }
  // }, [authUser?.user?.isMobileNoVerified])

  // REQUIRED AS UPON LOGIN it tends to keep old user data as cache and pass that as authUser without it
  useEffect(() => {
    if (userType === USERTYPE.PROVIDER) refetchProvider()
    if (userType === USERTYPE.SEEKER) refetchSeeker()
  }, [])
  useEffect(() => {
    scoop?.current?.scrollIntoView({ behavior: "smooth" })
    if (
      location.pathname === "/seeker-dashboard/my-requests" ||
      location.pathname === "/source-dashboard/my-requests" ||
      location.pathname.includes("my-requests")
    ) {
      setIsChatScreen(true)
    } else setIsChatScreen(false)
    setProfileCardInMobile(
      location.pathname.includes("my-account") ||
        location.pathname.includes("my-advt"),
    )
  }, [location.pathname])
  // const colRight = {
  //   lg: 8,
  //   xl: 9,
  // }
  useEffect(() => {
    if (authUser?.user?.socialLogin === SOCIAL_LOGIN_TYPES.GOOGLE) {
      if(!authUser?.user?.termsAccepted) {
        dispatch(setModalName(MODALNAME.TERMS_MODAL))
        serarchParams.set("modal", MODALNAME.TERMS_MODAL)
        setSearchParams(serarchParams)
      }
      else if (serarchParams.get('modal')) {
        serarchParams.delete('modal')
        setSearchParams(serarchParams)
      }
      if (authUser?.user?.activeReferral === undefined ) {
        dispatch(setModal({name: MODALNAME.REFFERAL_ENTRY, data: {userId: authUser.user._id, userType: authUser.role || authUser.currentUserRole}}))
      }
    }
  }, [authUser])

  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps] = useState<Step[]>(window.innerWidth > 991 ? menuItemDemo : menuItemMobileDemo);

  useEffect(() => {
    const hasSeenLogedinUSer = localStorage.getItem('hasSeenLogedinUSer');
    if (!hasSeenLogedinUSer) {
      setRun(isLaunched ? true : false);
      localStorage.setItem('hasSeenLogedinUSer', 'true');
    }
  }, []);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setRun(false);
      setStepIndex(0);
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(nextStepIndex);
    }
  };

  return (
    <>
    <Joyride
        steps={steps}
        run={run && (modalName === MODALNAME.CLOSED)}
        continuous={true}
        scrollToFirstStep={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        stepIndex={stepIndex}
        spotlightPadding={10}
        scrollDuration={20}
        styles={{options}}
      />
    <AnimatePresence mode="sync">
      <TopNavbar navClass=""    sidebarActive={sidebarActive} />
      <div className="d-lg-none">
        <DashNav userType={userType} />
        </div>
        {/* <DashboardNav userType={userType} />   */}
        <div className="d-flex justify-content-end ">
          <aside className="dash-aside d-none d-lg-block">
            <NewDashnav
              userType={userType}
              sidebarActive={sidebarActive}
              setSidebarActive={setSidebarActive}
            />
          </aside>

          <main
            className={`dash-main  bg-warning ${
              sidebarActive ? "dash-main-collapsed" : "dash-main-expanded"
            }`}
          >
            <section className="provider-sec bg-white ">
              <Container fluid className=" custom-scrollbar rounded-xl">
                <Row>
                  {location.pathname === "/seeker-dashboard/my-requests" ||
                  location.pathname === "/source-dashboard/my-requests" ||
                  isChatScreen ? (
                    <></>
                  ) : (
                    <Col
                      lg={4}
                      xl={3}
                      style={{ maxHeight: "90vh" }}
                      className={`p-0 m-0 custom-scrollbar overflow-y-auto overflow-x-hidden ${
                        ProfileCardInMobile
                          ? "d-block"
                          : "d-none d-lg-block rounded-xl"
                      }`}
                    >
                      <motion.div
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.6 }}
                        className="p-2 p-md-0 px-md-2 ps-xl-3"
                        ref={scoop}
                      >
                        <ProfileSection
                          userType={userType}
                          sourceProfileData={
                            USERTYPE.PROVIDER && sourceProfileData
                          }
                          seekerProfileData={
                            USERTYPE.SEEKER && seekerProfileData
                          }
                          isLoading={isLoading || getLoading}
                          authUser={authUser}
                        />
                      </motion.div>
                    </Col>
                  )}
                  <Col
                    lg={isChatScreen ? 12 : 8}
                    xl={isChatScreen ? 12 : 9}
                    className={` rounded-xl light-bg_2 box-scroll custom-scrollbar overflow-x-hidden ${serarchParams.get('requestId') !== null ? 'mb-0 px-0' : ''}`}
                  >
                    <div className="p-0 p-md-2 pt-md-4">
                      <PromotionpageWrapper >
                      <Outlet
                        key={location.pathname}
                        context={
                          {
                            userType,
                            sourceProfileData:
                              USERTYPE.PROVIDER && sourceProfileData,
                            seekerProfileData:
                              USERTYPE.SEEKER && seekerProfileData,
                            isLoading,
                            authUser,
                            chatUserData,
                            selectedChatData,
                          } satisfies dashProps
                        }
                      /></PromotionpageWrapper>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </main>
        </div>

      </AnimatePresence>
    </>
    // :
    // <Navigate to={"/journey-source"} replace />
  )
}

export default DashWrapper
