import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { ControllerRenderProps } from 'react-hook-form';
import {
    usePhoneInput,
    CountrySelector,
    DialCodePreview,
    CountryIso2,
} from 'react-international-phone';
import styles from './style.module.css';
import { sanitizePhoneNumber } from '../../utils/commonFunctions/CommonFunctions';

interface PhoneNumberInputFieldProps {
    onChange: (phone: string, country: string) => void;
    controller?: ControllerRenderProps;
    defaultCountry?: CountryIso2;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    inputFieldClassName?: string;
}

const PhoneNumberInputField: React.FC<PhoneNumberInputFieldProps> = ({
    onChange: handleChangeAtParent,
    controller,
    defaultCountry,
    placeholder = 'Enter your phone number',
    disabled = false,
    className,
    inputFieldClassName,
}) => {
    const {
        inputValue,
        phone,
        country,
        setCountry,
        handlePhoneValueChange,
        inputRef,
    } = usePhoneInput({
        defaultCountry: 'in',
        disableCountryGuess: true,
        value: controller?.value || '', // Use controller value if available
        onChange: ({ phone, inputValue, country }) => {
            const number = sanitizePhoneNumber(phone.replace(country.dialCode, ''))
            const countryCode = country.dialCode
            handleChangeAtParent(number, countryCode)

        },
    });

    
    function sanitizeInputValue(inputValue: string): string {
        if (!country?.dialCode) {
            return inputValue;
        }
    
        // Ensure the input always starts with the dial code
        const dialCodeWithPrefix = `${country.dialCode}`;
        if (!inputValue.startsWith(dialCodeWithPrefix)) {
            return dialCodeWithPrefix + inputValue.replace(/^\+?\d*/, '');
        }
    
        return inputValue;
    }
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handlePhoneValueChange(e);
    };
    
    

    return (
        <div
            className={`d-flex flex-row justify-content-center align-items-center ${
                className || ''
            }`}
        >
            {/* Country Selector */}
            <CountrySelector
                selectedCountry={country?.iso2 || defaultCountry || 'us'}
                onSelect={({ iso2 }) => setCountry(iso2)}
                disabled={disabled}
                className={`${styles.flagInputFieldClassName}`}
            />

            {/* Dial Code Preview */}
            {/* <DialCodePreview
                className={`${styles.dialCodeFieldClassName}`}
                dialCode={country?.dialCode || ''}
                prefix="+"
            /> */}

            {/* Input Field */}
            <span className={disabled?' text-black-50':' text-black'}>+</span>
            <Form.Control
                ref={inputRef}
                type="tel"
                value={sanitizeInputValue(inputValue)}
                onChange={handleInputChange}
                placeholder={placeholder}
                disabled={disabled}
                className={`h-100 ${
                    inputFieldClassName || ''
                } ${styles.inputFieldClassName}`}
            />
        </div>
    );
};

export default PhoneNumberInputField;
