import React, { lazy, Suspense, useEffect } from 'react'
const TermsModalContent = lazy(() => import('../../termsModalContent/TermsModalContent'))
import CustomBackNavButton from '../../buttons/custumBackNavButton/CustomBackNavButton'
import SpinnerSecondary from '../../spinner/SpinnerSecondary'
import { termsandConditionContent } from '../../../assets/content/termsandConditionscontent'

const TermsCnditionPage = () => {
  useEffect(()=>window.scrollTo(0,0),[])
  return (
    <>
    <div className='m-3 m-md-5 pt-5'>
    <CustomBackNavButton class_name=''/>
    <Suspense fallback={<SpinnerSecondary/>}>
    <TermsModalContent class_name={'m-1'} content={termsandConditionContent}/>
    </Suspense>
    </div>
    </>
  )
}

export default TermsCnditionPage