const base_url = 'https://www.vedichom.com'
export const privacyPolicyContentContent = (
  <>
    <div>
      <div className="tc-topic-text">
              VedicHom Privacy Policy</div>
      <p className="tc-text-info">
        At <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}, your privacy is important to us. This Privacy Policy outlines how we collect, use,
        store, and protect your personal information when you use our platform. By accessing or using
        <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "}, you agree to the terms of this Privacy Policy.

      </p>

    </div>
    <div>
      {/* <div className="tc-topic-text">GENERAL</div> */}
      <div className="tc-topic-text">1.  Information We Collect</div>
      <p className="tc-text-info">
        We collect both personal and non-personal information to provide a seamless experience for
        both teachers (sources) and students (seekers). The types of information we collect include:

      </p>
      <ul >
        <li >Personal Information:
          <ul className="tc-topic-ul">
            <li className="tc-text-info">For Teachers (Sources): Name, contact details, educational qualifications,
              teaching experience, subjects of expertise, payment details (for subscription and
              class fees).

            </li>
          </ul>
          <ul className="tc-topic-ul">
            <li className="tc-text-info">For Students (Seekers): Name, contact details, interests, and payment
              information.
            </li>
          </ul>
        </li>
      </ul>
      <ul >
        <li>Non-Personal Information:
          <ul className="tc-topic-ul" >
            <li className="tc-text-info">Browsing data, IP addresses, cookies, and interaction with the platform (e.g.,
              class bookings and preferences).
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div>
      <div className="tc-topic-text">2. How We Use Your Information</div>
      <p className="tc-text-info">
        We use the information collected to:
      </p>
      <ul>
        <li className="tc-text-info">Facilitate connections between teachers and students.</li>
        <li className="tc-text-info">Provide and manage our services, including class bookings, payments, and
          communications.</li>
        <li className="tc-text-info"> Improve our platform and personalize user experiences.</li>
        <li className="tc-text-info"> Process payments for classes and subscriptions.</li>
        <li className="tc-text-info"> Send notifications regarding platform updates, account activity, and promotions.</li>
        <li className="tc-text-info">Comply with legal obligations.</li>
      </ul>

    </div>
    <div>
      <div className="tc-topic-text">3. Sharing Your Information</div>
      <p className="tc-text-info">
        <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "} does not sell or rent your personal information to third parties. However, we may
        share information under the following circumstances:
      </p>
      <ul>
        <li className="tc-text-info">
          <strong>With Service Providers:</strong> Third-party vendors help us with services such as payment
          processing, communication tools, and analytics.
        </li>
        <li className="tc-text-info">
          <strong>With Students/Teachers:</strong> Personal information may be shared between teachers and
          students to facilitate class arrangements (e.g., contact details for class scheduling).
        </li>
        <li className="tc-text-info">
          <strong>Legal Obligations:</strong> We may disclose information if required by law or to protect the
          rights and safety of our users.
        </li>
      </ul>

    </div>
    <div>
      <div className="tc-topic-text">4.  Cookies and Tracking Technologies</div>
      <p className="tc-text-info">
        <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "} uses cookies and similar tracking technologies to improve your experience. These
        technologies allow us to remember preferences, track interactions, and enhance user
        experience.
      </p>
      <ul>
        <li className="tc-text-info"><strong>Types of Cookies:</strong>
          <ul className="tc-topic-ul">
            <li className="tc-text-info">Essential Cookies: Necessary for platform functionality.</li>
            <li className="tc-text-info">Performance Cookies: Used to analyze platform usage and improve services.</li>
            <li className="tc-text-info">Marketing Cookies: Help us provide targeted ads and promotions.</li>
          </ul>
        </li>
      </ul>
      <p className="tc-text-info">You can control cookie preferences through your browser settings, but disabling cookies may
        affect platform functionality.</p>
    </div>
    <div>
      <div className="tc-topic-text">5. Data Security</div>
      <p className="tc-text-info">
        We take reasonable steps to protect your personal information from unauthorized access, use,
        or disclosure. Our security practices include encryption, secure servers, and restricted access to
        sensitive information.
      </p>
      <p className="tc-text-info">
        While we strive to protect your data, no method of transmission over the internet is 100%
        secure. We recommend that you take precautions when sharing sensitive information.
      </p>
    </div>
    <div>
      <div className="tc-topic-text">6. Your Rights</div>
      <p className="tc-text-info">
        You have the right to:

        <ul>
          <li> <strong>Access:</strong> Request a copy of the personal data we hold about you.</li>
          <li> <strong>Correction:</strong> : Request updates or corrections to your personal information.</li>
          <li> <strong>Deletion:</strong> : Request the deletion of your personal information in certain circumstances.</li>
          <li> <strong>Opt-Out:</strong> : Opt out of receiving marketing communications at any time.</li>
        </ul>
        To exercise these rights, please contact us at .


      </p>

    </div>
    <div>
      <div className="tc-topic-text">
        7. Teachers' (Sources') Privacy
      </div>
      <p className="tc-text-info">
        As a teacher on the <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "} platform, you will share certain information with students to
        facilitate class arrangements. This may include your name, subject expertise, and contact
        information. You control the information you provide to students.
      </p>

    </div>
    <div>
      <div className="tc-topic-text">8. Third-Party Links</div>
      <p className="tc-text-info">
        <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "} may contain links to third-party websites. We are not responsible for the privacy
        practices or content of those sites. Please review their privacy policies when visiting these sites.

      </p>
    </div>
    <div>
      <div className="tc-topic-text">9. Changes to This Privacy Policy</div>
      <p className="tc-text-info">
        We may update this Privacy Policy from time to time to reflect changes in our practices or for
        legal reasons. Any updates will be posted on our platform, and the "Effective Date" will be
        updated.
      </p>
    </div>
    <div>
      <div className="tc-topic-text">10. Grievance Officer (do we want this?)</div>
      <p className="tc-text-info">
      In compliance with Indian law, <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>{" "} has appointed a Grievance Officer to handle any
      concerns related to your privacy. You can contact the Grievance Officer at:
      </p>
      <p  className="tc-text-info"><strong>Name:</strong> Aseem Angiras</p>
      <p  className="tc-text-info"><strong>Email:</strong><a className="support-email-text" href="mailto:support@vedichom.com"> support@vedichom.com</a></p>
    </div>
    <div>
      <div className="tc-topic-text">
        11. Contact Us
      </div>
      <p className="tc-text-info">
      If you have any questions or concerns about this Privacy Policy or how your personal data is
handled, please contact us at:
<p className="tc-text-info">
  <strong>Email:</strong> 
  <a className="support-email-text" href="mailto:support@vedichom.com"> support@vedichom.com</a>
</p>
 </p>
      
    </div>
    
  </>
)