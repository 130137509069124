import { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
import leftarrow from "../../../../assets/img/left-arrow.svg"
import LandingHeader from "../../../navbars/TopNavbar"
import vedicjourney from "../../../../assets/img/vedic-journey.png"
import { updateFunnel } from "../../../../features/ServiceFunnel/FeatSeekerFunnel/seekerFunnelSlice"
import { useAppDispatch } from "../../../../app/store"
import FormMultiSelect from "../../../form/FormMultiSelect/FormMultiSelect"
import { useForm } from "react-hook-form"
import { useAppSelector } from "../../../../app/hooks"
import { useGetVedicSubjectQuery } from "../../../../features/Auth/authApi"
import "../SeekerStepOne.css"

const SeekerStepTwo = () => {
  const [selectedValue, setSelectedValue] = useState<any>("")
  const dispatch = useAppDispatch()
  const seekerData = useAppSelector((state) => state.seekerFunnel)

  const handleSelectChange = (
    selectedValues: Array<{ value: string; label: string }>,
  ) => {
    const payloadVedicSubject = selectedValues.map((option) => option.value)
    setSelectedValue(selectedValues)
    const isStepValid = selectedValues.length > 0
    dispatch(
      updateFunnel({
        ...seekerData,
        vedicSubject: payloadVedicSubject,
        isStepValid,
      }),
    )
  }
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<any>({
    // resolver: schemaResolver,
  })
  const { data } = useGetVedicSubjectQuery()
  useEffect(() => {
    if (seekerData?.vedicSubject && seekerData?.vedicSubject.length > 0) {
      let tempArray: any[] = []

      data?.data?.map((options: any)=>{
        if(seekerData.vedicSubject.includes(options.value)) tempArray.push(options)
        })
        setSelectedValue(tempArray)
      }
  if(seekerData?.vedicSubject && seekerData?.vedicSubject.length > 0)dispatch(updateFunnel({ ...seekerData, isStepValid: true}))
  else dispatch(updateFunnel({ ...seekerData, isStepValid: false}))
  },[])
  return (
    <>
      <section>
        <Container>
          <div className="inner-fav pb-5 mb-5">
            <Row className="justify-content-center">
              <Col xl={6} lg={8}>
              <>
              <div>
                    <h3 className="get-started-heading">
                      Let’s Get started on your Vedic Journey
                    </h3>
                    <p className="get-started-text mb-5">
"Tell us what you seek; we'll find your ideal Vedic source"
                    </p>
                  </div>
                  </>
                <div className="d-flex justify-content-between flex-column mb-5">
                  <div>
                    <form>
                      <label className="get-started-text mb-2">
                        Which Vedic Subject you are interested in?
                      </label>
                      <FormMultiSelect
                        title=""
                        options={data?.data}
                        control={control}
                        onChange={(option) => handleSelectChange(option)}
                        name="select"
                        value={selectedValue}
                      />
                    </form>
                  </div>
                  <div></div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  )
}

export default SeekerStepTwo
