import { useEffect, useRef, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Button,
  Form,
  Row,
  Col,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap"
import "./TeacherProfile.css"
import verifiedicon from "../../../assets/img/verified-icon.svg"
import reviewimage from "../../../assets/img/certified-image2.png"
import starimage from "../../../assets/img/star-active-icon.svg"
import dummy from "../../../assets/images/dummy-profile-pic.jpg"
import playbutton from "../../../assets/img/play-icon.svg"
import hearticon from "../../../assets/img/heart.svg"
import hearticonWhite from "../../../assets/images/heartIconWhite.svg"
import LandingHeader from "../../navbars/TopNavbar"
import { MdOutlineReport } from "react-icons/md"
import { IoCloudUploadOutline } from "react-icons/io5"

import { FaCheck } from "react-icons/fa"
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import {
  useGetProviderDetailsQuery,
  useResendOtpMutation,
} from "../../../features/Auth/authApi"
import { useAppSelector } from "../../../app/hooks"
import { useAppDispatch } from "../../../app/store"
import { setModalName } from "../../../features/Auth/ModalHandler/modalSlice"
import { DeviceType, isLaunched, MODALNAME, USERTYPE } from "../../../constants"
import {
  selectCurrentUser,
  setCurrentUser,
  setEmailVerification,
} from "../../../features/Auth/authSlice"
import {
  useAddToFavMutation,
  useGetSeekerFAQQuery,
} from "../../../features/featSeekerDashboard/seekerDashboardApi"
import {
  useAskFAQMutation,
  useGetFAQForProviderQuery,
} from "../../../features/featProviderDashboard/FeatProviderApi"
import shareicon from "../../../assets/img/share.svg"
import profileverified from "../../../assets/img/profile-verified.svg"
import { IoIosSend } from "react-icons/io"
import LoginSeeker from "../../modals/AuthComponents/loginModal/LoginSeeker"
import { toast } from "react-toastify"
import RatingStars from "../../buttons/RatingStars"
import CustomModeOfClassCard from "../../cards/CustomModeOfClassCard"
import { checkConditions } from "../../../utils/ifObjectExists/ifObjectExists"
import RenderProfile from "../../../utils/RenderProfilePic/RenderProfilePic"
import SpinnerMain from "../../spinner/SpinnerMain"
import ReviewDisplayCard from "../../cards/commonReviewDisplayCard/ReviewDisplayCard"
import AllReviewsModal from "../../modals/allReviewsViewModal/AllReviewsModal"
import { motion, scroll, useAnimate, usePresence } from "framer-motion"
import { CiCircleInfo } from "react-icons/ci"
import ReportAdModal from "../../modals/reportAdvtModal/ReportAdModal"
import CustomBackNavButton from "../../buttons/custumBackNavButton/CustomBackNavButton"
import { convertToEmbedUrl } from "../../../utils/youtybeURLvalidator/handleYoutubeUrl"
import {
  OnlineBadge,
  HybridBadge,
  RecordBadge,
} from "../../../components/badges/ProfileBadhges"
import ErrorPage from "../../ErrorPage"
import { useDebounce } from "../../../utils/debouncer"
import { detectDeviceType } from "../../../utils/commonFunctions/CommonFunctions"
import { ReactGAImplementation } from "react-ga4"
import ReactGA from 'react-ga4';


enum otpSend {
  email = 0,
  phone = 1,
}
interface CardProps {}
const TeacherProfile = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [visible, setVisible] = useState(false);
  const advertId = searchParams.get("advertId") || ""
  const {
    isError,
    error,
    data: profileData,
    isLoading,
    refetch,
  } = useGetProviderDetailsQuery(advertId, {
    skip: advertId === "",
    refetchOnMountOrArgChange: true,
  })
  const { state } = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { seekerToken, providerToken } = useAppSelector(selectCurrentUser)
  const user = useAppSelector((state: any) => state.auth)
  const [loggedinUser, setLoggedinuser] = useState<boolean>(false)
  const [show, setshow] = useState<boolean>(false)
  const [isHovered, setHovered] = useState(false)
  const [question, setQuestion] = useState("")
  // const [isScrolled, setIsScrolled] = useState(false)
  const [questionError, setQuestionError] = useState<string>("")
  const [schedule, setSchedule] = useState<boolean>(false)
  const auth = useAppSelector((state: any) => state.auth)
  // const [selectedOption, setSelectedOption] = useState("realTime")
  const [seeAllReviews, _seeAllReviews] = useState<boolean>(false)
  const [FAQdata, setFAQdata] = useState<any[]>([])
  const [scoop, animate] = useAnimate()

  const [showMore, setShowMore] = useState(false)
  const toggleShowMore = () => {
    setShowMore(!showMore)
  }

  const displayedLanguages = showMore
    ? profileData?.data?.adDetail?.language
    : profileData?.data?.adDetail?.language?.slice(0, 2)

  const [
    sendOtp,
    { isLoading: otploading, error: resendError, isSuccess: resendsuccess },
  ] = useResendOtpMutation()
  const [
    addToFavourites,
    {
      data: favData,
      isError: favError,
      isLoading: favLoading,
      isSuccess: favSuccess,
    },
  ] = useAddToFavMutation()
  const [
    ask,
    {
      data: askFAQData,
      isLoading: askFAQLoading,
      isError: askFAQError,
      isSuccess,
    },
  ] = useAskFAQMutation()
  const {
    data: FAQdataForSeeker,
    isSuccess: FAQSuccessSeeker,
    isLoading: FAQLoading,
    refetch: faqSeekerRefetch,
  } = useGetSeekerFAQQuery(
    { adId: profileData?.data?.adDetail?._id },
    {
      skip:
        profileData?.data?.adDetail?._id === undefined || seekerToken === null,
      refetchOnMountOrArgChange: seekerToken !== null,
    },
  )
  const {
    data: FAQdataForProvider,
    isSuccess: FAQSuccessP,
    isLoading: FAQLoadingProvider,
  } = useGetFAQForProviderQuery(
    { adId: profileData?.data?.adDetail?._id },
    {
      skip:
        profileData?.data?.adDetail?._id === undefined ||
        providerToken === null,
      refetchOnMountOrArgChange: providerToken !== null,
    },
  )
  const handleSchedule = () => {
    if(!isLaunched) {
      toast.warn('Not allowed untill launch date')
      return
    }
    ReactGA.send("pageview");
    if (
      profileData?.data?.ifRequestAlreadyExist?.isRequestDeleted &&
      profileData?.data?.ifRequestAlreadyExist?.requestSent
    ) {
      toast.dismiss()
      toast.error("Source has Ignored your Request, Try again after few Days")
    }
    if (
      !profileData?.data?.ifRequestAlreadyExist?.isRequestDeleted &&
      profileData?.data?.ifRequestAlreadyExist?.requestSent
    ) {
      navigate(`/schedule-session/${advertId}`)

      // navigate(`/seeker-dashboard/my-requests?requestStatus=${user.isPremium ? "Accept" :"Pending"}&requestId=${advertId}`)
      toast.dismiss()
      toast.error("Request already sent")
    } else {
      setSchedule(!schedule)
      if (seekerToken) {
        if (user?.user?.isEmailVerified || user?.user?.isMobileNoVerified) {
          setLoggedinuser(true)
          navigate(`/schedule-session/${advertId}`)
        } else setshow(!show)
      } else {
        setLoggedinuser(false)

        if (!providerToken) dispatch(setModalName(MODALNAME.LOGIN))
      }
    }
  }

  const handleMouseEnter = () => {
    setHovered(true)
  }

  const handleMouseLeave = () => {
    // setHovered(false)
  }

  const adjustHeight = (element: any) => {
    element.style.height = "50px"
    element.style.height = `${element.scrollHeight}px`
  }
  const resetHeight = (element: any) => {
    element.target.style.height = '50px';
  }

  function errorTostify (message: string) {
    toast.dismiss()
    toast.error(message)
  }
  const debouncedErrorTostify = useDebounce(errorTostify, 300)
  const handleQuestionChange = (event: any) => {
    const text: string = event.target.value
    if (text?.length > 250) {
      setQuestion(text.slice(0, 250))
      debouncedErrorTostify("Questions should be asked under 250 charecters")
      return
    }
    setQuestionError("")
    setQuestion(text)
    adjustHeight(event)
  }
  const handleEnterPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleSendClick()
      resetHeight(event)
    }
  }

  const handleSendClick = async () => {
    toast.dismiss()
    if (auth?.seekerToken !== null) {
      if (question && !/^\s*$/.test(question)) {
        if (!askFAQLoading) {
          const res = await ask({
            adId: advertId,
            question: question,
          })
          if ((res as any)?.data?.message) {
            faqSeekerRefetch()
            toast.success("Successfully delivered your query ")
            // toast.success((res as any)?.data?.message)
            setQuestion("")
          } else {
            if ((res as any)?.error?.data?.message) {
              setQuestion("")
              toast.error((res as any)?.error?.data?.message)
            }
          }
        }
      } else {
        setQuestionError("Please enter your Question")
      }
    } else if (providerToken) {
      toast.error("You are not allowed to reply here !")
    } else {
      dispatch(setCurrentUser(USERTYPE.SEEKER))
      dispatch(setModalName(MODALNAME.LOGIN))
    }
  }

  const debouncedHandleSendClick = useDebounce(handleSendClick, 300)

  function handleSubmitOtp(choice: otpSend) {
    const payloade =
      choice == otpSend.email
        ? { email: user?.user?.email }
        : { mobileNo: user?.user?.mobileNo }

    sendOtp(payloade)
      .unwrap()
      .then(() => {
        setshow(!show)
        // dispatch(setEmailVerification(true as any))
        if (choice == otpSend.email)
          dispatch(setModalName(MODALNAME.OTP_VERIFICATION))
        else dispatch(setModalName(MODALNAME.OTP_VERIFICATION_PHONE))
      })
      .catch(() => {
        toast.error("Failed to send otp")
      })
  }
  const handleToggle = async (id: any) => {
    toast.dismiss()
    if (user.seekerToken) {
      addToFavourites(id).unwrap()
      .then((res: any)=>{
        refetch().unwrap().then(()=>{toast.success(res?.message)})
        .catch((error: any)=>{
          toast.error('Sorry, we are unable to update your status')
        })
        
      })
    } else if (user.providerToken) {
      toast.error("You are not allowed !")
    } else {
      dispatch(setModalName(MODALNAME.LOGIN))
    }
  }

  const debouncedHandleToggle = useDebounce(handleToggle, 300)

  useEffect(() => {
    if (user?.user && schedule) {
      setLoggedinuser(false)
      handleSchedule()
    }
  }, [user?.user])

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 30) {
  //       setIsScrolled(true)
  //     } else {
  //       setIsScrolled(false)
  //     }
  //   }
  //   window.addEventListener("scroll", handleScroll)

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll)
  //   }
  // }, [])

  const pulseAnimation = {
    initial: {
      boxShadow: "0 0 0 0 rgba(254, 226, 177, 1)", // Initial state with full opacity
    },
    animate: {
      boxShadow: [
        "0 0 0 0 rgba(254, 226, 177, 1)", // Starting from the smaller pulse
        "0 0 0 8px rgba(254, 226, 177, 0)", // Small pulse with fading effect
        "0 0 0 0 rgba(254, 226, 177, 0.5)", // Return to the initial state
      ],
    },
    transition: {
      duration: 2,
      ease: "easeInOut",
      repeat: Infinity,
    },
  }

  useEffect(() => {
    if (FAQdataForProvider) setFAQdata(FAQdataForProvider?.data)
    if (FAQdataForSeeker) setFAQdata(FAQdataForSeeker?.data)
  }, [FAQdataForSeeker, FAQdataForProvider])

  const betterScrollIntoView = function (
    el: { scrollIntoView: (arg0: {}) => void },
    options = {},
  ) {
    return new Promise<void>(function (resolve, reject) {
      const onScrollEnd = () => {
        resolve()
        window.removeEventListener("scrollend", onScrollEnd)
      }

      window.addEventListener("scrollend", onScrollEnd)

      el.scrollIntoView(options)
    })
  }
  async function handleScrollToReviewCard() {
    if (scoop?.current) {
      try {
        await betterScrollIntoView(scoop.current, { behavior: "smooth" })
        // Check if the device is desktop
        if (window.innerWidth >= 300) {
          // Adjust the value as per your desktop breakpoint
          // Scroll 100px down from the current position
          window.scrollBy({ top: -100, behavior: "auto" })
        }
        await animate(scoop.current, { opacity: [0, 1] })
      } catch (error) {
        console.error("Error during scrolling:", error)
      }
    } else {
      console.error("No valid element provided.")
    }
  }

  const handleScroll = () => {
    if (window.scrollY > 370) { // Adjust scroll threshold as needed
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <div className="top-nav">
        <LandingHeader />
      </div>
      {isLoading ? (
        <div className="spin-loading">
          <SpinnerMain />
        </div>
      ) : error ? (
        <div>
          <ErrorPage buttonText="Go Back" />
        </div>
      ) : profileData ? (
        <section className="explore-section section-padding teacher-explore-section">
          <Container fluid="lg">
            <div className="inner-explore">
              <Row>
                <Col xl={4} lg={5} className="mb-lg-0 mb-4 px-lg-3 px-0">
                  <div className="inner-teacher-card sticky-card">
                    <div className="fav-card teacher-card">
                      <CustomBackNavButton
                        onClick={() => {
                          !providerToken && window.close()
                        }}
                        path={
                          auth.currentUserRole === USERTYPE.PROVIDER
                            ? `/source-dashboard/my-advt?adid=${profileData?.data?.adDetail?._id}`
                            : undefined
                        }
                      />
                      <div className="position-relative fav-img-wrapper">
                        <div className="fav-img">
                          {isHovered ? (
                            <Modal
                              show={isHovered}
                              onHide={() => {
                                setHovered(false)
                              }}
                              className="bg-transparent custom-modal videoPlayback-Modal"
                              centered
                              animation={false}
                            >
                              <Modal.Body className=" bg-transparent p-0">
                                <div className="overflow-hidden d-flex justify-content-center align-items-center">
                                  <video
                                    muted
                                    loop
                                    controls
                                    autoPlay={true}
                                    className="h-100 w-100 rounded-3"
                                  >
                                    <source
                                      src={
                                        profileData?.data?.adDetail
                                          ?.profileVideo
                                      }
                                      type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                </div>
                              </Modal.Body>
                            </Modal>
                          ) : (
                            <img
                              src={
                                profileData?.data?.adDetail?.profileImage ||
                                dummy
                              }
                              alt={"play"}
                            />
                            // <RenderProfile senderNameValue={profileData?.data?.adDetail?.providerId
                            //   ?.firstName +
                            //   " " +
                            //   profileData?.data?.adDetail?.providerId?.lastName} senderProfilePic={profileData?.data?.adDetail?.profileImage} />
                          )}
                        </div>
                        {profileData?.data?.adDetail?.providerId
                          ?.isMobileNoVerified && (
                          <div className="verified-icon">
                            <img
                              src={profileverified}
                              title="Verified Profile"
                            />
                          </div>
                        )}
                      </div>
                      <div className="option-fav w-auto d-flex justify-content-end flex-column align-items-center cursor-pointer">
                        {profileData?.data?.adDetail?.profileVideo &&
                          profileData?.data?.adDetail?.profileVideo !== " " && (
                            <div
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                            >
                              <div className="play-btn-bg">
                                <motion.div
                                  initial={pulseAnimation.initial}
                                  animate={pulseAnimation.animate}
                                  transition={pulseAnimation.transition}
                                  className="plat-btn-pulse-animate"
                                />
                                <button
                                  className="play-btn"
                                  style={{ position: "relative", zIndex: 1 }}
                                >
                                  <img src={playbutton} alt="Play" />
                                </button>
                              </div>
                            </div>
                          )}
                            {profileData?.data?.adDetail?.isFavourite ? (
                              <div
                                className="fav-icon"
                                onClick={() => debouncedHandleToggle(profileData?.data?.adDetail?._id)}
                                id={`fav-icon`}
                              >
                                {(favLoading||isLoading) ? <><Spinner size="sm" /></>:<img src={hearticonWhite} alt="showWhiteHeart" />}
                              </div>
                            ) : (
                              <OverlayTrigger
                                key={`overlay`}
                                placement="bottom"
                                overlay={
                                  <Tooltip id={`tooltip`}>
                                    {user.providerToken || user.seekerToken
                                      ? "Click to add to favorites!"
                                      : "Login First"}
                                  </Tooltip>
                                }
                              >
                                <div
                                  className="fav-icon"
                                  onClick={() => debouncedHandleToggle(profileData?.data?.adDetail?._id)}
                                  id={`fav-icon`}
                                >
                                  {(favLoading||isLoading)?<><Spinner size="sm" /></>:<img src={hearticon} alt="showWhiteHeart" />}
                                </div>
                              </OverlayTrigger>
                            )}
                      </div>
                      <div className="info-astrologer text-center">
                        <div className="mb-1">
                          <h2 className="ast-name mb-1">
                            {profileData?.data?.adDetail?.providerId
                              ?.firstName +
                              " " +
                              profileData?.data?.adDetail?.providerId?.lastName}
                          </h2>
                          {profileData?.data?.adDetail?.providerId?.rating >
                            0 && (
                            <div className="rating mb-0">
                              <img src={starimage} width="14" height="12" />
                              <span className="total-rating">
                                {" "}
                                {
                                  profileData?.data?.adDetail?.providerId
                                    ?.rating
                                }
                              </span>{" "}
                              <small
                                className="reviews font-12"
                                onClick={handleScrollToReviewCard}
                              >
                                ({profileData?.data?.countReviews} Reviews)
                              </small>
                            </div>
                          )}
                        </div>
                        <div className="extra-info-tutor">
                          <div className="d-flex justify-content-between align-items-center pt-xl-2 pt-lg-2 pt-0">
                            <div className="ast-ocp  mb-2 text-start">
                              Vedic Subject
                            </div>
                            <div className="ast-ocp  font-xl-12 font-12 fw-bold mb-lg-2 mb-0 text-end">
                              {profileData?.data?.adDetail?.vedicSubject}
                            </div>
                          </div>
                          {profileData?.data?.adDetail?.responseTime && (
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="ast-ocp mb-2">Response Time</div>
                              <div className="ast-ocp font-xl-12 font-12 fw-bold mb-lg-2 mb-0">
                                {profileData?.data?.adDetail?.responseTime}
                              </div>
                            </div>
                          )}
                          {profileData?.data?.adDetail?.language && (
                            <div className="d-flex justify-content-between align-items-start mb-xl-2 mb-lg-2 mb-0">
                              <div className="ast-ocp  mb-2">Language</div>
                              <div className="ast-ocp setLanguages d-flex gap-1 ms-3  flex-wrap justify-content-end">
                                {displayedLanguages?.map(
                                  (language: string, index: number) => (
                                    <div
                                      key={index}
                                      className="ast-ocp   fw-bold mb-lg-3 mb-0"
                                    >
                                      {language.trim()}

                                      {index !==
                                        profileData.data.adDetail.language
                                          .length -
                                          1 && <span>,</span>}
                                    </div>
                                  ),
                                )}{" "}
                                {showMore && (
                                  <span
                                    className="lang-see-text cursor-pointer primary-text"
                                    onClick={toggleShowMore}
                                  >
                                    See less
                                  </span>
                                )}
                                {!showMore &&
                                  profileData?.data?.adDetail?.language
                                    ?.length > 2 && (
                                    <span
                                      className="lang-see-text cursor-pointer  primary-text"
                                      onClick={toggleShowMore}
                                    >
                                      ...See more
                                    </span>
                                  )}
                              </div>
                            </div>
                          )}
                        </div>
                        {/* <Container className="w-100 my-2">
                          <Row>
                            <Col xs={12} className="my-1 w-fit-content">
                              {profileData?.data?.adDetail?.isOnline && (
                                <OnlineBadge isOpened={true} />
                              )}
                            </Col>
                            <Col xs={12} className="my-1">
                              {profileData?.data?.adDetail?.isHybrid && (
                                <HybridBadge isOpened={true} />
                              )}
                            </Col>
                            <Col sm={12} className="my-1">
                              {profileData?.data?.adDetail?.recordedLect && (
                                <RecordBadge isOpened={true} />
                              )}
                            </Col>
                          </Row>
                        </Container> */}
                        <Button
                          onClick={handleSchedule}
                          className={`${detectDeviceType()===DeviceType.Mobile ? !visible ? 'schedule-session-btn cursor-pointer' : 'd-none': 'schedule-session-btn cursor-pointer'}`}
                        >
                          <span className="send-icon me-2">
                            <IoIosSend />
                          </span>
                          Schedule a Session
                        </Button>
                      </div>
                      {/* <div className="text-center mb-0">1st class free</div> */}
                      <div className="teacher-profile-modal">
                        <Button
                          className="w-100"
                          onClick={() => {
                            if (auth?.currentUserRole == USERTYPE.PROVIDER)
                              return
                            searchParams.set("modal", MODALNAME.REPORT_AD)
                            searchParams.set("adId", advertId)
                            setSearchParams(() => {
                              return searchParams
                            })
                          }}
                        >
                          Report This Ad
                          <MdOutlineReport />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`schedule-btn-mobile d-lg-none ${
                      visible ? "visible" : ""
                    }`}
                  >
                    <Button
                      onClick={handleSchedule}
                      className="schedule-session-btn cursor-pointer"
                    >
                      <span className="send-icon me-2">
                        <IoIosSend />
                      </span>
                      Schedule a Session
                    </Button>
                  </div>
                </Col>
                {/* <Col xl={1} lg={}></Col> */}
                <Col xl={8} lg={7} id="teacher-ads">
                  <div className=" teacher-ad-wrapper">
                    <div className="ad-profile-card shadow-none rounded-0 mb-4 pt-0">
                      <h3 className="">{/* Services Offered */}</h3>
                      <div className="session-listing-pill">
                        {/* {profileData?.data?.adDetail?.services?.map(
                          (item: any) => (
                            <span className="ses-pill-list" key={item}>
                              #{item}
                            </span>
                          ),
                        )} */}
                        {profileData?.data?.adDetail?.providerId?.skills
                          ?.length > 1 && (
                          <div className="ad-profile-card mb-4">
                            <h3 className="ad-heading-profile user-select-none">
                              Similar Course{" "}
                            </h3>
                            {profileData?.data?.adDetail?.providerId?.skills?.map(
                              (item: string) => {
                                if (
                                  item ==
                                  profileData?.data?.adDetail?.vedicSubject
                                )
                                  return
                                return (
                                  <span
                                    className="ses-pill-list user-select-none "
                                    key={item}
                                  >
                                    #{item}
                                  </span>
                                )
                              },
                            )}
                          </div>
                        )}
                        {profileData?.data?.adDetail?.skillSet?.length > 0 && (
                          <div className="ad-profile-card mb-4">
                            <h3 className="ad-heading-profile user-select-none">
                              Skills{" "}
                            </h3>
                            {profileData?.data?.adDetail?.skillSet.map(
                              (skill: any, index: number) => {
                                return (
                                  <span
                                    className="ses-pill-list user-select-none "
                                    key={index}
                                  >
                                    #{skill}
                                  </span>
                                )
                              },
                            )}
                          </div>
                        )}
                        {(profileData?.data?.adDetail?.isOnline ||
                          profileData?.data?.adDetail?.isHybrid ||
                          profileData?.data?.adDetail?.recordedLect) && (
                          <div className="ad-profile-card mb-4">
                            <h3 className="ad-heading-profile user-select-none">
                              Learning Modes
                            </h3>
                            <Row>
                              {profileData?.data?.adDetail?.isOnline && (
                                <Col
                                  xs={6}
                                  lg={4}
                                  className="my-1 w-fit-content"
                                >
                                  <OnlineBadge isOpened={true} />
                                </Col>
                              )}
                              {profileData?.data?.adDetail?.isHybrid && (
                                <Col
                                  xs={6}
                                  lg={4}
                                  className="my-1 w-fit-content"
                                >
                                  <HybridBadge isOpened={true} />
                                </Col>
                              )}

                              {profileData?.data?.adDetail?.recordedLect && (
                                <Col
                                  xs={12}
                                  lg={4}
                                  className="my-1 w-fit-content"
                                >
                                  <RecordBadge isOpened={true} />
                                </Col>
                              )}
                            </Row>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="ad-profile-card shadow-none rounded-0 mb-4">
                      <div className="about-teacher mb-0">
                        {profileData?.data?.adDetail?.title}
                      </div>
                    </div>
                    <div className="ad-profile-card mb-4">
                      <h3 className="ad-heading-profile">
                        About{" "}
                        <span className="highlight-text">
                          {profileData?.data?.adDetail?.providerId?.firstName}
                        </span>
                      </h3>
                      <div className="ad-text-profile mb-0">
                        {profileData?.data?.adDetail?.aboutYou}
                      </div>
                    </div>
                    <div className="ad-profile-card shadow-none mb-0">
                      {profileData?.data?.adDetail?.providerId
                        ?.isCredsVerified && (
                        <div className="verified-card">
                          <h3 className="ad-heading-profile">
                            <img src={verifiedicon} className="me-2" />
                            {
                              profileData?.data?.adDetail?.providerId
                                ?.isEmailVerified
                            }{" "}
                            <span>This is a Verified Profile</span>
                          </h3>
                        </div>
                      )}
                    </div>
                    <div className="ad-profile-card mb-4">
                      <h3 className="ad-heading-profile">
                        About <span className="highlight-text">Session</span>
                      </h3>

                      <ul className="session-list ps-0">
                        <li>
                          <b>Session Description</b> -{" "}
                          {profileData?.data?.adDetail?.aboutClass}
                        </li>
                        {/* <li>
                          <b>Techniques Used</b> -
                        </li> */}
                      </ul>
                    </div>

                    {profileData?.data?.countReviews != 0 &&
                      profileData?.data?.providerReviews?.length > 0 && (
                        <div className="ad-profile-card mb-4 " ref={scoop}>
                          <div className="d-flex justify-content-between align-items-center mb-3  ">
                            <h3 className="ad-heading-profile mb-0">Reviews</h3>

                            <div className="ad-text-profile mb-0 showing-box">
                              {profileData?.data?.providerReviews?.length}
                              <span className="">{` Reviews`}</span>
                            </div>
                          </div>
                          <div className="review-wrapper">
                            {profileData?.data?.providerReviews
                              ?.slice(0, 5)
                              .map((item: any, index: number) => (
                                <ReviewDisplayCard item={item} index={index} />
                              ))}
                          </div>
                          {profileData?.data?.countReviews > 0 &&
                            profileData?.data?.providerReviews?.length > 5 && (
                              <div
                                className=" primary-text text-end"
                                onClick={() => {
                                  _seeAllReviews(true)
                                }}
                              >
                                See more...
                              </div>
                            )}
                        </div>
                      )}
                    {profileData?.data?.recommendations && (
                      <div className="ad-profile-card mb-4">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h3 className="ad-heading-profile mb-0">Recommendations</h3>
                        </div>
                        <div className="review-wrapper">
                          {/* {profileData?.data?.recommendations ? (
                            <div className="review-card-profile mb-3">
                              <div className="d-flex justify-content-between align-items-center mb-4">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={reviewimage}
                                    className="me-2 review-img"
                                  />
                                  <div className="review-name">Supreet</div>
                                </div>
                              </div>
                              <div className="review-text mb-0">
                                Lorem ipsum dolor sit amet consectetur. A nisi a
                                egestas sit faucibus. Nulla hendrerit at at
                                magna consequat ut adipiscing. Elit enim
                                placerat purus netus in. Volutpat sed fermentum
                                ornare in pellente lectus. Pretium pharetra
                                mattis eu quis.
                              </div>
                            </div>
                          ) : (
                            ""
                          )} */}

                          <div className="text-end">
                            <a className="view-text">View All</a>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-12">
                        <div className="ad-profile-card mb-4">
                          <div className="row">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <h3 className="ad-heading-profile mb-0 ">Modes</h3>
                            </div>
                            <div>
                              {" "}
                              {(checkConditions(
                                profileData?.data?.adDetail?.prices.virtual,
                                "private",
                              ) ||
                                checkConditions(
                                  profileData?.data?.adDetail?.prices.virtual,
                                  "group",
                                )) && (
                                <>
                                  <div className="ad-profile-card-border">
                                    <Row>
                                      <Col className="real-virtual-btn border-0  mx-2">
                                        <div className="color-primary">
                                          {"Virtual"}
                                        </div>
                                      </Col>
                                    </Row>
                                    {checkConditions(
                                      profileData?.data?.adDetail?.prices
                                        .virtual,
                                      "private",
                                    ) && (
                                      <CustomModeOfClassCard
                                        price={
                                          profileData?.data?.adDetail.prices
                                            .virtual.private?.hourlyFee
                                        }
                                        title={"Private"}
                                      />
                                    )}
                                    {checkConditions(
                                      profileData?.data?.adDetail?.prices
                                        .virtual,
                                      "group",
                                    ) && (
                                      <CustomModeOfClassCard
                                        price={
                                          profileData?.data?.adDetail.prices
                                            .virtual.group?.hourlyFee
                                        }
                                        title={"Group"}
                                      />
                                    )}
                                  </div>
                                </>
                              )}
                              {(checkConditions(
                                profileData?.data?.adDetail?.prices.realTime,
                                "private",
                              ) ||
                                checkConditions(
                                  profileData?.data?.adDetail?.prices.realTime,
                                  "group",
                                )) && (
                                <>
                                  <div className="ad-profile-card-border">
                                    <Row>
                                      <Col className="real-virtual-btn  border-0  mx-2">
                                        <div className="color-primary">
                                          {"Real Time"}
                                        </div>
                                      </Col>
                                    </Row>
                                    {checkConditions(
                                      profileData?.data?.adDetail?.prices
                                        .realTime,
                                      "private",
                                    ) && (
                                      <CustomModeOfClassCard
                                        price={
                                          profileData?.data?.adDetail.prices
                                            .realTime.private?.hourlyFee
                                        }
                                        title={"Private"}
                                      />
                                    )}
                                    {checkConditions(
                                      profileData?.data?.adDetail?.prices
                                        .realTime,
                                      "group",
                                    ) && (
                                      <CustomModeOfClassCard
                                        price={
                                          profileData?.data?.adDetail.prices
                                            .realTime.group?.hourlyFee
                                        }
                                        title={"Group"}
                                      />
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {convertToEmbedUrl(profileData?.data?.adDetail.youtubeLink)
                      .isValid && (
                      <div className="ad-profile-card bg-transparent">
                        <h3 className="ad-heading-profile">
                          {profileData?.data?.adDetail?.providerId?.firstName}
                          {"'s "}
                          <span className="highlight-text">video </span>
                        </h3>
                        <iframe
                          width="100%"
                          height={315}
                          src={
                            convertToEmbedUrl(
                              profileData?.data?.adDetail.youtubeLink,
                            ).url
                          }
                          title="YouTube video player"
                          className="video-ad"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        />
                      </div>
                    )}

                    <div className="ad-profile-card bg-transparent">
                      <h3 className="ad-heading-profile">
                        Ask <span className="highlight-text">a Question</span>
                      </h3>

                      <div className="faq-section mt-2 ps-0">
                        <Accordion
                          className="faq-accordion"
                          defaultActiveKey="0"
                        >
                          <Accordion.Item eventKey="0" className="ps-0">
                            <Accordion.Header className="faq-head">
                              <h6>Click to ask a question</h6>
                            </Accordion.Header>
                            <Accordion.Body className="faq-body">
                              <Form>
                                <Form.Group controlId="questionInput">
                                  <Form.Label>Your Question:</Form.Label>
                                  <Form.Control
                                    type="text"
                                    className="custom-scrollbar"
                                    as={'textarea'}
                                    placeholder="Type your question here..."
                                    value={question}
                                    onChange={handleQuestionChange}
                                    onKeyDown={handleEnterPress}
                                  />
                                  <span className="text-danger">
                                    {questionError}
                                  </span>
                                </Form.Group>

                                <div className="d-flex justify-content-end">
                                  <Button
                                    className="mt-4 send-btn fit-loader"
                                    variant="transparent"
                                    disabled={askFAQLoading}
                                    onClick={debouncedHandleSendClick}
                                  >
                                    {askFAQLoading ? (
                                      <Spinner className="spinner-md" />
                                    ) : (
                                      "Send"
                                    )}
                                  </Button>
                                </div>
                              </Form>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>

                    {FAQdata?.length > 0 && (
                      <div className="ad-profile-card bg-transparent">
                        <h3 className="ad-heading-profile">
                          Frequently{" "}
                          <span className="highlight-text">
                            Asked Questions
                          </span>
                        </h3>
                        {FAQdata?.map((question: any, index: number) => {
                          return (
                            <div className="faq-section mt-2" key={index}>
                              <Accordion
                                className="faq-accordion"
                                defaultActiveKey="1"
                              >
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header className="faq-head">
                                    <h6>{question.question}</h6>
                                  </Accordion.Header>
                                  {!question?.answer && (
                                    <span className="noanswer">
                                      No Answer yet
                                    </span>
                                  )}

                                  <Accordion.Body className="faq-body">
                                    {question.answer}
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      ) :
      (
        <div className="spin-loading">
          <SpinnerMain />
        </div>
      )
      }
      <AllReviewsModal
        show={seeAllReviews}
        setShow={_seeAllReviews}
        profileData={profileData}
        reviews={profileData?.data?.providerReviews}
      />
      <Modal
        show={show}
        onHide={() => setshow(!show)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Container className="inner-fav mb-5 justify-content-center">
            <Row>
              <div className="d-flex justify-content-between flex-column h-100 text-center">
                <div>
                  <div className="verification-heading"> Verification</div>
                  <div className="get-started-text mb-1">
                    Please Verify your sign-up medium to Continue
                  </div>
                </div>
              </div>
            </Row>
            <Row className="mt-5">
              {user?.user?.email && !user?.user?.isEmailVerified && (
                <Col
                  xl={user?.user?.mobileNo ? 6 : 12}
                  sm={12}
                  className="pb-2"
                >
                  <button
                    type="button"
                    className="send-button w-100 h-100"
                    onClick={() => {
                      handleSubmitOtp(otpSend.email)
                    }}
                  >
                    {otploading ? <Spinner /> : "Send OTP on Email"}
                  </button>
                </Col>
              )}
              {user?.user?.mobileNo && !user?.user?.isMobileNoVerified && (
                <Col xl={user?.user?.email ? 6 : 12} sm={12} className="pb-2">
                  <button
                    type="button"
                    className="send-button w-100 h-100"
                    onClick={() => {
                      handleSubmitOtp(otpSend.phone)
                    }}
                  >
                    {otploading ? <Spinner /> : "Send OTP on Phone Number"}
                  </button>
                </Col>
              )}
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      {/* modal will be triggered enum for now design nedded */}
    </>
  )
}

export default TeacherProfile
