import React, { useEffect, useLayoutEffect, useState } from "react"
import { Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap"
import Cropper from "react-easy-crop"

import styles from "./modal.module.css"
import SpinnerSecondary from "../../spinner/SpinnerSecondary"
import { RxCross1, RxCross2 } from "react-icons/rx"
import getCroppedImg from "../../funnels/ProviderFunnel/uploadPhoto/GetCroppedImg"
import { toast } from "react-toastify"
import { generateRandomFileName } from "../../../utils/commonFunctions/randomFilenameGenerator"

interface props {
  showModal: boolean
  image: any
  handleClose?: any
  setImage: any
  setResults: any
  extension: string
}

const CroperModal = (prop: props) => {
    const [zoom, _zoom] = useState<number>(1)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [finalImg, setFinalImg] = useState<any>(null)
    const [init, setint] = useState<any>(null)
    const reader = new FileReader()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    
    function onCropComplete (croppedArea: any, croppedAreaPixels: any) {
        setFinalImg(croppedAreaPixels)
    }

  useLayoutEffect(() => {
    if (prop.image) {
      reader.readAsDataURL(prop.image)
      reader.onload = () => {
        setint((reader as any).result)
      }
    }
  }, [prop.image])

  async function cropSubmit() {
    setIsLoading(true)
    if (finalImg) {
      try {
        const croppedImgUrl = await getCroppedImg(init, finalImg)
        const blob = await fetch(croppedImgUrl).then((res) => res.blob())

      // Create a File object with a custom name
      const file = new File([blob], generateRandomFileName(init?.name?.split('.').at(-1) || prop.extension), { type: blob.type });

        prop.setResults(file)
        prop.handleClose()
        prop.setImage(null)
        _zoom(1)
      } catch (error) {
        toast.error("An error occurred while cropping/uploading image.")
      }
    }
    setIsLoading(false)
  }
  function closeModal() {
    prop.setImage(null)
    prop.handleClose()
    _zoom(1)
  }
  // useEffect(()=>{},[finalImg])

  return (
    <>
      <Modal show={prop.showModal}>
        <Modal.Header className="d-flex justify-content-between px-3">
          <Modal.Title className="delete-heading">Crop Image</Modal.Title>
          <Button
            variant="transparent"
            onClick={closeModal}
            className={styles.closeButton}
          >
          <RxCross2 />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Container className={styles.modalBody}>
            <Row className={styles.croperWrapper}>
              {init ? (
                <Cropper
                  image={init}
                  crop={crop}
                  zoom={zoom}
                  aspect={4 / 3}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={_zoom}
                />
              ) : (
                <SpinnerSecondary />
              )}
            </Row>
            <Row className="justify-content-center ">
              <p className="text-center my-2 mt-3">
                Drag this scrollbar to adjust image
              </p>
              <Col sm={10}>
                <input
                  type="range"
                  className="w-100 mt-2"
                  step={0.1}
                  value={zoom}
                  min={1}
                  max={3}
                  onChange={(e) => {
                    _zoom(e.target.value as any)
                  }}
                />
              </Col>
            </Row>
            <Row className=" justify-content-center w-50 mx-auto">
              <Button
                className="secondary-button btn btn-primary"
                onClick={() => {
                  cropSubmit()
                }}
              >
                {isLoading ? <><Spinner size="sm"/></> :" Save"}
              </Button>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CroperModal
