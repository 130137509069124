import { useNavigate } from 'react-router-dom';

const useNavigateAndScroll = () => {
    const navigate = useNavigate();

    const navigateAndScroll = (path: string, scrollToPosition?: number, elementId?: string, replace?: boolean) => {
        if(replace) navigate(path, {replace: true});
        else navigate(path);
        if (scrollToPosition !== undefined) {
            setTimeout(() => {
                window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
            }, 100); // Adjust timeout as needed for the page to load
        } else if (elementId) {
            setTimeout(() => {
                const element = document.getElementById(elementId);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100); // Adjust timeout as needed for the page to load
        }
    };

    return navigateAndScroll;
};

export default useNavigateAndScroll;
