import React, { Children, Suspense, lazy } from "react"
import FavouriteScreen from "./components/ScheduleSession/FavouriteScreen/FavouriteScreen"
import HelpCenter from "./components/ScheduleSession/HelpCenter/HelpCenter"
import PaymentCard from "./components/ScheduleSession/PaymentCard/PaymentCard"
import ScheduleSession from "./components/ScheduleSession/ScheduleSession/ScheduleSession"
import TeacherProfile from "./components/ScheduleSession/TeacherProfile/TeacherProfile"
import SeekerStepFive from "./components/funnels/seekersFunnel/seekerStepFive/SeekerStepFive"
import SeekerStepFour from "./components/funnels/seekersFunnel/seekerStepFour/SeekerStepFour"
import SeekerStepOne from "./components/funnels/seekersFunnel/seekerStepOne/SeekerStepOne"
import ThankyouVedic from "./components/funnels/seekersFunnel/seekerStepSix/SeekerStepSix"
import SeekerStepThree from "./components/funnels/seekersFunnel/seekerStepThree/SeekerStepThree"
import SeekerStepTwo from "./components/funnels/seekersFunnel/seekerStepTwo/SeekerStepTwo"
// import LandingPage from "./components/pages/LandingPage/LandingPage"
const LandingPage = lazy(
  () => import("./components/pages/LandingPage/LandingPage"),
)
import SelectPath from "./components/funnels/selectPath/SelectPath"
import SubjectDetail from "./components/funnels/ProviderFunnel/subjectDetail/SubjectDetail"
import CreateAdvert from "./components/funnels/ProviderFunnel/CreateAdvert/CreateAdvert"
import Reset from "./components/modals/AuthComponents/Reset/Reset"
import SuccesfullPassword from "./components/modals/AuthComponents/ChnageSuccess/SuccesfulChange"
import SignUpSuccess from "./components/modals/AuthComponents/SignupSuccess/SignUpSuccessfull"
import Providerlogin from "./components/funnels/ProviderFunnel/providerlogin/providerlogin"
import ClassInfo from "./components/funnels/ProviderFunnel/classInfo/ClassInfo"
import ProviderInfo from "./components/funnels/ProviderFunnel/providerInfo/ProviderInfo"
import UploadProviderPhoto from "./components/funnels/ProviderFunnel/uploadPhoto/UploadPhoto"
const DashboardOverview = lazy(() => import("./components/dasboard/Providerdashboard/DashboardOverview/DashboardOverview"))
const MyAds = lazy(() => import("./components/dasboard/Providerdashboard/Myads/MyAds"))
import ExploreWrapper from "./components/wrappers/ExploreWrapper"
const ReceivedReview = lazy(() => import("./components/dasboard/Providerdashboard/receivedReview/ReceivedReview"))
const ReceivedReviewCustomer = lazy(() => import("./components/dasboard/seekerDasboard/writeReview/MyReview"))
const WriteReviewCustomer = lazy(() => import("./components/dasboard/seekerDasboard/writeReview/WriteReview"))
import RequestSuccessfull from "./components/ScheduleSession/RequestSuccessfull/RequestSuccessfull"
import FlexiblePricing from "./components/FlexiblePricing/FlexiblePricing"
import ProviderDashboardPayment from "./components/ScheduleSession/Providerpackages/ProviderDashboardPayment"
import LandingHandler from "./utils/routeHandlers/landingHandler"
import ExploreMoreWrapper from "./components/wrappers/ExploreMore"
import SingleHelp from "./components/ScheduleSession/HelpCenter/SingleHelp"
import SpinnerMain from "./components/spinner/SpinnerMain"
import GoogleCallBackHandler from "./utils/GoogleCallBackHandler"
import { isLaunched, USERTYPE } from "./constants"
import PrivateRoute from "./utils/PrivateRoutes/PrivateRoute"
import ChatAuthenticator from "./features/chat/ChatAuthenticator"
import ErrorBoundary from "./errorBoundary/ErrorBoundary"
import ErrorChat from "./features/chat/ErrorChat"
const ChatWrapper = lazy(() => import("./components/chatUi/ChatWrapper"))
const OverviewPanel = lazy(() => import("./components/dasboard/newDash/DashboardRight"))
const NewMyAccount = lazy(() => import("./components/dasboard/newDash/MyAccount/NewMyAccount"))
import ChatArea from "./components/chatUi/ChatArea"
import SpinnerSecondary from "./components/spinner/SpinnerSecondary"
import PaymentSubWrapper from "./components/ScheduleSession/PaymentSubscription/PaymentSubWrapper"
const PaymentSub = lazy(() => import("./components/ScheduleSession/PaymentSubscription/PaymentSub"))
import NotificationPage from "./components/dasboard/commonDashboard/notification/NotificationPage"
import FaqScreen from "./components/dasboard/Providerdashboard/faqScreen/FaqScreen"
import ErrorPage from "./components/ErrorPage"
import PrivatePaymentRoute from "./utils/PrivateRoutes/PaymentPrivateRoute"
import ReportAdModal from "./components/modals/reportAdvtModal/ReportAdModal"
import TermsCnditionPage from "./components/pages/termsAndConditionsPage/TermsCnditionPage"
import AboutUs from "./components/pages/aboutUsPage/AboutUs"
import PrivacyPolicyPage from "./components/pages/privacyPolicyPage"
import RefundpolicyPage from "./components/pages/refundAndCanclelationPolicyPage"
import TermsAndPolicyPage from "./components/pages/termsAndPolicyPage"
import { techerProfileLoader } from "./utils/techerProfileLoader"
const PressCenterPage = lazy(() => import("./components/pages/pressCenterPage/PressCenterPage"))
const SitemapPage = lazy(() => import("./components/pages/siteMap/SitemapPage"))
const CareerPage = lazy(() => import("./components/pages/career/CareerPage"))
// import ProviderSignUp from "./components/funnels/ProviderFunnel/providerSignup/providerSignUp"
const ProviderSignUp = lazy(
  () =>
    import("./components/funnels/ProviderFunnel/providerSignup/providerSignUp"),
)
const PreLaunchPage = lazy(()=>import('./components/pages/prelaunchPage')) 

export const baseRoutes = [
  {
    index: true,

    element: (
      <Suspense fallback={<SpinnerMain />}>
        <LandingHandler>
          {isLaunched?<LandingPage />
          :<PreLaunchPage />}
        </LandingHandler>
      </Suspense>
    ),
  },
  {
    path: "/press-center",
    element: <Suspense fallback={<SpinnerMain />}><PressCenterPage /></Suspense>
  },
  {
    path: "/career",
    element: <Suspense fallback={<SpinnerMain />}><CareerPage /></Suspense>
  },
  {
    path: "/site-map",
    element: <Suspense fallback={<SpinnerMain />}><SitemapPage /></Suspense>
  },
  {
    path: "/about-us",
    element: <Suspense fallback={<SpinnerMain />}><AboutUs /></Suspense>
  },
  {
    path: "*", // This will match any route not matched above
    element: <ErrorPage />
  },
  { path: "/explore-more", element: <ExploreMoreWrapper /> },
  { path: "/teacher-profile", element: <TeacherProfile />, loader: techerProfileLoader },
  {
    path: "/schedule-session/:id",
    element: <ScheduleSession />,
  },

  {
    path: "/provider/auth/:token",
    element: <GoogleCallBackHandler userType={USERTYPE.PROVIDER} />,
  },
  {
    path: "/seeker/auth/:token",
    element: <GoogleCallBackHandler userType={USERTYPE.SEEKER} />,
  },
  {
    path: "/payment-card",
    element: (
      <PrivatePaymentRoute userType={USERTYPE.SEEKER}>
        <PaymentCard />
      </PrivatePaymentRoute>
    ),
  },
  {
    path: "/payment-sub/:id",
    element: (
        <PrivatePaymentRoute userType={USERTYPE.SEEKER}>
          <PaymentSubWrapper />
        </PrivatePaymentRoute>
    ),
    children: [{
      index: true,
      element:
        <Suspense fallback={<SpinnerSecondary />} >
          <PaymentSub userType={USERTYPE.SEEKER} />
        </Suspense>
    }]
  },
  {
    path: "/payment-sub/:id/subscription_plan",
    element: <FlexiblePricing userType={USERTYPE.SEEKER} />
  },
  {
    path: "/payment_card_pro",
    element: (
      <PrivatePaymentRoute userType={USERTYPE.PROVIDER}>
        <PaymentCard />
      </PrivatePaymentRoute>
    ),
  },
  {
    path: "/payment_pro/:id",
    element: (
      <ErrorBoundary fallback={<></>}>
        <PrivatePaymentRoute userType={USERTYPE.PROVIDER}>
          <PaymentSubWrapper />
        </PrivatePaymentRoute>
      </ErrorBoundary>
    ),
    children: [{
      index: true,
      element: <PaymentSub userType={USERTYPE.PROVIDER} />
    }]
  },
  {
    path: "/payment_pro/:id/subscription_plan",
    element: <FlexiblePricing userType={USERTYPE.PROVIDER} />
  },

  // {
  //   path: "/payment-success/:id",
  //   element: (
  //     <PrivateRoute>
  //       <PaymentSuccess />
  //     </PrivateRoute>
  //   ),
  // },

  {
    path: "/packagePayment/:id",
    element: (
      <PrivateRoute>
        <ProviderDashboardPayment />
      </PrivateRoute>
    ),
  },
  {
    path: "/request-success/:id",
    element: (
      <PrivateRoute>
        <RequestSuccessfull />
      </PrivateRoute>
    ),
  },

  {
    path: "/favourite-screen",
    element: (
      <PrivateRoute>
        <FavouriteScreen />
      </PrivateRoute>
    ),
  },
  {
    path: "/help-center",
    element: (

        <HelpCenter />

    ),
  },


  {
    path: "/journey",
    element: <SelectPath />,
  },
  {
    path: "/journey-success",
    element: <ThankyouVedic />,
  },

  {
    path: "/reset-password",
    element: <Reset />,
  },

  {
    path: "/password-successfully-change",
    element: <SuccesfullPassword />,
  },
  {
    path: "/source-signin",
    element: <Providerlogin />,
  },
  // {
  //   path: "/source-otp-verification",
  //   element: <ProviderOtpVerification />,
  // },
  {
    path: "/single-help",
    element: <SingleHelp />,
  },
  // {
  //   path: "/notification",
  //   element: <NotificationScreen />,
  // },
  {
    path: "/source-signup",
    element: (
      <Suspense
        fallback={<><SpinnerMain /></>}>
        <ProviderSignUp />
      </Suspense>
    ),
  },
  {
    path: "/verify-email",
    element: <SignUpSuccess />,
  },

  {
    path: "/results",
    element: <ExploreWrapper />,
  },
  {
    path: "/terms-and-policies",
    element: <TermsAndPolicyPage />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsCnditionPage />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "/refund-policy",
    element: <RefundpolicyPage />,
  },
]

export const providerFunnelRoutes: any = [
  {
    index: true, element:
      <Suspense fallback={<SpinnerMain/>}>
        <SubjectDetail />
      </Suspense>
  },

  {
    path: "/journey-source/avt-details",
    element: <CreateAdvert />,
  },

  {
    path: "/journey-source/source-details",
    element: <ProviderInfo />,
  },
  {
    path: "/journey-source/class-details",
    element: <ClassInfo />,
  },
  {
    path: "/journey-source/source-picture",
    element: (
      <Suspense fallback={<SpinnerMain/>}>
        <UploadProviderPhoto />
        </Suspense>),
  },
]

export const providerDashboardRoutes: any = [
  {
    index: true,
    element:
    <ErrorBoundary>
      <Suspense fallback={<SpinnerMain />}>
        <DashboardOverview />
      </Suspense>
      </ErrorBoundary>
    ,
  },

  {
    path: "/source-dashboard/my-advt",
    element:
    <ErrorBoundary>
      <Suspense fallback={<SpinnerMain />}>
        <MyAds />
      </Suspense>
      </ErrorBoundary>,
  },
  {
    path: "/source-dashboard/my-requests",
    element: (
      <ErrorBoundary fallback={<ErrorChat message="unable to open" />}>
        {/* <ChatAuthenticator> */}
        <Suspense fallback={<SpinnerMain />}>
          <ChatWrapper />
        </Suspense>
        {/* </ChatAuthenticator> */}
      </ErrorBoundary>
    ),
    children: [{
      index: true,
      element:
        <ErrorBoundary fallback={<ErrorChat message="unable to open" />}>
          <ChatArea />
        </ErrorBoundary>
    }]
  },

  {
    path: "/source-dashboard/my-evaluations", element:
    <ErrorBoundary>
      <Suspense fallback={<SpinnerMain />}>
        <ReceivedReview />
      </Suspense>
      </ErrorBoundary>
  },
  {
    path: "/source-dashboard/my-account", element:
    <ErrorBoundary>
      <Suspense fallback={<SpinnerMain />}>
        <NewMyAccount /> </Suspense>
        </ErrorBoundary>
  },
  {
    path: "/source-dashboard/my-notifications"
    ,
    element:
    <ErrorBoundary>
      <Suspense fallback={<SpinnerMain />}>
        <NotificationPage />
      </Suspense>
      </ErrorBoundary>
  },
  {
    path: "/source-dashboard/my-faqs"
    ,
    element:
    <ErrorBoundary>
      <Suspense fallback={<SpinnerMain />}>
        <FaqScreen />
      </Suspense>
      </ErrorBoundary>
  },


  // { path: "/source-dashboard/identity", element: 
  // <Suspense fallback={<SpinnerMain/>}>
  // <UploadIdentification /> 
  // </Suspense>},
]

export const customerDashboardRoutes: any = [
  {
    index: true,
    element:
      <Suspense fallback={<SpinnerMain />}>
        <OverviewPanel />
      </Suspense>,
  },
  {
    path: '/seeker-dashboard/subscription_plans',
    element:
      <Suspense fallback={<SpinnerMain />}>

        <FlexiblePricing userType={USERTYPE.SEEKER} />
      </Suspense>,
  },

  {
    path: "/seeker-dashboard/my-account",
    element:
      <Suspense fallback={<SpinnerMain />}>
        <NewMyAccount />
      </Suspense>,
  },
  // 
  // {
  // index: true,
  // path: "/seeker-dashboard",
  // element: (
  // ),
  // children: [
  //   {
  //     index: true,
  //     element: <OverviewPanel />,
  //   },
  //   {
  //     path: "/my-account",
  //     element: <NewMyAccount />,
  //   },
  // ],
  // },

  {
    path: "/seeker-dashboard/my-requests/",
    element: (
      <ErrorBoundary fallback={<ErrorChat message="unable to open" />}>
        {/* <ChatAuthenticator> */}
        <Suspense fallback={<SpinnerMain />}>
          <ChatWrapper />
        </Suspense>
        {/* </ChatAuthenticator> */}
      </ErrorBoundary>
    ),
    children: [{
      index: true,
      element: <ChatArea />
    }]
  },
  // {
  //   path: "/seeker-dashboard/my-account",
  //   element: <MyAccountCustomer />,
  // },

  {
    path: "/seeker-dashboard/my-evaluations",
    element:
      <Suspense fallback={<SpinnerMain />}>
        <ReceivedReviewCustomer />
      </Suspense>,
  },

  {
    path: "/seeker-dashboard/write-review",
    element:
      <Suspense fallback={<SpinnerMain />}>
        <WriteReviewCustomer />
      </Suspense>,
  },
  {
    path: "/seeker-dashboard/my-notifications"
    ,
    element:
      <Suspense fallback={<SpinnerMain />}>
        <NotificationPage />
      </Suspense>
  },
]
export const selectPath = [{ index: true, element: <SelectPath /> }]
export const seekerFunnelRoutes: any = [
  // { element: <SeekerStepOne />, index: true },
  {
    index: true,
    path: "/journey-seeker/seeker-select-subjects",
    element: <SeekerStepTwo />,
  },
  {
    path: "/journey-seeker/seeker-language",
    element: <SeekerStepThree />,
  },
  {
    path: "/journey-seeker/seeker-session",
    element: <SeekerStepFour />,
  },
  {
    path: "/journey-seeker/seeker-lesson",
    element: <SeekerStepFive />,
  },

  ,
]
