import {
  Badge,
  Col,
  Dropdown,
  DropdownButton,
  Row,
  Stack,
} from "react-bootstrap"
import VideoIcon from "../../assets/img/video-call.svg"
import styles from "./ChatUi.module.css"
import { CiLocationOn } from "react-icons/ci"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectCurrentUser } from "../../features/Auth/authSlice"
import RenderProfile from "../../utils/RenderProfilePic/RenderProfilePic"
import { useChatData } from "../../utils/commonFunctions/CommonFunctions"
import { ItemWithISODate } from "../../Types"
import { motion } from "framer-motion"
import { BiArchive, BiBlock, BiDotsVerticalRounded, BiError } from "react-icons/bi"
import { MODALNAME } from "../../constants"
import { setModal } from "../../features/Auth/ModalHandler/modalSlice"
import { GiWallet } from "react-icons/gi"
import { IoMdArrowRoundBack } from "react-icons/io"

interface selectedChatProps {
  handleBackNav: any,
  selectedChatInfo?: ItemWithISODate
}

const SelectedChatInfo = ({ selectedChatInfo, handleBackNav }: selectedChatProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { pendingChatData, liveChatData } = useChatData()
  const { seekerToken, providerToken, user } = useAppSelector(selectCurrentUser)
  function handleReportChat () {
    dispatch(setModal({name: MODALNAME.REPORT_CHAT, data: {
      requestId: selectedChatInfo?.requestId,
      reportedByUserType: user?.role,
      reportUserName: providerToken ? selectedChatInfo?.seekerName : seekerToken ? selectedChatInfo?.providerName : ''
    }}))
  }
  function handlePaymentInvoiceGenerate(): void {
    dispatch(setModal({name: MODALNAME.REQUEST_PAYMENT, data: {
      requestId: selectedChatInfo?.requestId,
      // ... more details that are needed
    }}))
  }

  return selectedChatInfo ? (
    <motion.div
      initial={{ translateX: -150, opacity: 0.1 }}
      whileInView={{ translateX: 0, opacity: 1 }}
      exit={{ translateX: -150, opacity: 0 }}
      transition={{ duration: 0.3, delay: 0 }}
      className="row mt-1 me-0 "
    >
      <Col
        className={`d-flex justify-content-between align-items-center mt-2 bg-white  mt-lg-0 ${styles["bottom-border"]} `}
      >
        <Stack direction="horizontal" gap={1}>
          <div onClick={()=>handleBackNav&&handleBackNav()} className={styles.backButton}>
          <IoMdArrowRoundBack />
          </div>
          <div className="p-2">
            {seekerToken ?<RenderProfile senderNameValue={selectedChatInfo?.providerName} senderProfilePic={selectedChatInfo?.providerProfilePic} size="sm"/>
            :<RenderProfile senderNameValue={ selectedChatInfo?.seekerName} senderProfilePic={selectedChatInfo?.seekerProfilePic} size="sm"/>}
          </div>
          <div className="p-2">
            <h2
              className={`ms-2 text-truncate ${styles["profile-name"]} `}
              onClick={() =>
                navigate(
                  `/teacher-profile?advertId=${selectedChatInfo?.adId}`,
                )
              }
            >
              {seekerToken
                ? selectedChatInfo?.providerName
                : selectedChatInfo.seekerName || ""}
            </h2>
            <p className={`ms-2  text-truncate ${styles["profile-desc"]} `}>
              {" "}
              {selectedChatInfo.vedicSubject}
            </p>
          </div>
        </Stack>
        <Stack direction="horizontal" gap={1}>
          <div>
            {/* <div
              className={`pb-1 text-truncate user-select-none ${styles["profile-desc"]}`}
            >
              <span className="d-none d-lg-inline-flex"> Your request is </span>{" "}
              {selectedChatInfo.requestStatus === "Pending"
                ? "Pending"
                : "Connected"}{" "}
              with{" "}
              {providerToken
                ? selectedChatInfo.seekerName
                : selectedChatInfo.providerName}
            </div> */}
            <div className={` ${styles["text-align-end"]}`}>
              <Badge
                bg={"white"}
                className={` user-select-none me-2 mb-1 ${styles["rounded-xl"]} ${styles["bar-badge"]}`}
              >
                {selectedChatInfo.sessionForWhom}
              </Badge>

              <Badge
                bg={"white"}
                className={`mb-1 user-select-none ${styles["rounded-xl"]} ${styles["bar-badge"]}`}
              >
                <img src={VideoIcon} className={` ${styles["video-icon"]}`} />{" "}
                {selectedChatInfo.sessionPreference}
              </Badge>
              {selectedChatInfo.location && (
                <Badge
                  bg={"white"}
                  className={`  me-2 mb-1 user-select-none ${styles["rounded-xl"]} ${styles["bar-badge"]}`}
                >
                  <CiLocationOn size={24} className="pe-1" />
                </Badge>
              )}
            </div>
          </div>
          <div className="card-dropdown-menu-2">
            <Dropdown>
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className={styles["dropdown-button"]}
              >
                <BiDotsVerticalRounded size={30} />
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-2 px-1">
                {/* <Dropdown.Item href="#/archive-chat">
                  <BiArchive size={20} className="me-2" /> Archive Chat
                </Dropdown.Item> */}
                <Dropdown.Item onClickCapture={handleReportChat}>
                  <BiError size={20} className="me-2" /> Report
                </Dropdown.Item>
                {/* POSTPONED AFTER BACKEND IMPLIMENTATION ADD PAYMENT FROM CHATS */}
                {/* {providerToken && <Dropdown.Item onClickCapture={handlePaymentInvoiceGenerate}>
                  <GiWallet size={20} className="me-2" /> Request Payment
                </Dropdown.Item>} */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Stack>
      </Col>
    </motion.div>
  ) : (
    <>
      {" "}
      <Row className="mt-1 me-0">
        <Col
          className={`d-flex justify-content-between align-items-center mt-2 mb-1 mt-lg-0 border-0 ${styles["bottom-shadow"]} ${styles["rounded-xl"]}`}
        >
          {" "}
          <div
            className={`d-none d-lg-block p-2 w-100 ${styles["dummy-div-top"]} `}
          ></div>
        </Col>
      </Row>
    </>
  )
}

export default SelectedChatInfo
