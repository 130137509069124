import React, { useEffect } from "react"
import { MODALNAME, USERTYPE } from "../../../constants"
import {
  Button,
  Col,
  Collapse,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap"
import verifiedbandage from "../../../assets/img/verified-bandage.svg"
import {
  useResendOtpMutation,
  useResendOtpProviderMutation,
} from "../../../features/Auth/authApi"
import { useProviderPhoneVerificationMutation } from "../../../features/featProviderDashboard/FeatProviderApi"
import { useSeekerPhoneVerificationMutation } from "../../../features/featSeekerDashboard/seekerDashboardApi"
import { useAppDispatch } from "../../../app/store"
import { setModalName } from "../../../features/Auth/ModalHandler/modalSlice"
import { toast } from "react-toastify"
import { BsFillInfoCircleFill, BsFillTelephoneFill } from "react-icons/bs"
import { motion } from "framer-motion"
import { MdEmail, MdOutlineMailOutline } from "react-icons/md"
import CustomTooltip from "../../buttons/CustomTooltip"
import { dashProps } from "../../../Types"
import { VscVerifiedFilled } from "react-icons/vsc"
import { PhoneInput } from "react-international-phone"
import { useLocation } from "react-router-dom"
import { useAppSelector } from "../../../app/hooks"

const VerifyCard = ({
  userType,
  seekerProfileData,
  sourceProfileData,
  isLoading,
  authUser: auth,
}: dashProps) => {
  const dispatch = useAppDispatch()
  const [open, setOpen] = React.useState(false)
  // add auto collapse on path change
  const location = useLocation()
  useEffect(()=>{setOpen(false)},[location])
  const authUser = useAppSelector((state: any) => state.auth)

  // phone verification
  const [
    phoneVerify,
    {
      data: phoneVerificationData,
      isLoading: phoneVerificationLoading,
      isSuccess: phoneVerificationSuccess,
    },
  ] = useProviderPhoneVerificationMutation()
  const [
    seekerphoneVerify,
    {
      data: seekerphoneVerificationData,
      isLoading: seekerphoneVerificationLoading,
      isSuccess: seekerphoneVerificationSuccess,
    },
  ] = useSeekerPhoneVerificationMutation()
  // email verification
  const [
    verifyEmail,
    {
      data: verifySeekerEmaildata,
      error: verifySeekerEmailError,
      isSuccess: verifySeekerEmailSuccess,
      isLoading: verifySeekerEmailLoading,
    },
  ] = useResendOtpMutation()
  const [
    verifyProviderEmail,
    {
      data: verifyProviderEmaildata,
      error: verifyProviderEmailError,
      isSuccess: verifyProviderEmailSuccess,
      isLoading: verifyProviderEmailLoading,
    },
  ] = useResendOtpProviderMutation()

  const handlePhoneVerify = async () => {
    toast.dismiss()
    if (userType === USERTYPE.PROVIDER) {
      if (!phoneVerificationLoading) {
        phoneVerify().unwrap()
        .then(()=>{dispatch(setModalName(MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_PHONE),)})
        .catch(()=>{toast.error("Something went Wrong, please try again later")})
        // Replaced
        // const res = await phoneVerify()
        // if ((res as any)?.data?.success) {
        //   dispatch(
        //     setModalName(MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_PHONE),
        //   )
        // } else {
        //   toast.dismiss()
        //   toast.error("Something went Wrong, please try again later")
        // }
      }
    } else {
      if (!seekerphoneVerificationLoading) {
        seekerphoneVerify().unwrap()
        .then(()=>{dispatch(setModalName(MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_PHONE),)})
        .catch(()=>{toast.error("Something went Wrong, please try again later")})
        // Replaced
        // const res = await seekerphoneVerify()
        // if ((res as any)?.data?.success) {
        //   dispatch(
        //     setModalName(MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_PHONE),
        //   )
        // } else {
        //   toast.dismiss()
        //   toast.error("Something went Wrong, please try again later")
        // }
      }
    }
  }
  const handleEmailVerify = async () => {
    try {
      if (auth?.currentUserRole === USERTYPE.PROVIDER) {
        if (!verifyProviderEmailLoading) {
          const res = await verifyProviderEmail({email: auth?.user?.email})
          if ((res as any)?.data?.success) {
            dispatch(
              setModalName(
                MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_EMAIL,
              ),
            )
          } else {
            toast.error("Something went Wrong, please try again later")
          }
        }
      } else {
        if (!verifySeekerEmailLoading) {
          const res = await verifyEmail({email: auth?.user?.email})
          if ((res as any)?.data?.success) {
            dispatch(
              setModalName(
                MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_EMAIL,
              ),
            )
          } else {
            toast.error("Something went Wrong, please try again later")
          }
        }
      }
    } catch (error) {
      console.error("Error while handling email verification:", error)
      toast.error("An unexpected error occurred. Please try again later.")
    }
  }
  return (
    <>
      <div className="verify-profile mb-md-3 border-0 custom-shadow mt-md-3 primary-shadow">
        <Button
          className="verify-card-btn collapsed border-0 rounded-xl d-flex"
          onClick={() => setOpen(!open)}
          aria-controls="verifyprofile"
          aria-expanded={open}
        >
          {/* <motion.div>
            <motion.img
              src={verifiedbandage}
              alt="Verified Bandage"
              // style={{ width: "200px", height: "200px" }} // Set initial width and height
              whileInView={{
                scale: [1, 0.8, 0.6, 0.8, 1], // Scale animation values
                rotate: [0, 0, 45, -45, 0], // Rotation animation values
              }}
              transition={{
                duration: 2.7,
                ease: "easeInOut",
                times: [0, 0.2, 0.4, 0.9, 1],
                repeat: Infinity,
                repeatDelay: 20, // Set repeat delay to 20 seconds
              }}
            />
          </motion.div> */}
          <motion.div
            whileInView={{
              scale: [1, 0.8, 0.6, 0.8, 1], // Scale animation values
              rotate: [0, 0, -20, 20, 0], // Rotation animation values
            }}
            transition={{
              duration: 2.7,
              ease: "easeInOut",
              times: [0, 0.2, 0.4, 0.9, 1],
              repeat: Infinity,
              repeatDelay: 20, // Set repeat delay to 20 seconds
            }}
            style={{ display: 'inline-block', fontSize: '48px', color: '#333' }} // Customize icon size and color
          >
            <VscVerifiedFilled size={26} color="#25A7DA"  className="mb-2 me-1 verifyicon-img" />
          </motion.div>
          Verify your profile
        </Button>
        <Collapse in={open}>
          <div id="verifyprofile" className="verifyprofile-info border-0 p-0 m-0">
            <Row className="mb-3 px-3 ps-4">
            {(seekerProfileData?.data?.mobileNo ||
                  sourceProfileData?.data?.mobileNo) && (
                    <>
              <Col xs={1} className=" mb-md-0 mb-2 verify-text p-0 pt-1">
                <BsFillTelephoneFill />{" "}
              </Col>
              <Col xs={10} className=" mb-0 verify-text text-truncate pe-0">
                      <Col xs="auto" className=" mb-0 verify-text text-truncate ">
                      <PhoneInput className="bg-transparent verify-country-items"
                      value={userType === USERTYPE.SEEKER
                        ? `${seekerProfileData?.data?.countryCode}${seekerProfileData?.data?.mobileNo}`
                        : `${sourceProfileData?.data?.countryCode}${sourceProfileData?.data?.mobileNo}`}
                      disabled 
                       />
                      </Col>
                      <Col xs={12} className="col-sm-4 mb-md-0 mb-2 verify-text ">
                        {isLoading ? (
                          <Spinner className="spinner-small" />
                        ) : (authUser?.user?.isMobileNoVerified) ? (
                          <span className="verifycard-email-badge">
                            Verified
                          </span>
                        ) : (
                          <>
                            <span
                              className="verifycard-mobile-badge "
                              onClick={handlePhoneVerify}

                            >
                              {seekerphoneVerificationLoading ||
                                phoneVerificationLoading ? (
                                <Spinner className="spinner-small ms-2" />
                              ) : (
                                "Verify"
                              )}
                            </span></>
                        )}
                      </Col>
              </Col>
              </>
            )}
            </Row>
            <Row className=" position-relative px-3 ps-4 pb-3">
              {(seekerProfileData?.data?.email || sourceProfileData?.data?.email) &&<>
              <Col xs={1} className="mb-md-0 mb-2 verify-text p-0">
                <MdOutlineMailOutline size={22} />

                {/* <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <Button
                    variant="transparent"
                    className="tooltip-btn-round ms-2"
                  >
                    <BsFillInfoCircleFill />
                  </Button>
                </OverlayTrigger> */}
              </Col>

              <Col xs={9} className="  mb-0 verify-text text-truncate pe-0">
                {userType === "SEEKER"
                  ? seekerProfileData?.data?.email
                  : sourceProfileData?.data?.email}
                <div className="col-4 mb-md-0 mb-2  verify-text">
                  {isLoading ? (
                    <Spinner className="spinner-small" />
                  ) : (authUser?.user?.isEmailVerified) ? (
                    <span
                      className="verifycard-email-badge"
                    >
                      Verified{" "}
                    </span>
                  ) : (
                    <span
                      onClick={handleEmailVerify}
                      className="verifycard-mobile-badge"
                    >
                      {verifyProviderEmailLoading ||
                        verifySeekerEmailLoading ? (
                          <Spinner className="spinner-small" />
                        ) : (
                        "Verify"
                      )}
                    </span>
                  )}
                </div>
              </Col>
              <Col xs={2} className=" p-0">
                <CustomTooltip
                  content={
                    userType === USERTYPE.SEEKER
                      ? seekerProfileData?.data?.email
                      : sourceProfileData?.data?.email
                  }
                  placement="left"
                />

                {/* <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <Button
                    variant="transparent"
                    className="tooltip-btn-round ms-2"
                  >
                    <BsFillInfoCircleFill />
                  </Button>
                </OverlayTrigger> */}
              </Col></>}
            </Row>
          </div>
        </Collapse>
      </div>
    </>
  )
}

export default VerifyCard
