import { Container } from 'react-bootstrap';
import { IoStar, IoStarOutline } from 'react-icons/io5';

interface Props {
  rating: number;
}

const RatingStars = ({ rating }: Props) => {
  const starsArray = Array.from({ length: rating }, (_, index) => index + 1);
  let emptyArray: JSX.Element[] = [];

  if (rating < 5) {
    for (let i = 0; i < 5 - rating; i++) {
      emptyArray.push(<IoStarOutline key={rating + i} className="text-black-50" />);
    }
  }

  return (
    <Container className="d-flex review-stars gap-1 px-1 ">
      {starsArray.map((star) => (
        <IoStar key={star} style={{ color: 'gold' }} />
      ))}
      {emptyArray}
    </Container>
  );
};

export default RatingStars;
