import { useEffect, useState } from "react";
import LandingHeader from "../../../navbars/TopNavbar";
import thankyouvedic from "../../../../assets/img/thankyou-vedic.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../app/hooks";
import ProviderCards from "../../../cards/providerCards";
import "./SeekerStepSix.css";
import { useAppDispatch } from "../../../../app/store";
import { resetFunel } from "../../../../features/ServiceFunnel/FeatSeekerFunnel/seekerFunnelSlice";
import "../SeekerStepOne.css"
import { Button, Row } from "react-bootstrap";

const ThankyouVedic = () => {
  const { state } = useLocation();
  const [data, _data] = useState<any[]>([])
  const [waiting, setWaiting] = useState<boolean>(true)
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const seekerFunnel = useAppSelector((state) => state.seekerFunnel)
  const [selectedData, setData] = useState<any>(null)
  useEffect(()=>{
    if(state?.data){
      _data(state.data.data)
    }
    else if(data?.length<1) navigate('/journey')
  },
    [state]
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      setWaiting(false)
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const handleBackNavigation = () => {
      dispatch(resetFunel())
      navigate('/journey');
    };

    const handlePopState = () => {
      handleBackNavigation();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);
  function handleSeeMore () {
    navigate(`/results`)
  }
  useEffect(()=>{
    setData(seekerFunnel)
    dispatch(resetFunel())
  },[])
  return (
    <>
      <div className="thankyoucontainer explore-wrapper container">
        {state?.error ? (
          <div className="text-center d-flex justify-content-center">
            <h1 className="thankyou-error mt-4">
              {state?.error?.data.message}
              <p>
                <Link
                  to={"/"}
                  className="common-btn d-inline-block w-auto mt-4 text-decoration-none"
                >
                  Go to home
                </Link>
              </p>
            </h1>
          </div>
        ) : ((data && data?.length<0) || waiting) ? (
          <div className="d-flex justify-content-center flex-column">
            <div className="text-center">
              <img src={thankyouvedic} className="thankyou-img" alt="Thank You Vedic" />
              <h3 className="thank-head">GREAT!</h3>
              <p className="thankyou-text mb-4">
                Thank you for sharing your preferences
              </p>
              <p className="subhead-thankyou">
                Please wait, we are loading the related results...
              </p>
            </div>
          </div>
        ) : (
          <>
          <div className="cards-wrapper">
          {data?.length > 0 &&<ProviderCards data={data}/>}
          </div>
          <Row className="justify-content-center p-2 mb-2">
            <Button className="px-5 text-center justify-content-center col-sm-12 col-md-3" variant="outline-dark" onClick={handleSeeMore}>Discover More </Button>
          </Row>
          </>
        )}
      </div>
    </>
  );
};

export default ThankyouVedic;
