/* eslint-disable prettier/prettier */
import { Timestamp } from "firebase/firestore"

const millisecondsPerMinute = 60 * 1000
const millisecondsPerHour = 60 * millisecondsPerMinute
const millisecondsPerDay = 24 * millisecondsPerHour
const millisecondsPerMonth = 30 * millisecondsPerDay

export function getTimeDifferenceAsString(firebaseTime: Timestamp ) {
    if(typeof firebaseTime == 'undefined') return 'some times ago'
    const milliseconds = (firebaseTime?.seconds * 1000) + (firebaseTime?.nanoseconds / 1000000);
    const dateObject =  new Date(milliseconds);
    
    const currentTime = new Date();
    const timeDifference = currentTime.getTime() - dateObject.getTime();


    if (timeDifference < millisecondsPerMinute) {
        let sec = Math.abs(Math.round(timeDifference / 1000))
        return sec < 2 ? 'Just now' : `${sec} secs ago`;
    } else if (timeDifference < millisecondsPerHour) {
        return Math.round(timeDifference / millisecondsPerMinute) + ' mins ago';
    } else if (timeDifference < 24 * millisecondsPerHour) {
        return Math.round(timeDifference / millisecondsPerHour) + ' hrs ago';
    } else if (timeDifference < 30 * millisecondsPerDay) {
        return Math.round(timeDifference / millisecondsPerDay) + ' days ago';
    } else {
        const monthsAgo = Math.round(timeDifference / millisecondsPerMonth);
        return monthsAgo + (monthsAgo === 1 ? ' month ago' : ' months ago');
    }
}

// Function to get the ordinal suffix
function getOrdinalSuffix(day: number) {
    const j = day % 10;
    const k = day % 100;
    if (j === 1 && k !== 11) {
        return "st";
    }
    if (j === 2 && k !== 12) {
        return "nd";
    }
    if (j === 3 && k !== 13) {
        return "rd";
    }
    return "th";
}

// Function to get the abbreviated month name
function getMonthName(monthIndex: number) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", 
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return monthNames[monthIndex];
}

const isoDateTimePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
export enum dateSeparator {
    dot = '.', dash = '-', slash = '/'
}
export enum dateReturnFormat {
    CommonFormat = 'CommonFormat',
    Day_Month = 'Day_Month'
}export function getDateConverted(rawDate: string, separator: dateSeparator, format?: dateReturnFormat) {
    if (rawDate && isoDateTimePattern.test(rawDate)) {
      const date = new Date(rawDate);
      const now = new Date();
  
      // Get day, month, and year components
      const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
      const year = date.getFullYear();
  
      if (format && format === dateReturnFormat.Day_Month) {
        const ordinalSuffix = getOrdinalSuffix(parseInt(day));
        const monthName = getMonthName(parseInt(month) - 1);
        return `${day}${ordinalSuffix} ${monthName} ${year}`; // Always include year
      }
  
      const formattedDate = `${day}${separator || dateSeparator.dot}${month}${separator || dateSeparator.dot}${year}`;
  
      return formattedDate;
    } else return rawDate;
  }
  export function getTimeDifferenceFromServerTime(rawDate: string): string {
    if (rawDate.length < 1) return '';

    const date = new Date(rawDate);
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (diffInSeconds < 60) {
        return `${diffInSeconds} sec${diffInSeconds !== 1 ? 's' : ''}`;
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
        return `${diffInMinutes} min${diffInMinutes !== 1 ? 's' : ''}`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
        return `${diffInHours} hr${diffInHours !== 1 ? 's' : ''}`;
    }

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) {
        return `${diffInDays} day${diffInDays !== 1 ? 's' : ''}`;
    }

    const diffInWeeks = Math.floor(diffInDays / 7);
    if (diffInWeeks < 4) {
        return `${diffInWeeks} week${diffInWeeks !== 1 ? 's' : ''}`;
    }

    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMonths < 12) {
        return `${diffInMonths} month${diffInMonths !== 1 ? 's' : ''}`;
    }

    const diffInYears = Math.floor(diffInDays / 365);
    return `${diffInYears} year${diffInYears !== 1 ? 's' : ''}`;
}

const HHMMoptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
};

// This will be use for chats only
export function formatTimeAgo(timestampString: string) {
    const date = new Date(timestampString);
    const now = new Date().getTime();
    const difference = now - date.getTime();

    const secondsAgo = Math.floor(difference / 1000);
    const minutesAgo = Math.floor(secondsAgo / 60);
    const hoursAgo = Math.floor(minutesAgo / 60);
    const daysAgo = Math.floor(hoursAgo / 24);

    if (daysAgo > 0) {
        const formatter = new Intl.DateTimeFormat('en-US', HHMMoptions as any);
        return {date: date, time: formatter.format(date)};
    } else if (hoursAgo > 0) {
        const formatter = new Intl.DateTimeFormat('en-US', HHMMoptions as any);
        return {date: date, time: formatter.format(date)};
    } else {
        return {date: date, time: `${(isNaN(minutesAgo) || minutesAgo<1) ? 'Just now' : `${minutesAgo} ${minutesAgo === 1 ? 'minute ago' : 'minutes ago'}`}`};
    }
}
