/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Image, Button } from "react-bootstrap"
import settingIcon from "../../../../assets/img/setting-icon.svg"
import noneRequesticon from "../../../../assets/img/noneRequestIcn.png"
import noneNotification from "../../../../assets/img/notificationNone.png"



import "./Notification.css"

import NotificationCard from "./NotificationCard"
import { useAppSelector } from "../../../../app/hooks"
import { allNotifications, archivedNotifications, readRequests, unseenNotifications } from "../../../../features/notification/notificationSlice"
import { toast } from "react-toastify"
import { auth } from "../../../../app/firebase"
import { useMarkAsREadedMutation, useMarkAsIgnoredMutation, useDeleteNotificationMutation } from "../../../../features/notification/notificationApi"
import { useAcceptDeleteRequestMutation } from "../../../../features/featProviderDashboard/FeatProviderApi"
import { useNavigate, useSearchParams } from "react-router-dom"
import { handleRedirect } from "../../../../features/notification/handleRedirects"

function NotificationPage() {
  const [allNotification, setAllNotifications] = useState<any[]>([])
  const [requestss, _requestsCount] = useState<any[]>([])
  const [unseens, _unseenCount] = useState<any[]>([])
  const [ignoredNotifications, _ignoredNotifications] = useState<any[]>([])
  const [selectedOption, _selectedOption] = useState<string>('all')
  const notifications = useAppSelector(allNotifications)
  // const notSeenNotification = useAppSelector(unseenNotifications)
  // const requestsNotifications = useAppSelector(readRequests)
  const ignorefNotificationsFromStore = useAppSelector(archivedNotifications)

  function setFilteredLists(notifications: Object[]) {
    var unseenNotifications: Object[] = []; var requests: Object[] = [];
    notifications?.map((notification: any) => {
      if (notification.data.category?.indexOf("Request") !== -1) requests.push(notification)
      if (!notification.data.isSeen) unseenNotifications.push(notification)
    })
    _requestsCount(requests); _unseenCount(unseenNotifications);
  }

  useEffect(() => {
    if (notifications) {
      if (notifications?.length>0){
        setAllNotifications(notifications)
        setFilteredLists(notifications)
      }
    //   if (notifications?.data) {
    //     setAllNotifications(notifications.data)
    //     setFilteredLists(notifications.data)
    //   }
    //   else if (notifications?.length>0){
    //   setAllNotifications(notifications)
    //   setFilteredLists(notifications)
    // }
  }
  },[notifications])


  // useEffect(()=>{
  //     if(notSeenNotification && notSeenNotification?.length)_unseenCount(notSeenNotification || [])
  // },[notSeenNotification])

  // useEffect(()=>{
  //   if(requestsNotifications && requestsNotifications?.length)_requestsCount(requestsNotifications || [])
  // },[requestsNotifications])

  useEffect(() => {
    if (ignorefNotificationsFromStore && ignorefNotificationsFromStore?.length) _ignoredNotifications(ignorefNotificationsFromStore || [])
  }, [ignorefNotificationsFromStore])


  const auth = useAppSelector((state: any) => state.auth)

  const [makedasRead] = useMarkAsREadedMutation()
  const [markAsIgnore] = useMarkAsIgnoredMutation()
  const [acceptRequest, { data: reqData, isSuccess: success }] =
    useAcceptDeleteRequestMutation()
  const [deleteData] = useDeleteNotificationMutation()

  function markAsReadedFunction(id: string) { makedasRead({ uid: auth?.user?._id, id }) }
  function markAsIgnoredFunction(id: string) { markAsIgnore({ uid: auth?.user?._id, id }) }
  function deleteNotification(id: string) { deleteData({ uid: auth?.user?._id, id }) }

  const navigate = useNavigate()
  const [searchparams, setSearchParams] = useSearchParams()


  const acceptNotification = (index: number, id: string, navId: string) => {
    acceptRequest({
      requestId: navId,
      action: 'Accept',
    }).unwrap().then((res: any) => {
      toast.success('Congrats ! you have added a new member in your journey')
      markAsIgnoredFunction(id)
    })
      .catch((error) => { toast.dismiss(); toast.error("\nPlease try again latter") })
  }

  const handleRedirectNotification = (
    id: string,
    index: number,
    category: string,
    navId?: string,
  ) => {
    markAsReadedFunction(id)
    const { path, state } = handleRedirect(category, id, auth?.role, navId)
    navigate(path, { state: state, replace: true })
  }

  const handleOnClick = (index: number, id: string, category: string, navigationId?: string) => {
    handleRedirectNotification(
      id,
      index,
      category,
      navigationId,
    )
  }

  const markAllAsReadNotification = () => {
    unseens.map((notification: any) => {
      if (!notification.data.isSeen) markAsReadedFunction(notification.id)
    })
  }

  const ignoreNotification = (index: number, id: string) => {
    markAsIgnoredFunction(id)
  }
  useEffect(()=>{
    switch (searchparams.get('notifications')) {
      case 'archive':
        if(ignoredNotifications.length > 0) _selectedOption("ignored")
        else {toast.warn('You have no archived requests',{delay: 600}); searchparams.delete('notifications'); setSearchParams(searchparams)}
        break;
      default:
        break;
    }
  },[searchparams])

  return (
    <div className="bg-white p-2 py-4  rounded-xl my-4 mt-0 notifications-page">
      <Container fluid>
        <Row className="justify-content-center">
          <Col xs={12} className="primary-scrollbar d-flex justify-content-center">
            <Container className="overflow-hidden d-flex flex-column p-0">
              <Row className="d-flex justify-content-between p-2">
                <Col as="p" className="m-0">
                  <h2 className="heading-2  text-lg-start">
                    Notifications
                  </h2>
                </Col>
                {unseens?.length > 0 && <Col
                  onClick={markAllAsReadNotification}
                  xs="auto"
                  className=" cursor-pointer text-end color-primary pg-mark-read-text"
                >
                  Mark all as read
                </Col>}
              </Row>
              <Row className="d-flex align-items-center gap-0 notification-tabs">
                <Col className="d-flex  align-items-center gap-4  notification-tabs-scroll-box">
                <Col
                  className={`d-flex justify-content-center align-items-center m-2 gap-2 ${selectedOption == "all" && "active"
                    }`}
                  onClick={() => {
                    _selectedOption("all")
                  }}
                >All
                    {selectedOption == "all" && (
                      allNotification?.length > 0 &&
                      < div className="digit-box ">
                        {allNotification?.length > 0 ? allNotification?.length : ''}
                      </div>
                  )}
                </Col>
                <Col
                  className={`d-flex justify-content-center align-items-center m-0 gap-2 ${selectedOption == "unseen" && "active"
                    }`}
                  onClick={() => {
                    _selectedOption("unseen")
                  }}
                >New
                    {selectedOption == "unseen" && (
                      unseens?.length > 0 &&
                    <div className="digit-box">
                      {unseens?.length < 1 ? "" : unseens.length}
                    </div>
                  )}
                </Col>
                <Col
                  className={`d-flex justify-content-center align-items-center m-0 gap-2 ${selectedOption == "requests" && "active"
                    }`}
                  onClick={() => {
                    _selectedOption("requests")
                  }}
                >Requests
                    {selectedOption == "requests" && (
                      requestss?.length > 0 &&

                    <div className="digit-box ">
                      {requestss?.length < 1 ? "" : requestss.length}
                    </div>
                  )}
                </Col>
                {(ignoredNotifications?.length > 0) && <Col

                  className={`d-flex justify-content-center align-items-center m-0 gap-2 ${selectedOption == "ignored" && "active"
                    }`}
                  onClick={() => {
                    _selectedOption("ignored")
                  }}
                >Archived
                  {selectedOption == "ignored" && (
                    <div className="digit-box ">
                      {ignoredNotifications?.length < 1 ? "" : ignoredNotifications.length}
                    </div>
                  )}
                </Col>}
                </Col>
                
              </Row>
              <Container className="d-flex flex-column  gap-0 h-100 overflow-y-auto overflow-x-hidden custom-scrollbar my-1">
                {(selectedOption == 'all' && allNotification.length > 0) && (allNotification.map((notification: any, index: number) => {
                  return (<>
                    <NotificationCard
                      notification={notification}
                      index={notification.id}
                      handleOnClick={handleOnClick}
                      acceptNotification={acceptNotification}
                      ignoreNotification={ignoreNotification}
                      isPremium={auth?.user?.isPremium}
                    />
                  </>
                  )
                }))}
                {(selectedOption == 'unseen' && unseens.length > 0) && (unseens.map((notification: any, index: number) => {
                  return (<>
                    <NotificationCard
                      notification={notification}
                      index={notification.id}
                      handleOnClick={handleOnClick}
                      acceptNotification={acceptNotification}
                      ignoreNotification={ignoreNotification}
                      isPremium={auth?.user?.isPremium}
                    />
                  </>
                  )
                }))}
                {(selectedOption == 'requests' && requestss.length > 0) && (requestss.map((notification: any, index: number) => {
                  return (<>
                    <NotificationCard
                      notification={notification}
                      index={notification.id}
                      handleOnClick={handleOnClick}
                      acceptNotification={acceptNotification}
                      ignoreNotification={ignoreNotification}
                      isPremium={auth?.user?.isPremium}
                    />
                  </>
                  )
                }))}
                {(selectedOption == "ignored" && ignoredNotifications.length > 0) && (ignoredNotifications.map((notification: any, index: number) => {
                  return (<>
                    <NotificationCard
                      notification={notification}
                      index={notification.id}
                      handleOnClick={handleOnClick}
                      acceptNotification={acceptNotification}
                      ignoreNotification={ignoreNotification}
                      ifIgnored={true}
                      isPremium={auth?.user?.isPremium}
                    />
                  </>
                  )
                }))}
                {(selectedOption == 'all' && allNotification.length < 1) && (<Row className=' text-center justify-content-center align-items-center py-3'> <div className='d-flex flex-column align-items-center justify-content-center mt-5'><img src={noneNotification} alt="" className='w-50 '/>
                  <div> Nothing to show here  </div></div></Row>)}  
                {(selectedOption == 'unseen' && unseens.length < 1) && (<Row className=' text-center justify-content-center align-items-center py-3'>
                <div className='d-flex flex-column align-items-center justify-content-center mt-5'><img src={noneNotification} alt="" className='w-50 '/>
       <div> Nothing to show here  </div></div>
                </Row>)}
                {(selectedOption == 'requests' && requestss.length < 1) && (<Row className=' text-center justify-content-center align-items-center py-3'>
                <div className='d-flex flex-column align-items-center justify-content-center mt-5'><img src={noneRequesticon} alt="" className='w-50 '/>
       <div> Nothing to show here  </div></div>
                </Row>)}
                {(selectedOption == 'ignored' && ignoredNotifications.length < 1) && (<Row className=' text-center justify-content-center align-items-center py-3'>Empty</Row>)}
              </Container>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default NotificationPage
