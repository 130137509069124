import { fakeBaseQuery } from '@reduxjs/toolkit/query'
import { createApi } from "@reduxjs/toolkit/query/react"
import { db, messaging } from '../../app/firebase'
import { collection, query, where, onSnapshot, getDocs, orderBy, Timestamp, startAfter, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { getTimeDifferenceAsString } from '../../utils/timeDifferene/getTimeDifference';
import {updateNotifications, updateArchiveNotifications, updateUnseenNotifications, getCurrentNotificationToken} from './notificationSlice'
import { getToken, onMessage } from 'firebase/messaging';
import { toast } from 'react-toastify';
import { RootState, store } from '../../app/store';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { NotificationTokenError, NotificationTokenResponse } from '../../Types';
import styles from './notificationStyles.module.css'
import { OverlayTrigger, Popover } from 'react-bootstrap';


interface args2 {
    uid?: string , 
    lastDate?: Timestamp
}

interface docPAth {
    uid?: string,
    id?: string
}

interface Notification {
  id: string;
  data: any; 
}
interface NotificationPayload {
  notification: {
    title: string;
    body: string;
  };
}
export const notificationApi = createApi({
    reducerPath: "notificationApi",
    baseQuery: fakeBaseQuery(),
    tagTypes: ["notification"],

    endpoints: (builder) => ({
      fetchNotificationInstance: builder.query<Notification[], string | null>({
        queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
          try {
            const notificationRef = collection(db, `/users/${arg}/notifications`);
            const notificationQuery = query(notificationRef,
              where('date', '!=', null),
              orderBy('date', 'desc')
            );
      
            // Initial fetch from Firestore
            const querySnapshot = await getDocs(notificationQuery);
            const data: Notification[] = querySnapshot.docs.map((doc) => {
              const id = doc.id;
              const rawData = doc.data();
              const timestamp = getTimeDifferenceAsString(rawData.date);
              rawData.date = timestamp;
              return { id, data: rawData };
            });
      
            return { data };
      
          } catch (error) {
            return { error: (error as any).message };
          }
        },
        async onCacheEntryAdded(
          arg,
          { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }
        ) {
          try {
            await cacheDataLoaded; // Ensure the cache is loaded before proceeding
      
            const notificationRef = collection(db, `/users/${arg}/notifications`);
            const notificationQuery = query(notificationRef,
              where('date', '!=', null),
              orderBy('date', 'desc')
            );
      
            // Subscribe to the query
            const unsubscribe = onSnapshot(notificationQuery, (querySnapshot) => {
              const data: Notification[] = [];
              const archiveData: Notification[] = [];
              let unseenData = 0;
      
              querySnapshot.docs.forEach((rawDoc) => {
                const id = rawDoc.id;
                const rawData = rawDoc.data();
                const timestamp = getTimeDifferenceAsString(rawData.date);
                rawData.date = timestamp;
      
                if (rawData.isClosed) {
                  archiveData.push({ id, data: rawData });
                } else {
                  if (!rawData.isSeen) {
                    unseenData += 1;
                  }
                  data.push({ id, data: rawData });
                }
              });
      
              updateCachedData((draft) => {
                draft.splice(0, draft.length, ...data); // Replace the draft array with new data
              });
              dispatch(updateNotifications({ data })); // Pass the data as the payload
              dispatch(updateArchiveNotifications({ data: archiveData })); // Pass the archive data as the payload
              dispatch(updateUnseenNotifications({ data: unseenData })); // Pass the unseen data as the payload
            });
      
            // Unsubscribe on cache entry removal
            await cacheEntryRemoved;
            unsubscribe();
          } catch (err) {
            console.error("Failed in onCacheEntryAdded lifecycle:", err);
          }
        },
        providesTags: (result) => 
          result
            ? [
                ...result.map(({ id }) => ({ type: 'notification' as const, id })),
                { type: 'notification' as const, id: 'NOTIFICATIONS' },
              ]
            : [{ type: 'notification' as const, id: 'NOTIFICATIONS' }],
        keepUnusedDataFor: 300,
      }),
      
      
      

          markAsREaded: builder.mutation<any, any>({
            async queryFn(arg: docPAth) {
                try{
                const dataRef = doc(db, `/users/${arg.uid}/notifications/${arg.id}`); // Replace placeholders
                updateDoc(dataRef, {
                    isSeen: true,
                  });
                }
                catch (error){
                  console.error(error)
                  return { data: null}
                }finally{return { data: null}}
            },
            invalidatesTags: ['notification']
          }),
          markAsIgnored: builder.mutation<any, any>({
            async queryFn(arg: docPAth) {
                try{
                const dataRef = doc(db, `/users/${arg.uid}/notifications/${arg.id}`); // Replace placeholders
                updateDoc(dataRef, {
                    isClosed: true,
                  });
                }
                catch (error){
                  console.error(error)
                  return { data: null}
                }
                finally{return { data: null}}
            },
          }),
          deleteNotification: builder.mutation<any, any>({
            async queryFn(arg: docPAth) {
                try{
                await deleteDoc(doc(db, `/users/${arg.uid}/notifications/${arg.id}`));
                }
                catch (error){
                  console.error(error)
                  return { data: null}
                }finally{return { data: null}}
            },
          }),
          fetchToken: builder.query<NotificationTokenResponse, string | undefined>({
            queryFn: async (VAPID: string | undefined, _queryApi): Promise<QueryReturnValue<NotificationTokenResponse, NotificationTokenError>> => {
              // Get the current state
              const state = _queryApi.getState() as RootState;
              const token = state.notificationSlice.notificationToken;
          
              // If a token is already present, return it as data
              if (token) return { data: { data: token } };
              else if (token !== null) return { error: { data: false } };
          
              if (VAPID) {
                try {
                  // Request notification permission
                  const permission = await Notification.requestPermission();
                  if (permission !== 'granted') {
                    if (permission === 'default') return { error: { message: 'Notification permission missing', data: null } };
                    else return { error: { message: 'Notification permission denied', data: false } };
                  }
          
                  if (messaging) {
                    // Check if the service worker is already registered
                    const registrations = await navigator.serviceWorker.getRegistrations();
                    let registration = registrations.find((reg) => reg.active?.scriptURL.includes('firebase-messaging-sw.js'));
          
                    if (!registration) {
                      // Register the service worker if it's not already registered
                      registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
                    }
          
                    await navigator.serviceWorker.ready;
          
                    // Get the notification token
                    const token = await getToken(messaging, { vapidKey: VAPID });
                    return { data: { data: token } };
                  } else {
                    return { error: { message: 'Messaging is not initialized', data: false } };
                  }
                } catch (error: any) {
                  // Handle any errors and return the error structure
                  return { error: { message: error.message, data: false } };
                }
              } else {
                // Return an error if VAPID key is missing
                return { error: { data: null } };
              }
            },
          }),   
          
          listenForMessages: builder.query<void, void>({
            queryFn: async () => {
              try {
                if (messaging) {
                  // Listen for foreground messages
                  onMessage(messaging, (payload: any) => {
                    const { title, body, image } = payload.notification;
          
                    // Show toast notification
                    toast(
                      <>
                        <div className={`${styles.toastPushNotificationTitle}`}>
                          <img 
                            className="me-2" 
                            src={image || "https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/Frame%201171276360.png"}
                            height={32} 
                            alt="logo" 
                          />
                          {title}
                        </div>
                        <OverlayTrigger
                          overlay={<Popover><p>{body}</p></Popover>}
                        >
                          <span className={`${styles.toastPushNotificationDescription}`}>{body}</span>
                        </OverlayTrigger>
                      </>,
                      {
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        autoClose: false,
                        icon: <></>,
                        closeButton: true,
                        onClick: () => {
                          window.open('https://vedichom.com', '_blank');  
                          toast.dismiss(); 
                        }
                      }
                    );                  
                  });
          
                  return { data: undefined }; // No need to return data since we don't store it
                } else {
                  return { error: { message: 'Messaging is not initialized' } };
                }
              } catch (error) {
                return { error: { message: (error as any)?.message } };
              }
            },
          }),
          
    })
})

export const { 
    useFetchNotificationInstanceQuery,
    useMarkAsREadedMutation,
    useMarkAsIgnoredMutation,
    useDeleteNotificationMutation,
    useFetchTokenQuery,
    useListenForMessagesQuery  } = notificationApi


