const base_url = 'www.vedichom.com'
export const refundPolicyContent = (
    <>
        <div>
            <div className="tc-topic-text">
                Refund and Cancellation Policy</div>
            <p className="tc-text-info">
                At <a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a>, we aim to provide seamless and flexible service for both our sources (teachers)
                and seekers (students). To maintain the integrity of the platform, we have established the
                following policy regarding refunds and cancellations:
            </p>

        </div>
        <div>
            {/* <div className="tc-topic-text">GENERAL</div> */}
            <div className="tc-topic-text">1. No Refunds or Cancellations</div>
            <p className="tc-text-info">
                <ul >
                    <li className="tc-text-info"><a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a> does not provide refunds or cancellations for any services or subscriptions
                        once a transaction is completed.
                    </li>
                </ul>

            </p>

        </div>
        <div>
            <div className="tc-topic-text">2. Grievance Consideration
            </div>
            <p className="tc-text-info">
                <ul>
                    <li><strong>Grievances</strong> will only be considered under the following circumstances:</li>
                    <ul className="tc-topic-ul">
                        <li><strong>Overpayment</strong>: If you believe you have paid more than the required amount for a
                            class or subscription, you may file a grievance.</li>
                    </ul>
                    <ul className="tc-topic-ul">
                        <li ><strong>Technical Glitch</strong>: If a technical issue during the payment process resulted in an
                            incorrect transaction, you may submit a grievance for review.
                        </li>
                    </ul>
                </ul>
            </p>
        </div>
        <div>
            <div className="tc-topic-text">3. Filing a Grievance</div>
            <p className="tc-text-info">
                <ul>
                    <li className="tc-text-info">
                        To file a grievance, please contact <a className="support-email-text" href="mailto:support@vedichom.com">support@vedichom.com</a> within <strong>7 days</strong>  of the
                        transaction, providing all relevant details including transaction ID, payment amount, and
                        the nature of the issue.
                    </li>
                    <li className="tc-text-info">
                        Grievances will be reviewed, and if deemed valid, any excess payment or error will be
                        rectified within <strong>10-15 working days.</strong> 
                    </li>
                </ul>
            </p>
        </div>
        <div>
            <div className="tc-topic-text">4.  Technical Support</div>
            <p className="tc-text-info">
                <ul>
                    <li className="tc-text-info">For any issues regarding your account, payments, or other technical difficulties, our
                    support team is available to assist you at <a className="support-email-text" href="mailto:support@vedichom.com">support@vedichom.com</a></li>
                </ul>
            </p>

        </div>
        <div>
            <div className="tc-topic-text">5. Changes to the Policy</div>
            <p className="tc-text-info">
               <ul>
                <li><a
              href={base_url}
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text text-decoration-none"
            >
              VedicHom{" "}
            </a> reserves the right to modify or update this policy without prior notice. Any
                changes will be posted on our website.</li>
               </ul>
            </p>    
        </div>
    </>)