import { createSlice } from '@reduxjs/toolkit';
import { signInChatThunk } from './signInChatThunk';

const firebaseAuthSlice = createSlice({
  name: 'firebaseAuth',
  initialState: {
    signedInUser: '',
    signinError: null,
    isLoading: false
  } as {
    signedInUser: any,
    signinError: any,
    isLoading: boolean
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signInChatThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.signedInUser = action.payload;
        state.signinError = null;
      })
      .addCase(signInChatThunk.pending, (state) => {
        state.isLoading = true;
        state.signedInUser = null;
        state.signinError = null;
      })
      .addCase(signInChatThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.signedInUser = null;
        state.signinError = action.payload;
      });
  },
});

export const firebaseAuthReducer = firebaseAuthSlice.reducer;

export const firebaseAuthInfo = (state: { firebaseAuth: { signedInUser: any, isLoading: boolean, signinError: any } }) => state.firebaseAuth;
