import { Form } from "react-bootstrap"
import { Controller } from "react-hook-form"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"
import FormLabel from "../FormLabel"
import "./formMultiSelect.css"

interface FormSelectProps {
  name: string
  title: string
  errors?: any
  control?: any
  options: any[]
  isMulti?: boolean
  placeholder?: string
  className?: string
  disabled?: boolean
  onChange?: (selectedOptions: any) => void
  value: string[]
}

function FormMultiSelect({
  errors,
  title,
  value,
  name,
  control,
  options,
  isMulti,
  placeholder = "",
  className = "",
  onChange,
  disabled,
}: FormSelectProps) {
  return (
    <>
      <Form.Group className={className}>
        <FormLabel title={title} />
        <Controller
          name={name}
          rules={{ required: "This is a required Field" }}
          control={control}
          render={({ field }) => (
            <div className={!className ? "multi-select-custom" : ""}>
              <Select
                options={options}
                value={value}
                placeholder="Select one or mutiple"
                isMulti
                menuPlacement="bottom"
                onChange={(selectedOptions) => {
                  // selectedOptions is multivalued array with [{lable, value}{..}]
                  field.onChange(selectedOptions)
                  if (onChange) {
                    onChange(selectedOptions)
                  }
                }}
                styles={{
                  menu: (provided) => ({ ...provided, bottom: "auto", display: options?.length>0 ?'auto':'none' }),
                }}
                isDisabled={disabled}
              ></Select>
            </div>
          )}
        />
        {errors && errors[name] ? (
          <div className="invalid-feedback d-block">
            {errors[name]["message"]}
          </div>
        ) : null}
      </Form.Group>
    </>
  )
}

export default FormMultiSelect
