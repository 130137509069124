import React, { useEffect, useState, useRef, Suspense } from "react"
import { toast } from "react-toastify"
import { Button, Form, Modal } from "react-bootstrap"
import mailicon from "../../../../assets/img/mail-icon.svg"
import passwordicon from "../../../../assets/img/password-icon.svg"
import googleimage from "../../../../assets/img/google-icon.png"
import profilecircle from "../../../../assets/images/profilecircle.svg"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import FormInput from "../../../form/FormInput"
import { useAppDispatch } from "../../../../app/store"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
  useGoogleSignupSeekerMutation,
  useGoogleSignupMutation,
  useUserRegisterMutation,
  useCheckCouponQuery,
} from "../../../../features/Auth/authApi"
import { setModalName } from "../../../../features/Auth/ModalHandler/modalSlice"
import { APP_ENV, MODALNAME, USERTYPE } from "../../../../constants"
import { useProviderRegisterMutation } from "../../../../features/Auth/authApi"
import { useAppSelector } from "../../../../app/hooks"
import {
  setCurrentUser,
  setTermApproved,
} from "../../../../features/Auth/authSlice"
import { MdEmail } from "react-icons/md"
import {
  seekerSignupWithEmailSchema,
  seekerSignupWithPhoneSchema,
} from "../../../../utils/yupSchema/Schema"
import "../authComponent.css"
import { TermsAndConditionLable } from "../../termsModal/TermsAndCModal"
import { PhoneInput, PhoneInputRefType } from "react-international-phone"
import { IoPhonePortraitOutline } from "react-icons/io5"
import SpinnerSecondary from "../../../spinner/SpinnerSecondary"
import { GoCrossReference } from "react-icons/go"
import { sanitizePhoneNumber } from "../../../../utils/commonFunctions/CommonFunctions"
import { useDebounce } from "../../../../utils/debouncer"
import { RiVerifiedBadgeFill } from "react-icons/ri"
import RecaptchaComponent from "../../../recaptaComponent"
// import PhoneInput from 'react-phone-number-input'
const BASE_URL = APP_ENV &&  ['testing','development'].includes(APP_ENV as string) ? import.meta.env.VITE_REACT_TEST_API_URL : import.meta.env.VITE_REACT_APP_API_URL 
const ENDPOINT_URL_PROVIDER = import.meta.env
  .VITE_REACT_APP_GOOGLE_SIGN_UP_PROVIDER
const ENDPOINT_URL_SEEKER = import.meta.env.VITE_REACT_APP_GOOGLE_SIGN_UP_SEEKER
type Props = { userType: any }
interface SignUpUser {
  firstName: string
  email: string
  lastName: string
  password: string
  phone: any
  countryCode: any
}

enum signUpMethode {
  PHONE = "Phone",
  E_MAIL = "e-mail",
  none = "none",
}

export default function SignupSeeker({ userType }: Props) {
  const [methode, setMethode] = useState<signUpMethode>(signUpMethode.none)
  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    formState: { errors },
    setError,
    clearErrors
  } = useForm<any>({
    resolver:
      methode === signUpMethode.E_MAIL
        ? seekerSignupWithEmailSchema
        : seekerSignupWithPhoneSchema,
  })
  const lastNameInputRef = React.createRef()
  const emailInputRef = useRef<HTMLInputElement>(null)
  const phoneInputRef = useRef<PhoneInputRefType>(null)
  const passwordInputRef = React.createRef()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isFormVisible, setFormVisible] = useState(false)
  const [phoneNumFocous, _phoneNumFocous] = useState(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const [CouponCode, setCode] = useState<string | null>(null)
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [showCaptcha, setShowCaptcha] = useState(false); // Adjust visibility as needed
  function resetCaptaverification () {
    setShowCaptcha(false)
    setCaptchaToken(null)
  }
    const handleCaptchaChange = (token: string | null) => {
        if (token) {
            setCaptchaToken(token);
            // Send the token to your server for validation
            console.log("CAPTCHA Token:", token);
        }
    };

    const handleCaptchaError = () => {
        console.error("CAPTCHA Error: Unable to render CAPTCHA.");
        // Additional error handling logic
    };

    const handleCaptchaExpired = () => {
        setCaptchaToken(null);
        console.warn("CAPTCHA Expired: Please try again.");
        // Additional expiration handling logic
    };

  function setReffralId () {
    if(searchParams.get('reffralId')) {
      setValue('referralId', searchParams.get('reffralId'))
    }
  }
  const toggleFormVisibility = (methode: signUpMethode) => {
    setMethode(methode)
    setFormVisible(!isFormVisible)
    reset()
    setReffralId()
  }
  function handleMethodeChange(signupMethode: signUpMethode) {
    resetCaptaverification()
    setLoading(true)
    setTimeout(() => {
      toggleFormVisibility(signupMethode)
      setFormVisible(true)
      setLoading(false)
    }, 310)
  }
  const [googleSignup, { data: googleData }] = useGoogleSignupMutation()
  const [googleLoginSeeker, { data: googleDataSeeker }] =
    useGoogleSignupSeekerMutation()
  const [userRegister, { data, isError, error, isSuccess }] =
    useUserRegisterMutation()
  const { isTermsConditionAccepted } = useAppSelector(
    (state: any) => state.auth,
  )

  const passwordWatcher = watch("password")
  const referralId = watch("referralId")
  const handleSeekerRegistration = async (payload: any) => {
    try {
      userRegister({ payload: payload, referralId: referralId?.trim() })
        .unwrap()
        .then(() => {
          dispatch(setCurrentUser(USERTYPE.SEEKER))
          if (methode === signUpMethode.E_MAIL)
            dispatch(setModalName(MODALNAME.OTP_VERIFICATION))
          else dispatch(setModalName(MODALNAME.OTP_VERIFICATION_PHONE))
        })
    } catch (error) {
      console.error("Error during registration:", error)
    }
  }
const errorToastify = (msg: string) => {toast.dismiss(); toast.error(msg)}
const debouncedRegistration = useDebounce(handleSeekerRegistration, 300)
const debouncedErrorToastify = useDebounce(errorToastify, 300)
  const firstName = watch("firstName")
  const lastName = watch("lastName")
  const onSubmit = (values: any) => {
    const payloade  = methode === signUpMethode.PHONE ? {
      firstName: values.firstName,
      password: values.password,
      lastName: values.lastName,
      mobileNo: sanitizePhoneNumber(values.phone),
      countryCode: values.countryCode,
    } : {
      firstName: values.firstName,
      email: values.email,
      password: values.password,
      lastName: values.lastName,
    }
    if (['testing','production'].includes(APP_ENV as string)) {
      setShowCaptcha(true);
      if (captchaToken) debouncedRegistration(payloade)
      else if(showCaptcha) toast.warn('Please fill captcha first')
    }
  else {
    debouncedRegistration(payloade)
  }
  }

  useEffect(() => {
    if (data?.message) {
      toast.success(data?.message)
    } else {
      debouncedErrorToastify((error as any)?.data?.message)
    }
  }, [data, error])

  const handleGoogleLogin = async () => {
    window.location.replace(`${BASE_URL}/api/seeker/auth/google`)
  }
  const handleKeyDown = (e: any, nextInputRef: any) => {
    if (e.key === "Enter") {
      e.preventDefault()
      nextInputRef.current.focus()
    }
  }
  const handlePhoneChange = (value: any, country: any) => {
    const countryCode = "+" + country.country.dialCode
    const phoneNum = value.replace(countryCode, "")
    setValue("countryCode", countryCode)
    setValue("phone", phoneNum, { shouldValidate: true })
  }

  useEffect(() => {
    dispatch(setTermApproved(false))
  }, [])

  useEffect(()=>{
    setReffralId()
  },[searchParams])
  const refferalCode = watch('referralId')
    const setCouponCodeDebounced = useDebounce(setCode, 890)
    useEffect(()=>{setCouponCodeDebounced(refferalCode)},[refferalCode])
    const {error: CouponVarificationFail, isLoading: loading, isError: isInValidCoupoun, isSuccess: isValidCoupoun} = useCheckCouponQuery({couponCode: CouponCode,userType: 'Seeker'},
        {
            skip: CouponCode === undefined || CouponCode === null ? true : CouponCode.length < 3,
            refetchOnMountOrArgChange: true
        }
    )
    useEffect(()=>{
      if(isInValidCoupoun) {
          setError("referralId", {
              type: "manual",
              message: (CouponVarificationFail as any)?.data?.message,
            });
      }
      else clearErrors('referralId')
  },[CouponVarificationFail])
  return (
    <>
        <Modal.Body className="popup-body login-tab-wrapper border-0 mb-4">
        <h3 className="popup-title mb-md-4 mb-3">Signup Seeker</h3>
        {isLoading ?
        <div className=" d-flex justify-content-center">
        <SpinnerSecondary wrapperClass=" " />
      </div>
      :<>
        <Form onSubmit={handleSubmit(onSubmit)} className="mb-4">
          <div
            className={`signup-form-wrapper mb-3 ${isFormVisible?"active":''}`}
          >
            <div className="popup-field-wrapper mt-1 w-100">
              <img src={profilecircle} alt="Mail Icon" className="pe-none" />
              <FormInput
                name="firstName"
                placeholder="First Name"
                register={register}
                // errors={errors}
                className="popup-field w-100"
                onKeyDown={(e: any) => handleKeyDown(e, lastNameInputRef)}
                watch={firstName}
              />
            </div>
            <span className="error text-danger ">
              {(errors as any)?.firstName?.message}
            </span>
            <div className="popup-field-wrapper mt-3 w-100">
              <img
                src={profilecircle}
                alt="Password Icon"
                className="pe-none"
              />

              <FormInput
                name="lastName"
                // title="Last Name"
                placeholder="Last Name"
                register={register}
                ref={lastNameInputRef}
                // errors={errors}
                watch={lastName}
                className="popup-field w-100"
                onKeyDown={(e: any) => handleKeyDown(e, phoneInputRef)}
              />
            </div>
            <span className="error text-danger ">
              {(errors as any)?.lastName?.message}
            </span>
            {methode === signUpMethode.PHONE && (
              <div
                className="popup-field-wrapper mt-3 PhoneInputInput_bg-gray--subtle"
                onFocus={() => _phoneNumFocous(true)}
              >
                {/* <img src={phone} alt="Password Icon" className="pe-none" /> */}
                <Suspense>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput
                        defaultCountry="in"
                        placeholder="Enter the phone number"
                        inputRef={phoneInputRef}
                        onChange={(phone: any, country: any) => {
                          handlePhoneChange(phone, country)
                        }}
                      />
                    )}
                  />
                </Suspense>
              </div>
            )}
            {phoneNumFocous && (errors as any)?.phone && (
              <span className="error text-danger">
                {(errors as any)?.phone?.message}
              </span>
            )}

            {methode === signUpMethode.E_MAIL && (
              <>
                <div className="popup-field-wrapper mt-3">
                  <img src={mailicon} alt="Mail Icon" className="pe-none" />
                  <FormInput
                    name="email"
                    placeholder="Enter your email"
                    register={register}
                    // errors={errors}
                    className="popup-field w-100"
                    ref={emailInputRef}
                    onKeyDown={(e: any) => handleKeyDown(e, emailInputRef)}
                  />
                </div>
                <span className="error text-danger">
                  {(errors as any)?.email?.message}
                </span>
              </>
            )}

            <div className="popup-field-wrapper mt-3">
              <img src={passwordicon} alt="Password Icon" className="pe-none" />

              <FormInput
                name="password"
                type="password"
                placeholder="Enter your password"
                register={register}
                watch={passwordWatcher}
                // errors={errors}
                className="popup-field w-100"
                ref={passwordInputRef}
                onKeyDown={(e: any) => handleKeyDown(e, emailInputRef)}
              />
            </div>
            <span className="error text-danger mb-3">
              {(errors as any)?.password?.message}
            </span>
            <>
            <div className="popup-field-wrapper mt-3 w-100">
              <GoCrossReference />
              <FormInput
                name="referralId"
                placeholder="Your Referral code (Optional)"
                register={register}
                className="popup-field w-100"
              />
              {isValidCoupoun&&<RiVerifiedBadgeFill size={32} color='green' />}
            </div>
            <span className="error text-danger">
              {(errors as any)?.referralId?.message}
            </span>
            </>
            <TermsAndConditionLable />
            <RecaptchaComponent
                show={showCaptcha}
                onChange={handleCaptchaChange}
                onErrored={handleCaptchaError}
                onExpired={handleCaptchaExpired}
            />
            <Button
              type="submit"
              className="common-btn auth-btn mb-3 mt-3"
              disabled={
                Object.keys(errors).length !== 0 || !isTermsConditionAccepted
              }
            >
              Signup
            </Button>
          </div>
        </Form>
            <>
          <div className="gap-3 max-w-450 mx-auto mb-4 my-1">
            <a
              className="loginwith-button google-btn d-block mb-3"
              onClick={handleGoogleLogin}
            >
              <img src={googleimage} alt="Google Icon" className="pe-none" />
              Signup with Google
            </a>
            {/* <a className="loginwith-button facebook-btn d-block mb-3">
            <img src={facebookimage} alt="Facebook Icon" />
            Signup with Facebook
          </a> */}
            <a
              className={`loginwith-button signup-btn d-block mb-3 ${
                methode === signUpMethode.PHONE && isFormVisible ? "d-none" : ""
              }`}
              onClick={() => {
                handleMethodeChange(signUpMethode.PHONE)
              }}
            >
              <IoPhonePortraitOutline /> Signup with Phone
            </a>
            <a
              className={`loginwith-button signup-btn d-block mb-3 ${
                methode === signUpMethode.E_MAIL && isFormVisible
                  ? "d-none"
                  : ""
              }`}
              onClick={() => {
                handleMethodeChange(signUpMethode.E_MAIL)
              }}
            >
              <MdEmail /> Signup with Email
            </a>
          </div>
          <div className="text-center">
            <a className="signup-link">
              Already have an account?{" "}
              <span
                onClick={() => dispatch(setModalName(MODALNAME.LOGIN))}
                // onClick={() => dispatch(setModalName(MODALNAME.OTP_VERIFICATION))}
              >
                Log in
              </span>
            </a>
            <div className="text-center mt-0">
              or <br />
              <div
                className=" cursor-pointer primary-bold-text"
                onClick={() => {
                  navigate("/source-signup"),
                    dispatch(setModalName(MODALNAME.CLOSED))
                }}
              >
                Signup as a Source
              </div>
            </div>
          </div>
          </>
        
        </>}
        </Modal.Body>
    </>
  )
}
