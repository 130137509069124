import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Stack,
  Badge,
} from "react-bootstrap"
import sendIcon from "../../assets/img/send-icon.svg"
import styles from "./ChatUi.module.css"
import ChatDialogues from "./ChatDialogues"
import { useAppSelector } from "../../app/hooks"
import { requestData } from "../../features/chat/ChatSlice"
import { useEffect, useRef, useState } from "react"
import { auth, db } from "../../app/firebase"
import {
  arrayUnion,
  collection,
  doc,
  getCountFromServer,
  query,
  runTransaction,
  serverTimestamp,
  where,
  writeBatch,
} from "firebase/firestore"
import { useGetChatTokenQuery } from "../../features/Auth/authApi"
import {
  IoArrowBackCircle,
  IoChevronBackCircleSharp,
  IoChevronDownCircleSharp,
} from "react-icons/io5"
import { CiLocationOn } from "react-icons/ci"
import { useNavigate, useSearchParams } from "react-router-dom"
import SelectedChatInfo from "./SelectedChatInfo"
import {
  useChatData,
  useUserData,
} from "../../utils/commonFunctions/CommonFunctions"
import { Message, chatApi } from "../../features/chat/ChatApi"
import { useAppDispatch } from "../../app/store"
import { nanoid } from "@reduxjs/toolkit"
import {
  ProviderToken,
  SeekerToken,
  selectCurrentUser,
} from "../../features/Auth/authSlice"
import { Provider } from "react-redux"
import { useAcceptDeleteRequestMutation } from "../../features/featProviderDashboard/FeatProviderApi"
import { toast } from "react-toastify"
import ChatAcceptReject from "./UnlockPremiumChat"
import { ItemWithISODate } from "../../Types"
import NoRequest from "./NoRequestYet"
import UnlockPremiumChat from "./UnlockPremiumChat"
import { BsBackpack } from "react-icons/bs"
import { easeIn, easeInOut, motion } from "framer-motion"
import ChatApprovedCard from "./ChatApprovedCard"
import { USERTYPE } from "../../constants"
import { FaArrowLeft } from "react-icons/fa"
import ChatScreen from "./ChatScreen"

const ChatArea = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { userType, sourceProfileData, seekerProfileData, isLoading } =
    useUserData()
  const { seekerToken, providerToken, user } = useAppSelector(selectCurrentUser)
  const { pendingChatData, liveChatData } = useChatData()
  const selectedChatInfo: ItemWithISODate | undefined = [
    ...pendingChatData,
    ...liveChatData,
  ]?.find(
    (item: ItemWithISODate) => item.requestId === searchParams.get("requestId"),
  )
  // console.log(selectedChatInfo, "check nnnnnnnn", [
  //   ...pendingChatData,
  //   ...liveChatData,
  // ])
  // const {data} = useGetChatTokenQuery()
  const { uid, displayName }: any = auth.currentUser
  const [msg, setMsg] = useState<string>("")
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false)
  const chatRef = useRef<any>(null)
  const chatboxBottomDivRef = useRef<any>(null)
  const chatInputRef = useRef<any>(null)
  const handleScrollToBottom = () => {
    chatboxBottomDivRef.current?.scrollIntoView({ behavior: "smooth" })
  }
  const handleEmojiClick = (event: any) => {
    setMsg((prevMessage) => prevMessage + event.emoji)
  }
  const openEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker)
  }
  const handleChange1 = (e: any) => {
    setMsg(e.target.value)
    adjustHeight(e.target)
  }

  const adjustHeight = (element: any) => {
    element.style.height = "53px"
    element.style.height = `${element.scrollHeight}px`
  }
  const resetHeight = () => {
    if (chatInputRef?.current) {
      chatInputRef.current.style.height = '53px';
    }
}
  const dispatch: any = useAppDispatch()
  const handleSendMessage = async ({ requestId, uid, msg, lastSeen }: any) => {
    setShowEmojiPicker(false)
    if (msg.trim() !== "" && requestId) {
      const requestRef = doc(db, "requests", requestId)
      const id = nanoid()
      const patchResult = dispatch(
        chatApi.util.updateQueryData(
          "getMessages",
          { reqId: searchParams.get("requestId"), messageLimit: 40 },
          (draft) => {
            draft.push({
              id: id,
              senderMsg: msg,
              senderId: uid,
              date: "",
              read: "false",
              localOnly: true,
              failed: false,
            })
          },
        ),
      )
      try {
        setMsg("")

        const newMessageBatch = writeBatch(db)
        const messagesCollection = collection(db, "requests", requestId, "chat")
        const newMessageRef = doc(messagesCollection)

        newMessageBatch.set(newMessageRef, {
          id: id,
          senderMsg: msg,
          senderId: uid,
          date: serverTimestamp(),
          read: !!seekerToken,
          readSource: !!providerToken,
          messageId: newMessageRef.id,
        })
        seekerToken
          ? newMessageBatch.update(requestRef, {
              id: id,
              lastMessage: msg,
              lastMessageTime: serverTimestamp(),
              unread: "true",
              unreadSeekerCount: arrayUnion(newMessageRef.id),
            })
          : newMessageBatch.update(requestRef, {
              id: id,
              lastMessage: msg,
              lastMessageTime: serverTimestamp(),
              unreadSource: "true",
              unreadSourceCount: arrayUnion(newMessageRef.id),
            })

        await newMessageBatch.commit()
        handleScrollToBottom()
        resetHeight()
      } catch (e) {
        patchResult.undo()
        dispatch(
          chatApi.util.updateQueryData(
            "getMessages",
            { reqId: searchParams.get("requestId"), messageLimit: 40 },
            (draft) => {
              draft.push({
                id: id,
                senderMsg: msg,
                senderId: uid,
                date: "",
                read: "false",
                localOnly: true,
                failed: true,
              })
            },
          ),
        )
        setMsg("")

        console.error(e)
      }
    }
  }
  const handleKeyPress = (event: any) => {
    setShowEmojiPicker(false)
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault()
      if (!!searchParams.get("requestId"))
        handleSendMessage({
          requestId: searchParams.get("requestId") || "",
          uid,
          msg,
        })
    }
  }
  function handleBackNav() {
    setShowEmojiPicker(false)
    searchParams.delete("requestId")
    setSearchParams(searchParams)
  }
  useEffect
  const handleChange = (e: any) => {
    handleChange1(e)
    setMsg(e.target.value)
  }
  function handleEmojiChange (value: any) {
    setMsg((prev)=>{return prev+value})
  }
  useEffect(() => {
    chatInputRef?.current?.focus()
    setMsg("")
  }, [searchParams.get("requestId")])
  const { seekerPic, sessionFor, preference } = useAppSelector(requestData)
  const dataa = useAppSelector(requestData)
  const navigate = useNavigate()
  // TSX RETUEN STATEMENT
  return (
    <>
    {searchParams.get("requestId") ? (
          <ChatScreen selectedChatInfo={selectedChatInfo} 
          chatRef={chatRef} 
          chatboxBottomDivRef={chatboxBottomDivRef} 
          msg={msg} 
          uid={uid} 
          handleSendMessage={handleSendMessage} 
          handleChange={handleChange} 
          handleKeyPress={handleKeyPress} 
          handleBackNav={handleBackNav}
          handleEmojiChange={handleEmojiChange}
          showEmojiPicker={showEmojiPicker} setShowEmojiPicker={setShowEmojiPicker} />
    ) : (
        <div className={`${styles["chat-area"]} d-lg-block flex-1 p-2 p-lg-3 pt-4 mb-2 bg-white position-relative ${styles["rounded-xl"]} ${searchParams.get("requestId") ? '':'d-none d-lg-block'}`}><UnlockPremiumChat /></div>
    )}
    </>
  );
}

export default ChatArea
