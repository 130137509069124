import React, { lazy, Suspense } from 'react'
import CustomBackNavButton from '../../buttons/custumBackNavButton/CustomBackNavButton'
import SpinnerSecondary from '../../spinner/SpinnerSecondary'
const TermsModalContent = lazy(() => import('../../termsModalContent/TermsModalContent'))
import { refundPolicyContent } from '../../../assets/content/refundPolicyContent'

const RefundpolicyPage = () => {
  return (
    <div className='m-3 m-md-5 pt-5'>
    <CustomBackNavButton class_name=''/>
    <Suspense fallback={<SpinnerSecondary/>}>
    <TermsModalContent class_name={'m-1'} content={refundPolicyContent} />
    </Suspense>
    </div>
  )
}

export default RefundpolicyPage