import React, { useState } from 'react'
import RatingStars from '../../buttons/RatingStars'
import RenderProfile from '../../../utils/RenderProfilePic/RenderProfilePic'

const ReviewDisplayCard = ({item, index}: any) => {
  const [isExpand, setIsExpand] = useState<boolean>(false)
  return (
    <>
        <div
            className="review-card-profile mb-3"
            key={index}
            onClick={()=>{setIsExpand(!isExpand)}}
          >
              <div className="d-flex justify-content-between align-items-center mb-4">
                  <div className="d-flex align-items-center">
                      {/* <img
                          src={reviewimage}
                          className="me-2 review-img"
                      /> */}
                      <RenderProfile customClass='me-2 review-img' 
                      senderNameValue={item?.seekerId?.firstName +
                        " " +
                        item?.seekerId?.lastName} 
                        senderProfilePic={item?.seekerId.profilePic}/>
                      <p className="review-name lh-1">
                          {item?.seekerId?.firstName +
                              " " +
                              item?.seekerId?.lastName}
                      </p>
                  </div>
                  <span className="review-given d-flex align-items-center">
                      <RatingStars rating={item?.rating} />
                  </span>
              </div>
              <p className={`mb-1 ${isExpand ? 'review-text': 'text-review--collapsed' }`}>
                  {item?.message}
              </p>
        </div></>
  )
}

export default ReviewDisplayCard