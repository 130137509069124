import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { GoDotFill } from 'react-icons/go'
import { useNavigate } from 'react-router-dom'
import RenderProfile from '../../../../utils/RenderProfilePic/RenderProfilePic'
import { useAppSelector } from '../../../../app/hooks'

const LockedNotification = ({ index, notification }: any) => {
    const navigate = useNavigate()
    const { seekerToken, providerToken } = useAppSelector((state)=> state.auth)
    return (
            <Container  className={`notification-card pt-2 px-4 pb-2 cursor-pointer ${
                notification.data.isSeen ? "" : "unseen-bg"
              }`}
              onClick={(e) => { navigate(`${seekerToken? '/seeker-dashboard':'/source-dashboard'}/my-requests?requestStatus=Pending`) }}>
                     {/* onMouseEnter={(e)=>{_isHover(true)}}
                     onMouseLeave={(e)=>{_isHover(false)}}  */}
                         {/* {isHover&&
            <div className=' position-absolute z-3 d-flex  justify-content-center align-items-center'>
            <Button  onClick={(e) => { navigate(`my-requests?requestStatus=Pending`) }}>Buy Premium to unlock</Button>
            </div>
            
            } */}
                <Row>
                    <Col
                        xs={2}
                        md={1}
                        className="d-flex justify-content-center align-items-start notification-card--blur "
                        f={{ width: "3rem" }}
                    >
                    <RenderProfile senderNameValue={notification?.data?.senderName} 
        senderProfilePic={notification.data.category == "Payment Success" 
        ?'https://img.freepik.com/premium-psd/credit-card-with-bell-notification-icon-payment-notification-payment-reminder-notification-concept_68971-1820.jpg'
        :notification?.data.senderProfilePic} 
                 />
                    </Col>
                    <Col>
                        <Row className='noti-title ms-1'>
                            {/* Someone have sent you an request  */}
                            You have got a class Request
                        </Row>
                        <div className="d-flex text-black-50 justify-content-start align-items-center gap-2 noti-date">
                            <p className="mb-0">You need to be premium</p>
                            <GoDotFill className="dot-img mt-1" />
                            <p className="mb-0">to check</p>
                        </div>
                    </Col>
                </Row>
            </Container>
    )
}

export default LockedNotification