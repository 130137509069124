/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Image, Button, Badge } from "react-bootstrap"
import settingIcon from "../../../../assets/img/setting-icon.svg"
import dummy from "../../../../assets/images/dummy-profile-pic.jpg"
import dot from "../../../../assets/img/dot.png"
import noneNotification from "../../../../assets/img/notificationNone.png"
import noneRequest from "../../../../assets/img/noneRequestIcn.png"

import "./Notification.css"
// import { renderProfile } from '../../../../utils/RenderProfilePic/RenderProfilePic';
import { GoDotFill } from "react-icons/go"
import {
  allNotifications,
  archivedNotifications,
  unseenNotifications,
  readRequests,
} from "../../../../features/notification/notificationSlice"
import { useAppSelector } from "../../../../app/hooks"
import { toast } from "react-toastify"
import NotificationCard from "./NotificationCard"
import { Link, useNavigate } from "react-router-dom"
import { handleRedirect } from "../../../../features/notification/handleRedirects"
import {
  useDeleteNotificationMutation,
  useMarkAsIgnoredMutation,
  useMarkAsREadedMutation,
} from "../../../../features/notification/notificationApi"
import { useAcceptDeleteRequestMutation } from "../../../../features/featProviderDashboard/FeatProviderApi"

interface props {
  closeDropDown?: any
  closeMobileDropdown?: any
}

function NotificationDropDown({ closeDropDown, closeMobileDropdown }: props) {
  const auth = useAppSelector((state: any) => state.auth)
  const notifications = useAppSelector(allNotifications)
  const [currentUser, setUserRole] = useState<string | null>(null)
  const [allNotification, setAllNotifications] = useState<any[]>([])
  const [requestss, _requestsCount] = useState<any[]>([])
  const [unseens, _unseenCount] = useState<any[]>([])
  const [selectedOption, _selectedOption] = useState<string>("all")
  useEffect(() => {
    setUserRole(auth?.user?.role)
  }, [auth])
  function setFilteredLists(data: Object[]) {
    var unseenNotifications: Object[] = []
    var requests: Object[] = []
    data?.map((notification: any) => {
      if (notification.data.category?.indexOf("Request") !== -1)
        requests.push(notification)
      if (!notification.data.isSeen) unseenNotifications.push(notification)
    })
    _requestsCount(requests.slice(0, 5))
    _unseenCount(unseenNotifications.slice(0, 5))
  }

  useEffect(() => {
    if (notifications) {
      if (notifications?.length > 0) {
        setAllNotifications(notifications.slice(0, 5))
        setFilteredLists(notifications)
      }
      // if (notifications?.data) {
      //   setAllNotifications(notifications.data.slice(0, 5))
      //   setFilteredLists(notifications.data)
      // } else if (notifications?.length > 0) {
      //   setAllNotifications(notifications.slice(0, 5))
      //   setFilteredLists(notifications)
      // }
    }
  }, [notifications])
  // useEffect(()=>{
  //   if(notSeenNotification && notSeenNotification?.length)_unseenCount(notSeenNotification.slice(0, 5) || [])
  // },[notSeenNotification])
  // useEffect(()=>{
  //   if(requestsNotifications && requestsNotifications?.length)_requestsCount(requestsNotifications.slice(0, 5) || [])
  // },[requestsNotifications])

  const [makedasRead] = useMarkAsREadedMutation()
  const [markAsIgnore] = useMarkAsIgnoredMutation()
  const [acceptRequest, { data: reqData, isSuccess: success }] =
    useAcceptDeleteRequestMutation()
  const [deleteData] = useDeleteNotificationMutation()
  function markAsReadedFunction(id: string) {
    makedasRead({ uid: auth?.user?._id, id })
  }
  function markAsIgnoredFunction(id: string) {
    markAsIgnore({ uid: auth?.user?._id, id })
  }
  function deleteNotification(id: string) {
    deleteData({ uid: auth?.user?._id, id })
  }
  const navigate = useNavigate()
  const acceptNotification = (index: number, id: string, navId: string) => {
    acceptRequest({
      requestId: navId,
      action: "Accept",
    })
      .unwrap()
      .then((res: any) => {
        toast.success(res?.message)
        deleteNotification(id)
      })
      .catch((error: any) => {
        toast.dismiss()
        toast.error(error?.data?.message)
      })
  }

  const handleOnClick = (
    index: number,
    id: string,
    catagory: string,
    navigationId?: string,
  ) => {
    markAsReadedFunction(id)
    const { path, state } = handleRedirect(
      catagory,
      id,
      auth?.role,
      navigationId,
    )
    if (closeMobileDropdown) closeMobileDropdown()
    navigate(path, { state: state, replace: true })
    closeDropDown()
  }

  const markAllAsReadNotification = () => {
    unseens.map((notification: any) => {
      if (!notification.data.isSeen) markAsReadedFunction(notification.id)
    })
  }

  const ignoreNotification = (index: number, id: string) => {
    markAsIgnoredFunction(id)
  }
  return (
    <Container className="notification-container-2 overflow-hidden d-flex flex-column p-0">
      <Row className="d-flex justify-content-between p-2">
        <Col as="p" className="m-0 fw-bold">
          {/* <h2 className="heading-2 text-center text-lg-start"> */}
          Notification
          {/* </h2> */}
        </Col>
        {unseens.length > 0 && (
          <Col
            onClick={markAllAsReadNotification}
            xs="auto"
            className="fw-normal cursor-pointer text-end see-all-text text-decoration-none"
          >
            Mark all as read
          </Col>
        )}
      </Row>
      <Row className="d-flex align-items-center gap-0 notification-tabs ">
        <Col
          xs={3}
          className={`d-flex justify-content-center align-items-center m-2 gap-2  ${
            selectedOption == "all" && "active"
          }`}
          onClick={() => {
            _selectedOption("all")
          }}
        >
          All
          {selectedOption == "all" && allNotification?.length > 0 && (
            <div className="digit-box">
              {allNotification?.length < 1 ? "" : allNotification.length}
            </div>
          )}
        </Col>
        <Col
          className={`d-flex justify-content-center align-items-center m-0 gap-2 ${
            selectedOption == "unseen" && "active"
          }`}
          onClick={() => {
            _selectedOption("unseen")
          }}
        >
          New
          {selectedOption == "unseen" && unseens?.length > 0 && (
            <div className="digit-box">
              {unseens?.length < 1 ? "" : unseens.length}
            </div>
          )}
        </Col>
        <Col
          className={`d-flex justify-content-center align-items-center m-0 gap-2 ${
            selectedOption == "requests" && "active"
          }`}
          onClick={() => {
            _selectedOption("requests")
          }}
        >
          Requests
          {selectedOption == "requests" && requestss?.length > 0 && (
            <div className="digit-box ">
              {requestss?.length < 1 ? "" : requestss.length}
            </div>
          )}
        </Col>
      </Row>

      <Container className="d-flex flex-column  gap-0 h-100 overflow-y-auto overflow-x-hidden custom-scrollbar my-1">
        {selectedOption == "all" &&
          allNotification &&
          allNotification.map((notification: any, index: number) => {
            return (
              <>
                <NotificationCard
                  notification={notification}
                  index={notification.id}
                  handleOnClick={handleOnClick}
                  acceptNotification={acceptNotification}
                  ignoreNotification={ignoreNotification}
                  isPremium={auth?.user?.isPremium}
                />
              </>
            )
          })}
        {selectedOption == "unseen" &&
          unseens.map((notification: any, index: number) => {
            return (
              <>
                <NotificationCard
                  notification={notification}
                  index={notification.id}
                  handleOnClick={handleOnClick}
                  acceptNotification={acceptNotification}
                  ignoreNotification={ignoreNotification}
                  isPremium={auth?.user?.isPremium}
                />
              </>
            )
          })}
        {selectedOption == "requests" &&
          requestss.map((notification: any, index: number) => {
            return (
              <>
                <NotificationCard
                  notification={notification}
                  index={notification.id}
                  handleOnClick={handleOnClick}
                  acceptNotification={acceptNotification}
                  ignoreNotification={ignoreNotification}
                  isPremium={auth?.user?.isPremium}
                />
              </>
            )
          })}
        {selectedOption == "all" && allNotification.length < 1 && (
          <Row className=" text-center justify-content-center align-items-center py-3">
            {" "}
            <div className="d-flex flex-column align-items-center justify-content-center mt-5">
              <img src={noneNotification} alt="" className="w-50 " />
              <div> Nothing to show here </div>
            </div>
          </Row>
        )}
        {selectedOption == "unseen" && unseens.length < 1 && (
          <Row className=" text-center justify-content-center align-items-center py-3">
            <div className="d-flex flex-column align-items-center justify-content-center mt-5">
              <img src={noneNotification} alt="" className="w-50 " />
              <div> Nothing to show here </div>
            </div>{" "}
          </Row>
        )}
        {selectedOption == "requests" && requestss.length < 1 && (
          <Row className=" text-center justify-content-center align-items-center py-3">
            <div className="d-flex flex-column align-items-center justify-content-center mt-5">
              <img src={noneRequest} alt="" className="w-50 " />
              <div> Nothing to show here </div>
            </div>
          </Row>
        )}
      </Container>
      <Link
        className=" see-all-btn mx-auto"
        onClick={() => {
          closeDropDown()
        }}
        to={
          currentUser == "Provider"
            ? "/source-dashboard/my-notifications"
            : "/seeker-dashboard/my-notifications"
        }
      >
        See all
      </Link>
    </Container>
  )
}

export default NotificationDropDown
