import { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
import logo from "../../../../assets/img/logo.png"
import googleimage from "../../../../assets/img/google-icon.png"
import facebookimage from "../../../../assets/img/facebook-icon.png"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
import "./providerSignUp.css"
import { useProviderRegisterMutation } from "../../../../features/Auth/authApi"
import { useNavigate } from "react-router-dom"
import { FormInput } from "../../../form"
import { toast } from "react-toastify"
import { providerLoginSchema } from "../../../../utils/yupSchema/Schema"
import { useProviderloginMutation } from "../../../../features/featProviderDashboard/FeatProviderApi"

type Props = {}
interface SignUpUser {
  firstName: string
  email: string
  lastName: string
  password: string
}

const Providerlogin = ({}: Props) => {
  const navigate = useNavigate()
  const [loginForm, { isError, error, data }] = useProviderloginMutation()
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<SignUpUser>({
    resolver: providerLoginSchema as any,
  })

  const submit = (values: SignUpUser) => {
    const payload = {
      email: values.email,
      password: values.password,
    }
    loginForm(payload)
  }
  useEffect(() => {
    if (data?.message) {
      toast.success(data?.message)
      navigate("/")
    } else if ((error as any)?.data?.message) {
      toast.error((error as any)?.data?.message)
    }
  }, [data, error])

  return (
    <>
      <section className="signup-tutor-section">
        <div className="container-fluid padding-right-0">
          <div className="inner-tutor">
            <div className="row justify-content-end">
              <div className="col-xxl-4 col-lg-5 py-5 h-100">
                <div className="signup-card">
                  <h3 className="signup-heading mb-4">Login</h3>
                  <form onSubmit={handleSubmit(submit)}>
                    <div className="mb-4">
                      <FormInput
                        name="email"
                        title="Email ID"
                        placeholder="Plase enter your email"
                        register={register}
                        errors={errors}
                      />
                    </div>
                    <div className="mb-4">
                      <FormInput
                        name="password"
                        title="Password"
                        type="password"
                        placeholder="Please enter your password"
                        register={register}
                        errors={errors}
                      />
                    </div>

                    <div className="text-end">
                      <button className="schedule-btn2 ms-auto">Sign In</button>
                    </div>
                  </form>
                  <div className="divider my-5">
                    <span>Or</span>
                  </div>
                  <div className="text-center">
                    <a
                      href="#"
                      className="loginwith-button google-btn d-block mb-4"
                    >
                      <img src={googleimage} />
                      Sign up with with Google
                    </a>
                    <a className="loginwith-button facebook-btn d-block">
                      <img src={facebookimage} />
                      Sign up with Facebook
                    </a>
                    <button
                      className="schedule-btn2 mt-4"
                      onClick={() => navigate("/source-signup")}
                    >
                      Sign up
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-1" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Providerlogin
