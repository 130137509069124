import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { collection, doc, getDoc, getDocs, query, onSnapshot } from 'firebase/firestore';
import { db } from '../../app/firebase';
import { getCounts } from '../../utils/notificationUtils/notificationHandler';

export const classCountsApi = createApi({
  reducerPath: 'classCountsApi',
  baseQuery: fakeBaseQuery(),
  endpoints: (builder) => ({
    getCounts: builder.query({
      queryFn: async (arg: { userID: string; }, api: any, extraOptions: any, baseQuery: any) => {
        const docRef = doc(db, "users", arg.userID);
        try {
          const docSnap = await getDoc(docRef);
          const initialData = docSnap.data()?.totalRequests;
          return { data: getCounts(initialData) };
        } catch (error: any) {
          return { error: { message: error.message, data: { acceptCount: 0, pendingCount: 0 } } };
        }
      },
      onCacheEntryAdded: async (arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
        const docRef = doc(db, "users", arg.userID);

        try {
          await cacheDataLoaded;

          const unsubscribe = onSnapshot(docRef, (docSnap) => {
            if (docSnap.exists()) {
              const updatedData = docSnap.data()?.totalRequests;
              updateCachedData((draft) => {
                if (draft) {
                  Object.assign(draft, getCounts(updatedData));
                }
              });
            }
          });

          await cacheEntryRemoved;
          unsubscribe();
        } catch (error) {
          console.error("Failed in onCacheEntryAdded lifecycle:", error);
        }
      },
    }),
  }),
});

export const { useGetCountsQuery } = classCountsApi;
