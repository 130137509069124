import { useEffect, useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import mailicon from "../../../../assets/img/mail-icon.svg";
import backicon from "../../../../assets/img/back-icon.svg";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import "../authComponent.css";
import FormInput from "../../../form/FormInput";
import { useAppSelector } from "../../../../app/hooks";
import { useAppDispatch } from "../../../../app/store";
import { MODALNAME } from "../../../../constants";
import {
  closeModal,
  setModalName,
} from "../../../../features/Auth/ModalHandler/modalSlice";
import { useForgetPasswordMutation } from "../../../../features/Auth/authApi";
import { useProviderforgetPasswordMutation } from "../../../../features/Auth/authApi";
import { forgotPasswordWithEmailSchema, forgotPasswordWithPhoneSchema } from "../../../../utils/yupSchema/Schema";
import { setForgotPasswordEmail } from "../../../../features/Auth/authSlice";
import { IoPhonePortraitOutline } from "react-icons/io5";

interface CardProps {}

enum modes {
  email = "email",
  phone = "phone",
}

const Forgot = ({}: CardProps) => {
  const modalName = useAppSelector((state: any) => state.authModal.modalName);
  const [modeOfOperation, setModeOfOperation] = useState<modes>(modes.email);
  const [userModalName, setUserModalName] = useState<string>();
  const dispatch = useAppDispatch();

  const [forgetSeekerPassword, { isError, error, data, isLoading }] =
    useForgetPasswordMutation();
  const [
    forgetProviderPassword,
    {
      isError: providerisError,
      error: providerError,
      data: providerData,
      isLoading: forgetProviderloading,
    },
  ] = useProviderforgetPasswordMutation();

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: modeOfOperation === modes.email ? yupResolver(forgotPasswordWithEmailSchema) : yupResolver(forgotPasswordWithPhoneSchema) ,
  });

  const onSubmit = async (values: any) => {
    toast.dismiss();
    const payload =
      modeOfOperation === modes.email
        ? { email: values.email }
        : { email: values.mobileNo };
    if (modalName === MODALNAME.FORGET_PASSWORD_TUTOR || modalName === MODALNAME.FORGET_PASSWORD_AFTER_LOGIN_TUTOR) {
      toast.dismiss()
      setUserModalName("Provider");
      const res = await forgetProviderPassword(payload);
      if ((res as any)?.data?.success) {
        dispatch(setForgotPasswordEmail(modeOfOperation === modes.email ? values.email : values.mobileNo));
        toast.success("Request Successful");
        reset();
        dispatch(setModalName(MODALNAME.RESET_PASSWORD_OTP_PROVIDER));
      } else if ((res as any)?.data?.message) {
        toast.error((res as any).data.message);
      }
    } else if (modalName === MODALNAME.FORGET_PASSWORD_SEEKER || modalName === MODALNAME.FORGET_PASSWORD_AFTER_LOGIN_SEEKER) {
      setUserModalName("Seeker");
      const res = await forgetSeekerPassword(payload);
      if ((res as any)?.data?.success) {
        dispatch(setForgotPasswordEmail(modeOfOperation === modes.email ? values.email : values.mobileNo));
        toast.success("Request Successful");
        reset();
        dispatch(setModalName(MODALNAME.RESET_PASSWORD_OTP_SEEKER));
      } else if ((res as any)?.data?.message) {
        toast.error((res as any).data.message);
      }
    }
  };

  const handleCloseModal = () => {
    reset();
    dispatch(closeModal());
  };

  const handleBackIcon = () => {
    reset();
    dispatch(setModalName(MODALNAME.LOGIN));
  };

  useEffect(() => {
    if (providerError && userModalName === "Provider") {
      toast.error((providerError as any)?.data?.message);
    } else if (error && userModalName === "Seeker") {
      toast.error((error as any)?.data?.message);
    }
  }, [error, providerError, userModalName]);

  const toggleMode = () => {
    reset();
    setModeOfOperation(
      modeOfOperation === modes.email ? modes.phone : modes.email
    );
  };

  return (
    <>
      <Modal
        animation
        centered
        show={
          modalName === MODALNAME.FORGET_PASSWORD_TUTOR ||
          modalName === MODALNAME.FORGET_PASSWORD_SEEKER ||
          modalName === MODALNAME.FORGET_PASSWORD_AFTER_LOGIN_SEEKER ||
          modalName === MODALNAME.FORGET_PASSWORD_AFTER_LOGIN_TUTOR
        }
        className="login-modal"
        size="lg"
        aria-labelledby="exampleModalToggleLabel"
      >
        <Modal.Dialog className="w-100 my-0 ms-0">
          <div className="pb-5 popup-content">
            <Modal.Header className="popup-header border-0">
              <Modal.Title
                className="popup-heading d-flex"
                id="exampleModalToggleLabel"
              >
                {!(modalName === MODALNAME.FORGET_PASSWORD_AFTER_LOGIN_SEEKER || modalName === MODALNAME.FORGET_PASSWORD_AFTER_LOGIN_TUTOR) &&
                <span
                  className="back-link px-0 d-inline-flex justify-content-center"
                  onClick={handleBackIcon}
                >
                  <img src={backicon} alt="Back Icon" />
                </span>}
              </Modal.Title>
              <Button
                variant="secondary"
                onClick={handleCloseModal}
                className="btn-close"
              ></Button>
            </Modal.Header>
            <Modal.Body className="popup-body login-tab-wrapper border-0">
              <h3 className="popup-title">
                Forgot Password{" "}
                {modalName === MODALNAME.FORGET_PASSWORD_SEEKER
                  ? "as Seeker"
                  : "as Source"}
              </h3>
              {modeOfOperation === modes.email ? (
                <p className="mb-4 font-15" style={{ textAlign: "center" }}>
                  Enter the email address associated with your account
                </p>
              ) : (
                <p className="mb-4 font-15" style={{ textAlign: "center" }}>
                  Enter your mobile number associated with this account
                </p>
              )}
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="popup-field-wrapper mb-3">
                  {modeOfOperation === modes.email ? (
                    <img src={mailicon} alt="Mail Icon" />
                  ) : (
                    <IoPhonePortraitOutline color="#727272" size={25} />
                  )}

                  <FormInput
                    name={modeOfOperation === modes.email ? "email": 'mobileNo'}
                    type={modeOfOperation === modes.email ? "email" : "number"}
                    placeholder={
                      modeOfOperation === modes.email
                        ? "Enter E-mail Address"
                        : "Enter phone number"
                    }
                    register={register}
                    className="popup-field w-100"
                  />
                </div>
                <span className="error text-danger mb-2">
                  {modeOfOperation === modes.email
                    ? errors?.email?.message && errors?.email?.message?.toString()
                    : errors?.mobileNo?.message && errors?.mobileNo?.message?.toString()}
                </span>
                <Button
                  type="submit"
                  className="common-btn auth-btn mb-2"
                  disabled={isLoading || forgetProviderloading}
                >
                  {isLoading || forgetProviderloading ? <Spinner className="spinner-small" /> : "Send"}
                </Button>
              </Form>
              <div
                onClick={toggleMode}
                className="d-flex justify-content-center"
                style={{ cursor: "pointer" }}
              >
                <span className="primary-text">
                  Try with{" "}
                  {modeOfOperation === modes.email ? "phone number" : "email"}
                </span>
              </div>
            </Modal.Body>
          </div>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default Forgot;
