import { useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap"
import { AiOutlineBell } from "react-icons/ai"
import { MdDashboard } from "react-icons/md"
import { CgLoadbarSound } from "react-icons/cg"
import { AiOutlineComment } from "react-icons/ai"
import { TbGraph } from "react-icons/tb"
import { AiTwotoneSetting } from "react-icons/ai"
import headerimage from "../../../../assets/img/header-image.png"
import dashboardicon from "../../../assets/img/dashboard-icon.svg"
import dashboardicongreen from "../../../assets/img/dashboard-icon-green.svg"
import charticon from "../../../assets/img/chart.svg"
import charticongreen from "../../../assets/img/chart-green.svg"
import requesticon from "../../../assets/img/request-icon.svg"
import requesticongreen from "../../../assets/img/request-icon-green.svg"
import evalutionicon from "../../../assets/img/stariconwhite.svg"
import evalutionicongreen from "../../../assets/img/evolution-green.svg"
import settingicon from "../../../assets/img/settingicon.svg"
import settingicongreen from "../../../assets/img/settingicongreen.svg"
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import "./DashNav.css"
import { isLaunched, menuItem, USERTYPE } from "../../../constants"
import lockImg from "/src/assets/img/lock-icon.png"
import { GoDotFill } from "react-icons/go"
import { useAppSelector } from "../../../app/hooks"
interface headerProps {
  userType: USERTYPE
}
const DashNav = ({ userType }: headerProps) => {
  // const [userTypes, setTypes] = useState("")
  const [activeTab, setActiveTab] = useState<any>(
    userType === USERTYPE.SEEKER ? "/seeker-dashboard" : "/source-dashboard",
  )
  const { unreadPendingRequests, unreadLiveRequests } = useAppSelector((state) => state.ChatSlice,)

  const location = useLocation()
  const [searchParams]=useSearchParams()
  const handleTabClick = (targetRoute: any) => {
    setActiveTab(targetRoute)
    navigate(targetRoute)
  }
  useEffect(() => {
    setActiveTab(location.pathname)
  }, [location.pathname])
  const navigate = useNavigate()
  return (
    <>
      <header className={`${searchParams.get('requestId') !== null ? 'd-none' : 'd-block'}`}>
        <Navbar fixed="top" className="custom-header dashboard-header mx-lg-5  mx-3 rounded-4 d-lg-none">
          <div className="container-fluid">
            {/* <Navbar.Brand>Vedic Hom</Navbar.Brand>
                        <div className="d-none d-flex justify-content-between">
                            <Navbar.Toggle aria-controls="navbarTogglerDemo01" />
                            <Nav.Link className="notif-link me-4">
                                <span className="notif-dot"></span>
                                <i className="fa-regular fa-bell"></i>
                            </Nav.Link>
                            <a href="#" className="d-block mx-lg-0 mx-auto user-link"></a>
                        </div> */}
            <Navbar.Collapse id="navbarTogglerDemo01">
              <Nav className="ms-0 mb-lg-0 nav-listing gap-md-5 gap-2 ">
                <Nav.Item id={menuItem.root+'-mobile'}>
                  <Nav.Link
                    className={`dash-link text-center ${
                      activeTab === "/seeker-dashboard" ||
                      activeTab === "/source-dashboard"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      handleTabClick(
                        userType === USERTYPE.SEEKER
                          ? "/seeker-dashboard"
                          : "/source-dashboard",
                      )
                    }
                  >
                    <img src={dashboardicon} className="me-md-2" />
                    <img src={dashboardicongreen} className="me-md-3 d-none" />

                    <span className="d-md-inline-block d-none">Dashboard</span>
                    {!isLaunched&&<img className="nav-collapse-lock" src={lockImg} alt="" />}
                  </Nav.Link>
                </Nav.Item>
                {!(userType === USERTYPE.SEEKER) && (
                  <Nav.Item id={menuItem.myAds+'-mobile'}>
                    <Nav.Link
                      className={`dash-link text-center ${
                        activeTab === "/source-dashboard/my-advt"
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        handleTabClick("/source-dashboard/my-advt")
                      }
                    >
                      <img src={charticon} className="me-md-3" />
                      <img src={charticongreen} className="me-md-3 d-none" />
                      <span className="d-md-inline-block d-none">My Ads</span>
                    </Nav.Link>
                  </Nav.Item>
                )}
                <Nav.Item id={menuItem.myRequests+'-mobile'}>
                  <Nav.Link
                    className={`dash-link text-center ${
                      activeTab === "/seeker-dashboard/my-requests" ||
                      activeTab === "/source-dashboard/my-requests"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      handleTabClick(
                        userType === USERTYPE.SEEKER
                          ? "/seeker-dashboard/my-requests?requestStatus=Accept"
                          : "/source-dashboard/my-requests?requestStatus=Accept",
                      )
                    }
                  >
                    <img src={requesticon} className="me-md-3" />
                    <img src={requesticongreen} className="me-md-3 d-none" />
                    { (unreadPendingRequests > 0 || unreadLiveRequests>0)  &&
                      <span className="chat-req-dot"><GoDotFill /></span>}
                    <span className="d-md-inline-block d-none">Requests</span>
                    {!isLaunched&&<img className="nav-collapse-lock" src={lockImg} alt="" />}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item id={menuItem.reviews+'-mobile'}>
                  <Nav.Link
                    className={`dash-link text-center ${
                      activeTab === "/seeker-dashboard/my-evaluations" ||
                      activeTab === "/source-dashboard/my-evaluations"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      handleTabClick(
                        userType === USERTYPE.SEEKER
                          ? "/seeker-dashboard/my-evaluations"
                          : "/source-dashboard/my-evaluations",
                      )
                    }
                  >
                    <img src={evalutionicon} className="me-md-3" />
                    <img src={evalutionicongreen} className="me-md-3 d-none" />
                    <span className="d-md-inline-block d-none">Reviews</span>
                    {!isLaunched&&<img className="nav-collapse-lock" src={lockImg} alt="" />}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item id={menuItem.myAccount+'-mobile'}>
                  <Nav.Link
                    className={`dash-link text-center ${
                      activeTab === "/seeker-dashboard/my-account" ||
                      activeTab === "/source-dashboard/my-account"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      handleTabClick(
                        userType === USERTYPE.SEEKER
                          ? "/seeker-dashboard/my-account"
                          : "/source-dashboard/my-account",
                      )
                    }
                  >
                    <img src={settingicon} className="me-md-3" />
                    <img src={settingicongreen} className="me-md-3 d-none" />
                    <span className="d-md-inline-block d-none">My Account</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              {/* <div className="d-lg-flex d-none">
                                <Nav.Link className="notif-link me-lg-4">
                                    <span className="notif-dot"></span>
                                    <AiOutlineBell />
                                </Nav.Link>
                                <a href="#" className="d-block mx-lg-0 mx-auto user-link"><img src={headerimage} /></a>
                            </div> */}
            </Navbar.Collapse>
          </div>
        </Navbar>
      </header>
    </>
  )
}

export default DashNav
